import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api, multipartApi } from '../../config/api';

export const revertAllL3User = createAction('REVERT_ALL_L3_User');
export const clearL3ListNotification = createAction(
  'CLEAR_L3List_NOTIFICATION'
);

export const clearL3ViewNotification = createAction(
  'CLEAR_L3View_NOTIFICATION'
);

export const clearL3ofL1ListNotification = createAction(
  'CLEAR_L3OFL1List_NOTIFICATION'
);

export const clearL3ofL2ListNotification = createAction(
  'CLEAR_L3OFL2List_NOTIFICATION'
);
const initial = {
  isCreateEmployeeL3Loading: false,
  isCreateEmployeeL3Success: false,
  isCreateEmployeeL3Failed: false,
  createEmployeeL3ErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isL2UsersListLoading: false,
  isL2UsersListSuccess: false,
  isL2UsersListFailed: false,
  l2UsersList: [],
  l2UsersListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  islistingL3Loading: false,
  islistingL3Success: false,
  islistingL3Failed: false,
  l3UsersData: {
    totalCount: 0,
    l3UserList: []
  },
  listingL3ErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  islistingL3ofL1Loading: false,
  islistingL3ofL1Success: false,
  islistingL3ofL1Failed: false,
  l3ofL1UsersData: {
    totalCount: 0,
    l3ofL1UserList: []
  },
  listingL3ofL1ErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  islistingL3ofL2Loading: false,
  islistingL3ofL2Success: false,
  islistingL3ofL2Failed: false,
  l3ofL2UsersData: {
    totalCount: 0,
    l3ofL2UserList: []
  },
  listingL3ofL2ErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isemployeeByL3IdLoading: false,
  isemployeeL3ByIdSuccess: false,
  isemployeeL3ByIdFailed: false,
  employeeL3ByIdData: {},
  isemployeeL3ByIdErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  }
};

export const saveEmployeeL3 = createAsyncThunk(
  'admin/saveEmployeeL3',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await multipartApi.post(
        `/admin/saveEmployeeL3`,
        payload
      );

      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchReportToL2 = createAsyncThunk(
  'admin/fetchReportToL2',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`/dropDown/l2`);

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchListingL3 = createAsyncThunk(
  'admin/listingL3',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post(`/admin/listingL3`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchListingL3ofL1 = createAsyncThunk(
  'admin/fetchListingL3ofL1',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post(`/admin/l1/listingL3`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchListingL3ofL2 = createAsyncThunk(
  'admin/fetchListingL3ofL2',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post(`/admin/l2/listingL3`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const employeeL3DetailsById = createAsyncThunk(
  'admin/employeeL3DetailsById',
  async (Id, { rejectWithValue }) => {
    try {
      const response = await api.get(`/admin/employeeL3DeatilsById?id=${Id}`);

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const EmployeeL3Slice = createSlice({
  name: 'l3users',
  initialState: initial,
  extraReducers: builder => {
    builder

      .addCase(revertAllL3User, state => {
        state.isCreateEmployeeL3Loading = false;
        state.isCreateEmployeeL3Success = false;
        state.isCreateEmployeeL3Failed = false;
        state.createEmployeeL3ErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(clearL3ofL1ListNotification, state => {
        (state.islistingL3ofL1Loading = false),
          (state.islistingL3ofL1Success = false),
          (state.l3ofL1UsersData = {
            totalCount: 0,
            l3ofL1UserList: []
          }),
          (state.islistingL3ofL1Failed = false),
          (state.listingL3ofL1ErrorContainer = {
            error: false,
            errorMessage: 'Internal Server Error',
            description: 'Something Went Wrong',
            statusCode: 0
          }),
          (state.isemployeeByL3IdLoading = false),
          (state.isemployeeL3ByIdSuccess = false),
          (state.isemployeeL3ByIdFailed = false),
          (state.employeeL3ByIdData = initial.employeeL3ByIdData),
          (state.isemployeeL3ByIdErrorContainer = {
            error: false,
            errorMessage: 'Internal Server Error',
            description: 'Something Went Wrong',
            statusCode: 0
          });
      })
      .addCase(clearL3ofL2ListNotification, state => {
        (state.islistingL3ofL2Loading = false),
          (state.islistingL3ofL2Success = false),
          (state.l3ofL2UsersData = {
            totalCount: 0,
            l3ofL2UserList: []
          }),
          (state.islistingL3ofL2Failed = false),
          (state.listingL3ofL2ErrorContainer = {
            error: false,
            errorMessage: 'Internal Server Error',
            description: 'Something Went Wrong',
            statusCode: 0
          }),
          (state.isemployeeByL3IdLoading = false),
          (state.isemployeeL3ByIdSuccess = false),
          (state.isemployeeL3ByIdFailed = false),
          (state.employeeL3ByIdData = initial.employeeL3ByIdData),
          (state.isemployeeL3ByIdErrorContainer = {
            error: false,
            errorMessage: 'Internal Server Error',
            description: 'Something Went Wrong',
            statusCode: 0
          });
      })
      .addCase(clearL3ListNotification, state => {
        (state.islistingL3Loading = false),
          (state.islistingL3Success = false),
          (state.l3UsersData = {
            totalCount: 0,
            l3UserList: []
          }),
          (state.islistingL3Failed = false),
          (state.listingL3ErrorContainer = {
            error: false,
            errorMessage: 'Internal Server Error',
            description: 'Something Went Wrong',
            statusCode: 0
          });
      })
      .addCase(clearL3ViewNotification, state => {
        (state.isemployeeByL3IdLoading = false),
          (state.isemployeeL3ByIdSuccess = false),
          (state.isemployeeL3ByIdFailed = false),
          (state.employeeL3ByIdData = initial.employeeL3ByIdData),
          (state.isemployeeL3ByIdErrorContainer = {
            error: false,
            errorMessage: 'Internal Server Error',
            description: 'Something Went Wrong',
            statusCode: 0
          });
      })

      .addCase(saveEmployeeL3.pending, state => {
        state.isCreateEmployeeL3Loading = true;
        state.isCreateEmployeeL3Success = false;
        state.isCreateEmployeeL3Failed = false;
        state.createEmployeeL3ErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(saveEmployeeL3.fulfilled, state => {
        state.isCreateEmployeeL3Loading = false;
        state.isCreateEmployeeL3Failed = false;
        state.isCreateEmployeeL3Success = true;
      })
      .addCase(saveEmployeeL3.rejected, (state, action) => {
        state.isCreateEmployeeL3Loading = false;
        state.isCreateEmployeeL3Success = false;
        state.isCreateEmployeeL3Failed = true;
        state.createEmployeeL3ErrorContainer = {
          ...state.createEmployeeL3ErrorContainer,
          ...action.payload
        };
      })

      .addCase(fetchReportToL2.pending, state => {
        state.isL2UsersListLoading = true;
        state.isL2UsersListSuccess = false;
        state.isL2UsersListFailed = false;
        state.l2UsersListErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(fetchReportToL2.fulfilled, (state, action) => {
        state.isL2UsersListLoading = false;
        state.isL2UsersListSuccess = true;
        state.l2UsersList = action.payload || '';
        state.isL2UsersListFailed = false;
      })

      .addCase(fetchReportToL2.rejected, (state, action) => {
        state.isL2UsersListLoading = false;
        state.isL2UsersListSuccess = false;
        state.isL2UsersListFailed = true;
        state.l2UsersList = initial.l2UsersList;

        state.l2UsersListErrorContainer = {
          ...state.l2UsersListErrorContainer,
          ...action.payload
        };
      })
      .addCase(fetchListingL3.pending, state => {
        state.islistingL3Loading = true;
        state.islistingL3Success = false;
        state.islistingL3Failed = false;
        state.listingL3ErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(fetchListingL3.fulfilled, (state, action) => {
        state.islistingL3Loading = false;
        state.islistingL3Success = true;
        state.l3UsersData = {
          totalCount: action.payload?.count,
          l3UserList: action.payload?.data
        };
        state.islistingL3Failed = false;
      })

      .addCase(fetchListingL3.rejected, (state, action) => {
        state.islistingL3Loading = false;
        state.islistingL3Success = false;
        state.islistingL3Failed = true;
        state.l3UsersData = initial.l3UsersData;
        state.listingL3ErrorContainer = {
          ...state.listingL3ErrorContainer,
          ...action.payload
        };
      })
      .addCase(fetchListingL3ofL1.pending, state => {
        state.islistingL3ofL1Loading = true;
        state.islistingL3ofL1Success = false;
        state.islistingL3ofL1Failed = false;
        state.listingL3ofL1ErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(fetchListingL3ofL1.fulfilled, (state, action) => {
        state.islistingL3ofL1Loading = false;
        state.islistingL3ofL1Success = true;
        state.l3ofL1UsersData = {
          totalCount: action.payload?.count,
          l3ofL1UserList: action.payload?.data
        };
        state.islistingL3ofL1Failed = false;
      })

      .addCase(fetchListingL3ofL1.rejected, (state, action) => {
        state.islistingL3ofL1Loading = false;
        state.islistingL3ofL1Success = false;
        state.islistingL3ofL1Failed = true;
        state.l3ofL1UsersData = initial.l3ofL1UsersData;
        state.listingL3ofL1ErrorContainer = {
          ...state.listingL3ofL1ErrorContainer,
          ...action.payload
        };
      })

      .addCase(fetchListingL3ofL2.pending, state => {
        state.islistingL3ofL2Loading = true;
        state.islistingL3ofL2Success = false;
        state.islistingL3ofL2Failed = false;
        state.listingL3ofL2ErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(fetchListingL3ofL2.fulfilled, (state, action) => {
        state.islistingL3ofL2Loading = false;
        state.islistingL3ofL2Success = true;
        state.l3ofL2UsersData = {
          totalCount: action.payload?.count,
          l3ofL2UserList: action.payload?.data
        };
        state.islistingL3ofL2Failed = false;
      })

      .addCase(fetchListingL3ofL2.rejected, (state, action) => {
        state.islistingL3ofL2Loading = false;
        state.islistingL3ofL2Success = false;
        state.islistingL3ofL2Failed = true;
        state.l3ofL2UsersData = initial.l3ofL2UsersData;
        state.listingL3ofL2ErrorContainer = {
          ...state.listingL3ofL2ErrorContainer,
          ...action.payload
        };
      })
      .addCase(employeeL3DetailsById.pending, state => {
        state.isemployeeByL3IdLoading = true;
        state.isemployeeL3ByIdSuccess = false;
        state.isemployeeL3ByIdFailed = false;
        state.isemployeeL3ByIdErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(employeeL3DetailsById.fulfilled, (state, action) => {
        state.isemployeeByL3IdLoading = false;
        state.isemployeeL3ByIdSuccess = true;
        state.employeeL3ByIdData = action.payload || '';
        state.isemployeeL3ByIdFailed = false;
      })

      .addCase(employeeL3DetailsById.rejected, (state, action) => {
        state.isemployeeByL3IdLoading = false;
        state.isemployeeL3ByIdSuccess = false;
        state.isemployeeL3ByIdFailed = true;
        state.employeeL3ByIdData = initial.employeeL3ByIdData;

        state.isemployeeL3ByIdErrorContainer = {
          ...state.isemployeeL3ByIdErrorContainer,
          ...action.payload
        };
      });
  }
});

export default EmployeeL3Slice.reducer;
