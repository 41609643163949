import { Grid, Skeleton } from '@mui/material';
import GradientCard from '../../../components/CustomCard';

function CountTiles({
  isLoading,
  totalApplications,
  creditApproved,
  creditRejected,
  creditReponed,
  creditPending
}) {
  return (
    <Grid container spacing={2} marginTop={1}>
      <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
        {isLoading ? (
          <Skeleton height={140} variant="sqaure" />
        ) : (
          <GradientCard
            color={['#403c64', '#403c64']}
            title1="TOTAL"
            title2="APPLICATIONS"
            count={totalApplications}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
        {isLoading ? (
          <Skeleton height={140} variant="sqaure" />
        ) : (
          <GradientCard
            color={['#60ac1c', '#60ac1c']}
            title1="CREDIT"
            title2="APPROVED"
            count={creditApproved}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
        {isLoading ? (
          <Skeleton height={140} variant="sqaure" />
        ) : (
          <GradientCard
            color={['#ff6c4c', '#ff6c4c']}
            title1="CREDIT"
            title2="REJECTED"
            count={creditRejected}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
        {isLoading ? (
          <Skeleton height={140} variant="sqaure" />
        ) : (
          <GradientCard
            color={['#38bca4', '#38bca4']}
            title1="CREDIT"
            title2="REOPEN"
            count={creditReponed}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
        {isLoading ? (
          <Skeleton height={140} variant="sqaure" />
        ) : (
          <GradientCard
            color={['#ffac1c', '#ffac1c']}
            title1="CREDIT"
            title2="PENDING"
            count={creditPending}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default CountTiles;
