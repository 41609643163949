import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography,
  DialogTitle,
  FormHelperText,
  Autocomplete,
  Checkbox,
  Divider,
  InputAdornment,
  ListItemText,
  IconButton,
  Tooltip
} from '@mui/material';
import { StyledGrid } from '../../../../../utils/styledLayout';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import {
  colorLightYellow,
  colorPrimaryBlue,
  colorWhite
} from '../../../../../config/theme';
import UploadIcon from '@mui/icons-material/Upload';
import { LoadingButton } from '@mui/lab';
import SearchIcon from '@mui/icons-material/Search';
import ImageADDIcon from '../../../../../assets/ImageADDIcon.svg';
import * as yup from 'yup';
import ModalDialog from '../../../../../components/ModalDialog';
import {
  capitalizeWords,
  createTitle,
  CustomCalendarIcon,
  enterOnlyAadhaarNumber,
  enterOnlyAlphabates,
  enterOnlyAlphabetsAndNumbers,
  enterOnlyEmail,
  enterOnlyNumbers,
  enterOnlyNumbersWithDecimal,
  enterOnlyPanNumber,
  formatDate,
  getFullName,
  handleError,
  isArrayNotEmpty,
  isImageExtensionAllowed,
  isValidAadhaarNumber,
  isValidEmail,
  isValidMobileNumber,
  isValidPanNumber,
  isValidPinCode
} from '../../../../../utils/utils';
import { Search, CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import CustomDataTable from '../../../../../components/CustomDataTable';
import EditImage from '../../../../../assets/editPen.svg';
import DeleteImage from '../../../../../assets/delete.svg';
import { useDispatch, useSelector } from 'react-redux';
import { UploadDocumentAllowedPDFTypes } from '../../../../../utils/constant';
import {
  fetchProducts,
  fetchStates
} from '../../../../../redux/reducers/EmployeeL1Slice';
import { NEW_L2_ADD_SUCCESS } from '../../../../../utils/messages';
import SnackBarBox from '../../../../../components/SnackBarBox';
import Loader from '../../../../../components/Loader';
import { styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import {
  clearL2City,
  fetchCityMaster,
  fetchReportToL1,
  revertAllL2User,
  saveEmployeeL2
} from '../../../../../redux/reducers/EmployeeL2Slice';
import { VariableSizeList, ListChildComponentProps } from 'react-window';

const formControl = {
  margin: '0.5rem auto'
};

const StyledPopper = styled(Popper)(({ theme }) => ({
  '& .MuiAutocomplete-listbox': {
    maxHeight: 'calc(35vh - 70px)',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.4em',
      height: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(0, 0, 0, 0.3)',
      borderRadius: '4px'
    }
  }
}));

const AddL2Users = () => {
  const [isShowAddInsModal, setIsShowAddInsModal] = useState(false);
  const [isIncentiveData, setIsIncentiveData] = useState([]);
  const [selectedValues, setSelectedValues] = useState(null);
  const [selectedStates, setSelectedStates] = useState([]);

  const [uploadedFileName, setUploadedFileName] = useState('');
  const [imageUploadError, setImageUploadError] = useState({
    error: false,
    errorMessage: ''
  });
  const [isEditing, setIsEditing] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);

  const { products, states } = useSelector(state => state.employeeL1);
  const {
    l1UsersList,
    cityList,
    isCreateEmployeeL2Loading,
    isCreateEmployeeL2Success,
    isCreateEmployeeL2Failed,
    createEmployeeL2ErrorContainer
  } = useSelector(state => state.employeeL2);

  const [filteredCities, setFilteredCities] = useState(cityList);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  useEffect(() => {
    document.title = createTitle('AddL2User');

    dispatch(revertAllL2User());
    dispatch(fetchProducts());
    dispatch(fetchReportToL1());
    dispatch(fetchStates());
    dispatch(clearL2City());
    // dispatch(fetchCityMaster());
  }, []);

  const handleEdit = row => {
    setCurrentRow(row);
    setIsEditing(true);
    setIsShowAddInsModal(true);
    addIncentiveFormik.setValues({
      productName: row.productName,
      percentange: row.percentange
    });
  };

  const handleCloseAddL1 = () => {
    formik.resetForm();
    navigate(-1);
  };

  const onCancelIncentiveHdlr = () => {
    setCurrentRow(null);
    setIsEditing(false);
    setIsShowAddInsModal(false);
    addIncentiveFormik.resetForm();
  };

  useEffect(() => {
    if (!isCreateEmployeeL2Loading && isCreateEmployeeL2Success) {
      setTimeout(() => {
        handleCloseAddL1();
        dispatch(revertAllL2User());
      }, 1500);
      return;
    }
    if (!isCreateEmployeeL2Loading && isCreateEmployeeL2Failed) {
      setTimeout(() => {
        dispatch(revertAllL2User());
        return;
      }, 1500);
      return;
    }
  }, [
    isCreateEmployeeL2Loading,
    isCreateEmployeeL2Success,
    isCreateEmployeeL2Failed
  ]);

  const handleDelete = id => {
    setIsIncentiveData(prevData =>
      prevData.filter(incentive => incentive.id !== id.id)
    );
  };

  // const debounce = (func, delay) => {
  //   let debounceTimer;
  //   return function (...args) {
  //     const context = this;
  //     clearTimeout(debounceTimer);
  //     debounceTimer = setTimeout(() => func.apply(context, args), delay);
  //   };
  // };

  // const handleInputChange = debounce((event, value) => {
  //   if (value.length > 2) {
  //     setFilteredCities(
  //       cityList.filter(city =>
  //         city.value.toLowerCase().includes(value.toLowerCase())
  //       )
  //     );
  //   } else {
  //     setFilteredCities([]);
  //   }
  // }, 300);

  const handleImageUpload = event => {
    const file = event.target.files[0];

    if (file) {
      const allowedTypes = UploadDocumentAllowedPDFTypes;
      const maxSizeMB = 5;
      const fileName = file.name;

      if (allowedTypes.includes(file.type)) {
        if (file.size / (1024 * 1024) <= maxSizeMB) {
          setUploadedFileName(fileName);
          setImageUploadError({ error: false, errorMessage: '' });
        } else {
          setImageUploadError({
            error: true,
            errorMessage: `File size exceeds the maximum allowed (${maxSizeMB} MB).`
          });
        }
      } else {
        setImageUploadError({
          error: true,
          errorMessage: 'Please upload a PDF file.'
        });
      }

      formik.setFieldValue('aggrementPath', file);
    }
  };
  const headersList = [
    {
      id: 'productName',
      label: 'Product Name',
      sortable: true,
      align: 'center',
      width: '45%'
    },
    {
      id: 'percentange',
      label: 'Incentive Percentage(%)',
      sortable: true,
      width: 40,
      align: 'center',
      render: row => (
        <div style={{ textAlign: 'center', width: '40%' }}>
          {row.percentange}
        </div>
      )
    },

    {
      id: 'action',
      label: 'Action',
      sortable: true,
      width: 30,
      align: 'center',
      render: row => {
        return (
          <>
            <Tooltip placement="top" title="Edit">
              <IconButton aria-label="edit" onClick={() => handleEdit(row)}>
                <img
                  src={EditImage}
                  alt="Edit"
                  style={{ width: '18px', height: '20px' }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip placement="top" title="Delete">
              <IconButton aria-label="delete" onClick={() => handleDelete(row)}>
                <img
                  src={DeleteImage}
                  alt="Delete"
                  style={{ width: '18px', height: '20px' }}
                />
              </IconButton>
            </Tooltip>
          </>
        );
      }
    }
  ];

  const handleOpenAddIncentive = () => {
    setIsShowAddInsModal(!isShowAddInsModal);
  };

  const validateFields = values => {
    const errors = {};

    const { accountNo, accHolderName, ifscCode } = values;

    const isAnyFieldEntered = !!(accountNo || accHolderName || ifscCode);
    const areAllFieldsEntered = !!(accountNo && accHolderName && ifscCode);

    if (isAnyFieldEntered && !areAllFieldsEntered) {
      errors.accountNo =
        'If any one of Account Number, Account Holder Name, or IFSC Code is entered, the other two must also be entered.';
      errors.accHolderName =
        'If any one of Account Number, Account Holder Name, or IFSC Code is entered, the other two must also be entered.';
      errors.ifscCode =
        'If any one of Account Number, Account Holder Name, or IFSC Code is entered, the other two must also be entered.';
    }

    return errors;
  };
  const validationSchema = yup.object().shape({
    levelName: yup.string().required('Name is required'),
    name: yup.string().required('Name is required'),
    mobileNo: yup
      .string()
      .test(
        'is-valid-mobile-number',
        'The mobile number should start with 6-9 and consist of exactly 10 digits.',
        value => isValidMobileNumber(value)
      )
      .required('Mobile Number is required'),
    email: yup
      .string()
      .test('is-valid-email', 'Please provide a valid email address.', value =>
        isValidEmail(value)
      )
      .required('Email ID is required'),
    pinCode: yup
      .string()
      .test('is-valid-pinCode', 'Please provide a valid Pin Code.', value =>
        isValidPinCode(value)
      )
      .required('Email ID is required'),
    city: yup
      .array()
      .of(
        yup.object().shape({
          key: yup.string().required(),
          value: yup.string().required()
        })
      )
      .min(1, 'Please select at least one city')
      .required('City is required'),
    accHolderName: yup.lazy(value =>
      value
        ? yup.string().min(3, 'Account name must be at least 3 characters')
        : yup.string()
    ),
    accountNo: yup.lazy(value =>
      value
        ? yup.string().matches(/^\d{8,}$/, 'Must be a valid account number')
        : yup.mixed()
    ),
    ifscCode: yup.lazy(value =>
      value
        ? yup
            .string()
            .matches(/^[A-Za-z]{4}\d{7}$/, 'Must be a valid IFSC code')
        : yup.mixed()
    ),
    aadhaarNumber: yup.lazy(value =>
      value
        ? yup.string().matches(/^\d{12}$/, 'Must be a valid Aadhaar number')
        : yup.mixed()
    ),
    panNo: yup.lazy(value =>
      value
        ? yup
            .string()
            .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, 'Must be a valid PAN number')
        : yup.mixed()
    ),
    aggrementPath: yup.mixed(),
    aggrementDate: yup
      .string()
      .nullable()
      .test(
        'is-within-range',
        'Date must be between 01-01-1900 and today',
        value => {
          if (!value) return true;
          const date = dayjs(value);

          const minDate = dayjs('1900-01-01');
          const maxDate = dayjs();

          if (
            date.isValid() &&
            date.isAfter(minDate) &&
            date.isBefore(maxDate)
          ) {
            return true;
          } else {
            return false;
          }
        }
      )
  });

  const sessionData = JSON.parse(
    sessionStorage.getItem('x-auth-token-admin-panel') || '{}'
  );
  const { userId, role } = sessionData;

  const formik = useFormik({
    initialValues: {
      levelName: '',
      name: '',
      city: [],
      email: '',
      mobileNo: '',
      pinCode: '',
      targetAmtOfInvestor: '',
      targetAmtOfBorrower: '',
      reportToL1Id: role === 'ROLE_L1' ? userId : '',
      accHolderName: '',
      accountNo: '',
      ifscCode: '',
      aadhaarNumber: '',
      panNo: '',
      aggrementPath: '',
      aggrementDate: null
    },
    validationSchema: validationSchema,
    validate: validateFields,

    onSubmit: values => {
      const formattedDate = values.aggrementDate
        ? dayjs(values.aggrementDate).format('DD-MM-YYYY')
        : null;
      const cities = values.city
        ? values.city?.map(city => {
            return city.value;
          })
        : null;

      const incentiveDetails = isIncentiveData.map(item => ({
        productName: item.productName,
        productKey: item.productKey,
        percentange: parseFloat(item.percentange)
      }));
      let submitPayload = {
        id: null,
        action: 'Save',
        levelName: values.levelName,
        name: values.name,
        city: cities,
        email: values.email,
        mobileNo: values.mobileNo,
        pinCode: values.pinCode,
        targetAmtOfInvestor: parseFloat(values.targetAmtOfInvestor),
        targetAmtOfBorrower: parseFloat(values.targetAmtOfBorrower),
        // reportToL1Id: values.reportToL1Id,
        reportToL1Id: role === 'ROLE_L1' ? userId : values.reportToL1Id,
        accHolderName: values.accHolderName,
        accountNo: values.accountNo,
        ifscCode: values.ifscCode,
        aadhaarNumber: values.aadhaarNumber,
        panNo: values.panNo,
        aggrementDate: formattedDate,
        incentiveDetails: incentiveDetails
      };
      // console.log('Form values:', submitPayload);
      const createFormData = new FormData();
      createFormData.append(
        'file',
        formik.values.aggrementPath ? formik.values.aggrementPath : null
      );
      createFormData.append('employeeL2info', JSON.stringify(submitPayload));
      dispatch(saveEmployeeL2(createFormData));
    }
  });

  const onSelectionChange = value => {
    setSelectedValues(value);
  };

  const addIncentiveFormik = useFormik({
    initialValues: {
      productName: '',
      percentange: '',
      productKey: ''
    },
    validationSchema: yup.object({
      productName: yup.string().required('productName Manager is required'),
      percentange: yup.string().required('Incentive % is required')
    }),
    onSubmit: values => {
      if (isEditing) {
        const updatedData = isIncentiveData.map(item =>
          item.id === currentRow.id ? { ...item, ...values } : item
        );
        setIsIncentiveData(updatedData);
        setIsEditing(false);
      } else {
        const newIncentive = {
          ...values,
          id: values.productKey
        };
        setIsIncentiveData(prevData => [...prevData, newIncentive]);
      }

      addIncentiveFormik.resetForm();
      setIsShowAddInsModal(false);
    }
  });

  return (
    <StyledGrid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Box
        sx={{
          borderRadius: 5,
          backgroundColor: 'white',
          width: '100%',
          boxShadow: '0px 1px 17px -5px rgba(0,0,0,0.67)',
          paddingLeft: 1,
          paddingRight: 1,
          padding: 4
        }}
      >
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          <DialogTitle
            sx={{
              m: 0,
              p: 1,
              marginLeft: '5px',
              backgroundColor: 'white',
              fontWeight: '600',
              color: colorPrimaryBlue
            }}
            id="customized-dialog-title"
          >
            Add L2 User
          </DialogTitle>
          <Grid container spacing={3} marginBottom={4}>
            <Grid item xs={12} sm={4}>
              <TextField
                size="small"
                fullWidth
                label="Level Name"
                required
                value={formik.values.levelName}
                onChange={formik.handleChange}
                name="levelName"
                inputProps={{ maxLength: 40 }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                size="small"
                fullWidth
                label="Full Name"
                required
                value={formik.values.name}
                onChange={e => {
                  const value = e.target.value;
                  const cleanedValue = enterOnlyAlphabates(value);
                  const capitalizedValue = capitalizeWords(cleanedValue);
                  formik.setFieldValue('name', capitalizedValue);
                  // formik.setFieldValue(
                  //   'name',
                  //   enterOnlyAlphabates(e.target.value)
                  // );
                }}
                name="name"
                inputProps={{ maxLength: 40 }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                size="small"
                fullWidth
                label="Email ID"
                required
                value={formik.values.email}
                onChange={e => {
                  const email = e.target.value.replace(/[^a-zA-Z@.^0-9]/g, '');
                  formik.setFieldValue('email', email);
                }}
                onBlur={formik.handleBlur}
                name="email"
                error={Boolean(formik.touched.email && formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} marginBottom={4}>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                label="Mobile No"
                name="mobileNo"
                size="small"
                fullWidth
                inputProps={{ maxLength: 10 }}
                placeholder="Mobile No"
                value={formik.values.mobileNo}
                onChange={e => {
                  formik.setFieldValue(
                    'mobileNo',
                    enterOnlyNumbers(e.target.value)
                  );
                }}
                error={
                  formik.touched.mobileNo && Boolean(formik.errors.mobileNo)
                }
                helperText={formik.touched.mobileNo && formik.errors.mobileNo}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl size="small" fullWidth required>
                <Autocomplete
                  multiple
                  onChange={(event, newValues) => {
                    setSelectedStates(newValues);
                    formik.setFieldValue('states', newValues);
                    const stateKeys = newValues.map(state => state.key);

                    if (stateKeys.length > 0) {
                      dispatch(fetchCityMaster(stateKeys));
                    } else {
                      dispatch(clearL2City());
                    }
                  }}
                  disableCloseOnSelect
                  getOptionLabel={option => option.value}
                  isOptionEqualToValue={(option, value) =>
                    option.value === (value ? value.value : null)
                  }
                  options={states}
                  value={selectedStates}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckBox fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.value}
                    </li>
                  )}
                  renderInput={params => (
                    <TextField
                      name="states"
                      size="small"
                      label="States"
                      {...params}
                      error={
                        formik.touched.states && Boolean(formik.errors.states)
                      }
                      helperText={formik.touched.states && formik.errors.states}
                      InputLabelProps={{
                        required: true,

                        children: (
                          <>
                            State <span style={{ color: 'red' }}>*</span>
                          </>
                        )
                      }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <Search
                              fontSize="medium"
                              style={{ marginRight: 8, color: '#757575' }}
                            />
                            {params.InputProps.startAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                  PopperComponent={StyledPopper}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl size="small" fullWidth required>
                <Autocomplete
                  multiple
                  onChange={(event, newValues) => {
                    formik.setFieldValue('city', newValues);
                  }}
                  disableCloseOnSelect
                  getOptionLabel={option => option.value}
                  isOptionEqualToValue={(option, value) =>
                    option.key === value.key
                  }
                  options={cityList}
                  value={formik.values.city}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckBox fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.value}
                    </li>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="City"
                      error={formik.touched.city && Boolean(formik.errors.city)}
                      helperText={formik.touched.city && formik.errors.city}
                      InputLabelProps={{
                        required: true,
                        children: (
                          <>
                            City <span style={{ color: 'red' }}>*</span>
                          </>
                        )
                      }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <Search
                              fontSize="medium"
                              style={{ marginRight: 8, color: '#757575' }}
                            />
                            {params.InputProps.startAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                  PopperComponent={StyledPopper}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} marginBottom={4}>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                label="Pin Code"
                name="pinCode"
                size="small"
                fullWidth
                inputProps={{ maxLength: 6 }}
                placeholder="Pin Code"
                value={formik.values.pinCode}
                onChange={e => {
                  formik.setFieldValue(
                    'pinCode',
                    enterOnlyNumbers(e.target.value)
                  );
                }}
                error={formik.touched.pinCode && Boolean(formik.errors.pinCode)}
                helperText={formik.touched.pinCode && formik.errors.pinCode}
              />
            </Grid>
            {role !== 'ROLE_L1' && (
              <Grid item xs={12} sm={4}>
                <FormControl variant="outlined" size="small" fullWidth required>
                  <InputLabel id="reportToL1Id">Report to L1</InputLabel>
                  <Select
                    labelId="reportToL1Id"
                    id="reportToL1Id"
                    label="Report to L1"
                    value={formik.values.reportToL1Id}
                    onChange={event => {
                      const selectedOption = l1UsersList.find(
                        option => option.key === event.target.value
                      );
                      formik.setFieldValue(
                        'reportToL1Id',
                        selectedOption ? selectedOption.key : ''
                      );
                      formik.setFieldValue(
                        'showTargetAmtOfBorrower',
                        selectedOption
                          ? selectedOption.others === 'Credit'
                          : true
                      );
                    }}
                    name="reportToL1Id"
                  >
                    <MenuItem value="">Select Option</MenuItem>
                    {l1UsersList &&
                      l1UsersList.map(option => (
                        <MenuItem key={option.key} value={option.key}>
                          {option.value}
                        </MenuItem>
                      ))}
                  </Select>
                  {formik.touched.reportToL1Id &&
                    formik.errors.reportToL1Id && (
                      <FormHelperText error>
                        {formik.errors.reportToL1Id}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12} sm={4}>
              <TextField
                size="small"
                fullWidth
                value={formik.values.targetAmtOfInvestor}
                onChange={e => {
                  formik.setFieldValue(
                    'targetAmtOfInvestor',
                    enterOnlyNumbersWithDecimal(e.target.value)
                  );
                }}
                name="targetAmtOfInvestor"
                label="Annual Target (Investor) (₹)"
                inputProps={{ maxLength: 20 }}
              />
            </Grid>
            {formik.values.showTargetAmtOfBorrower && (
              <Grid item xs={12} sm={4}>
                <TextField
                  size="small"
                  fullWidth
                  value={formik.values.targetAmtOfBorrower}
                  onChange={e => {
                    formik.setFieldValue(
                      'targetAmtOfBorrower',
                      enterOnlyNumbersWithDecimal(e.target.value)
                    );
                  }}
                  name="targetAmtOfBorrower"
                  label="Annual Target (Borrower) (₹)"
                  inputProps={{ maxLength: 20 }}
                />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={3} marginBottom={4}>
            <Grid item xs={12} sm={4}>
              <TextField
                size="small"
                fullWidth
                value={formik.values.accHolderName}
                onChange={e => {
                  formik.setFieldValue(
                    'accHolderName',
                    enterOnlyAlphabates(e.target.value)
                  );
                }}
                name="accHolderName"
                label="Account Holder Name"
                inputProps={{ maxLength: 40 }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.accHolderName &&
                  Boolean(formik.errors.accHolderName)
                }
                helperText={
                  formik.touched.accHolderName && formik.errors.accHolderName
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                size="small"
                fullWidth
                value={formik.values.accountNo}
                name="accountNo"
                label="Account Number"
                inputProps={{ maxLength: 20 }}
                onChange={e => {
                  formik.setFieldValue(
                    'accountNo',
                    enterOnlyNumbers(e.target.value)
                  );
                }}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.touched.accountNo && formik.errors.accountNo
                )}
                helperText={formik.touched.accountNo && formik.errors.accountNo}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                size="small"
                fullWidth
                value={formik.values.ifscCode}
                name="ifscCode"
                label="IFSC Code"
                inputProps={{ maxLength: 11 }}
                onChange={e => {
                  formik.setFieldValue(
                    'ifscCode',
                    String(
                      enterOnlyAlphabetsAndNumbers(e.target.value)
                    ).toUpperCase()
                  );
                }}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.touched.ifscCode && formik.errors.ifscCode
                )}
                helperText={formik.touched.ifscCode && formik.errors.ifscCode}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} marginBottom={4}>
            <Grid item xs={12} sm={4}>
              <TextField
                size="small"
                fullWidth
                value={formik.values.aadhaarNumber}
                name="aadhaarNumber"
                label="Aadhaar Number"
                inputProps={{ maxLength: 12 }}
                onChange={e => {
                  const aadhaarNumber = enterOnlyAadhaarNumber(e.target.value);
                  formik.setFieldValue(
                    'aadhaarNumber',
                    String(aadhaarNumber).toUpperCase()
                  );
                }}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.touched.aadhaarNumber && formik.errors.aadhaarNumber
                )}
                helperText={
                  formik.touched.aadhaarNumber && formik.errors.aadhaarNumber
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                size="small"
                fullWidth
                value={formik.values.panNo}
                name="panNo"
                label="PAN Number"
                inputProps={{ maxLength: 12 }}
                onChange={e => {
                  const upperCasePanNo = e.target.value.toUpperCase();
                  formik.setFieldValue(
                    'panNo',
                    enterOnlyPanNumber(upperCasePanNo)
                  );
                }}
                onBlur={formik.handleBlur}
                error={Boolean(formik.touched.panNo && formik.errors.panNo)}
                helperText={formik.touched.panNo && formik.errors.panNo}
              />
            </Grid>
            <Grid item sm={12} lg={4}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    minDate={dayjs('01-01-1900')}
                    disableFuture={dayjs(new Date())}
                    label="Agreement Date"
                    name="aggrementDate"
                    value={
                      formik.values.aggrementDate
                        ? dayjs(formik.values.aggrementDate)
                        : null
                    }
                    format="DD-MM-YYYY"
                    slotProps={{ textField: { size: 'small' } }}
                    onChange={date =>
                      formik.setFieldValue('aggrementDate', date)
                    }
                    slots={{
                      openPickerIcon: CustomCalendarIcon
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        error={
                          formik.touched.aggrementDate &&
                          Boolean(formik.errors.aggrementDate)
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
                {formik.touched.aggrementDate &&
                  formik.errors.aggrementDate && (
                    <FormHelperText>
                      {formik.errors.aggrementDate}
                    </FormHelperText>
                  )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={3} marginBottom={4}>
            <Grid item sm={12} lg={1.4} marginLeft={1}>
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: 'bold', color: colorPrimaryBlue }}
              >
                Agreement Copy
              </Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <LoadingButton
                startIcon={<UploadIcon />}
                onClick={() => fileInputRef.current.click()}
                variant="outlined"
                sx={{
                  background: '#FFF8E6',
                  border: '1px dashed #FD9600',
                  borderRadius: '10px',
                  opacity: 1
                }}
              >
                UPLOAD
                <input
                  ref={fileInputRef}
                  key={'uploadProfile'}
                  type="file"
                  style={{ display: 'none' }}
                  accept={UploadDocumentAllowedPDFTypes.join(',')}
                  onChange={handleImageUpload}
                />
              </LoadingButton>
              {uploadedFileName && (
                <div>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: 'bold', color: 'primary' }}
                  >
                    Uploaded File:
                  </Typography>
                  <Typography variant="body1">{uploadedFileName}</Typography>
                </div>
              )}

              {imageUploadError.error && (
                <div style={{ color: 'red' }}>
                  <Typography variant="body1">
                    Error: {imageUploadError.errorMessage}
                  </Typography>
                </div>
              )}
            </Grid>
          </Grid>

          <Grid
            container
            spacing={3}
            marginBottom={4}
            marginTop={4}
            marginLeft={0.5}
            marginRight={0.5}
            style={{
              background: '#FFFFFF',
              border: '1px solid #999999',
              borderRadius: '6px',
              opacity: 1,
              width: '100%'
            }}
          >
            <Grid container spacing={3} padding={3}>
              <Grid item sm={12} lg={10} justifyContent="flex-start">
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 'bold',
                    color: colorPrimaryBlue,
                    display: 'flex'
                  }}
                >
                  Incentive
                </Typography>
              </Grid>
              <Grid
                item
                sm={12}
                lg={2}
                sx={{
                  display: 'flex',
                  paddingRight: '10px',
                  cursor: 'pointer'
                }}
                justifyContent="flex-end"
              >
                <img
                  onClick={handleOpenAddIncentive}
                  src={ImageADDIcon}
                  alt="Add Icon"
                />
              </Grid>

              <Grid item sm={12} lg={10} justifyContent="flex-start">
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: 'black',
                    display: 'flex'
                  }}
                >
                  Click on the + button to add incentive.
                </Typography>
              </Grid>
            </Grid>
            {isIncentiveData && isIncentiveData.length > 0 && (
              <Grid container direction="row" sx={{ marginTop: 1 }}>
                <CustomDataTable
                  columns={headersList}
                  data={isIncentiveData}
                  headStyle={{ color: 'black' }}
                  onSelectionChange={onSelectionChange}
                  selectedRows={selectedValues}
                  defaultPage={0}
                  defaultRowsPerPage={5}
                  totalCount={5}
                />
              </Grid>
            )}
          </Grid>

          <Grid px={2} pt={1} item display={'flex'} justifyContent={'end'}>
            <Button
              variant="outlined"
              sx={{
                borderColor: colorPrimaryBlue,
                color: colorPrimaryBlue,
                fontWeight: '700',
                fontSize: '14px',
                mr: 2,
                width: '115px',
                height: '50px',
                borderWidth: 2
              }}
              onClick={handleCloseAddL1}
            >
              CANCEL
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              sx={{
                color: colorWhite,
                fontWeight: '500',
                fontSize: '14px',
                mr: 2,
                width: '120px',
                height: '50px',
                borderWidth: 2
              }}
            >
              SUBMIT
            </LoadingButton>
          </Grid>
        </form>
        {isCreateEmployeeL2Loading && <Loader key="add_l1" />}

        {!isCreateEmployeeL2Loading && isCreateEmployeeL2Failed && (
          <SnackBarBox
            type="error"
            message={handleError(createEmployeeL2ErrorContainer.errorMessage)}
          />
        )}

        {!isCreateEmployeeL2Loading && isCreateEmployeeL2Success && (
          <SnackBarBox type="success" message={NEW_L2_ADD_SUCCESS} />
        )}

        {isShowAddInsModal && (
          <ModalDialog
            fullwidth={true}
            isShowTitle={true}
            titleName={isEditing ? 'EDIT INCENTIVE' : 'ADD INCENTIVE'}
            size="xs"
            open={isShowAddInsModal}
            onClose={() => {
              setIsShowAddInsModal(false);
              setIsEditing(false);
              addIncentiveFormik.resetForm();
            }}
          >
            <form autoComplete="off" onSubmit={addIncentiveFormik.handleSubmit}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                marginTop={0.5}
              >
                <Grid item xs={12} sx={{ width: '80%' }}>
                  <FormControl size="small" fullWidth required>
                    <InputLabel>Product Name</InputLabel>
                    <Select
                      label="Product Name"
                      name="productName"
                      value={addIncentiveFormik.values.productName}
                      // onChange={addIncentiveFormik.handleChange}
                      onChange={e => {
                        const selectedProduct = products.find(
                          product => product.value === e.target.value
                        );
                        addIncentiveFormik.setFieldValue(
                          'productName',
                          e.target.value
                        );
                        addIncentiveFormik.setFieldValue(
                          'productKey',
                          selectedProduct ? selectedProduct.key : ''
                        );
                      }}
                      error={
                        addIncentiveFormik.touched.productName &&
                        Boolean(addIncentiveFormik.errors.productName)
                      }
                    >
                      {products
                        .filter(
                          product =>
                            !isIncentiveData.some(
                              data => data.productName === product.value
                            ) ||
                            (isEditing &&
                              currentRow.productName === product.value)
                        )
                        .map(product => (
                          <MenuItem key={product.key} value={product.value}>
                            {product.value}
                          </MenuItem>
                        ))}
                    </Select>
                    {addIncentiveFormik.touched.productName &&
                      addIncentiveFormik.errors.productName && (
                        <FormHelperText error>
                          {addIncentiveFormik.errors.productName}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sx={{ width: '80%' }}>
                  <input
                    type="hidden"
                    name="productKey"
                    value={addIncentiveFormik.values.productKey}
                  />
                </Grid>

                <Grid item xs={12} sx={{ width: '80%' }}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      size="small"
                      fullWidth
                      label="Incentive Percentage"
                      required
                      name="percentange"
                      inputProps={{
                        maxLength: 6,
                        inputMode: 'decimal'
                      }}
                      value={addIncentiveFormik.values.percentange}
                      onChange={e => {
                        const value = e.target.value;
                        const regex =
                          /^(100(\.0{0,2})?|([1-9]?[0-9](\.[0-9]{0,2})?))$/;
                        if (value === '' || regex.test(value)) {
                          addIncentiveFormik.handleChange(e);
                        }
                      }}
                      error={
                        addIncentiveFormik.touched.percentange &&
                        Boolean(addIncentiveFormik.errors.percentange)
                      }
                      helperText={
                        addIncentiveFormik.touched.percentange &&
                        addIncentiveFormik.errors.percentange
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} textAlign="right">
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: colorPrimaryBlue,
                      color: colorPrimaryBlue,
                      fontWeight: '700',
                      fontSize: '14px',
                      mr: 2,
                      width: '110px',
                      height: '48px',
                      borderWidth: 2
                    }}
                    onClick={onCancelIncentiveHdlr}
                  >
                    CANCEL
                  </Button>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{
                      color: colorWhite,
                      fontWeight: '500',
                      fontSize: '14px',
                      mr: 2,
                      width: '115px',
                      height: '48px',
                      borderWidth: 2
                    }}
                  >
                    SUBMIT
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </ModalDialog>
        )}
      </Box>
    </StyledGrid>
  );
};

export default AddL2Users;
