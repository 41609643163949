import { Grid, Typography } from '@mui/material';
import { colorGrayish } from '../../../config/theme';
import { DOC_TYPE_PHOTO } from '../../../utils/constant';
import ViewImage from '../../../components/ViewImage';
import { createTitle } from '../../../utils/utils';
import { useEffect } from 'react';

export const UploadPhotoDetails = () => {
  useEffect(() => {
    document.title = createTitle('Upload Photo');
  }, []);

  return (
    <>
      <Grid container spacing={2} p={2}>
        <Grid item xs={12} md={6} sm={6}>
          <Typography
            variant="subtitle2"
            fontWeight={'500'}
            color={colorGrayish}
          >
            Upload Photo
          </Typography>
          <ViewImage key={DOC_TYPE_PHOTO} docType={DOC_TYPE_PHOTO} />
        </Grid>
      </Grid>
    </>
  );
};
