import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '../../config/api';

export const revertAll = createAction('REVERT_ALL');
export const logoutAndClearToken = createAction('LOGOUT_AND_CLEAR_TOKEN');

export const clearDeviationSubmitNotification = createAction(
  'CLEAR_DEVIATION_SUBMIT_NOTIFICATION'
);

export const stopDeviationProcess = createAction('STOP_DEVIATION_PROCESS');

export const clearRMNotification = createAction('CLEAR_RM_NOTIFICATION');

export const clearBranchNotification = createAction(
  'CLEAR_BRANCH_NOTIFICATION'
);

export const clearBusinessNotification = createAction(
  'CLEAR_BUSINESS_NOTIFICATION'
);

export const clearDownloadReportNotification = createAction(
  'CLEAR_DOWNLOAD_REPORT_NOTIFICATION'
);

export const fetchBusinessUser = createAsyncThunk(
  'admin/fetch_business_user',
  async (pageData, { rejectWithValue }) => {
    try {
      const { page, size, fromDate, toDate, businessName, purpose } = pageData;

      const response = await api.get(
        `/admin/business?page=${page}&size=${size}&fromDate=${fromDate}&toDate=${toDate}&businessName=${businessName}&purpose=${purpose}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchBranches = createAsyncThunk(
  'admin/fetch_branches',
  async (pageData, { rejectWithValue }) => {
    try {
      const {
        page,
        size,
        email,
        phoneNo,
        branchName,
        location,
        targetAmount,
        fromDate,
        toDate
      } = pageData;

      const response = await api.get(
        `/admin/branch?page=${page}&size=${size}&email=${email}&phoneNo=${phoneNo}&branchName=${branchName}&location=${location}&targetAmount=${targetAmount}&fromDate=${fromDate}&toDate=${toDate}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchInvestors = createAsyncThunk(
  'admin/fetchInvestors',
  async (pageData, { rejectWithValue }) => {
    try {
      const response = await api.post(`/admin/getInvestorTab`, pageData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchBorrowers = createAsyncThunk(
  'admin/fetchBorrowers',
  async (pageData, { rejectWithValue }) => {
    try {
      const response = await api.post(`admin/getBorrowerTab`, pageData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDeviationUploads = createAsyncThunk(
  'admin/fetchDeviationUploads',
  async (pageData, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/deviation/getDeviationMailView`,
        pageData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDeviationQDEDetails = createAsyncThunk(
  'deviation/QDEDetails',
  async ({ userId, borrowingDetailsId }, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/users/getQDEDetails?userId=${userId}&borrowingDetailsId=${borrowingDetailsId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const submitApprovedDeviationDecision = createAsyncThunk(
  'deviation/submitApprovedDeviationDecision',
  async (deviationPayload, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/deviation/updateDeviationDecision`,
        JSON.stringify(deviationPayload)
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const submitRejectedDeviationDecision = createAsyncThunk(
  'deviation/submitRejectedDeviationDecision',
  async (deviationPayload, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/deviation/updateDeviationDecision`,
        JSON.stringify(deviationPayload)
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchRMView = createAsyncThunk(
  'admin/fetch_rm_view',
  async (pageData, { rejectWithValue }) => {
    try {
      const {
        page,
        size,
        email,
        mobileNo,
        bankAccountNo,
        ifsc,
        relationshipManagerId,
        fromDate,
        toDate
      } = pageData;

      const response = await api.get(
        `/admin/rm?page=${page}&size=${size}&email=${email}&phoneNo=${mobileNo}&relationshipManagerId=${relationshipManagerId}&bankAccountNo=${bankAccountNo}&ifsc=${ifsc}&fromDate=${fromDate}&toDate=${toDate}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addNewRm = createAsyncThunk(
  'admin/addNewRm',
  async (rmDetails, { rejectWithValue }) => {
    try {
      const response = await api.post(`/admin/rm`, rmDetails);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editNewRm = createAsyncThunk(
  'admin/editNewRm',
  async ({ rmDetails, rmId }, { rejectWithValue }) => {
    try {
      const response = await api.put(`/admin/rm/${rmId}`, rmDetails);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchToRMList = createAsyncThunk(
  'admin/fetchToRMList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`/dropDown/rm`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchToBranchList = createAsyncThunk(
  'admin/fetchToBranchList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`/dropDown/branch`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchToBusinessList = createAsyncThunk(
  'admin/fetchToBusinessList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`/dropDown/business`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const submitTransferRM = createAsyncThunk(
  'admin/submitTransferRM',
  async (values, { rejectWithValue }) => {
    try {
      const { fromRMId, toRMId, transferReason } = values;
      const response = await api.post(
        `/admin/rm/transfer?fromRMId=${fromRMId}&toRMId=${toRMId}&transferReason=${transferReason}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addNewBranch = createAsyncThunk(
  'admin/addNewBranch',
  async (branchDetails, { rejectWithValue }) => {
    try {
      const response = await api.post(`/admin/branch`, branchDetails);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editBranch = createAsyncThunk(
  'admin/editBranch',
  async ({ branchDetails, branchId }, { rejectWithValue }) => {
    try {
      const response = await api.put(
        `/admin/branch/${branchId}`,
        branchDetails
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const submitTransferBranch = createAsyncThunk(
  'admin/submitTransferBranch',
  async (newValues, { rejectWithValue }) => {
    try {
      const { fromBranchId, toBranchId, transferReason } = newValues;
      const response = await api.post(
        `/admin/branch/transfer?fromBranchId=${fromBranchId}&toBranchId=${toBranchId}&transferReason=${transferReason}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const submitTransferBusiness = createAsyncThunk(
  'admin/submitTransferBusiness',
  async (newValues, { rejectWithValue }) => {
    try {
      const { businessFromId, businessToId } = newValues;
      const response = await api.post(
        `/admin/business/transfer?businessFromId=${businessFromId}&businessToId=${businessToId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const downloadReport = createAsyncThunk(
  'admin/downloadReport',
  async ({ pageData, type }, { rejectWithValue }) => {
    try {
      let excelURL = '';

      if (type === 'investor') {
        excelURL = '/admin/exportInvestor';
      } else {
        excelURL = '/admin/exportBorrower';
      }

      const blobSource = await api.post(excelURL, pageData, {
        responseType: 'blob'
      });

      // const content = blobSource.request.getResponseHeader(
      //   'Content-Disposition'
      // );
      // const fileName = getFileName(content);

      let filename = `${type === 'investor' ? 'investor_report_' : 'borrower_report_'}${new Date().getTime()}.xlsx`;

      if (blobSource) {
        const blobData = blobSource.data;
        const blobUrl = URL.createObjectURL(blobData);
        if (blobUrl) {
          const a = document.createElement('a');
          a.href = blobUrl;
          a.download = filename;
          a.click();
        }
        return blobUrl;
      }
    } catch (error) {
      console.error('Report download ::  ', error);
      return rejectWithValue({
        error: true,
        errorMessage: 'Report download failed,Try after sometime',
        description: 'Something Went Wrong',
        statusCode: error.response.status
      });
    }
  }
);

export const addNewBusiness = createAsyncThunk(
  'admin/addNewBusiness',
  async (businessDetails, { rejectWithValue }) => {
    try {
      const response = await api.post(`/admin/business`, businessDetails);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editNewBusiness = createAsyncThunk(
  'admin/editNewBusiness',
  async ({ businessDetails, businessId }, { rejectWithValue }) => {
    try {
      const response = await api.put(
        `/admin/business/${businessId}`,
        businessDetails
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
const initialState = {
  businessUserDetails: {
    content: [],
    pageable: {},
    totalElements: 0,
    totalPages: 0,
    last: false,
    size: 0,
    number: 0,
    sort: [],
    numberOfElements: 0,
    first: false,
    empty: false
  },
  isFetchBusinessUserLoading: false,
  isFetchBusinessUserSuccess: false,
  isFetchBusinessUserFailed: false,
  fetchBusinessUserErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  branchDetails: {
    content: [],
    pageable: {},
    totalElements: 0,
    totalPages: 0,
    last: false,
    size: 0,
    number: 0,
    sort: [],
    numberOfElements: 0,
    first: false,
    empty: false
  },
  isFetchBranchesLoading: false,
  isFetchBranchesSuccess: false,
  isFetchBranchesFailed: false,
  fetchBranchesErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  RMDetails: {
    content: [],
    pageable: {},
    totalElements: 0,
    totalPages: 0,
    last: false,
    size: 0,
    number: 0,
    sort: [],
    numberOfElements: 0,
    first: false,
    empty: false
  },
  isFetchRMViewLoading: false,
  isFetchRMViewSuccess: false,
  isFetchRMViewFailed: false,
  fetchRMViewErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  investorDetails: {
    content: [],
    pageable: {},
    totalElements: 0,
    totalPages: 0,
    last: false,
    size: 0,
    number: 0,
    sort: [],
    numberOfElements: 0,
    first: false,
    empty: false
  },
  isFetchInvestorLoading: false,
  isFetchInvestorSuccess: false,
  isFetchInvestorFailed: false,
  fetchInvestorErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  borrowerDetails: {
    content: [],
    pageable: {},
    totalElements: 0,
    totalPages: 0,
    last: false,
    size: 0,
    number: 0,
    sort: [],
    numberOfElements: 0,
    first: false,
    empty: false
  },
  isFetchBorrowerLoading: false,
  isFetchBorrowerSuccess: false,
  isFetchBorrowerFailed: false,
  fetchBorrowerErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  deviationUploadDetails: {
    content: [],
    pageable: {},
    totalElements: 0,
    totalPages: 0,
    last: false,
    size: 0,
    number: 0,
    sort: [],
    numberOfElements: 0,
    first: false,
    empty: false
  },
  isFetchDeviationUploadLoading: false,
  isFetchDeviationUploadSuccess: false,
  isFetchDeviationUploadFailed: false,
  fetchDeviationUploadErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  deviationQDEDetails: {},
  isFetchDeviationQDELoading: false,
  isFetchDeviationQDESuccess: false,
  isFetchDeviationQDEFailed: false,
  fetchDeviationQDEErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isDeviationDecisionApproveLoading: false,
  isDeviationDecisionApproveSuccess: false,
  isDeviationDecisionApproveFailed: false,
  deviationDecisionApproveErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isDeviationDecisionRejectLoading: false,
  isDeviationDecisionRejectSuccess: false,
  isDeviationDecisionRejectFailed: false,
  deviationDecisionRejectErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isAddRMLoading: false,
  isAddRMSuccess: false,
  isAddRMFailed: false,
  addRMUserErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isEditRMLoading: false,
  isEditRMSuccess: false,
  isEditRMFailed: false,
  editRMUserErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  toRmList: [],
  isFetchToRMListLoading: false,
  isFetchToRMListSuccess: false,
  isFetchToRMListFailed: false,
  fetchToRMListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isTransferRMLoading: false,
  isTransferRMSuccess: false,
  isTransferRMFailed: false,
  transferRMErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isAddBranchLoading: false,
  isAddBranchSuccess: false,
  isAddBranchFailed: false,
  addBranchErrorContainer: {
    error: false,
    erroBranchessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isEditBranchLoading: false,
  isEditBranchSuccess: false,
  isEditBranchFailed: false,
  editBranchErrorContainer: {
    error: false,
    erroBranchessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  toBranchList: [],
  isFetchToBranchListLoading: false,
  isFetchToBranchListSuccess: false,
  isFetchToBranchListFailed: false,
  fetchToBranchListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isTransferBranchLoading: false,
  isTransferBranchSuccess: false,
  isTransferBranchFailed: false,
  transferBranchErrorContainer: {
    error: false,
    erroBranchessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  toBusinessList: [],
  isFetchToBusinessListLoading: false,
  isFetchToBusinessListSuccess: false,
  isFetchToBusinessListFailed: false,
  fetchToBusinessListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isDownloadReportLoading: false,
  isDownloadReportSuccess: false,
  isDownloadReportFailed: false,
  downloadReportErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isAddBusinessUserLoading: false,
  isAddBusinessUserSuccess: false,
  isAddBusinessUserFailed: false,
  addBusinessUserErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isEditBusinessUserLoading: false,
  isEditBusinessUserSuccess: false,
  isEditBusinessUserFailed: false,
  editBusinessUserErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isTransferBusinessUserLoading: false,
  isTransferBusinessUserSuccess: false,
  isTransferBusinessUserFailed: false,
  transferBusinessUserErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  }
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(logoutAndClearToken, () => initialState)

      .addCase(fetchBusinessUser.pending, state => {
        (state.isFetchBusinessUserLoading = true),
          (state.isFetchBusinessUserSuccess = false),
          (state.isFetchBusinessUserFailed = false),
          (state.fetchBusinessUserErrorContainer =
            initialState.fetchBusinessUserErrorContainer);
      })
      .addCase(fetchBusinessUser.fulfilled, (state, action) => {
        (state.isFetchBusinessUserLoading = false),
          (state.isFetchBusinessUserSuccess = true),
          (state.businessUserDetails = action.payload);
      })
      .addCase(fetchBusinessUser.rejected, (state, action) => {
        (state.isFetchBusinessUserLoading = false),
          (state.isFetchBusinessUserFailed = true),
          (state.fetchBusinessUserErrorContainer = {
            ...state.fetchBusinessUserErrorContainer,
            ...action.payload
          });
      })

      .addCase(fetchBranches.pending, state => {
        state.isFetchBranchesLoading = true;
        state.isFetchBranchesSuccess = false;
        state.isFetchBranchesFailed = false;
        state.fetchBranchesErrorContainer =
          initialState.fetchBranchesErrorContainer;
      })
      .addCase(fetchBranches.fulfilled, (state, action) => {
        state.isFetchBranchesLoading = false;
        state.isFetchBranchesSuccess = true;
        state.branchDetails = action.payload;
      })
      .addCase(fetchBranches.rejected, (state, action) => {
        state.isFetchBranchesLoading = false;
        state.isFetchBranchesFailed = true;
        state.fetchBranchesErrorContainer = {
          ...state.fetchBranchesErrorContainer,
          ...action.payload
        };
      })

      .addCase(fetchRMView.pending, state => {
        state.isFetchRMViewLoading = true;
        state.isFetchRMViewSuccess = false;
        state.isFetchRMViewFailed = false;
        state.fetchRMViewErrorContainer =
          initialState.fetchRMViewErrorContainer;
      })
      .addCase(fetchRMView.fulfilled, (state, action) => {
        state.isFetchRMViewLoading = false;
        state.isFetchRMViewSuccess = true;
        state.RMDetails = action.payload;
      })
      .addCase(fetchRMView.rejected, (state, action) => {
        state.isFetchRMViewLoading = false;
        state.isFetchRMViewFailed = true;
        state.fetchRMViewErrorContainer = {
          ...state.fetchRMViewErrorContainer,
          ...action.payload
        };
      })
      .addCase(fetchInvestors.pending, state => {
        (state.isFetchInvestorLoading = true),
          (state.isFetchInvestorSuccess = false),
          (state.isFetchInvestorFailed = false),
          (state.fetchInvestorErrorContainer =
            initialState.fetchInvestorErrorContainer);
      })
      .addCase(fetchInvestors.fulfilled, (state, action) => {
        (state.isFetchInvestorLoading = false),
          (state.isFetchInvestorSuccess = true),
          (state.investorDetails = action.payload);
      })
      .addCase(fetchInvestors.rejected, (state, action) => {
        (state.isFetchInvestorLoading = false),
          (state.isFetchInvestorFailed = true),
          (state.fetchInvestorErrorContainer = {
            ...state.fetchInvestorErrorContainer,
            ...action.payload
          });
      })
      .addCase(fetchBorrowers.pending, state => {
        (state.isFetchBorrowerLoading = true),
          (state.isFetchBorrowerSuccess = false),
          (state.isFetchBorrowerFailed = false),
          (state.fetchBorrowerErrorContainer =
            initialState.fetchBorrowerErrorContainer);
      })
      .addCase(fetchBorrowers.fulfilled, (state, action) => {
        (state.isFetchBorrowerLoading = false),
          (state.isFetchBorrowerSuccess = true),
          (state.borrowerDetails = action.payload);
      })
      .addCase(fetchBorrowers.rejected, (state, action) => {
        (state.isFetchBorrowerLoading = false),
          (state.isFetchBorrowerFailed = true),
          (state.fetchBorrowerErrorContainer = {
            ...state.fetchBorrowerErrorContainer,
            ...action.payload
          });
      })
      .addCase(fetchDeviationUploads.pending, state => {
        (state.isFetchDeviationUploadLoading = true),
          (state.isFetchDeviationUploadSuccess = false),
          (state.isFetchDeviationUploadFailed = false),
          (state.fetchDeviationUploadErrorContainer =
            initialState.fetchDeviationUploadErrorContainer);
      })
      .addCase(fetchDeviationUploads.fulfilled, (state, action) => {
        (state.isFetchDeviationUploadLoading = false),
          (state.isFetchDeviationUploadSuccess = true),
          (state.deviationUploadDetails = action.payload);
      })
      .addCase(fetchDeviationUploads.rejected, (state, action) => {
        (state.isFetchDeviationUploadLoading = false),
          (state.isFetchDeviationUploadFailed = true),
          (state.fetchDeviationUploadErrorContainer = {
            ...state.fetchDeviationUploadErrorContainer,
            ...action.payload
          });
      })
      .addCase(getDeviationQDEDetails.pending, state => {
        (state.isFetchDeviationQDELoading = true),
          (state.isFetchDeviationQDESuccess = false),
          (state.isFetchDeviationQDEFailed = false),
          (state.fetchDeviationQDEErrorContainer =
            initialState.fetchDeviationQDEErrorContainer);
      })
      .addCase(getDeviationQDEDetails.fulfilled, (state, action) => {
        (state.isFetchDeviationQDELoading = false),
          (state.isFetchDeviationQDESuccess = true),
          (state.deviationQDEDetails = action.payload);
      })
      .addCase(getDeviationQDEDetails.rejected, (state, action) => {
        (state.isFetchDeviationQDELoading = false),
          (state.isFetchDeviationQDEFailed = true),
          (state.fetchDeviationQDEErrorContainer = {
            ...state.fetchDeviationQDEErrorContainer,
            ...action.payload
          });
      })
      .addCase(submitApprovedDeviationDecision.pending, state => {
        (state.isDeviationDecisionApproveLoading = true),
          (state.isDeviationDecisionApproveSuccess = false),
          (state.isDeviationDecisionApproveFailed = false),
          (state.deviationDecisionApproveErrorContainer =
            initialState.deviationDecisionApproveErrorContainer);
      })
      .addCase(submitApprovedDeviationDecision.fulfilled, state => {
        (state.isDeviationDecisionApproveLoading = false),
          (state.isDeviationDecisionApproveSuccess = true);
      })
      .addCase(submitApprovedDeviationDecision.rejected, (state, action) => {
        (state.isDeviationDecisionApproveLoading = false),
          (state.isDeviationDecisionApproveSuccess = false),
          (state.isDeviationDecisionApproveFailed = true),
          (state.deviationDecisionApproveErrorContainer = {
            ...state.deviationDecisionApproveErrorContainer,
            ...action.payload
          });
      })
      .addCase(clearDeviationSubmitNotification, state => {
        (state.isDeviationDecisionApproveSuccess = false),
          (state.isDeviationDecisionApproveFailed = false),
          (state.isDeviationDecisionRejectSuccess = false),
          (state.isDeviationDecisionRejectFailed = false);
      })
      .addCase(stopDeviationProcess, state => {
        (state.isDeviationDecisionApproveLoading = false),
          (state.isDeviationDecisionRejectLoading = false),
          (state.isDeviationDecisionApproveSuccess = false),
          (state.isDeviationDecisionRejectSuccess = false),
          (state.isDeviationDecisionApproveFailed = false),
          (state.isDeviationDecisionRejectFailed = false),
          (state.deviationDecisionApproveErrorContainer =
            initialState.deviationDecisionApproveErrorContainer),
          (state.deviationDecisionRejectErrorContainer =
            initialState.deviationDecisionRejectErrorContainer);
      })
      .addCase(submitRejectedDeviationDecision.pending, state => {
        (state.isDeviationDecisionRejectLoading = true),
          (state.isDeviationDecisionRejectSuccess = false),
          (state.isDeviationDecisionRejectFailed = false),
          (state.deviationDecisionRejectErrorContainer =
            initialState.deviationDecisionRejectErrorContainer);
      })
      .addCase(submitRejectedDeviationDecision.fulfilled, state => {
        (state.isDeviationDecisionRejectLoading = false),
          (state.isDeviationDecisionRejectSuccess = true);
      })
      .addCase(submitRejectedDeviationDecision.rejected, (state, action) => {
        (state.isDeviationDecisionRejectLoading = false),
          (state.isDeviationDecisionRejectSuccess = false),
          (state.isDeviationDecisionRejectFailed = true),
          (state.deviationDecisionRejectErrorContainer = {
            ...state.deviationDecisionRejectErrorContainer,
            ...action.payload
          });
      })
      .addCase(addNewRm.pending, state => {
        (state.isAddRMLoading = true),
          (state.isAddRMSuccess = false),
          (state.isAddRMFailed = false),
          (state.addRMUserErrorContainer =
            initialState.addRMUserErrorContainer);
      })
      .addCase(addNewRm.fulfilled, state => {
        (state.isAddRMLoading = false), (state.isAddRMSuccess = true);
      })
      .addCase(addNewRm.rejected, (state, action) => {
        (state.isAddRMLoading = false),
          (state.isAddRMSuccess = false),
          (state.isAddRMFailed = true),
          (state.addRMUserErrorContainer = {
            ...state.addRMUserErrorContainer,
            ...action.payload
          });
      })
      .addCase(editNewRm.pending, state => {
        (state.isEditRMLoading = true),
          (state.isEditRMSuccess = false),
          (state.isEditRMFailed = false),
          (state.editRMUserErrorContainer =
            initialState.editRMUserErrorContainer);
      })
      .addCase(editNewRm.fulfilled, state => {
        (state.isEditRMLoading = false), (state.isEditRMSuccess = true);
      })
      .addCase(editNewRm.rejected, (state, action) => {
        (state.isEditRMLoading = false),
          (state.isEditRMSuccess = false),
          (state.isEditRMFailed = true),
          (state.editRMUserErrorContainer = {
            ...state.editRMUserErrorContainer,
            ...action.payload
          });
      })
      .addCase(fetchToRMList.pending, state => {
        (state.isFetchToRMListLoading = true),
          (state.isFetchToRMListSuccess = false),
          (state.isFetchToRMListFailed = false),
          (state.fetchToRMListErrorContainer =
            initialState.fetchToRMListErrorContainer);
      })
      .addCase(fetchToRMList.fulfilled, (state, action) => {
        (state.isFetchToRMListLoading = false),
          (state.isFetchToRMListSuccess = true),
          (state.toRmList = action.payload);
      })
      .addCase(fetchToRMList.rejected, (state, action) => {
        (state.isFetchToRMListLoading = false),
          (state.isFetchToRMListSuccess = false),
          (state.isFetchToRMListFailed = true),
          (state.fetchToRMListErrorContainer = {
            ...state.fetchToRMListErrorContainer,
            ...action.payload
          });
      })
      .addCase(submitTransferRM.pending, state => {
        (state.isTransferRMLoading = true),
          (state.isTransferRMSuccess = false),
          (state.isTransferRMFailed = false),
          (state.transferRMErrorContainer =
            initialState.transferRMErrorContainer);
      })
      .addCase(submitTransferRM.fulfilled, state => {
        (state.isTransferRMLoading = false), (state.isTransferRMSuccess = true);
      })
      .addCase(submitTransferRM.rejected, (state, action) => {
        (state.isTransferRMLoading = false),
          (state.isTransferRMSuccess = false),
          (state.isTransferRMFailed = true),
          (state.transferRMErrorContainer = {
            ...state.transferRMErrorContainer,
            ...action.payload
          });
      })
      .addCase(clearRMNotification, state => {
        (state.isAddRMSuccess = false),
          (state.isAddRMFailed = false),
          (state.isEditRMSuccess = false),
          (state.isEditRMFailed = false);
        state.isTransferRMSuccess = false;
        state.isTransferRMFailed = false;
      })
      .addCase(clearBranchNotification, state => {
        (state.isAddBranchSuccess = false),
          (state.isAddBranchFailed = false),
          (state.isEditBranchSuccess = false),
          (state.isEditBranchFailed = false),
          (state.isTransferBranchSuccess = false),
          (state.isTransferBranchFailed = false);
      })
      .addCase(addNewBranch.pending, state => {
        (state.isAddBranchLoading = true),
          (state.isAddBranchSuccess = false),
          (state.isAddBranchFailed = false),
          (state.addBranchErrorContainer =
            initialState.addBranchErrorContainer);
      })
      .addCase(addNewBranch.fulfilled, state => {
        (state.isAddBranchLoading = false), (state.isAddBranchSuccess = true);
      })
      .addCase(addNewBranch.rejected, (state, action) => {
        (state.isAddBranchLoading = false),
          (state.isAddBranchSuccess = false),
          (state.isAddBranchFailed = true),
          (state.addBranchErrorContainer = {
            ...state.addBranchErrorContainer,
            ...action.payload
          });
      })
      .addCase(editBranch.pending, state => {
        (state.isEditBranchLoading = true),
          (state.isEditBranchSuccess = false),
          (state.isEditBranchFailed = false),
          (state.editBranchErrorContainer =
            initialState.editBranchErrorContainer);
      })
      .addCase(editBranch.fulfilled, state => {
        (state.isEditBranchLoading = false), (state.isEditBranchSuccess = true);
      })
      .addCase(editBranch.rejected, (state, action) => {
        (state.isEditBranchLoading = false),
          (state.isEditBranchSuccess = false),
          (state.isEditBranchFailed = true),
          (state.editBranchErrorContainer = {
            ...state.editBranchErrorContainer,
            ...action.payload
          });
      })
      .addCase(fetchToBranchList.pending, state => {
        (state.isFetchToBranchListLoading = true),
          (state.isFetchToBranchListSuccess = false),
          (state.isFetchToBranchListFailed = false),
          (state.fetchToBranchListErrorContainer =
            initialState.fetchToBranchListErrorContainer);
      })
      .addCase(fetchToBranchList.fulfilled, (state, action) => {
        (state.isFetchToBranchListLoading = false),
          (state.isFetchToBranchListSuccess = true),
          (state.toBranchList = action.payload);
      })
      .addCase(fetchToBranchList.rejected, (state, action) => {
        (state.isFetchToBranchListLoading = false),
          (state.isFetchToBranchListSuccess = false),
          (state.isFetchToBranchListFailed = true),
          (state.fetchToBranchListErrorContainer = {
            ...state.fetchToBranchListErrorContainer,
            ...action.payload
          });
      })
      .addCase(submitTransferBranch.pending, state => {
        (state.isTransferBranchLoading = true),
          (state.isTransferBranchSuccess = false),
          (state.isTransferBranchFailed = false),
          (state.transferBranchErrorContainer =
            initialState.transferBranchErrorContainer);
      })
      .addCase(submitTransferBranch.fulfilled, (state, action) => {
        (state.isTransferBranchLoading = false),
          (state.isTransferBranchSuccess = true);
      })
      .addCase(submitTransferBranch.rejected, (state, action) => {
        (state.isTransferBranchLoading = false),
          (state.isTransferBranchSuccess = false),
          (state.isTransferBranchFailed = true),
          (state.transferBranchErrorContainer = {
            ...state.transferBranchErrorContainer,
            ...action.payload
          });
      })
      .addCase(fetchToBusinessList.pending, state => {
        (state.isFetchToBusinessListLoading = true),
          (state.isFetchToBusinessListSuccess = false),
          (state.isFetchToBusinessListFailed = false),
          (state.fetchToBusinessListErrorContainer =
            initialState.fetchToBusinessListErrorContainer);
      })
      .addCase(fetchToBusinessList.fulfilled, (state, action) => {
        (state.isFetchToBusinessListLoading = false),
          (state.isFetchToBusinessListSuccess = true),
          (state.toBusinessList = action.payload);
      })
      .addCase(fetchToBusinessList.rejected, (state, action) => {
        (state.isFetchToBusinessListLoading = false),
          (state.isFetchToBusinessListSuccess = false),
          (state.isFetchToBusinessListFailed = true),
          (state.fetchToBusinessListErrorContainer = {
            ...state.fetchToBusinessListErrorContainer,
            ...action.payload
          });
      })
      .addCase(downloadReport.pending, state => {
        (state.isDownloadReportLoading = true),
          (state.isDownloadReportSuccess = false),
          (state.isDownloadReportFailed = false),
          (state.downloadReportErrorContainer =
            initialState.downloadReportErrorContainer);
      })
      .addCase(downloadReport.fulfilled, state => {
        (state.isDownloadReportLoading = false),
          (state.isDownloadReportSuccess = true);
      })
      .addCase(downloadReport.rejected, (state, action) => {
        (state.isDownloadReportLoading = false),
          (state.isDownloadReportSuccess = false),
          (state.isDownloadReportFailed = true),
          (state.downloadReportErrorContainer = {
            ...state.downloadReportErrorContainer,
            ...action.payload
          });
      })
      .addCase(clearDownloadReportNotification, state => {
        (state.isDownloadReportSuccess = false),
          (state.isDownloadReportFailed = false);
      })
      .addCase(addNewBusiness.pending, state => {
        (state.isAddBusinessUserLoading = true),
          (state.isAddBusinessUserSuccess = false),
          (state.isAddBusinessUserFailed = false),
          (state.addBusinessUserErrorContainer =
            initialState.addBusinessUserErrorContainer);
      })
      .addCase(addNewBusiness.fulfilled, (state, action) => {
        (state.isAddBusinessUserLoading = false),
          (state.isAddBusinessUserSuccess = true),
          (state.toBusinessList = action.payload);
      })
      .addCase(addNewBusiness.rejected, (state, action) => {
        (state.isAddBusinessUserLoading = false),
          (state.isAddBusinessUserSuccess = false),
          (state.isAddBusinessUserFailed = true),
          (state.addBusinessUserErrorContainer = {
            ...state.addBusinessUserErrorContainer,
            ...action.payload
          });
      })
      .addCase(editNewBusiness.pending, state => {
        (state.isEditBusinessUserLoading = true),
          (state.isEditBusinessUserSuccess = false),
          (state.isEditBusinessUserFailed = false),
          (state.editBusinessUserErrorContainer =
            initialState.editBusinessUserErrorContainer);
      })
      .addCase(editNewBusiness.fulfilled, (state, action) => {
        (state.isEditBusinessUserLoading = false),
          (state.isEditBusinessUserSuccess = true);
      })
      .addCase(editNewBusiness.rejected, (state, action) => {
        (state.isEditBusinessUserLoading = false),
          (state.isEditBusinessUserSuccess = false),
          (state.isEditBusinessUserFailed = true),
          (state.editBusinessUserErrorContainer = {
            ...state.editBusinessUserErrorContainer,
            ...action.payload
          });
      })
      .addCase(submitTransferBusiness.pending, state => {
        (state.isTransferBusinessUserLoading = true),
          (state.isTransferBusinessUserSuccess = false),
          (state.isTransferBusinessUserFailed = false),
          (state.transferBusinessUserErrorContainer =
            initialState.transferBusinessUserErrorContainer);
      })
      .addCase(submitTransferBusiness.fulfilled, (state, action) => {
        (state.isTransferBusinessUserLoading = false),
          (state.isTransferBusinessUserSuccess = true);
      })
      .addCase(submitTransferBusiness.rejected, (state, action) => {
        (state.isTransferBusinessUserLoading = false),
          (state.isTransferBusinessUserSuccess = false),
          (state.isTransferBusinessUserFailed = true),
          (state.transferBusinessUserErrorContainer = {
            ...state.transferBusinessUserErrorContainer,
            ...action.payload
          });
      });
  }
});

export default adminSlice.reducer;
