import { useEffect, useState } from 'react';
import { Grid, Box, Tooltip, Skeleton, styled } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  IMG_IMAGE_PREVIEW,
  IMG_PDF_PREVIEW
} from '../../../../../utils/imageUrls';
import {
  createTitle,
  getImageDetails,
  handleError
} from '../../../../../utils/utils';
import {
  downloadDeviationDocument,
  viewImage
} from '../../../../../redux/reducers/casesummarySlice';
import SnackBarBox from '../../../../../components/SnackBarBox';

const Img = styled('img')(({ theme }) => ({
  objectFit: 'contain',
  [theme.breakpoints.down('sm')]: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  [theme.breakpoints.up('md')]: {
    height: 250,
    width: 250
  }
}));

const ViewImageQDE = ({ docType = null }) => {
  const dispatch = useDispatch();

  const { deviationQDEDetails } = useSelector(state => state.admin);
  const {
    isDownloadDeviationDocsLoading,
    downloadDeviationDocsErrorContainer,
    isDownloadDeviationDocsFailed
  } = useSelector(state => state.caseSummary);

  const [actualImage, setActualImage] = useState(null);

  const [isLoadingSavedImage, setIsLoadingSavedImage] = useState(false);

  const [isImage, setIsImage] = useState(null);

  const [currentUploadedFile, setCurrentUploadedFile] = useState(null);

  const documentUploads = deviationQDEDetails['documentUploads'];

  useEffect(() => {
    const getImage = async () => {
      setIsLoadingSavedImage(true);

      const uploadedLink = getImageDetails(docType, documentUploads);

      if (uploadedLink) {
        if (
          uploadedLink?.contentType &&
          String(uploadedLink?.contentType).includes('pdf')
        ) {
          setIsImage(false);
        } else {
          setIsImage(true);
        }
        setCurrentUploadedFile(uploadedLink);
        const img = await viewImage(uploadedLink?.s3ObjectKey);
        setActualImage(img);
      }
      setIsLoadingSavedImage(false);
    };
    getImage();
  }, [documentUploads, docType]);

  const handleDownloadImage = () => {
    dispatch(
      downloadDeviationDocument({
        fileName: currentUploadedFile?.fileName,
        fileUrl: currentUploadedFile?.s3ObjectKey
      })
    );
  };

  useEffect(() => {
    document.title = createTitle('View Image');
  }, []);

  return (
    <>
      {isLoadingSavedImage ? (
        <>
          <Box paddingTop={1}>
            <Skeleton
              sx={{ borderRadius: '10px' }}
              variant="sqaure"
              height={180}
              width={280}
            />
          </Box>
        </>
      ) : (
        <Grid item xs={12} md={12} marginTop={1}>
          <div style={{ position: 'relative' }}>
            {actualImage ? (
              <>
                {isImage && isImage ? (
                  <Box>
                    <Tooltip
                      placement="top"
                      title={`Download ${currentUploadedFile?.fileName}`}
                    >
                      <Img
                        className="imgbox"
                        src={actualImage}
                        alt="Actual Image"
                        width={280}
                        height={180}
                        onClick={handleDownloadImage}
                      />
                    </Tooltip>
                  </Box>
                ) : (
                  <Box>
                    <Tooltip
                      placement="top"
                      title={`Download ${currentUploadedFile?.fileName}`}
                    >
                      <Img
                        sx={{ paddingTop: 4, paddingBottom: 4 }}
                        className="imgbox"
                        src={IMG_PDF_PREVIEW}
                        alt="Uploaded PDF"
                        width={150}
                        height={150}
                        onClick={handleDownloadImage}
                      />
                    </Tooltip>
                  </Box>
                )}
              </>
            ) : (
              <Img width={280} src={IMG_IMAGE_PREVIEW} alt="Image Preview" />
            )}
          </div>
        </Grid>
      )}

      {!isDownloadDeviationDocsLoading && isDownloadDeviationDocsFailed && (
        <SnackBarBox
          type="error"
          message={handleError(
            downloadDeviationDocsErrorContainer?.errorMessage
          )}
        />
      )}
    </>
  );
};

ViewImageQDE.propTypes = {
  docType: PropTypes.string
};
export default ViewImageQDE;
