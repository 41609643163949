import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Box,
  Autocomplete,
  Checkbox,
  Typography,
  Button
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { DASHBOARD_SEARCH_CRITERIA_LIST } from '../../../../utils/constant';
import { isArrayNotEmpty, isValidPinCode } from '../../../../utils/utils';
import { Search, CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import {
  colorGolden,
  colorPrimaryBlue,
  colorWhite
} from '../../../../config/theme';
import { fetchStates } from '../../../../redux/reducers/EmployeeL1Slice';

import { useDispatch, useSelector } from 'react-redux';
import {
  clearL2City,
  fetchCityMaster
} from '../../../../redux/reducers/EmployeeL2Slice';
import dayjs from 'dayjs';
import {
  fetchAdminReportDate,
  fetchAdminReportMaturityDate,
  fetchMappedUser,
  fetchReportAdmin,
  fetchUserLevel,
  revertAllAdminReport
} from '../../../../redux/reducers/ReportAdminSlice';

const TYPE_LIST = [
  { key: 'investor', value: 'Investor' },
  { key: 'borrower', value: 'Borrower' }
];

const StyledPopper = styled(Popper)(({ theme }) => ({
  '& .MuiAutocomplete-listbox': {
    maxHeight: 'calc(35vh - 70px)',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.4em',
      height: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(0, 0, 0, 0.3)',
      borderRadius: '4px'
    }
  }
}));

const getValidationSchema = (role, userLevel) => {
  const schema = {
    type: yup.string().required('Type is required'),
    pinCodes: yup
      .string()
      .test('is-valid-pinCode', 'Please provide a valid Pin Code.', value =>
        isValidPinCode(value)
      )
      .nullable()
  };

  if (role === 'ROLE_ADMIN' && userLevel !== 'Level 3') {
    schema.stateIds = yup
      .array()
      .of(
        yup.object().shape({
          key: yup.string().required('Key is required'),
          value: yup.string().required('Value is required'),
          others: yup.mixed().nullable() // Adjust as needed
        })
      )
      .required('State IDs are required')
      .min(1, 'At least one State ID must be selected');
  }

  return yup.object().shape(schema);
};

const ReportsFilter = ({ onFilterSubmit }) => {
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const { states } = useSelector(state => state.employeeL1);
  const { cityList } = useSelector(state => state.employeeL2);
  const {
    isUserLevelList,
    isMappedUserList,
    isMaturityDateList,
    isReportDateList
  } = useSelector(state => state.adminReport);

  const dispatch = useDispatch();
  const sessionData = JSON.parse(
    sessionStorage.getItem('x-auth-token-admin-panel') || '{}'
  );
  const { userId, role } = sessionData;
  const [validationSchema, setValidationSchema] = useState(
    getValidationSchema(role, '')
  );

  useEffect(() => {
    formik.setFieldValue('userLevel', 'All');
    dispatch(fetchMappedUser('All'));
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchStates());
    // dispatch(fetchCityMaster());
    dispatch(fetchUserLevel());
    dispatch(fetchAdminReportDate('adminReportDate'));
  }, []);

  const handleUserLevelChange = event => {
    formik.handleChange(event);
    const selectedUserLevel = event.target.value;
    dispatch(fetchMappedUser(selectedUserLevel));
  };

  // const validationSchema = yup.object().shape({
  //   type: yup.string().required('Type is required'),
  //   stateIds: yup
  //     .array()
  //     .of(
  //       yup.object().shape({
  //         key: yup.string().required('Key is required'),
  //         value: yup.string().required('Value is required'),
  //         others: yup.mixed().nullable() // Adjust as needed
  //       })
  //     )
  //     .required('State IDs are required')
  //     .min(1, 'At least one State ID must be selected')
  // });

  // const validationSchema = getValidationSchema(role, formik.values.userLevel);
  const formik = useFormik({
    initialValues: {
      type: '',
      userLevel: '',
      incomingUserId: '',
      maturityDate: null,
      isMaturityDate: false,
      dateTitle: null,
      investmentStartDate: null,
      investmentEndDate: null,
      page: 0,
      size: 10,
      stateIds: [],
      cityIds: [],
      pinCodes: ''
    },
    validationSchema, // Use the state validation schema
    onSubmit: values => {
      const stateIdsArray = values?.stateIds.map(state =>
        parseInt(state?.key, 10)
      );

      const cityIdsArray = values?.cityIds.map(city => parseInt(city?.key, 10));

      const { maturityDate, dateTitle, ...otherValues } = values;
      let submitPayload = {
        ...otherValues,
        isMaturityDate: maturityDate ? true : false,
        dateTitle: maturityDate || dateTitle,
        investmentStartDate: maturityDate ? null : values?.investmentStartDate,
        investmentEndDate: maturityDate ? null : values?.investmentEndDate,
        stateIds: (values?.stateIds || []).length > 0 ? stateIdsArray : [],
        cityIds: (values?.cityIds || []).length > 0 ? cityIdsArray : []
      };

      onFilterSubmit(submitPayload);
    }
  });

  useEffect(() => {
    // Update validation schema when role or userLevel changes
    setValidationSchema(getValidationSchema(role, formik.values.userLevel));
  }, [role, formik.values.userLevel]);

  useEffect(() => {
    if (formik.values.type === 'investor') {
      dispatch(fetchAdminReportMaturityDate('adminReportMaturityDate'));
    }
  }, [formik.values.type, dispatch]);

  const resetFilter = () => {
    formik.resetForm();
    dispatch(revertAllAdminReport());
    dispatch(clearL2City());
  };

  const handleStartDate = value => {
    formik.setFieldValue('startDate', value, true);
    formik.setFieldValue('endDate', '', true);
  };

  const handleEndDate = value => {
    formik.setFieldValue('endDate', value, true);
  };

  const toggleFilters = () => {
    setShowMoreFilters(!showMoreFilters);
  };

  return (
    <form autoComplete="off" onSubmit={formik.handleSubmit}>
      <Grid
        container
        spacing={3}
        padding={1}
        px={2}
        alignItems="center"
        style={{ color: 'black' }}
      >
        {role !== 'ROLE_L3' && (
          <Grid item xs={8} sm={4} md={4}>
            <FormControl fullWidth size="small">
              <InputLabel id="user-level">User Level</InputLabel>
              <Select
                labelId="user-level"
                id="user-level"
                label="User Level"
                value={formik.values.userLevel}
                onChange={handleUserLevelChange}
                name="userLevel"
                size="small"
              >
                {isUserLevelList &&
                  isUserLevelList.map(level => (
                    <MenuItem key={level.key} value={level.value}>
                      {level.value}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {formik.touched.userLevel && formik.errors.userLevel && (
              <FormHelperText sx={{ textAlign: 'start', fontSize: 10 }}>
                {formik.errors.userLevel}
              </FormHelperText>
            )}
          </Grid>
        )}
        {role !== 'ROLE_L3' && (
          <Grid item xs={12} sm={6} md={4}>
            <FormControl variant="outlined" size="small" fullWidth required>
              <InputLabel id="user-list">User List</InputLabel>
              <Select
                labelId="user-list"
                id="user-list"
                label="User List"
                value={formik.values.incomingUserId}
                onChange={event => {
                  const selectedKey = event.target.value;
                  const selectedOption = isMappedUserList.find(
                    user => user.key === selectedKey
                  );
                  formik.setFieldValue(
                    'incomingUserId',
                    selectedOption ? selectedOption.key : ''
                  );
                }}
                name="incomingUserId"
              >
                <MenuItem value="">Select Option</MenuItem>
                {isMappedUserList &&
                  isMappedUserList.map(user => (
                    <MenuItem key={user.key} value={user.key}>
                      {user.value}
                    </MenuItem>
                  ))}
              </Select>
              {formik.touched.incomingUserId &&
                formik.errors.incomingUserId && (
                  <FormHelperText error>
                    {formik.errors.incomingUserId}
                  </FormHelperText>
                )}
            </FormControl>
          </Grid>
        )}
        <Grid item xs={8} sm={4} md={4}>
          <FormControl fullWidth size="small" required>
            <InputLabel id="type">Type</InputLabel>
            <Select
              labelId="type"
              id="type"
              label="Type"
              value={formik.values.type}
              onChange={formik.handleChange}
              name="type"
              size="small"
            >
              {TYPE_LIST.map(searchC => (
                <MenuItem key={searchC.key} value={searchC.key}>
                  <em style={{ fontStyle: 'normal' }}>{searchC.value}</em>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {formik.touched.type && formik.errors.type && (
            <FormHelperText sx={{ textAlign: 'start', fontSize: 10 }}>
              {formik.errors.type}
            </FormHelperText>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        padding={1}
        px={2}
        alignItems="center"
        style={{ color: 'black' }}
      >
        {formik.values.userLevel !== 'Level 3' &&
          (role === 'ROLE_ADMIN' || role === 'ROLE_L1') && (
            <Grid item xs={12} sm={4}>
              <FormControl size="small" fullWidth required>
                <Autocomplete
                  multiple
                  onChange={(event, newValues) => {
                    formik.setFieldValue('stateIds', newValues);
                    const stateKeys = newValues.map(state => state.key);
                    if (stateKeys.length > 0) {
                      dispatch(fetchCityMaster(stateKeys));
                    } else {
                      dispatch(clearL2City());
                    }
                  }}
                  disableCloseOnSelect
                  getOptionLabel={option => option.value}
                  isOptionEqualToValue={(option, value) =>
                    option.value === (value ? value.value : null)
                  }
                  options={states}
                  value={formik.values.stateIds}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckBox fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.value}
                    </li>
                  )}
                  renderInput={params => (
                    <TextField
                      name="stateIds"
                      size="small"
                      label="States"
                      {...params}
                      error={
                        formik.touched.stateIds &&
                        Boolean(formik.errors.stateIds)
                      }
                      helperText={
                        formik.touched.stateIds && formik.errors.stateIds
                      }
                      InputLabelProps={{
                        required: true,
                        children: (
                          <>
                            State <span style={{ color: 'red' }}>*</span>
                          </>
                        )
                      }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <Search
                              fontSize="medium"
                              style={{ marginRight: 8, color: '#757575' }}
                            />
                            {params.InputProps.startAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                  PopperComponent={StyledPopper}
                />
              </FormControl>
            </Grid>
          )}
        {formik.values.userLevel !== 'Level 3' &&
          role !== 'ROLE_L2' &&
          role !== 'ROLE_L3' && (
            <Grid item xs={8} sm={4} md={4}>
              <FormControl size="small" fullWidth required>
                <Autocomplete
                  multiple
                  onChange={(event, newValues) => {
                    formik.setFieldValue('cityIds', newValues);
                  }}
                  disableCloseOnSelect
                  getOptionLabel={option => option.value}
                  isOptionEqualToValue={(option, value) =>
                    option.value === (value ? value.value : null)
                  }
                  options={cityList}
                  value={formik.values.cityIds}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckBox fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.value}
                    </li>
                  )}
                  renderInput={params => (
                    <TextField
                      name="cityIds"
                      size="small"
                      label="City"
                      {...params}
                      error={
                        formik.touched.cityIds && Boolean(formik.errors.cityIds)
                      }
                      helperText={
                        formik.touched.cityIds && formik.errors.cityIds
                      }
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <Search
                              fontSize="medium"
                              style={{ marginRight: 8, color: '#757575' }}
                            />
                            {params.InputProps.startAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                  PopperComponent={StyledPopper}
                />
              </FormControl>
              {/* {formik.touched.cityIds && formik.errors.cityIds && (
                <FormHelperText sx={{ textAlign: 'start', fontSize: 10 }}>
                  {formik.errors.cityIds}
                </FormHelperText>
              )} */}
            </Grid>
          )}
        {role !== 'ROLE_L3' && (
          <Grid item xs={8} sm={4} md={4}>
            <FormControl fullWidth size="small">
              <TextField
                label="Pin Code"
                name="pinCodes"
                size="small"
                inputProps={{ maxLength: 6 }}
                value={formik.values.pinCodes}
                onChange={formik.handleChange}
                error={
                  formik.touched.pinCodes && Boolean(formik.errors.pinCodes)
                }
                helperText={formik.touched.pinCodes && formik.errors.pinCodes}
              />
            </FormControl>

            {/* {formik.touched.pinCodes && formik.errors.pinCodes && (
              <FormHelperText sx={{ textAlign: 'start', fontSize: 10 }}>
                {formik.errors.pinCodes}
              </FormHelperText>
            )} */}
          </Grid>
        )}
      </Grid>
      {showMoreFilters && (
        <Grid
          container
          spacing={3}
          padding={2}
          px={2}
          alignItems="center"
          style={{ color: 'black' }}
        >
          {formik.values.type === 'investor' && (
            <Grid item xs={8} sm={4} md={4}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel id="maturity-date">Maturity Date</InputLabel>
                <Select
                  labelId="maturity-date"
                  id="maturity-date"
                  label="Maturity Date"
                  value={formik.values.maturityDate || null}
                  onChange={event => {
                    formik.setFieldValue('maturityDate', event.target.value);
                  }}
                  name="maturityDate"
                >
                  <MenuItem value="">Select Option</MenuItem>
                  {isMaturityDateList &&
                    isMaturityDateList.map(option => (
                      <MenuItem key={option.key} value={option.key}>
                        {option.value}
                      </MenuItem>
                    ))}
                </Select>
                {formik.touched.maturityDate && formik.errors.maturityDate && (
                  <FormHelperText error>
                    {formik.errors.maturityDate}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

          {(!formik.values.maturityDate ||
            formik.values.maturityDate === '') && (
            <Grid item xs={8} sm={4} md={4}>
              <FormControl fullWidth size="small">
                <InputLabel id="dateTitle">Date Title</InputLabel>
                <Select
                  labelId="dateTitle"
                  id="dateTitle"
                  label="Date Title"
                  value={formik.values.dateTitle || null}
                  onChange={event => {
                    formik.setFieldValue('dateTitle', event.target.value);
                  }}
                  name="dateTitle"
                >
                  <MenuItem value="">Select Option</MenuItem>
                  {isReportDateList &&
                    isReportDateList.map(option => (
                      <MenuItem key={option.key} value={option.key}>
                        {option.value}
                      </MenuItem>
                    ))}
                </Select>
                {formik.touched.dateTitle && formik.errors.dateTitle && (
                  <FormHelperText error>
                    {formik.errors.dateTitle}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

          {formik.values.dateTitle === 'customDate' &&
            (formik.values.maturityDate === null ||
              formik.values.maturityDate === '') && (
              <>
                <Grid item xs={8} sm={4} md={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: '100%' }}
                      label="Start Date"
                      disableFuture
                      slotProps={{ textField: { size: 'small' } }}
                      value={formik.values.investmentStartDate}
                      onChange={date => {
                        formik.setFieldValue('investmentStartDate', date);
                      }}
                      slots={{
                        textField: params => (
                          <TextField {...params} name="investmentStartDate" />
                        )
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={8} sm={4} md={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: '100%' }}
                      label="End Date"
                      slotProps={{ textField: { size: 'small' } }}
                      disableFuture
                      value={formik.values.investmentEndDate}
                      onChange={date => {
                        formik.setFieldValue('investmentEndDate', date);
                      }}
                      slots={{
                        textField: params => (
                          <TextField {...params} name="investmentEndDate" />
                        )
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </>
            )}
        </Grid>
      )}

      <Grid pl={3} item xs={12}>
        <Typography
          variant="subtitle2"
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            color: colorGolden,
            fontWeight: 600
          }}
          onClick={toggleFilters}
        >
          {showMoreFilters ? 'CLICK TO LESS FILTERS' : 'CLICK TO MORE FILTERS'}
          {showMoreFilters ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Typography>
      </Grid>
      <Grid item xs={12} padding={2}>
        <div
          style={{
            borderTop: '2px dashed rgba(0, 0, 0, 0.2)',
            width: '100%',
            marginBottom: '10px'
          }}
        ></div>
      </Grid>
      <Grid px={2} pt={1} item display={'flex'} justifyContent={'end'}>
        <Button
          variant="outlined"
          sx={{
            borderColor: colorPrimaryBlue,
            color: colorPrimaryBlue,
            fontWeight: '700',
            fontSize: '14px',
            mr: 2,
            width: '105px',
            height: '45px',
            borderWidth: 2
          }}
          onClick={resetFilter}
        >
          RESET
        </Button>
        <LoadingButton
          variant="contained"
          type="submit"
          sx={{
            color: colorWhite,
            fontWeight: '600',
            fontSize: '12px',
            mr: 2,
            width: '105px',
            height: '45px',
            borderWidth: 2
          }}
        >
          SEARCH
        </LoadingButton>
      </Grid>
    </form>
  );
};

export default ReportsFilter;
