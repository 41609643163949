import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '../../config/api';

export const revertAgeGroupAnalytics = createAction(
  'REVERT_AGE_GROUP_ANALYTICS_DATA'
);

export const revertPoolAmt = createAction('REVERT_POOL_AMT_DATA');
export const revertBusinessAnalytics = createAction(
  'REVERT_BUSINESS_ANALYTICS_DATA'
);
export const revertTotalBusinessAnalytics = createAction(
  'REVERT_TOTAL_BUSINESS_ANALYTICS_DATA'
);

export const revertRegionAnalytics = createAction(
  'REVERT_REGION_ANALYTICS_DATA'
);

const initial = {
  isAgeWiseReportLoading: false,
  isAgeWiseReportSuccess: false,
  isAgeWiseReportFailed: false,
  ageWiseReport: [],
  isAgeWiseReportErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isBusinessWiseReportLoading: false,
  isBusinessWiseReportSuccess: false,
  isBusinessWiseReportFailed: false,
  businessWiseReport: [],
  isBusinessWiseReportErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isRegionWiseReportLoading: false,
  isRegionWiseReportSuccess: false,
  isRegionWiseReportFailed: false,
  regionWiseReport: [],
  isRegionWiseReportErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isTotalBusinessWiseReportLoading: false,
  isTotalBusinessWiseReportSuccess: false,
  isTotalBusinessWiseReportFailed: false,
  totalBusinessWiseReport: [],
  isTotalBusinessWiseReportErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isMonthWiseReportLoading: false,
  isMonthWiseReportSuccess: false,
  isMonthWiseReportFailed: false,
  monthWiseReport: [],
  isMonthWiseReportErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isPoolAmtLoading: false,
  isPoolAmtSuccess: false,
  isPoolAmtFailed: false,
  poolAmt: [],
  isPoolAmtErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  }
};

export const getPoolAmount = createAsyncThunk(
  'admin/getPoolAmount',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`/borrowers/lendbox/getPoolAmount`);

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const ageWiseReportChart = createAsyncThunk(
  'admin/ageWiseReport',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`/reports/ageWiseReport`);

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const businessWiseReportChart = createAsyncThunk(
  'admin/businessWiseReport',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`/reports/businessWiseReport`);

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const regionWiseReportChart = createAsyncThunk(
  'admin/regionWiseReport',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`/reports/regionWiseReport`);

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const totalBusinessWiseReportChart = createAsyncThunk(
  'admin/totalBusinessWiseReport',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`/reports/totalBusinessWiseReport`);

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const monthWiseReportChart = createAsyncThunk(
  'admin/monthWiseReport',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`/reports/monthWiseReport`);

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const ChartSlice = createSlice({
  name: 'adminChartReport',
  initialState: initial,
  extraReducers: builder => {
    builder

      .addCase(revertPoolAmt, state => {
        state.isPoolAmtLoading = initial.isPoolAmtLoading;
        state.isPoolAmtSuccess = initial.isPoolAmtSuccess;
        state.isPoolAmtFailed = initial.isPoolAmtFailed;
        state.poolAmt = initial.poolAmt;
        state.isPoolAmtErrorContainer = initial.isPoolAmtErrorContainer;
      })
      .addCase(revertAgeGroupAnalytics, state => {
        state.isAgeWiseReportLoading = initial.isAgeWiseReportLoading;
        state.isAgeWiseReportSuccess = initial.isAgeWiseReportSuccess;
        state.isAgeWiseReportFailed = initial.isAgeWiseReportFailed;
        state.ageWiseReport = initial.ageWiseReport;
        state.isAgeWiseReportErrorContainer =
          initial.isAgeWiseReportErrorContainer;
      })
      .addCase(revertBusinessAnalytics, state => {
        state.isBusinessWiseReportLoading = initial.isBusinessWiseReportLoading;
        state.isBusinessWiseReportSuccess = initial.isBusinessWiseReportSuccess;
        state.isBusinessWiseReportFailed = initial.isBusinessWiseReportFailed;
        state.businessWiseReport = initial.businessWiseReport;
        state.isBusinessWiseReportErrorContainer =
          initial.isBusinessWiseReportErrorContainer;
      })
      .addCase(revertTotalBusinessAnalytics, state => {
        state.isTotalBusinessWiseReportLoading =
          initial.isTotalBusinessWiseReportLoading;
        state.isTotalBusinessWiseReportSuccess =
          initial.isTotalBusinessWiseReportSuccess;
        state.isTotalBusinessWiseReportFailed =
          initial.isTotalBusinessWiseReportFailed;
        state.totalBusinessWiseReport = initial.totalBusinessWiseReport;
        state.isTotalBusinessWiseReportErrorContainer =
          initial.isTotalBusinessWiseReportErrorContainer;
      })

      .addCase(revertRegionAnalytics, state => {
        state.isRegionWiseReportLoading = initial.isRegionWiseReportLoading;
        state.isRegionWiseReportSuccess = initial.isRegionWiseReportSuccess;
        state.isRegionWiseReportFailed = initial.isRegionWiseReportFailed;
        state.regionWiseReport = initial.regionWiseReport;
        state.isRegionWiseReportErrorContainer =
          initial.isRegionWiseReportErrorContainer;
      })
      .addCase(getPoolAmount.pending, state => {
        state.isPoolAmtLoading = true;
        state.isPoolAmtSuccess = false;
        state.isPoolAmtFailed = false;
        state.isPoolAmtErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(getPoolAmount.fulfilled, (state, action) => {
        state.isPoolAmtLoading = false;
        state.isPoolAmtSuccess = true;
        state.poolAmt = action.payload || '';
        state.isPoolAmtFailed = false;
      })

      .addCase(getPoolAmount.rejected, (state, action) => {
        state.isPoolAmtLoading = false;
        state.isPoolAmtSuccess = false;
        state.isPoolAmtFailed = true;
        state.poolAmt = initial.poolAmt;

        state.isPoolAmtErrorContainer = {
          ...state.isPoolAmtErrorContainer,
          ...action.payload
        };
      })
      .addCase(ageWiseReportChart.pending, state => {
        state.isAgeWiseReportLoading = true;
        state.isPoolAmtSuccess = false;
        state.isPoolAmtFailed = false;
        state.isAgeWiseReportErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(ageWiseReportChart.fulfilled, (state, action) => {
        state.isAgeWiseReportLoading = false;
        state.isAgeWiseReportSuccess = true;
        state.ageWiseReport = action.payload || '';
        state.isAgeWiseReportFailed = false;
      })

      .addCase(ageWiseReportChart.rejected, (state, action) => {
        state.isAgeWiseReportLoading = false;
        state.isAgeWiseReportSuccess = false;
        state.isAgeWiseReportFailed = true;
        state.ageWiseReport = initial.ageWiseReport;

        state.isAgeWiseReportErrorContainer = {
          ...state.isAgeWiseReportErrorContainer,
          ...action.payload
        };
      })
      .addCase(businessWiseReportChart.pending, state => {
        state.isBusinessWiseReportLoading = true;
        state.isBusinessWiseReportSuccess = false;
        state.isBusinessWiseReportFailed = false;
        state.isBusinessWiseReportErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(businessWiseReportChart.fulfilled, (state, action) => {
        state.isBusinessWiseReportLoading = false;
        state.isBusinessWiseReportSuccess = true;
        state.businessWiseReport = action.payload || '';
        state.isBusinessWiseReportFailed = false;
      })

      .addCase(businessWiseReportChart.rejected, (state, action) => {
        state.isBusinessWiseReportLoading = false;
        state.isBusinessWiseReportSuccess = false;
        state.isBusinessWiseReportFailed = true;
        state.businessWiseReport = initial.businessWiseReport;

        state.isBusinessWiseReportErrorContainer = {
          ...state.isBusinessWiseReportErrorContainer,
          ...action.payload
        };
      })
      .addCase(totalBusinessWiseReportChart.pending, state => {
        state.isTotalBusinessWiseReportLoading = true;
        state.isTotalBusinessWiseReportSuccess = false;
        state.isTotalBusinessWiseReportFailed = false;
        state.isTotalBusinessWiseReportErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(totalBusinessWiseReportChart.fulfilled, (state, action) => {
        state.isTotalBusinessWiseReportLoading = false;
        state.isTotalBusinessWiseReportSuccess = true;
        state.totalBusinessWiseReport = action.payload || '';
        state.isTotalBusinessWiseReportFailed = false;
      })

      .addCase(totalBusinessWiseReportChart.rejected, (state, action) => {
        state.isTotalBusinessWiseReportLoading = false;
        state.isTotalBusinessWiseReportSuccess = false;
        state.isTotalBusinessWiseReportFailed = true;
        state.totalBusinessWiseReport = initial.totalBusinessWiseReport;

        state.isTotalBusinessWiseReportErrorContainer = {
          ...state.isTotalBusinessWiseReportErrorContainer,
          ...action.payload
        };
      })
      .addCase(monthWiseReportChart.pending, state => {
        state.isMonthWiseReportLoading = true;
        state.isMonthWiseReportSuccess = false;
        state.isMonthWiseReportFailed = false;
        state.isMonthWiseReportErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(monthWiseReportChart.fulfilled, (state, action) => {
        state.isMonthWiseReportLoading = false;
        state.isMonthWiseReportSuccess = true;
        state.monthWiseReport = action.payload || '';
        state.isMonthWiseReportFailed = false;
      })

      .addCase(monthWiseReportChart.rejected, (state, action) => {
        state.isMonthWiseReportLoading = false;
        state.isMonthWiseReportSuccess = false;
        state.isMonthWiseReportFailed = true;
        state.monthWiseReport = initial.monthWiseReport;

        state.isMonthWiseReportErrorContainer = {
          ...state.isMonthWiseReportErrorContainer,
          ...action.payload
        };
      })
      .addCase(regionWiseReportChart.pending, state => {
        state.isRegionWiseReportLoading = true;
        state.isRegionWiseReportSuccess = false;
        state.isRegionWiseReportFailed = false;
        state.isRegionWiseReportErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(regionWiseReportChart.fulfilled, (state, action) => {
        state.isRegionWiseReportLoading = false;
        state.isRegionWiseReportSuccess = true;
        state.regionWiseReport = action.payload || '';
        state.isRegionWiseReportFailed = false;
      })

      .addCase(regionWiseReportChart.rejected, (state, action) => {
        state.isRegionWiseReportLoading = false;
        state.isRegionWiseReportSuccess = false;
        state.isRegionWiseReportFailed = true;
        state.regionWiseReport = initial.regionWiseReport;

        state.isRegionWiseReportErrorContainer = {
          ...state.isRegionWiseReportErrorContainer,
          ...action.payload
        };
      });
  }
});

export default ChartSlice.reducer;
