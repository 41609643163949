import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import { useDispatch, useSelector } from 'react-redux';
import {
  revertTotalBusinessAnalytics,
  totalBusinessWiseReportChart
} from '../../../../redux/reducers/ChartSlice';
import { colorGolden } from '../../../../config/theme';
import { Expand05Icon, FileDownload03Icon } from '../../../../utils/iconSvg';
import {
  Grid,
  IconButton,
  SvgIcon,
  Dialog,
  DialogContent,
  Menu,
  MenuItem
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const TotalBusinessWiseLineChart = () => {
  const {
    isTotalBusinessWiseReportLoading,
    isTotalBusinessWiseReportSuccess,
    isTotalBusinessWiseReportFailed,
    totalBusinessWiseReport,
    isTotalBusinessWiseReportErrorContainer
  } = useSelector(state => state.chartReport);

  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(false);
  const [chartWrapper, setChartWrapper] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    dispatch(totalBusinessWiseReportChart());
    return () => {
      dispatch(revertTotalBusinessAnalytics());
    };
  }, [dispatch]);

  const handleClickOpen = () => {
    setIsExpanded(prev => !prev);
  };

  const handleDownload = () => {
    if (chartWrapper) {
      const imageURI = chartWrapper.getChart().getImageURI();
      const link = document.createElement('a');
      link.href = imageURI;
      link.download = 'total-business-chart.png';
      link.click();
    }
  };

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const chartOptions = {
    title: '',
    chartArea: {
      width: '80%',
      height: '70%',
      bottom: 40
    },
    hAxis: {
      title: 'Business',
      textStyle: { fontSize: 12, italic: false },
      titleTextStyle: { fontSize: 12, italic: false, bold: true },
      ticks: ['L1', 'L2', 'L3']
    },
    vAxis: {
      title: 'Thousands',
      minValue: 0,
      format: 'short', // Use 'short' format to automatically format numbers to thousands (k)
      textStyle: { fontSize: 12, italic: false },
      titleTextStyle: { fontSize: 12, italic: false, bold: true },
      viewWindow: {
        min: 0,
        max: 500000 // Set the maximum value for the y-axis
      },
      gridlines: { count: 6 }, // Number of gridlines to display
      ticks: [0, 100000, 200000, 300000, 400000, 500000] // Custom tick values
    },
    series: {
      0: {
        pointShape: 'circle',
        pointSize: 5 // Adjust the size of the dots
      }, // Sum of Target Amount (Investor)
      1: {
        pointShape: 'circle',
        pointSize: 5 // Adjust the size of the dots
      }, // Sum of Achieved Amount (Investor)
      2: {
        pointShape: 'circle',
        pointSize: 5 // Adjust the size of the dots
      }, // Sum of Target Amount (Borrower)
      3: {
        pointShape: 'circle',
        pointSize: 5 // Adjust the size of the dots
      } // Sum of Achieved Amount (Borrower)
    },
    colors: ['#FF694E', '#5EAC1B', colorGolden, '#34BFA3'],
    legend: {
      position: 'none'
    },
    height: 350
  };

  const legendItems = [
    { color: '#FF694E', text: 'Sum of Target Amount (Investor)' },
    { color: '#5EAC1B', text: 'Sum of Achieved Amount (Investor)' },
    { color: colorGolden, text: 'Sum of Target Amount (Borrower)' },
    { color: '#34BFA3', text: 'Sum of Achieved Amount (Borrower)' }
  ];

  return (
    <div>
      <Grid container alignItems="center" justifyContent="space-between" mb={2}>
        <Grid item>
          <div
            style={{
              fontSize: '15px',
              fontWeight: 600
            }}
          >
            Target Vs Achieved by Business
          </div>
        </Grid>
        <Grid item>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleMenuClick}
          >
            <MoreVertIcon fontSize="small" />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={() => {
                handleClickOpen();
                handleMenuClose();
              }}
            >
              <SvgIcon
                component={Expand05Icon}
                sx={{ width: 16, height: 16, mr: 1 }}
              />
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleDownload();
                handleMenuClose();
              }}
            >
              <SvgIcon
                component={FileDownload03Icon}
                style={{ fontSize: 20, marginRight: 8 }}
              />
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
      {isTotalBusinessWiseReportLoading ? (
        <p>Loading...</p>
      ) : isTotalBusinessWiseReportFailed ? (
        <p>Error loading data</p>
      ) : (
        <>
          <Grid item xs={12} mb={2}>
            <Chart
              chartType="LineChart"
              width="100%"
              height={chartOptions.height + 'px'}
              data={totalBusinessWiseReport}
              options={chartOptions}
              getChartWrapper={chartWrapper => setChartWrapper(chartWrapper)}
            />
          </Grid>
          {/* Custom Legend */}
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap'
              }}
            >
              {legendItems.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '5px 10px'
                  }}
                >
                  <div
                    style={{
                      width: '22px',
                      height: '12px',
                      backgroundColor: item.color,
                      marginRight: '5px'
                    }}
                  ></div>
                  <span
                    style={{
                      fontSize: '12px',
                      fontWeight: 'normal',
                      fontFamily: 'Arial, sans-serif'
                    }}
                  >
                    {item.text}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <Dialog
            open={isExpanded}
            onClose={handleClickOpen}
            maxWidth="lg"
            fullWidth
          >
            <DialogContent>
              <Chart
                chartType="LineChart"
                width="100%"
                height="500px"
                data={totalBusinessWiseReport}
                options={{ ...chartOptions, height: 500 }}
              />
              {/* Custom Legend for Expanded View */}
              <div style={{ textAlign: 'center', marginTop: '10px' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap'
                  }}
                >
                  {legendItems.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '5px 10px'
                      }}
                    >
                      <div
                        style={{
                          width: '22px',
                          height: '12px',
                          backgroundColor: item.color,
                          marginRight: '5px'
                        }}
                      ></div>
                      <span
                        style={{
                          fontSize: '12px',
                          fontWeight: 'normal',
                          fontFamily: 'Arial, sans-serif'
                        }}
                      >
                        {item.text}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </>
      )}
    </div>
  );
};

export default TotalBusinessWiseLineChart;
