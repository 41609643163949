import { Add, Remove } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography
} from '@mui/material';
import AccordionComponent from '../../components/AccordionComponent';
import {
  colorGolden,
  colorLightGray,
  colorMidLightGray
} from '../../config/theme';
import { useEffect, useState } from 'react';
import { LeadDetails } from './LeadDetails';
import QDEDetails from './QDEDetails';
import { BureauDetails } from './BureauDetails';
import { BankAnalysisDetails } from './BankAnalysisDetails';
import { DeviationMailUpload } from './DeviationMailUpload';
import Decisioning from './Decisioning';
import { StyledGrid } from '../../utils/styledLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchBureauResult,
  getCaseCountDetails,
  getCaseSummaryDetails,
  getTotalPoolAmount
} from '../../redux/reducers/casesummarySlice';
import SkeletonBars from '../../components/SkeletonBars';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BackButton from '../../components/BackButton';
import { countBureauFlags, createTitle } from '../../utils/utils';

const accordianSummaryStyle = {
  height: 60,
  backgroundColor: colorLightGray,
  borderBottom: `1px solid ${colorMidLightGray}`,
  fontWeight: '600'
};

const accordianStyle = {
  border: `1px solid ${colorMidLightGray}`,
  width: '100%',
  mt: 1
};

export const CaseSummary = () => {
  const { id, userId } = useParams();

  const [flagCount, setFlagCount] = useState();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = createTitle('Case Summary');
  }, []);

  const {
    isCaseSummaryLoading,
    isCaseSummaryFailed,
    bureauResult,
    caseSummaryDetails
  } = useSelector(state => state.caseSummary);
  const borrowerDetails = caseSummaryDetails['borrowerDetails'];

  const [expanded, setExpanded] = useState({ ['caseDetails']: true });

  const [detailExpanded, setDetailExpanded] = useState({
    ['leadDetails']: true
  });

  const handleAccordionChange = panel => isExpanded => {
    setExpanded({ [panel]: isExpanded });
  };

  const handleDetailAccordionChange = panel => isExpanded => {
    setDetailExpanded({ [panel]: isExpanded });
  };

  useEffect(() => {
    dispatch(getCaseSummaryDetails({ userId: userId, borrowingDetailsId: id }));
    dispatch(getCaseCountDetails());
    dispatch(getTotalPoolAmount());
  }, []);

  useEffect(() => {
    setFlagCount(countBureauFlags(bureauResult));
  }, [bureauResult]);

  useEffect(() => {
    if (borrowerDetails?.lbUserId) {
      dispatch(fetchBureauResult(borrowerDetails?.lbUserId));
    }
  }, [borrowerDetails]);

  return (
    <>
      <StyledGrid
        container
        direction="row"
        justifyContent="left"
        alignItems="left"
      >
        {isCaseSummaryLoading ? (
          <SkeletonBars />
        ) : !isCaseSummaryFailed ? (
          <>
            <BackButton text="Back to Dashboard" path="/credit-dashboard" />
            <Grid container>
              <Grid item xs={12} mt={5}>
                <AccordionComponent
                  style={{
                    minHeight: 60,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                  key="caseDetails"
                  onChange={() =>
                    handleAccordionChange('caseDetails')(
                      !expanded['caseDetails']
                    )
                  }
                  expanded={Boolean(expanded[`caseDetails`])}
                  titleStyleProp={{ fontSize: 20 }}
                  icon={
                    !expanded[`caseDetails`] ? (
                      <Add sx={{ color: colorGolden, fontSize: '2rem' }} />
                    ) : (
                      <Remove sx={{ color: colorGolden, fontSize: '2rem' }} />
                    )
                  }
                  title={'Case Details'}
                >
                  {expanded[`caseDetails`] && (
                    <>
                      <Accordion
                        sx={accordianStyle}
                        onChange={() =>
                          handleDetailAccordionChange('leadDetails')(
                            !detailExpanded['leadDetails']
                          )
                        }
                        expanded={Boolean(detailExpanded[`leadDetails`])}
                        elevation={0}
                        key="leadDetails"
                      >
                        <AccordionSummary
                          sx={accordianSummaryStyle}
                          expandIcon={
                            !detailExpanded[`leadDetails`] ? (
                              <Add
                                sx={{ color: colorGolden, fontSize: '2rem' }}
                              />
                            ) : (
                              <Remove
                                sx={{ color: colorGolden, fontSize: '2rem' }}
                              />
                            )
                          }
                        >
                          Lead Details
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 0 }}>
                          {detailExpanded[`leadDetails`] && <LeadDetails />}
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        sx={accordianStyle}
                        onChange={() =>
                          handleDetailAccordionChange('qde')(
                            !detailExpanded['qde']
                          )
                        }
                        expanded={Boolean(detailExpanded[`qde`])}
                        elevation={0}
                        key="qde"
                      >
                        <AccordionSummary
                          sx={accordianSummaryStyle}
                          expandIcon={
                            !detailExpanded[`item-${1}`] ? (
                              <Add
                                sx={{ color: colorGolden, fontSize: '2rem' }}
                              />
                            ) : (
                              <Remove
                                sx={{ color: colorGolden, fontSize: '2rem' }}
                              />
                            )
                          }
                        >
                          QDE
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 0 }}>
                          {detailExpanded[`qde`] && <QDEDetails />}
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        sx={accordianStyle}
                        onChange={() =>
                          handleDetailAccordionChange('beauraoResult')(
                            !detailExpanded['beauraoResult']
                          )
                        }
                        expanded={Boolean(detailExpanded[`beauraoResult`])}
                        elevation={0}
                        key="beauraoResult"
                      >
                        <AccordionSummary
                          sx={{
                            height: 60,
                            backgroundColor: colorLightGray,
                            borderBottom: `1px solid ${colorMidLightGray}`,
                            fontWeight: '600'
                          }}
                          expandIcon={
                            !detailExpanded[`beauraoResult`] ? (
                              <Add
                                sx={{ color: colorGolden, fontSize: '2rem' }}
                              />
                            ) : (
                              <Remove
                                sx={{ color: colorGolden, fontSize: '2rem' }}
                              />
                            )
                          }
                        >
                          Bureau Result
                        </AccordionSummary>
                        <AccordionDetails>
                          {detailExpanded[`beauraoResult`] && <BureauDetails />}
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        sx={accordianStyle}
                        onChange={() =>
                          handleDetailAccordionChange('bankAnalysis')(
                            !detailExpanded['bankAnalysis']
                          )
                        }
                        expanded={Boolean(detailExpanded[`bankAnalysis`])}
                        elevation={0}
                        key="bankAnalysis"
                      >
                        <AccordionSummary
                          sx={accordianSummaryStyle}
                          expandIcon={
                            !detailExpanded[`bankAnalysis`] ? (
                              <Add
                                sx={{ color: colorGolden, fontSize: '2rem' }}
                              />
                            ) : (
                              <Remove
                                sx={{ color: colorGolden, fontSize: '2rem' }}
                              />
                            )
                          }
                        >
                          Bank Analysis
                        </AccordionSummary>
                        <AccordionDetails>
                          {detailExpanded['bankAnalysis'] && (
                            <BankAnalysisDetails />
                          )}
                        </AccordionDetails>
                      </Accordion>
                      {flagCount > 2 && (
                        <Accordion
                          sx={accordianStyle}
                          onChange={() =>
                            handleDetailAccordionChange('deviationMailUpload')(
                              !detailExpanded['deviationMailUpload']
                            )
                          }
                          expanded={Boolean(
                            detailExpanded[`deviationMailUpload`]
                          )}
                          elevation={0}
                          key="deviationMailUpload"
                        >
                          <AccordionSummary
                            sx={accordianSummaryStyle}
                            expandIcon={
                              !detailExpanded[`deviationMailUpload`] ? (
                                <Add
                                  sx={{ color: colorGolden, fontSize: '2rem' }}
                                />
                              ) : (
                                <Remove
                                  sx={{ color: colorGolden, fontSize: '2rem' }}
                                />
                              )
                            }
                          >
                            Deviation Mail Upload
                          </AccordionSummary>
                          <AccordionDetails>
                            {detailExpanded['deviationMailUpload'] && (
                              <DeviationMailUpload />
                            )}
                          </AccordionDetails>
                        </Accordion>
                      )}
                    </>
                  )}
                </AccordionComponent>

                <AccordionComponent
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                  onChange={() =>
                    handleAccordionChange('decisioning')(
                      !expanded['decisioning']
                    )
                  }
                  expanded={Boolean(expanded[`decisioning`])}
                  titleStyleProp={{ fontSize: 20 }}
                  icon={
                    !expanded[`decisioning`] ? (
                      <Add sx={{ color: colorGolden, fontSize: '2rem' }} />
                    ) : (
                      <Remove sx={{ color: colorGolden, fontSize: '2rem' }} />
                    )
                  }
                  title={'Decisioning'}
                >
                  {expanded[`decisioning`] && (
                    <Decisioning flagCount={flagCount} />
                  )}
                </AccordionComponent>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid
              padding={5}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="body1" fontWeight={800} align="center">
                ⚠️ Apologies, we couldn&apos;t find any details for the
                requested borrower.
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                onClick={() => {
                  navigate('/credit-dashboard');
                  return;
                }}
                variant="contained"
                startIcon={<ArrowBackIcon />}
              >
                Back to Dashboard
              </Button>
            </Grid>
          </>
        )}
      </StyledGrid>
    </>
  );
};
