import { Grid, Icon, Typography } from '@mui/material';
import { colorGrayish } from '../../../config/theme';
import { useSelector } from 'react-redux';
import {
  createTitle,
  formatDate,
  printStringValue,
  toCamelCase
} from '../../../utils/utils';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DOC_TYPE_PAN } from '../../../utils/constant';
import ViewImage from '../../../components/ViewImage';
import { useEffect } from 'react';

export const PANDetails = () => {
  const { caseSummaryDetails } = useSelector(state => state.caseSummary);
  const borrowerDetails = caseSummaryDetails['borrowerDetails'];

  const isPanVerified = Boolean(
    borrowerDetails?.panNumber &&
      borrowerDetails?.dobAsPerPan &&
      borrowerDetails?.nameAsPerPan &&
      borrowerDetails?.gender
  );

  useEffect(() => {
    document.title = createTitle('PAN Details');
  }, []);

  return (
    <>
      {borrowerDetails && (
        <Grid container spacing={2} p={2}>
          <Grid item xs={12} md={12} sm={12}>
            <Typography
              variant="subtitle2"
              fontWeight={'500'}
              color={colorGrayish}
            >
              Pan Card Image
            </Typography>
            <ViewImage key={DOC_TYPE_PAN} docType={DOC_TYPE_PAN} />
          </Grid>

          <Grid item xs={12} md={3} sm={6}>
            <Typography
              variant="subtitle2"
              fontWeight={'500'}
              color={colorGrayish}
            >
              Pan Number
            </Typography>
            <Typography variant="subtitle2" fontWeight={'600'}>
              {printStringValue(borrowerDetails?.panNumber)}
            </Typography>
          </Grid>

          <Grid item xs={12} md={3} sm={6}>
            <Typography
              variant="subtitle2"
              fontWeight={'500'}
              color={colorGrayish}
            >
              Date Of Birth
            </Typography>
            <Typography variant="subtitle2" fontWeight={'600'}>
              {formatDate(borrowerDetails?.dobAsPerPan, 'DD-MM-YYYY')}
            </Typography>
          </Grid>

          <Grid item xs={12} md={3} sm={6}>
            <Typography
              variant="subtitle2"
              fontWeight={'500'}
              color={colorGrayish}
            >
              Name
            </Typography>
            <Typography variant="subtitle2" fontWeight={'600'}>
              {printStringValue(borrowerDetails?.nameAsPerPan)}
            </Typography>
          </Grid>

          <Grid item xs={12} md={3} sm={6}>
            <Typography
              variant="subtitle2"
              fontWeight={'500'}
              color={colorGrayish}
            >
              Gender
            </Typography>
            <Typography variant="subtitle2" fontWeight={'600'}>
              {printStringValue(toCamelCase(borrowerDetails?.gender))}
            </Typography>
          </Grid>

          <Grid item xs={12} md={3} sm={6}>
            <Typography
              variant="subtitle2"
              fontWeight={'500'}
              color={colorGrayish}
            >
              PAN Verification
            </Typography>
            <Typography variant="subtitle2" fontWeight={'600'} display={'flex'}>
              {isPanVerified ? 'Verified' : 'Not Verified'}
              {isPanVerified ? (
                <Icon
                  sx={{ marginLeft: '10px' }}
                  component={CheckCircleIcon}
                  fontSize="medium"
                  color="success"
                />
              ) : (
                <Icon
                  sx={{ marginLeft: '10px' }}
                  component={ErrorIcon}
                  fontSize="medium"
                  color="warning"
                />
              )}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};
