import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api, multipartApi } from '../../config/api';

export const revertAllL2User = createAction('REVERT_ALL_L2_User');
export const clearL2ListNotification = createAction(
  'CLEAR_L2List_NOTIFICATION'
);

export const clearL2ofL1ListNotification = createAction(
  'CLEAR_L2OFL1List_NOTIFICATION'
);

export const clearL2ViewNotification = createAction(
  'CLEAR_L2View_NOTIFICATION'
);

export const clearL2City = createAction('CLEAR_L2_City');

const initial = {
  isCreateEmployeeL2Loading: false,
  isCreateEmployeeL2Success: false,
  isCreateEmployeeL2Failed: false,
  createEmployeeL2ErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isL1UsersListLoading: false,
  isL1UsersListSuccess: false,
  isL1UsersListFailed: false,
  l1UsersList: [],
  l1UsersListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isCityListLoading: false,
  isCityListSuccess: false,
  isCityListFailed: false,
  cityList: [],
  cityListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  islistingL2Loading: false,
  islistingL2Success: false,
  islistingL2Failed: false,
  l2UsersData: {
    totalCount: 0,
    l2UserList: []
  },
  listingL2ErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  islistingL2ofL1Loading: false,
  islistingL2ofL1Success: false,
  islistingL2ofL1Failed: false,
  l2ofL1UsersData: {
    totalCount: 0,
    l2ofL1UserList: []
  },
  listingL2ofL1ErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isemployeeByL2IdLoading: false,
  isemployeeL2ByIdSuccess: false,
  isemployeeL2ByIdFailed: false,
  employeeL2ByIdData: {},
  isemployeeL2ByIdErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  }
};

export const saveEmployeeL2 = createAsyncThunk(
  'admin/saveEmployeeL2',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await multipartApi.post(
        `/admin/saveEmployeeL2`,
        payload
      );

      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchReportToL1 = createAsyncThunk(
  'admin/fetchReportToL1',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`/dropDown/l1`);

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCityMaster = createAsyncThunk(
  'admin/fetchCityMaster',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post(`/dropDown/cityMaster`, payload);

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchListingL2 = createAsyncThunk(
  'admin/listingL2',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post(`/admin/listingL2`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchListingL2ofL1 = createAsyncThunk(
  'admin/listingL2ofL1',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post(`/admin/l1/listingL2`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const employeeL2DetailsById = createAsyncThunk(
  'admin/employeeL2DetailsById',
  async (Id, { rejectWithValue }) => {
    try {
      const response = await api.get(`/admin/employeeL2DeatilsById?id=${Id}`);

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const EmployeeL2Slice = createSlice({
  name: 'l2users',
  initialState: initial,
  extraReducers: builder => {
    builder

      .addCase(revertAllL2User, state => {
        state.isCreateEmployeeL2Loading = false;
        state.isCreateEmployeeL2Success = false;
        state.isCreateEmployeeL2Failed = false;
        state.createEmployeeL2ErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(clearL2City, state => {
        state.isCityListLoading = false;
        state.isCityListSuccess = false;
        state.isCityListFailed = false;
        state.cityList = [];
        state.cityListErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })

      .addCase(clearL2ListNotification, state => {
        (state.islistingL2Loading = false),
          (state.islistingL2Success = false),
          (state.l2UsersData = {
            totalCount: 0,
            l2UserList: []
          }),
          (state.islistingL2Failed = false),
          (state.listingL2ErrorContainer = {
            error: false,
            errorMessage: 'Internal Server Error',
            description: 'Something Went Wrong',
            statusCode: 0
          });
      })

      .addCase(clearL2ofL1ListNotification, state => {
        (state.islistingL2ofL1Loading = false),
          (state.islistingL2ofL1Success = false),
          (state.l2ofL1UsersData = {
            totalCount: 0,
            l2ofL1UserList: []
          }),
          (state.islistingL2ofL1Failed = false),
          (state.listingL2ofL1ErrorContainer = {
            error: false,
            errorMessage: 'Internal Server Error',
            description: 'Something Went Wrong',
            statusCode: 0
          }),
          (state.isemployeeByL2IdLoading = false),
          (state.isemployeeL2ByIdSuccess = false),
          (state.isemployeeL2ByIdFailed = false),
          (state.employeeL2ByIdData = initial.employeeL2ByIdData),
          (state.isemployeeL2ByIdErrorContainer = {
            error: false,
            errorMessage: 'Internal Server Error',
            description: 'Something Went Wrong',
            statusCode: 0
          });
      })
      .addCase(clearL2ViewNotification, state => {
        (state.isemployeeByL2IdLoading = false),
          (state.isemployeeL2ByIdSuccess = false),
          (state.isemployeeL2ByIdFailed = false),
          (state.employeeL2ByIdData = initial.employeeL2ByIdData),
          (state.isemployeeL2ByIdErrorContainer = {
            error: false,
            errorMessage: 'Internal Server Error',
            description: 'Something Went Wrong',
            statusCode: 0
          });
      })
      .addCase(saveEmployeeL2.pending, state => {
        state.isCreateEmployeeL2Loading = true;
        state.isCreateEmployeeL2Success = false;
        state.isCreateEmployeeL2Failed = false;
        state.createEmployeeL2ErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(saveEmployeeL2.fulfilled, state => {
        state.isCreateEmployeeL2Loading = false;
        state.isCreateEmployeeL2Failed = false;
        state.isCreateEmployeeL2Success = true;
      })
      .addCase(saveEmployeeL2.rejected, (state, action) => {
        state.isCreateEmployeeL2Loading = false;
        state.isCreateEmployeeL2Success = false;
        state.isCreateEmployeeL2Failed = true;
        state.createEmployeeL2ErrorContainer = {
          ...state.createEmployeeL2ErrorContainer,
          ...action.payload
        };
      })

      .addCase(fetchReportToL1.pending, state => {
        state.isL1UsersListLoading = true;
        state.isL1UsersListSuccess = false;
        state.isL1UsersListFailed = false;
        state.l1UsersListErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(fetchReportToL1.fulfilled, (state, action) => {
        state.isL1UsersListLoading = false;
        state.isL1UsersListSuccess = true;
        state.l1UsersList = action.payload || '';
        state.isL1UsersListFailed = false;
      })

      .addCase(fetchReportToL1.rejected, (state, action) => {
        state.isL1UsersListLoading = false;
        state.isL1UsersListSuccess = false;
        state.isL1UsersListFailed = true;
        state.l1UsersList = initial.l1UsersList;

        state.l1UsersListErrorContainer = {
          ...state.l1UsersListErrorContainer,
          ...action.payload
        };
      })
      .addCase(fetchListingL2ofL1.pending, state => {
        state.islistingL2ofL1Loading = true;
        state.islistingL2ofL1Success = false;
        state.islistingL2ofL1Failed = false;
        state.listingL2ofL1ErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(fetchListingL2ofL1.fulfilled, (state, action) => {
        state.islistingL2ofL1Loading = false;
        state.islistingL2ofL1Success = true;
        state.l2ofL1UsersData = {
          totalCount: action.payload?.count,
          l2ofL1UserList: action.payload?.data
        };
        state.islistingL2ofL1Failed = false;
      })

      .addCase(fetchListingL2ofL1.rejected, (state, action) => {
        state.islistingL2ofL1Loading = false;
        state.islistingL2ofL1Success = false;
        state.islistingL2ofL1Failed = true;
        state.l2ofL1UsersData = initial.l2ofL1UsersData;
        state.listingL2ofL1ErrorContainer = {
          ...state.listingL2ofL1ErrorContainer,
          ...action.payload
        };
      })

      .addCase(fetchCityMaster.pending, state => {
        state.isCityListLoading = true;
        state.isCityListSuccess = false;
        state.isCityListFailed = false;
        state.cityListErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(fetchCityMaster.fulfilled, (state, action) => {
        state.isCityListLoading = false;
        state.isCityListSuccess = true;
        state.cityList = action.payload || '';
        state.isCityListFailed = false;
      })

      .addCase(fetchCityMaster.rejected, (state, action) => {
        state.isCityListLoading = false;
        state.isCityListSuccess = false;
        state.isCityListFailed = true;
        state.cityList = initial.cityList;

        state.cityListErrorContainer = {
          ...state.cityListErrorContainer,
          ...action.payload
        };
      })

      .addCase(fetchListingL2.pending, state => {
        state.islistingL2Loading = true;
        state.islistingL2Success = false;
        state.islistingL2Failed = false;
        state.listingL2ErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(fetchListingL2.fulfilled, (state, action) => {
        state.islistingL2Loading = false;
        state.islistingL2Success = true;
        state.l2UsersData = {
          totalCount: action.payload?.count,
          l2UserList: action.payload?.data
        };
        state.islistingL2Failed = false;
      })

      .addCase(fetchListingL2.rejected, (state, action) => {
        state.islistingL2Loading = false;
        state.islistingL2Success = false;
        state.islistingL2Failed = true;
        state.l2UsersData = initial.l2UsersData;
        state.listingL2ErrorContainer = {
          ...state.listingL2ErrorContainer,
          ...action.payload
        };
      })

      .addCase(employeeL2DetailsById.pending, state => {
        state.isemployeeByL2IdLoading = true;
        state.isemployeeL2ByIdSuccess = false;
        state.isemployeeL2ByIdFailed = false;
        state.isemployeeL2ByIdErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(employeeL2DetailsById.fulfilled, (state, action) => {
        state.isemployeeByL2IdLoading = false;
        state.isemployeeL2ByIdSuccess = true;
        state.employeeL2ByIdData = action.payload || '';
        state.isemployeeL2ByIdFailed = false;
      })

      .addCase(employeeL2DetailsById.rejected, (state, action) => {
        state.isemployeeByL2IdLoading = false;
        state.isemployeeL2ByIdSuccess = false;
        state.isemployeeL2ByIdFailed = true;
        state.employeeL2ByIdData = initial.employeeL2ByIdData;

        state.isemployeeL2ByIdErrorContainer = {
          ...state.isemployeeL2ByIdErrorContainer,
          ...action.payload
        };
      });
  }
});

export default EmployeeL2Slice.reducer;
