import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
  reSendOtpForResetPassword,
  verifyOtpForResetPassword
} from '../../../redux/reducers/authSlice';
import {
  Box,
  Button,
  FormControl,
  Grid,
  Tooltip,
  Typography
} from '@mui/material';
import {
  enterEmailOrMobile,
  enterOnlyNumbers,
  maskMobile
} from '../../../utils/utils';
import GenericInput from '../../../components/GenericInput';
import OtpTimer from '../../../components/OtpTimer';
import { FormButtonBottom } from '../../../utils/styledLayout';
import PasswordIcon from '@mui/icons-material/Password';
import { LoadingButton } from '@mui/lab';
import ShowBackToLoginButton from './ShowBackToLoginButton';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

const formControl = {
  margin: '0.5rem auto'
};
function VerifyOtpForm({
  mobileNumber,
  oneMinuteFromNow,
  otpExpired,
  setOTPExpired
}) {
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    isReSendOtpForResetPasswordLoading,
    isVerifyOtpForResetPasswordFailed
  } = useSelector(state => state.auth);

  const otpformik = useFormik({
    initialValues: {
      otp: ''
    },
    validationSchema: yup.object({
      otp: yup
        .string()
        .test('otp', 'OTP must be of 4 digits', val => val.length === 4)
        .required('OTP is required')
    }),
    onSubmit: values => {
      const loginData = {
        mobileNo: mobileNumber.trim(),
        mobileOTP: values.otp.trim(),
        module: location?.state
      };
      dispatch(verifyOtpForResetPassword(loginData));
    }
  });

  useEffect(() => {
    otpformik.resetForm();
  }, [isVerifyOtpForResetPasswordFailed]);

  const resendOtp = async () => {
    setOTPExpired(false);
    otpformik.resetForm();
    if (otpExpired) {
      dispatch(reSendOtpForResetPassword({ mobileNo: mobileNumber }));
    }
  };

  const handleOTPTimerExpired = () => {
    setOTPExpired(true);
  };

  return (
    <Box sx={{ marginTop: '2rem' }}>
      <form autoComplete="off" onSubmit={otpformik.handleSubmit}>
        <Box>
          <Typography mt="1.5rem" align="center" variant="subtitle2">
            OTP has been sent on registered mobile number{' '}
          </Typography>
          <Typography align="center" fontWeight={'600'}>
            {maskMobile(enterEmailOrMobile(mobileNumber))}
          </Typography>

          <FormControl sx={{ ...formControl, mt: 3 }} fullWidth>
            <GenericInput
              label="Enter OTP"
              required
              name="otp"
              size="small"
              inputProps={{ maxLength: 4 }}
              value={otpformik.values.otp}
              onChange={e =>
                otpformik.setFieldValue('otp', enterOnlyNumbers(e.target.value))
              }
              error={otpformik.touched.otp && otpformik.errors.otp}
              startIcon={<PasswordIcon className="icon_color" />}
            />
          </FormControl>

          <Grid container>
            <Grid item textAlign={'start'} xs>
              {!otpExpired && (
                <OtpTimer
                  typographyStyle={{ margin: 0, textAlignLast: 'start' }}
                  expiryTimestamp={oneMinuteFromNow}
                  onTimeout={handleOTPTimerExpired}
                />
              )}
            </Grid>
            <Grid item textAlign={'end'} xs>
              <Tooltip
                placement="top"
                title={otpExpired ? `Click to Resend the OTP` : ''}
              >
                <Typography sx={formControl}>
                  <Button
                    size="medium"
                    onClick={resendOtp}
                    style={{ fontWeight: '700' }}
                    disabled={!otpExpired || isReSendOtpForResetPasswordLoading}
                  >
                    {isReSendOtpForResetPasswordLoading
                      ? 'Sending otp'
                      : 'RESEND OTP'}
                  </Button>
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>

          <FormButtonBottom sx={formControl}>
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="medium"
              sx={{
                mt: 3,
                fontSize: 15,
                fontWeight: '600',
                textTransform: 'uppercase'
              }}
            >
              Verify & Continue
            </LoadingButton>
          </FormButtonBottom>
          <ShowBackToLoginButton />
        </Box>
      </form>
    </Box>
  );
}

VerifyOtpForm.propTypes = {
  mobileNumber: PropTypes.string,
  oneMinuteFromNow: PropTypes.string,
  otpExpired: PropTypes.bool,
  setOTPExpired: PropTypes.func
};

export default VerifyOtpForm;
