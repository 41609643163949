import { useEffect, useState } from 'react';
import { StyledGrid } from '../../../../utils/styledLayout';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import CustomDataTable from '../../../../components/CustomDataTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearDeviationSubmitNotification,
  fetchDeviationUploads,
  stopDeviationProcess,
  submitApprovedDeviationDecision,
  submitRejectedDeviationDecision
} from '../../../../redux/reducers/adminSlice';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { LoadingButton } from '@mui/lab';
import {
  createTitle,
  formatDate,
  handleError,
  isArrayNotEmpty
} from '../../../../utils/utils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from 'react-router';
import Loader from '../../../../components/Loader';
import {
  DEVIATION_FAILED,
  DEVIATION_SUCCESS
} from '../../../../utils/messages';
import ResponseModal from '../../../../components/ResponseModal';
import DeviationFilter from './DeviationFilter';
import DownloadIcon from '@mui/icons-material/Download';
import { downloadDeviationDocument } from '../../../../redux/reducers/casesummarySlice';
import { colorGolden } from '../../../../config/theme';

function AdminDaviationUpload() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const headersList = [
    {
      uniquekey: 'id',
      id: 'loanType',
      label: 'Loan Type',
      sortable: true
    },
    {
      id: 'fullname',
      label: 'Full Name',
      sortable: true
    },
    {
      id: 'entryDate',
      label: 'Created on',
      sortable: true
    },
    { id: 'status', label: 'Credit Status', sortable: true },
    {
      id: 'fileName',
      label: 'Document',
      sortable: true,
      render: row => {
        return (
          <>
            <Tooltip placement="top" title="Download File">
              <IconButton
                aria-label="view"
                id="view-button"
                aria-haspopup="true"
                onClick={() => handleDownloadFile(row)}
              >
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      }
    },
    {
      id: 'view',
      label: 'View',
      sortable: true,
      render: row => {
        return (
          <>
            <IconButton
              aria-label="view"
              id="view-button"
              aria-haspopup="true"
              onClick={() => handleViewClick(row)}
            >
              <RemoveRedEyeIcon />
            </IconButton>
          </>
        );
      }
    }
  ];

  const handleViewClick = row => {
    return navigate(`/deviation-qde/${row?.borrowingDetailsId}/${row?.userId}`);
  };

  const handleDownloadFile = row => {
    dispatch(
      downloadDeviationDocument({
        fileName: row?.fileName,
        fileUrl: row?.s3objectKey
      })
    );
  };

  const [pageData, setPageData] = useState({
    page: 0,
    size: 5,
    status: '',
    fromDate: '',
    toDate: '',
    fullname: '',
    loanType: ''
  });

  const [selectedValues, setSelectedValues] = useState(null);

  const {
    isFetchDeviationUploadLoading,
    deviationUploadDetails,
    isDeviationDecisionApproveLoading,
    isDeviationDecisionApproveSuccess,
    isDeviationDecisionApproveFailed,
    deviationDecisionApproveErrorContainer,
    isDeviationDecisionRejectLoading,
    isDeviationDecisionRejectSuccess,
    isDeviationDecisionRejectFailed,
    deviationDecisionRejectErrorContainer
  } = useSelector(state => state.admin);

  useEffect(() => {
    document.title = createTitle('Deviation Upload');
    return () => dispatch(clearDeviationSubmitNotification());
  }, []);

  useEffect(() => {
    dispatch(fetchDeviationUploads(pageData));
  }, [pageData]);

  useEffect(() => {
    if (
      (!isFetchDeviationUploadLoading && isDeviationDecisionApproveSuccess) ||
      (!isDeviationDecisionRejectLoading && isDeviationDecisionRejectSuccess)
    ) {
      dispatch(fetchDeviationUploads(pageData));
    }
    setSelectedValues(null);
  }, [isDeviationDecisionApproveSuccess, isDeviationDecisionRejectSuccess]);

  const onPageChange = page => {
    setPageData({ ...pageData, page: page });
  };
  const onRowsPerPageChange = page => {
    setPageData({ ...pageData, size: page });
  };

  const onSelectionChange = value => {
    setSelectedValues(value);
  };

  const findById = (array, id) => array.find(item => item.id === id);

  const handleDeviationActionClick = action => {
    const deviationDetails = findById(
      deviationUploadDetails['content'],
      selectedValues[0]
    );

    let deviationDecisionPayload = {
      borrowingDetailsId: deviationDetails?.borrowingDetailsId,
      actionTakenBy: deviationDetails?.userId,
      remarks: 'SUBMITTED BY ADMIN',
      status: action === 'approve' ? 'Approved' : 'Rejected',
      deviationName: deviationDetails?.deviationName
    };

    if (action === 'approve') {
      dispatch(submitApprovedDeviationDecision(deviationDecisionPayload));
    } else {
      dispatch(submitRejectedDeviationDecision(deviationDecisionPayload));
    }
  };

  const handleDeviationClose = () => {
    dispatch(stopDeviationProcess());
  };

  const onFilterSubmit = values => {
    setPageData({
      ...pageData,
      page: 0,
      status: values.searchCriteria === 'status' ? values.searchField : '',
      fromDate: values.startDate
        ? formatDate(values.startDate, 'YYYY-MM-DD')
        : '',
      toDate: values.endDate ? formatDate(values.endDate, 'YYYY-MM-DD') : '',
      fullname: values.searchCriteria === 'fullName' ? values.searchField : '',
      loanType: values.searchCriteria === 'loanType' ? values.searchField : ''
    });
  };

  return (
    <StyledGrid
      container
      direction="row"
      justifyContent="left"
      alignItems="left"
    >
      <Box
        sx={{
          borderRadius: 5,
          backgroundColor: 'white',
          width: '100%',
          boxShadow: '0px 1px 17px -5px rgba(0,0,0,0.67)',
          paddingLeft: 1,
          paddingRight: 1
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="left"
          spacing={3}
          paddingRight={2}
          paddingTop={3}
          paddingBottom={2}
        >
          <Grid item>
            <DeviationFilter onFilterSubmit={onFilterSubmit} />
          </Grid>
        </Grid>

        <Grid container direction="row" sx={{ marginTop: 3 }}>
          <CustomDataTable
            columns={headersList}
            data={deviationUploadDetails['content']}
            headStyle={{ backgroundColor: colorGolden, color: 'black' }}
            totalCount={deviationUploadDetails['totalElements']}
            defaultRowsPerPage={5}
            defaultPage={pageData?.page}
            onRowsPerPageChange={onRowsPerPageChange}
            onPageChange={onPageChange}
            isCursor={false}
            allowSelection={true}
            onSelectionChange={onSelectionChange}
            selectedRows={selectedValues}
            isLoading={isFetchDeviationUploadLoading}
            checkType="radio"
          />
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          marginBottom={2}
        >
          <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
            <LoadingButton
              startIcon={<CancelIcon />}
              fullWidth
              style={{ textTransform: 'uppercase' }}
              variant="contained"
              color="error"
              onClick={() => handleDeviationActionClick('reject')}
              disabled={Boolean(!isArrayNotEmpty(selectedValues))}
            >
              Reject
            </LoadingButton>
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
            <LoadingButton
              startIcon={<CheckCircleIcon />}
              fullWidth
              style={{ textTransform: 'uppercase' }}
              variant="contained"
              color="success"
              onClick={() => handleDeviationActionClick('approve')}
              disabled={Boolean(!isArrayNotEmpty(selectedValues))}
            >
              Approve
            </LoadingButton>
          </Grid>
        </Grid>

        {isDeviationDecisionApproveLoading && (
          <Loader key="loading_deviation_approved" />
        )}

        {!isDeviationDecisionApproveLoading &&
          isDeviationDecisionApproveSuccess && (
            <ResponseModal
              buttonText="Ok"
              fullwidth={true}
              message={DEVIATION_SUCCESS}
              onClose={handleDeviationClose}
              type="success"
              key="approved_message_success"
            />
          )}

        {!isDeviationDecisionApproveLoading &&
          isDeviationDecisionApproveFailed && (
            <ResponseModal
              buttonText="Ok"
              fullwidth={true}
              message={handleError(
                deviationDecisionApproveErrorContainer?.errorMessage
              )}
              onClose={handleDeviationClose}
              type="error"
              key="approved_message_failed"
            />
          )}

        {isDeviationDecisionRejectLoading && (
          <Loader key="loading_deviation_rejected" />
        )}

        {!isDeviationDecisionRejectLoading &&
          isDeviationDecisionRejectSuccess && (
            <ResponseModal
              buttonText="Ok"
              fullwidth={true}
              message={DEVIATION_FAILED}
              onClose={handleDeviationClose}
              type="success"
              key="rejected_message_success"
            />
          )}

        {!isDeviationDecisionRejectLoading &&
          isDeviationDecisionRejectFailed && (
            <ResponseModal
              buttonText="Ok"
              fullwidth={true}
              message={handleError(
                deviationDecisionRejectErrorContainer?.errorMessage
              )}
              onClose={handleDeviationClose}
              type="error"
              key="rejected_message_failed"
            />
          )}
      </Box>
    </StyledGrid>
  );
}

export default AdminDaviationUpload;
