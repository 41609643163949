import { Grid, Typography } from '@mui/material';
import { colorGrayish } from '../../../config/theme';
import { useSelector } from 'react-redux';
import {
  createTitle,
  printStringValue,
  toCamelCase
} from '../../../utils/utils';
import { useEffect } from 'react';

export const NonFamilyReferenceDetails = () => {
  const { caseSummaryDetails } = useSelector(state => state.caseSummary);

  const references = caseSummaryDetails['references'];

  const isNonFamilyReference = references => {
    return references?.referenceTag === 'nonFamily';
  };

  useEffect(() => {
    document.title = createTitle('Non-Family Reference');
  }, []);

  return (
    <>
      <Grid container spacing={2} p={2}>
        <Grid item xs={12} md={3} sm={6}>
          <Typography
            variant="subtitle2"
            fontWeight={'500'}
            color={colorGrayish}
          >
            Relationship
          </Typography>
          <Typography variant="subtitle2" fontWeight={'600'}>
            {isNonFamilyReference(references)
              ? printStringValue(toCamelCase(references?.referenceRelationship))
              : '-'}
          </Typography>
        </Grid>

        <Grid item xs={12} md={3} sm={6}>
          <Typography
            variant="subtitle2"
            fontWeight={'500'}
            color={colorGrayish}
          >
            Name
          </Typography>
          <Typography variant="subtitle2" fontWeight={'600'}>
            {' '}
            {isNonFamilyReference(references)
              ? printStringValue(references?.referenceName)
              : '-'}
          </Typography>
        </Grid>

        <Grid item xs={12} md={3} sm={6}>
          <Typography
            variant="subtitle2"
            fontWeight={'500'}
            color={colorGrayish}
          >
            Mobile Number
          </Typography>
          <Typography variant="subtitle2" fontWeight={'600'}>
            {isNonFamilyReference(references)
              ? printStringValue(references?.mobileNo)
              : '-'}
          </Typography>
        </Grid>

        <Grid item xs={12} md={3} sm={6}>
          <Typography
            variant="subtitle2"
            fontWeight={'500'}
            color={colorGrayish}
          >
            Email ID
          </Typography>
          <Typography variant="subtitle2" fontWeight={'600'}>
            {isNonFamilyReference(references)
              ? printStringValue(references?.emailId)
              : '-'}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
