import { LoadingButton } from '@mui/lab';
import { Dialog, DialogContent, Grid } from '@mui/material';
import { useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import UploadIcon from '@mui/icons-material/Upload';
import CancelIcon from '@mui/icons-material/Cancel';
import PropTypes from 'prop-types';

const ImageCropper = ({
  incomingFile,
  handleFileUpload,
  handleFileCancel,
  uploadedFileName = ''
}) => {
  const initialCrop = { unit: '%', height: 100, width: 100, aspect: 16 / 9 };

  const [crop, setCrop] = useState(initialCrop);
  const [completedCrop, setCompletedCrop] = useState(null);
  const imgRef = useRef(null);
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  async function handleCrop() {
    const croppedImage = completedCrop
      ? await getCroppedImage(imgRef.current, completedCrop)
      : await getEntireImage(imgRef.current);
    handleFileUpload(croppedImage);
    setOpen(false);
  }
  async function getEntireImage(image) {
    const canvas = document.createElement('canvas');
    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

    return new Promise(resolve => {
      canvas.toBlob(
        blob => {
          if (blob) {
            blob.arrayBuffer().then(buffer => {
              const file = new File(
                [buffer],
                uploadedFileName ? uploadedFileName : 'CroppedImage.jpg',
                {
                  type: 'image/jpeg'
                }
              );
              resolve(file);
            });
          }
        },
        'image/jpeg',
        0.9
      );
    });
  }

  const onCancel = () => {
    setCompletedCrop(null);
    handleFileCancel();
    setOpen(false);
  };

  async function getCroppedImage(image, crop) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise(resolve => {
      canvas.toBlob(
        blob => {
          if (blob) {
            blob.arrayBuffer().then(buffer => {
              const file = new File(
                [buffer],
                uploadedFileName ? uploadedFileName : 'CroppedImage.jpg',
                {
                  type: 'image/jpeg'
                }
              );
              resolve(file);
            });
          }
        },
        'image/jpeg',
        0.9
      );
    });
  }

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={(_event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        disableEscapeKeyDown
        scroll="body"
        className="custom-dialog"
      >
        <DialogContent className="MuiDialogContentRootc">
          {incomingFile && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={c => setCompletedCrop(c)}
            >
              <img ref={imgRef} alt="Crop Image" src={incomingFile} />
            </ReactCrop>
          )}

          <Grid
            spacing={3}
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            marginTop={1}
          >
            <Grid item xs>
              <LoadingButton
                startIcon={<UploadIcon />}
                fullWidth
                style={{ textTransform: 'uppercase' }}
                variant="contained"
                color="primary"
                size="small"
                onClick={handleCrop}
              >
                Upload
              </LoadingButton>
            </Grid>
            <Grid item xs>
              <LoadingButton
                startIcon={<CancelIcon />}
                fullWidth
                style={{ textTransform: 'uppercase' }}
                variant="contained"
                color="error"
                size="small"
                onClick={onCancel}
              >
                Cancel
              </LoadingButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

ImageCropper.propTypes = {
  incomingFile: PropTypes.any,
  handleFileUpload: PropTypes.func,
  handleFileCancel: PropTypes.func,
  uploadedFileName: PropTypes.string
};

export default ImageCropper;
