import {
  Button,
  Grid,
  Paper,
  Skeleton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createTitle, getImageDetails, handleError } from '../../utils/utils';
import {
  colorLightGray,
  colorMidLightGray,
  colorPrimaryBlue
} from '../../config/theme';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { DOC_TYPE_BANK_ANALYSIS } from '../../utils/constant';
import {
  clearDeviationUploadNotification,
  downloadDeviationDocument
} from '../../redux/reducers/casesummarySlice';
import SnackBarBox from '../../components/SnackBarBox';

export const BankAnalysisDetails = () => {
  const dispatch = useDispatch();

  const capptureButton = {
    color: colorPrimaryBlue,
    padding: '5px'
  };

  const {
    isBankAnalysisDetailsLoading,
    caseSummaryDetails,
    isDownloadDeviationDocsLoading,
    downloadDeviationDocsErrorContainer,
    isDownloadDeviationDocsFailed
  } = useSelector(state => state.caseSummary);

  const documentUploads = caseSummaryDetails['documentUploads'];

  const [fileName, setFileName] = useState();

  const [currentUploadedFile, setCurrentUploadedFile] = useState(null);

  useEffect(() => {
    document.title = createTitle('Bank Analysis Details');
    const uploadedLink = getImageDetails(
      DOC_TYPE_BANK_ANALYSIS,
      documentUploads
    );
    setCurrentUploadedFile(uploadedLink);
    setFileName(uploadedLink?.fileName);
    return () => dispatch(clearDeviationUploadNotification());
  }, []);

  const downloadDocument = () => {
    dispatch(
      downloadDeviationDocument({
        fileName: currentUploadedFile?.fileName,
        fileUrl: currentUploadedFile?.s3ObjectKey
      })
    );
  };

  return (
    <>
      {!isBankAnalysisDetailsLoading && fileName && (
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead
                  sx={{
                    backgroundColor: colorLightGray,
                    borderBottom: `1px solid ${colorMidLightGray}`
                  }}
                >
                  <TableRow>
                    <TableCell sx={{ fontWeight: '600', fontSize: 16 }}>
                      Document Name
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: '600', fontSize: 16 }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    key="perfios"
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 }
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontWeight: '600', fontSize: 15 }}
                    >
                      {fileName}
                    </TableCell>
                    <TableCell sx={{ fontSize: 15, display: 'flex' }}>
                      <Button
                        fullWidth
                        startIcon={<FileDownloadOutlinedIcon />}
                        size="small"
                        sx={capptureButton}
                        onClick={downloadDocument}
                      >
                        Download Document
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}

      {isBankAnalysisDetailsLoading && (
        <Skeleton
          sx={{ p: 0 }}
          variant="rectangular"
          height={100}
          width={'100%'}
        />
      )}

      {!isDownloadDeviationDocsLoading && isDownloadDeviationDocsFailed && (
        <SnackBarBox
          type="error"
          message={handleError(
            downloadDeviationDocsErrorContainer?.errorMessage
          )}
        />
      )}
      {!isBankAnalysisDetailsLoading && !fileName && (
        <Grid
          sx={{
            p: 0,
            height: 100,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          <Typography fontWeight={'600'}>
            No Bank Analysis Detail Found
          </Typography>
        </Grid>
      )}
    </>
  );
};
