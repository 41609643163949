import {
  Box,
  Grid,
  IconButton,
  Skeleton,
  Tooltip,
  Typography
} from '@mui/material';
import { colorDanger } from '../../config/theme';
import { useDispatch, useSelector } from 'react-redux';
import SnackBarBox from '../../components/SnackBarBox';
import {
  createTitle,
  handleError,
  isArrayNotEmpty,
  truncateString
} from '../../utils/utils';
import UploadImage from '../../components/UploadImage';
import { DOC_TYPE_DEVIATION_MAIL } from '../../utils/constant';
import { useEffect } from 'react';
import {
  clearDeviationUploadNotification,
  deleteDeviationDocument,
  downloadDeviationDocument,
  fetchDeviationDocumentList,
  uploadDeviationDocument
} from '../../redux/reducers/casesummarySlice';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  DOCUMENT_DELETE_SUCCESS,
  DOCUMENT_UPLOAD_SUCCESS
} from '../../utils/messages';
import Loader from '../../components/Loader';

export const DeviationMailUpload = () => {
  const dispatch = useDispatch();

  const {
    isFetchDevialtionDocListLoading,
    isFetchDevialtionDocListSuccess,
    isFetchDevialtionDocListFailed,
    fetchDevialtionDocListErrorContainer,
    caseSummaryDetails,
    deviationDocumentList,
    isUploadDeviationDocsLoading,
    isUploadDeviationDocsSuccess,
    isUploadDeviationDocsFailed,
    uploadDeviationDocsErrorContainer,
    isDeleteDeviationDocsLoading,
    isDeleteDeviationDocsSuccess,
    isDeleteDeviationDocsFailed,
    deleteDeviationDocsErrorContainer
  } = useSelector(state => state.caseSummary);

  const borrowerDetails = caseSummaryDetails['borrowerDetails'];

  const borrowingDetails = caseSummaryDetails['borrowingDetails'];

  const onImageUpload = fileData => {
    if (Object.keys(fileData).length !== 0) {
      const formData = new FormData();
      formData.append('file', fileData?.file);
      dispatch(
        uploadDeviationDocument({
          data: formData,
          userId: borrowerDetails?.userId,
          borrowerDetailsId: borrowerDetails?.id,
          referenceId: borrowingDetails?.lbLoanId
        })
      );
    }
  };

  const deleteDeviation = docs => {
    const deviationName = docs?.deviationName;
    const fileName = docs?.fileName;
    const borrowingDetailsId = docs?.borrowingDetailsId;

    dispatch(
      deleteDeviationDocument({
        fileName: fileName,
        deviationName: deviationName,
        borrowingDetailsId: borrowingDetailsId
      })
    );
  };

  const handleDownloadFile = docs => {
    dispatch(
      downloadDeviationDocument({
        fileName: docs?.fileName,
        fileUrl: docs?.s3ObjectKey
      })
    );
  };

  useEffect(() => {
    document.title = createTitle('Deviation Mail Upload');
    dispatch(
      fetchDeviationDocumentList({
        borrowerDetailsId: borrowerDetails?.id,
        referenceId: borrowingDetails?.lbLoanId
      })
    );
    return () => dispatch(clearDeviationUploadNotification());
  }, []);

  useEffect(() => {
    if (
      (!isUploadDeviationDocsLoading && isUploadDeviationDocsSuccess) ||
      (!isDeleteDeviationDocsLoading && isDeleteDeviationDocsSuccess)
    ) {
      dispatch(
        fetchDeviationDocumentList({
          borrowerDetailsId: borrowerDetails?.id,
          referenceId: borrowingDetails?.lbLoanId
        })
      );
    }
  }, [isUploadDeviationDocsSuccess, isDeleteDeviationDocsSuccess]);

  return (
    <>
      <Box sx={{ marginTop: 2 }}>
        <UploadImage
          buttonName="DEVIATION MAIL UPLOAD"
          docName={DOC_TYPE_DEVIATION_MAIL}
          key={DOC_TYPE_DEVIATION_MAIL}
          onImageUpload={onImageUpload}
        />
      </Box>

      {isArrayNotEmpty(deviationDocumentList) &&
        isFetchDevialtionDocListSuccess &&
        deviationDocumentList.map((docs, index) => {
          return (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={3}
              key={`${docs?.fileName}_${index}`}
            >
              <Grid item xs={8} sm={8} md={4} lg={4} xl={4}>
                <Tooltip placement="top" title={`Download ${docs?.fileName}`}>
                  <Typography
                    variant="subtitle2"
                    onClick={() => handleDownloadFile(docs)}
                    style={{ cursor: 'pointer' }}
                  >
                    {index + 1}. {truncateString(docs?.fileName, 35)}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <Tooltip placement="top" title={`Delete ${docs?.fileName}`}>
                  <IconButton
                    onClick={() => {
                      deleteDeviation(docs);
                    }}
                    edge="end"
                    aria-label="delete"
                  >
                    <CancelIcon sx={{ color: colorDanger }} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          );
        })}

      {isFetchDevialtionDocListLoading && (
        <Skeleton
          sx={{ p: 0 }}
          variant="rectangular"
          height={100}
          width={'100%'}
        />
      )}

      {isUploadDeviationDocsLoading && (
        <Loader key="deviation_upload" text="Uploading." />
      )}

      {isDeleteDeviationDocsLoading && (
        <Loader key="deviation_delete" text="Deleting." />
      )}

      {!isFetchDevialtionDocListLoading &&
        !isArrayNotEmpty(deviationDocumentList) && (
          <Grid
            sx={{
              p: 0,
              height: 100,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'center'
            }}
          >
            <Typography fontWeight={'600'}>No deviation mail found.</Typography>
          </Grid>
        )}

      {!isFetchDevialtionDocListLoading && isFetchDevialtionDocListFailed && (
        <SnackBarBox
          type="error"
          message={handleError(
            fetchDevialtionDocListErrorContainer?.errorMessage
          )}
        />
      )}

      {!isUploadDeviationDocsLoading && isUploadDeviationDocsFailed && (
        <SnackBarBox
          type="error"
          message={handleError(uploadDeviationDocsErrorContainer?.errorMessage)}
        />
      )}

      {!isDeleteDeviationDocsLoading && isDeleteDeviationDocsFailed && (
        <SnackBarBox
          type="error"
          message={handleError(deleteDeviationDocsErrorContainer?.errorMessage)}
        />
      )}

      {!isUploadDeviationDocsLoading && isUploadDeviationDocsSuccess && (
        <SnackBarBox type="success" message={DOCUMENT_UPLOAD_SUCCESS} />
      )}

      {!isDeleteDeviationDocsLoading && isDeleteDeviationDocsSuccess && (
        <SnackBarBox type="success" message={DOCUMENT_DELETE_SUCCESS} />
      )}
    </>
  );
};
