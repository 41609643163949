import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api, apiNoAuth, multipartApi } from '../../config/api';
import {
  clearAuthData,
  clearToken,
  setAuthData,
  setToken
} from '../../config/cookie';
import axios from 'axios';
export const revertAll = createAction('REVERT_ALL');
export const setAuthDetailsByLocalStorage = createAction('SET_AUTH_DETAILS');
export const logoutAndClearToken = createAction('LOGOUT_AND_CLEAR_TOKEN');
export const clearLoginState = createAction('CLEAR_LOGIN_STATE');
export const setResetPasswordDetails = createAction(
  'SET_RESET_PASSWORD_DETAILS'
);

export const clearOtpNotification = createAction('SET_CLEAR_OTP_NOTIFICATION');

export const clearAllNotification = createAction('SET_CLEAR_ALL_NOTIFICATION');

const setTokenToApi = token => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  multipartApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const logoutUser = createAsyncThunk(
  'auth/logout',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await apiNoAuth.post('/logout', credentials);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const pingServer = createAsyncThunk(
  'auth/actuator/ping',
  async (_, { rejectWithValue }) => {
    try {
      let url = process.env.REACT_APP_BASE_API_URL;
      const parts = url.split('/api/v1');
      const trimmedURl = parts[0];
      const response = await axios.get(`${trimmedURl}/actuator/health`);
      return response.data;
    } catch (error) {
      if (
        error &&
        error?.message &&
        String(error?.message).includes('Network Error')
      ) {
        return { status: 'DOWN' };
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const authenticateUser = createAsyncThunk(
  'login/authenticate',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await apiNoAuth.post('/authenticate', credentials);
      return response.data;
    } catch (error) {
      if (
        error &&
        error?.message &&
        String(error?.message).includes('Network Error')
      ) {
        return rejectWithValue({
          error: true,
          errorMessage: 'Network Error',
          description: 'Something Went Wrong',
          statusCode: 500
        });
      } else if (
        error?.response &&
        error?.response?.data?.description &&
        String(error?.response?.data?.description)
          .toLowerCase()
          .includes('invalid username/password')
      ) {
        return rejectWithValue({
          error: true,
          errorMessage: 'Incorrect Employee ID or Password. Please try again',
          description: 'Something Went Wrong',
          statusCode: 500
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const sendOtpForResetPassword = createAsyncThunk(
  'register/send_otp',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await apiNoAuth.post(
        '/forgot?resetType=Password',
        credentials
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const reSendOtpForResetPassword = createAsyncThunk(
  'register/re_send_otp',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await apiNoAuth.post(
        '/forgot?resetType=Password',
        credentials
      );
      return response.data;
      // return new Promise((resolve, reject) => {
      //   resolve({
      //     msg: 'SMS send successfully.'
      //   });
      // });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyOtpForResetPassword = createAsyncThunk(
  'register/verify_otp',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await apiNoAuth.post('/login/verifyOTP', credentials);
      return response.data;
      // return new Promise((resolve, reject) => {
      //   resolve({
      //     msg: 'SMS Verified successfully.'
      //   });
      // });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// export const submitRequestForResetPassword = createAsyncThunk(
//   'register/submit_reset_password_request',
//   async ({ payload, token }, { rejectWithValue }) => {
//     try {
//       const response = await apiNoAuth.post(
//         '/resetPasswordForCredit?resetType=Password',
//         payload,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`
//           }
//         }
//       );
//       return response.data;
//       // return new Promise((resolve, reject) => {
//       //   resolve({
//       //     msg: 'SMS Verified successfully.'
//       //   });
//       // });
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

export const submitRequestForResetPassword = createAsyncThunk(
  'register/submit_reset_password_request',
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await apiNoAuth.post(
        '/reset?resetType=Password',
        payload
      );
      return response.data;
      // return new Promise((resolve, reject) => {
      //   resolve({
      //     msg: 'SMS Verified successfully.'
      //   });
      // });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const intial = {
  isLoading: false,
  isLoggedIn: false,
  authData: {
    jwtToken: '',
    role: '',
    email: '',
    mobileNo: '',
    fullName: '',
    userId: 0
  },
  isError: false,
  errorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  resetOtpDetails: {
    otp: '',
    mobileNo: '',
    resetToken: ''
  },
  applicationStatus: 'UP',
  isPingServerLoading: false,
  isPingServerSuccess: false,
  isPingServerFailed: false,
  isSendOtpForResetPasswordLoading: false,
  isSendOtpForResetPasswordSuccess: false,
  isSendOtpForResetPasswordFailed: false,
  sendOtpForResetPasswordErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isVerifyOtpForResetPasswordLoading: false,
  isVerifyOtpForResetPasswordSuccess: false,
  isVerifyOtpForResetPasswordFailed: false,
  verifyOtpForResetPasswordErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isSubmitResetPasswordLoading: false,
  isSubmitResetPasswordSuccess: false,
  isSubmitResetPasswordFailed: false,
  submitResetPasswordErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isReSendOtpForResetPasswordLoading: false,
  isReSendOtpForResetPasswordSuccess: false,
  isReSendOtpForResetPasswordFailed: false,
  reSendOtpForResetPasswordErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  }
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: intial,
  extraReducers: builder => {
    builder
      .addCase(logoutUser.pending, state => {
        (state.isLoading = true),
          (state.isError = false),
          (state.errorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(logoutUser.fulfilled, state => {
        state.isLoading = false;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        clearToken();
        (state.isLoading = false),
          (state.isLoggedIn = false),
          (state.isError = true),
          (state.errorContainer = {
            ...state.errorContainer,
            ...action.payload
          });
      })
      .addCase(revertAll, () => {
        return intial;
      })
      .addCase(logoutAndClearToken, () => {
        clearToken();
        clearAuthData();
        return intial;
      })
      .addCase(clearLoginState, state => {
        (state.isError = false),
          (state.errorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(setAuthDetailsByLocalStorage, (state, action) => {
        (state.isLoading = false),
          (state.isLoggedIn = true),
          (state.authData.userId = Number(action.payload['userId'])),
          (state.authData.role = action.payload['role']),
          (state.authData.fullName = action.payload['fullName']),
          (state.authData.mobileNo = action.payload['mobileNo']),
          (state.authData.email = action.payload['email']);
      })
      .addCase(pingServer.pending, state => {
        (state.isPingServerLoading = true), (state.isPingServerFailed = false);
      })
      .addCase(pingServer.fulfilled, (state, action) => {
        (state.isPingServerLoading = false),
          (state.isPingServerSuccess = true),
          (state.applicationStatus = action.payload?.status);
      })
      .addCase(pingServer.rejected, state => {
        (state.isPingServerLoading = false), (state.isPingServerFailed = true);
      })
      .addCase(authenticateUser.pending, state => {
        (state.isLoading = true),
          (state.isError = false),
          (state.errorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(authenticateUser.fulfilled, (state, action) => {
        setTokenToApi(action.payload?.jwtToken);
        clearToken();
        clearAuthData();
        setToken(action.payload?.jwtToken);
        setAuthData({
          userId: action.payload?.userId,
          role: action.payload?.role,
          fullName: action.payload?.fullName,
          mobileNo: action.payload?.mobileNo,
          email: action.payload?.email
        }),
          (state.isLoading = false),
          (state.isLoggedIn = true),
          (state.authData = { ...state.authData, ...action.payload });
      })
      .addCase(authenticateUser.rejected, (state, action) => {
        (state.isLoggedIn = false),
          (state.isError = true),
          (state.isLoading = false),
          (state.errorContainer = {
            ...state.errorContainer,
            ...action.payload
          });
      })
      .addCase(sendOtpForResetPassword.pending, state => {
        (state.isSendOtpForResetPasswordLoading = true),
          (state.isSendOtpForResetPasswordSuccess = false),
          (state.isSendOtpForResetPasswordFailed = false),
          (state.sendOtpForResetPasswordErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(sendOtpForResetPassword.fulfilled, state => {
        (state.isSendOtpForResetPasswordLoading = false),
          (state.isSendOtpForResetPasswordSuccess = true);
      })
      .addCase(sendOtpForResetPassword.rejected, (state, action) => {
        (state.isSendOtpForResetPasswordLoading = false),
          (state.isSendOtpForResetPasswordSuccess = false),
          (state.isSendOtpForResetPasswordFailed = true),
          (state.sendOtpForResetPasswordErrorContainer = {
            ...state.sendOtpForResetPasswordErrorContainer,
            ...action.payload
          });
      })
      .addCase(reSendOtpForResetPassword.pending, state => {
        (state.isReSendOtpForResetPasswordLoading = true),
          (state.isReSendOtpForResetPasswordSuccess = false),
          (state.isReSendOtpForResetPasswordFailed = false),
          (state.reSendOtpForResetPasswordErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(reSendOtpForResetPassword.fulfilled, state => {
        (state.isReSendOtpForResetPasswordLoading = false),
          (state.isReSendOtpForResetPasswordSuccess = true);
      })
      .addCase(reSendOtpForResetPassword.rejected, (state, action) => {
        (state.isReSendOtpForResetPasswordLoading = false),
          (state.isReSendOtpForResetPasswordSuccess = false),
          (state.isReSendOtpForResetPasswordFailed = true),
          (state.reSendOtpForResetPasswordErrorContainer = {
            ...state.reSendOtpForResetPasswordErrorContainer,
            ...action.payload
          });
      })
      .addCase(setResetPasswordDetails, (state, action) => {
        state.resetOtpDetails = action.payload;
      })
      .addCase(verifyOtpForResetPassword.pending, state => {
        (state.isVerifyOtpForResetPasswordLoading = true),
          (state.isVerifyOtpForResetPasswordSuccess = false),
          (state.isVerifyOtpForResetPasswordFailed = false),
          (state.verifyOtpForResetPasswordErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(verifyOtpForResetPassword.fulfilled, (state, action) => {
        (state.isVerifyOtpForResetPasswordLoading = false),
          (state.isVerifyOtpForResetPasswordSuccess = true),
          (state.isSendOtpForResetPasswordSuccess = false);
        state.isReSendOtpForResetPasswordSuccess = false;
        state.resetOtpDetails.resetToken = action.payload?.jwtToken;
      })
      .addCase(verifyOtpForResetPassword.rejected, (state, action) => {
        (state.isVerifyOtpForResetPasswordLoading = false),
          (state.isVerifyOtpForResetPasswordSuccess = false),
          (state.isVerifyOtpForResetPasswordFailed = true),
          (state.verifyOtpForResetPasswordErrorContainer = {
            ...state.verifyOtpForResetPasswordErrorContainer,
            ...action.payload
          });
      })
      .addCase(submitRequestForResetPassword.pending, state => {
        (state.isSubmitResetPasswordLoading = true),
          (state.isSubmitResetPasswordSuccess = false),
          (state.isSubmitResetPasswordFailed = false),
          (state.submitResetPasswordErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          }),
          (state.isSendOtpForResetPasswordSuccess = false);
      })
      .addCase(submitRequestForResetPassword.fulfilled, state => {
        (state.isSubmitResetPasswordLoading = false),
          (state.isSubmitResetPasswordSuccess = true);
      })
      .addCase(submitRequestForResetPassword.rejected, (state, action) => {
        (state.isSubmitResetPasswordLoading = false),
          (state.isSubmitResetPasswordSuccess = false),
          (state.isSubmitResetPasswordFailed = true),
          (state.submitResetPasswordErrorContainer = {
            ...state.submitResetPasswordErrorContainer,
            ...action.payload
          });
      })
      .addCase(clearOtpNotification, state => {
        state.isSendOtpForResetPasswordSuccess = false;
        state.isSendOtpForResetPasswordFailed = false;
        state.isReSendOtpForResetPasswordSuccess = false;
        state.isReSendOtpForResetPasswordFailed = false;
      })
      .addCase(clearAllNotification, state => {
        (state.isSendOtpForResetPasswordSuccess = false),
          (state.isSendOtpForResetPasswordFailed = false),
          (state.isReSendOtpForResetPasswordSuccess = false),
          (state.isReSendOtpForResetPasswordFailed = false),
          (state.isSubmitResetPasswordSuccess = false),
          (state.isSubmitResetPasswordFailed = false),
          (state.resetOtpDetails.mobileNo = ''),
          (state.resetOtpDetails.otp = ''),
          (state.resetOtpDetails.resetToken = ''),
          (state.isVerifyOtpForResetPasswordFailed = false),
          (state.isVerifyOtpForResetPasswordSuccess = false);
      });
  }
});

export default authSlice.reducer;
