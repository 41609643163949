import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import { useDispatch, useSelector } from 'react-redux';
import {
  ageWiseReportChart,
  revertAgeGroupAnalytics
} from '../../../../redux/reducers/ChartSlice';
import { colorGolden, colorPrimaryBlue } from '../../../../config/theme';
import { Expand05Icon, FileDownload03Icon } from '../../../../utils/iconSvg';
import {
  Grid,
  IconButton,
  SvgIcon,
  Dialog,
  DialogContent,
  Menu,
  MenuItem
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const AgeGroupBarChart = () => {
  const {
    isAgeWiseReportLoading,
    isAgeWiseReportSuccess,
    isAgeWiseReportFailed,
    ageWiseReport,
    isAgeWiseReportErrorContainer
  } = useSelector(state => state.chartReport);

  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(false);
  const [chartWrapper, setChartWrapper] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    dispatch(ageWiseReportChart());
    return () => {
      dispatch(revertAgeGroupAnalytics());
    };
  }, [dispatch]);

  // Function to handle chart expansion using Dialog
  const handleClickOpen = () => {
    setIsExpanded(prev => !prev);
  };

  // Function to download the chart as an image
  const handleDownload = () => {
    if (chartWrapper && chartWrapper.getChart()) {
      try {
        const imageURI = chartWrapper.getChart().getImageURI();
        const link = document.createElement('a');
        link.href = imageURI;
        link.download = 'age-group-chart.png';
        link.click();
      } catch (error) {
        console.error('Error downloading the chart:', error);
      }
    } else {
      console.warn('Chart wrapper or chart instance is not available.');
    }
  };

  // Function to open the menu
  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  // Function to close the menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Chart options
  const chartOptions = {
    title: '',
    chartArea: {
      width: '80%',
      height: '70%',
      bottom: 90
    },
    hAxis: {
      title: 'Thousands',
      minValue: 0,
      format: 'short',
      textStyle: { fontSize: 12, italic: false },
      titleTextStyle: { fontSize: 12, italic: false, bold: true },
      ticks: [0, 20000, 40000, 60000, 80000, 100000],
      viewWindow: {
        min: 0,
        max: 100000
      },
      gridlines: { count: 6 }
    },
    vAxis: {
      title: 'Age',
      ticks: ['18-30', '31-40', '41-50', '51-60', '61-68'],
      textStyle: { fontSize: 12, italic: false },
      titleTextStyle: { fontSize: 12, italic: false, bold: true }
    },
    bars: 'horizontal',
    colors: [colorPrimaryBlue, colorGolden],
    legend: {
      position: 'bottom',
      alignment: 'center',
      textStyle: { fontSize: 12 }
    },
    height: 350
  };

  return (
    <div>
      <Grid container alignItems="center" justifyContent="space-between" mb={2}>
        <Grid item>
          <div
            style={{
              fontSize: '15px',
              fontWeight: 600
            }}
          >
            Invested and Borrowed Amount by Age Group
          </div>
        </Grid>
        <Grid item>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleMenuClick}
          >
            <MoreVertIcon fontSize="small" />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={() => {
                handleClickOpen();
                handleMenuClose();
              }}
            >
              <SvgIcon
                component={Expand05Icon}
                sx={{ width: 16, height: 16, mr: 1 }}
              />
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleDownload();
                handleMenuClose();
              }}
            >
              <SvgIcon
                component={FileDownload03Icon}
                style={{ fontSize: 20, marginRight: 8 }}
              />
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
      {isAgeWiseReportLoading ? (
        <p>Loading...</p>
      ) : isAgeWiseReportFailed ? (
        <p>Error loading data</p>
      ) : (
        <>
          <Grid item xs={12} mb={2}>
            <Chart
              chartType="BarChart"
              width="100%"
              height={chartOptions.height + 'px'}
              data={ageWiseReport}
              options={chartOptions}
              getChartWrapper={chartWrapper => setChartWrapper(chartWrapper)}
            />
          </Grid>
          <Dialog
            open={isExpanded}
            onClose={handleClickOpen}
            maxWidth="lg"
            fullWidth
          >
            <DialogContent>
              <Chart
                chartType="BarChart"
                width="100%"
                height="500px"
                data={ageWiseReport}
                options={{ ...chartOptions, height: 500 }}
              />
            </DialogContent>
          </Dialog>
        </>
      )}
    </div>
  );
};

export default AgeGroupBarChart;
