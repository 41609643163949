const tokenKey = 'x-auth-token-admin-panel';
import Cookies from 'js-cookie';

const ACTIVE_MENU = 'ACTIVE_MENU';
const OPEN_MENU = 'OPEN_MENU';

export const setToken = token => {
  // const expirationTime = new Date();
  // expirationTime.setTime(expirationTime.getTime() + 60 * 60 * 1000);
  try {
    if (!token || typeof token !== 'string') {
      throw new Error('Invalid token format');
    }
    Cookies.set(tokenKey, token, {
      // secure: process.env.NODE_ENV === 'production',
      // expires: expirationTime,
      // httpOnly: true,
    });
  } catch (error) {
    throw Error(`Failed to set token: ${error}`);
  }
};

export const getToken = () => {
  try {
    const token = Cookies.get(tokenKey);
    return token ? token : null;
  } catch (error) {
    throw Error(`Failed to get token: ${error}`);
  }
};

export const clearToken = () => {
  try {
    Cookies.remove(tokenKey);
  } catch (error) {
    throw Error(`Failed clear the token: ${error}`);
  }
};

export const setAuthData = authData => {
  try {
    if (sessionStorage) {
      sessionStorage.setItem(tokenKey, JSON.stringify(authData));
    }
  } catch (error) {
    throw Error(`Failed to set authdata: ${error}`);
  }
};
export const getAuthData = () => {
  try {
    if (sessionStorage[tokenKey]) {
      return JSON.parse(sessionStorage.getItem(tokenKey));
    }
  } catch (error) {
    throw Error(`Failed to get authdata: ${error}`);
  }
};
export const clearAuthData = () => {
  try {
    if (sessionStorage[tokenKey]) {
      return sessionStorage.removeItem(tokenKey);
    }
  } catch (error) {
    throw Error(`Failed to remove authdata: ${error}`);
  }
};

export const setActiveMenuData = menuData => {
  try {
    if (sessionStorage) {
      sessionStorage.setItem(ACTIVE_MENU, menuData);
    }
  } catch (error) {
    throw Error(`Failed to set ACTIVE_MENU: ${error}`);
  }
};
export const getActiveMenuData = () => {
  try {
    if (sessionStorage[ACTIVE_MENU]) {
      return sessionStorage.getItem(ACTIVE_MENU);
    }
  } catch (error) {
    throw Error(`Failed to get ACTIVE_MENU: ${error}`);
  }
};

export const setOpenMenuData = menuData => {
  try {
    if (sessionStorage) {
      sessionStorage.setItem(OPEN_MENU, menuData);
    }
  } catch (error) {
    throw Error(`Failed to set OPEN_MENU: ${error}`);
  }
};
export const getOpenMenuData = () => {
  try {
    if (sessionStorage[OPEN_MENU]) {
      return sessionStorage.getItem(OPEN_MENU);
    }
  } catch (error) {
    throw Error(`Failed to get OPEN_MENU: ${error}`);
  }
};
