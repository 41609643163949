import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Menu,
  Select,
  TextField,
  Tooltip,
  SvgIcon,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Link
} from '@mui/material';
import { multipartApi } from '../../../config/api';

import { FormButtonBottom, StyledGrid } from '../../../utils/styledLayout';
import CustomDataTable from '../../../components/CustomDataTable';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddIcon from '@mui/icons-material/Add';
import { useDropzone } from 'react-dropzone';
import { LoadingButton } from '@mui/lab';
import {
  createTitle,
  formatDateAdmin,
  formatINR,
  formatNumberWithCommas,
  getFullName,
  handleError,
  isArrayNotEmpty,
  isValidEmail,
  isValidMobileNumber
} from '../../../utils/utils';
//'../../../../../utils/utils';
import EditImage from '../../../assets/editPen.svg';
import ErrorFailed from '../../../assets/ErrorFailed.svg';
import SuccessIcon from '../../../assets/SuccessIconB.svg';
import UploadCloud from '../../../assets/cloudUpload.svg';
import AttachImage from '../../../assets/attach.svg';
import DeleteImage from '../../../assets/delete.svg';
import ModalDialog from '../../../components/ModalDialog';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  colorGolden,
  colorPrimaryBlue,
  colorWhite
} from '../../../config/theme';
import UploadIcon from '@mui/icons-material/Upload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  VIEW_L1_ADD_SUCCESS,
  VIEW_L2_ADD_SUCCESS,
  VIEW_L3_ADD_SUCCESS
} from '../../../utils/messages';
import Loader from '../../../components/Loader';
import SnackBarBox from '../../../components/SnackBarBox';
import {
  KeyboardArrowDown,
  NotificationsOutlined,
  Search,
  SearchOutlined,
  PersonOffOutlined,
  PersonOutline,
  MoreVert,
  East
} from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import GenericInput from '../../../components/GenericInput';
import { useNavigate } from 'react-router-dom';
// import {
//   bulkUploadL1,
//   clearL1ListNotification,
//   clearL1ViewNotification,
//   employeeL1DeatilsById,
//   fetchListingL1,
//   viewEmploymentAgreement
// } from '../../../../../redux/reducers/EmployeeL1Slice';
import axios from 'axios';
import {
  clearL2ViewNotification,
  employeeL2DetailsById,
  fetchListingL2ofL1
} from '../../../redux/reducers/EmployeeL2Slice';
import { RegionCell } from '../../../components/TableMoreCell';
import {
  downloadSampleExcel,
  viewEmploymentAgreement
} from '../../../redux/reducers/EmployeeL1Slice';
import {
  clearL3ofL1ListNotification,
  employeeL3DetailsById,
  fetchListingL3ofL1
} from '../../../redux/reducers/EmployeeL3Slice';

let searchValue = '';

const L1ViewL3UsersList = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [initialValues, setInitalValues] = useState({
    page: 0,
    size: 10,
    searchFilter: ''
  });

  const styleModelB = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    textAlign: 'center'
  };

  const {
    isemployeeByL3IdLoading,
    isemployeeL3ByIdSuccess,
    isemployeeL3ByIdFailed,
    employeeL3ByIdData,
    isemployeeL3ByIdErrorContainer,
    islistingL3ofL1Loading,
    islistingL3ofL1Success,
    islistingL3ofL1Failed,
    l3ofL1UsersData,
    listingL3ofL1ErrorContainer
  } = useSelector(state => state.employeeL3);

  const [modalViewOpen, setModalViewOpen] = useState(false);
  const [modalViewData, setModalViewData] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [modalBulkUploadOpen, setModalBulkUploadOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [modalBulkErrorOpen, setModalBulkErrorOpen] = useState(false);
  const [modalBulkSuccessOpen, setModalBulkSuccessOpen] = useState(false);
  const [bulkUploadResError, setBulkUploadResError] = useState({
    error: false,
    errorMessage: ''
  });

  useEffect(() => {
    document.title = createTitle('L3');

    dispatch(
      fetchListingL3ofL1({
        pageNumber: initialValues.page + 1,
        pageSize: initialValues.size,
        search: initialValues.searchFilter
      })
    );
    return () => {
      dispatch(clearL3ofL1ListNotification());
    };
  }, [initialValues]);

  const handleViewClick = () => {
    if (currentRow) {
      setModalViewData(currentRow);
      setModalViewOpen(true);
      dispatch(employeeL3DetailsById(currentRow?.id));
    }
    handleClose();
  };

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadSample = () => {
    dispatch(
      downloadSampleExcel({
        fileName: 'L3_Sample_BulkUpload_Format',
        level: 'L3'
      })
    );
  };
  const isValidDate = date => {
    const parsedDate = Date.parse(date);
    return (
      !isNaN(parsedDate) && new Date(parsedDate).toString() !== 'Invalid Date'
    );
  };

  const headersList = [
    {
      uniquekey: 'id',
      id: 'id',
      label: 'ID',
      sortable: true,
      width: 50
    },
    { id: 'levelName', label: 'Level Name', sortable: true, width: 120 },
    {
      id: 'name',
      label: 'Full Name',
      sortable: true,
      width: 200
    },

    {
      id: 'email',
      label: 'Email ID',
      sortable: true
    },
    {
      id: 'mobileNo',
      label: 'Mobile No',
      sortable: true
    },

    {
      id: 'pinCode',
      label: 'Pin Code',
      sortable: true,
      width: 100
    },
    {
      id: 'reportToL2Name',
      label: 'Report to L2',
      sortable: true,
      width: 150
    },
    {
      id: 'category',
      label: 'Category',
      sortable: true
    },

    {
      id: 'targetAmtOfInvestor',
      label: 'Annual Target (Investor) (₹)',
      sortable: true,
      width: 150,
      render: row => {
        const formattedValue = row.targetAmtOfInvestor
          ? formatNumberWithCommas(row.targetAmtOfInvestor)
          : '-';
        return <>{formattedValue}</>;
      }
    },
    {
      id: 'achiveAmtOfInvestor',
      label: 'Achieved Amount Of (Investor) (₹)',
      sortable: true,
      width: 150,
      render: row => {
        const formattedValue = row.achiveAmtOfInvestor
          ? formatNumberWithCommas(row.achiveAmtOfInvestor)
          : '-';
        return <>{formattedValue}</>;
      }
    },
    {
      id: 'targetAmtOfBorrower',
      label: 'Annual Target (Borrower) (₹)',
      sortable: true,
      width: 150,
      render: row => {
        const formattedValue = row.targetAmtOfBorrower
          ? formatNumberWithCommas(row.targetAmtOfBorrower)
          : '-';
        return <>{formattedValue}</>;
      }
    },
    {
      id: 'achiveAmtOfBorrower',
      label: 'Achieved Amount Of (Borrower) (₹)',
      sortable: true,
      width: 150,
      render: row => {
        const formattedValue = row.achiveAmtOfBorrower
          ? formatNumberWithCommas(row.achiveAmtOfBorrower)
          : '-';
        return <>{formattedValue}</>;
      }
    },
    {
      id: 'createdBy',
      label: 'Created By',
      sortable: true,
      width: 150
    },
    {
      id: 'createdOn',
      label: 'Created On',
      sortable: true,
      width: 130
    },
    {
      id: 'updatedBy',
      label: 'Updated By',
      sortable: true,
      width: 120
    },
    {
      id: 'updatedOn',
      label: 'Updated On',
      sortable: true,
      width: 130
    },
    {
      id: 'action',
      label: 'Action',
      sortable: true,
      render: row => {
        return (
          <>
            <Tooltip placement="top" title="Actions">
              <IconButton
                aria-label="actions"
                onClick={event => handleClick(event, row)}
              >
                <MoreVert />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  handleViewClick(row);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <VisibilityIcon sx={{ color: colorGolden }} />
                </ListItemIcon>
                <ListItemText
                  primary={<span style={{ color: colorGolden }}>View</span>}
                />{' '}
              </MenuItem>
            </Menu>
          </>
        );
      }
    }
  ];

  const handleButtonViewBackClick = () => {
    dispatch(clearL2ViewNotification());
    setModalViewOpen(false);
  };

  const handleViewAgreement = async () => {
    try {
      const response = await dispatch(
        viewEmploymentAgreement(employeeL3ByIdData?.aggrementPath)
      );
      const blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
      setTimeout(() => window.URL.revokeObjectURL(url), 100);
    } catch (error) {
      console.error('Failed to fetch the agreement file binary data:', error);
    }
  };

  const handleSearch = e => {
    const term = e.target.value;
    searchValue = term;

    if (term.length >= 3 || term.length === 0) {
      debouncedSearch(term);
    }
  };

  const debouncedSearch = _.debounce(term => {
    setInitalValues(prevValues => ({
      ...prevValues,
      searchFilter: term,
      page: 0
    }));
  }, 300);

  const blackTextStyles = {
    color: 'black'
  };

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length > 0) {
      setSelectedFile(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx'
      ]
    }
  });

  const handleDelete = () => {
    setSelectedFile(null);
  };

  return (
    <StyledGrid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Box
        sx={{
          borderRadius: 5,
          backgroundColor: 'white',
          width: '100%',
          boxShadow: '0px 1px 17px -5px rgba(0,0,0,0.67)',
          padding: 2,
          overflow: 'hidden', // Ensure any overflow is hidden
          color: 'black'
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={12} sm={6} md={8} lg={8} xl={8}>
            <FormControl variant="outlined" size="small">
              <GenericInput
                label="Search "
                name="searchField"
                size="small"
                // value={formik.values.searchField}
                // onChange={formik.handleChange}
                startIcon={<SearchIcon />}
                onChange={e => {
                  searchValue = e.target.value;
                  handleSearch(e);
                }}
              />
            </FormControl>
            <FormHelperText sx={{ textAlign: 'start', fontSize: 10 }}>
              {/* {formik.errors.searchField} */}
            </FormHelperText>
          </Grid>
          {/* <Grid
            item
            container
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={4}
            justifyContent="flex-end"
            alignItems="center"
          >
            <LoadingButton
              startIcon={<UploadIcon />}
              variant="outlined"
              sx={{
                borderColor: '#303157',
                borderRadius: '6px',
                color: 'black',
                borderWidth: '2px',
                width: '150px',
                height: '45px',
                marginRight: '20px'
              }}
              onClick={onClickBulkHdlr}
            >
              Bulk Upload
              <input
                key={'uploadProfile'}
                type="file"
                style={{ display: 'none' }}
                accept={'.csv'}
              />
            </LoadingButton>
            <Tooltip placement="top" title="Add L2 User">
              <IconButton
                // onClick={handleOpenAddBranch}
                onClick={() => {
                  navigate('/addL2User');
                  return;
                }}
                aria-label="add"
                size="medium"
                style={{ background: '#303157' }}
              >
                <AddIcon
                  sx={{
                    fontSize: { xs: '1.5rem', sm: '2rem' },
                    fontWeight: 'bold',
                    color: '#FFFFFF'
                  }}
                  fontSize="inherit"
                />
              </IconButton>
            </Tooltip>
          </Grid> */}
          {!islistingL3ofL1Loading && islistingL3ofL1Success && (
            <Grid
              container
              direction="row"
              sx={{ marginTop: 1, overflow: 'auto' }}
            >
              <Box sx={{ width: '100%', overflow: 'auto', padding: 3 }}>
                <CustomDataTable
                  columns={headersList}
                  allowSelection={false}
                  defaultOrderBy={'createdDate'}
                  data={
                    Array.isArray(l3ofL1UsersData?.l3ofL1UserList)
                      ? l3ofL1UsersData?.l3ofL1UserList
                      : []
                  }
                  onPageChange={val => {
                    setInitalValues({ ...initialValues, page: val });
                  }}
                  onRowsPerPageChange={val => {
                    setInitalValues({ ...initialValues, size: val });
                  }}
                  defaultPage={initialValues?.page}
                  defaultRowsPerPage={initialValues?.size}
                  totalCount={l3ofL1UsersData?.totalCount}
                  isLoading={islistingL3ofL1Loading}
                />
              </Box>
            </Grid>
          )}
          {!islistingL3ofL1Loading && islistingL3ofL1Failed && (
            <SnackBarBox
              type="error"
              message={handleError(listingL3ofL1ErrorContainer?.errorMessage)}
            />
          )}
          {islistingL3ofL1Loading && <Loader key="listing_l2" />}
        </Grid>

        <Box>
          {modalViewOpen && (
            <ModalDialog
              fullwidth={true}
              isShowTitle={true}
              titleName={'VIEW DETAILS'}
              size="md"
              open={modalViewOpen}
              onClose={() => {
                setModalViewOpen(false);
              }}
            >
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                marginTop={0.5}
                padding={2}
              >
                <Grid container spacing={3} marginBottom={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography sx={blackTextStyles} variant="subtitle2">
                      Level Name
                    </Typography>
                    <Typography sx={blackTextStyles} variant="body2">
                      <strong>{employeeL3ByIdData?.levelName}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography sx={blackTextStyles} variant="subtitle2">
                      Name
                    </Typography>
                    <Typography sx={blackTextStyles} variant="body2">
                      <strong>{employeeL3ByIdData?.name}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography sx={blackTextStyles} variant="subtitle2">
                      Email ID
                    </Typography>
                    <Typography sx={blackTextStyles} variant="body2">
                      <strong>{employeeL3ByIdData?.email}</strong>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3} marginBottom={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography sx={blackTextStyles} variant="subtitle2">
                      Mobile No
                    </Typography>
                    <Typography sx={blackTextStyles} variant="body2">
                      <strong>{employeeL3ByIdData?.mobileNo}</strong>
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Typography sx={blackTextStyles} variant="subtitle2">
                      Pin Code
                    </Typography>
                    <Typography sx={blackTextStyles} variant="body2">
                      <strong>{employeeL3ByIdData?.pinCode}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography sx={blackTextStyles} variant="subtitle2">
                      Report to L2
                    </Typography>
                    <Typography sx={blackTextStyles} variant="body2">
                      <strong>
                        {employeeL3ByIdData?.reportToCheckList?.value || ' - '}
                      </strong>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3} marginBottom={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography sx={blackTextStyles} variant="subtitle2">
                      Category
                    </Typography>
                    <Typography sx={blackTextStyles} variant="body2">
                      <strong>{employeeL3ByIdData?.category || ' - '}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography sx={blackTextStyles} variant="subtitle2">
                      Annual Target (Investor) (₹)
                    </Typography>
                    <Typography sx={blackTextStyles} variant="body2">
                      <strong>
                        {employeeL3ByIdData?.targetAmtOfInvestor === 0.0 ||
                        employeeL3ByIdData?.targetAmtOfInvestor === null
                          ? ' - '
                          : formatNumberWithCommas(
                              employeeL3ByIdData?.targetAmtOfInvestor
                            )}
                      </strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography sx={blackTextStyles} variant="subtitle2">
                      Achieved Amount (Investor) (₹)
                    </Typography>
                    <Typography sx={blackTextStyles} variant="body2">
                      <strong>
                        {employeeL3ByIdData?.achiveAmtOfInvestor === 0.0 ||
                        employeeL3ByIdData?.achiveAmtOfInvestor === null
                          ? ' - '
                          : formatNumberWithCommas(
                              employeeL3ByIdData?.achiveAmtOfInvestor
                            )}
                      </strong>
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={3} marginBottom={2}>
                  {employeeL3ByIdData?.category === 'Credit' && (
                    <Grid item xs={12} sm={4}>
                      <Typography sx={blackTextStyles} variant="subtitle2">
                        Annual Target (Borrower) (₹)
                      </Typography>
                      <Typography sx={blackTextStyles} variant="body2">
                        <strong>
                          {employeeL3ByIdData?.targetAmtOfBorrower === 0.0 ||
                          employeeL3ByIdData?.targetAmtOfBorrower === null
                            ? ' - '
                            : formatNumberWithCommas(
                                employeeL3ByIdData?.targetAmtOfBorrower
                              )}
                        </strong>
                      </Typography>
                    </Grid>
                  )}
                  {employeeL3ByIdData?.category === 'Credit' && (
                    <Grid item xs={12} sm={4}>
                      <Typography sx={blackTextStyles} variant="subtitle2">
                        Achieved Amount (Borrower) (₹)
                      </Typography>
                      <Typography sx={blackTextStyles} variant="body2">
                        <strong>
                          {employeeL3ByIdData?.achiveAmtOfBorrower === 0.0 ||
                          employeeL3ByIdData?.achiveAmtOfBorrower === null
                            ? ' - '
                            : formatNumberWithCommas(
                                employeeL3ByIdData?.achiveAmtOfBorrower
                              )}
                        </strong>
                      </Typography>
                    </Grid>
                  )}
                </Grid>

                <Grid container spacing={3} marginBottom={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography sx={blackTextStyles} variant="subtitle2">
                      Account Holder Name
                    </Typography>
                    <Typography sx={blackTextStyles} variant="body2">
                      <strong>
                        {employeeL3ByIdData?.accHolderName || ' - '}
                      </strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography sx={blackTextStyles} variant="subtitle2">
                      Account Number
                    </Typography>
                    <Typography sx={blackTextStyles} variant="body2">
                      <strong>{employeeL3ByIdData?.accountNo || ' - '}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography sx={blackTextStyles} variant="subtitle2">
                      IFSC Code
                    </Typography>
                    <Typography sx={blackTextStyles} variant="body2">
                      <strong>{employeeL3ByIdData?.ifscCode || ' - '}</strong>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3} marginBottom={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography sx={blackTextStyles} variant="subtitle2">
                      Aadhaar Number
                    </Typography>
                    <Typography sx={blackTextStyles} variant="body2">
                      <strong>
                        {employeeL3ByIdData?.aadhaarNumber || ' - '}
                      </strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography sx={blackTextStyles} variant="subtitle2">
                      PAN Number
                    </Typography>
                    <Typography sx={blackTextStyles} variant="body2">
                      <strong>{employeeL3ByIdData?.panNo || ' - '}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography sx={blackTextStyles} variant="subtitle2">
                      Agreement Date
                    </Typography>
                    <Typography sx={blackTextStyles} variant="body2">
                      <strong>
                        {employeeL3ByIdData?.aggrementDate || ' - '}
                        {/* {employeeL3ByIdData?.aggrementDate &&
                        isValidDate(employeeL3ByIdData.aggrementDate)
                          ? employeeL3ByIdData.aggrementDate
                          : ' - '} */}
                      </strong>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3} marginBottom={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography sx={blackTextStyles} variant="subtitle2">
                      Agreement Copy
                    </Typography>
                    <Typography sx={blackTextStyles} variant="body2">
                      {employeeL3ByIdData?.aggrementPath ? (
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          <a
                            href={employeeL3ByIdData?.aggrementPath}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: 'none',
                              color: 'inherit',
                              marginRight: '20px',
                              wordBreak: 'break-all', // Break long words to fit the container
                              whiteSpace: 'normal',
                              flex: '1 1 auto'
                            }}
                          >
                            <strong>
                              {employeeL3ByIdData?.aggrementPath
                                .substring(
                                  employeeL3ByIdData?.aggrementPath.lastIndexOf(
                                    '/'
                                  ) + 1
                                )
                                .split('_')
                                .pop()}
                            </strong>
                          </a>
                          <ListItemIcon
                            onClick={handleViewAgreement}
                            style={{ cursor: 'pointer' }}
                          >
                            <VisibilityIcon />
                          </ListItemIcon>
                        </span>
                      ) : (
                        <strong>No agreement uploaded</strong>
                      )}
                    </Typography>
                  </Grid>
                </Grid>

                <Divider variant="middle" sx={{ width: '100%' }} />

                <Typography
                  variant="h6"
                  gutterBottom
                  // sx={{ mt: 1, textAlign: 'left', width: '100%' }}
                  sx={{
                    mt: 1,
                    textAlign: 'left',
                    width: '100%',
                    fontWeight: 600,
                    fontSize: '24px',
                    color: '#000000'
                  }}
                >
                  Incentive
                </Typography>

                <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
                  <Table sx={{ borderCollapse: 'collapse' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ borderBottom: 'none', width: '25%' }}>
                          <strong>Product Name</strong>
                        </TableCell>
                        <TableCell
                          sx={{ borderBottom: 'none', textAlign: 'center' }}
                        >
                          <strong>Incentive Percentage</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {employeeL3ByIdData?.incentiveDetails &&
                        employeeL3ByIdData?.incentiveDetails.length > 0 &&
                        employeeL3ByIdData?.incentiveDetails.map(
                          (incentive, index) => (
                            <TableRow key={index}>
                              <TableCell
                                sx={{
                                  borderBottom: 'none',
                                  fontWeight: 'bold',
                                  width: '25%'
                                }}
                              >
                                {incentive.productName}
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: 'none',
                                  fontWeight: 'bold',
                                  textAlign: 'center'
                                }}
                              >
                                {incentive.percentange}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    borderTop: '2px dashed rgba(0, 0, 0, 0.2)',
                    width: '100%',
                    marginBottom: '10px'
                  }}
                ></div>
              </Grid>
              <Grid item xs={12} textAlign="right">
                <LoadingButton
                  variant="contained"
                  type="submit"
                  sx={{
                    color: colorWhite,
                    fontWeight: '500',
                    fontSize: '12px',
                    mr: 2,
                    width: '106px',
                    height: '42px',
                    borderWidth: 2
                  }}
                  onClick={handleButtonViewBackClick}
                >
                  OK
                </LoadingButton>
              </Grid>

              {isemployeeByL3IdLoading && <Loader key="add_l1" />}
              {!isemployeeByL3IdLoading && isemployeeL3ByIdFailed && (
                <SnackBarBox
                  type="error"
                  message={handleError(
                    isemployeeL3ByIdErrorContainer.errorMessage
                  )}
                />
              )}
              {!isemployeeByL3IdLoading && isemployeeL3ByIdSuccess && (
                <SnackBarBox type="success" message={VIEW_L3_ADD_SUCCESS} />
              )}
            </ModalDialog>
          )}
        </Box>
      </Box>
    </StyledGrid>
  );
};

export default L1ViewL3UsersList;
