import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api, multipartApi } from '../../config/api';
import {
  DOC_TYPE_DEVIATION_MAIL,
  USER_TYPE_BORROWER
} from '../../utils/constant';

export const logoutAndClearToken = createAction('LOGOUT_AND_CLEAR_TOKEN');
export const clearDecisioningState = createAction('CLEAR_CASE_DECISIONING');
export const clearDeviationUploadNotification = createAction(
  'CLEAR_DEVIATION_UPLOAD_NOTIFICATION'
);
export const clearBureauNotification = createAction('CLEAR_BUREAU');

const intial = {
  isCaseSummaryLoading: false,
  caseSummaryDetails: {},
  totalPoolAmount: 0,
  totalAllocatedAmount: 0,
  isCaseSummaryFailed: false,
  caseSummaryErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isDeviationMailUploadLoading: false,
  deviationMailUpload: null,
  isDeviationMailUploadError: false,
  deviationMailUploadErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isCaseCountLoading: false,
  caseCountDetails: {},
  isCaseCountSuccess: false,
  isCaseCountFailed: false,
  caseCountErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isTotalPoolAmountLoading: false,
  isTotalPoolAmountSuccess: false,
  isTotalPoolAmountFailed: false,
  totalPoolAmountErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  caseListDetails: {
    content: [],
    pageable: {},
    totalElements: 0,
    totalPages: 0,
    last: false,
    size: 0,
    number: 0,
    sort: [],
    numberOfElements: 0,
    first: false,
    empty: false
  },
  isCaseListingLoading: false,
  isCaseListingSuccess: false,
  isCaseListingFailed: false,
  caseListingErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isCaseDecisioningLoading: false,
  isCaseDecisioningSuccess: false,
  isCaseDecisioningFailed: false,
  caseDecisioningErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isBureauResultLoading: false,
  isBureauResultSuccess: false,
  isBureauResultFailed: false,
  bureauResult: {
    success: false,
    msg: '',
    data: {
      score: 0,
      totalActiveAccounts: 0,
      totalSecuredAccounts: 0,
      totalUnSecuredAccounts: 0,
      numberOfSpecialMentionedAccounts: 0,
      numberOfSubStandardAccounts: 0,
      numberOfDoubtfullAccounts: 0,
      numberOfLossAccounts: 0,
      numberOf30PlusDpdIn3Months: 0,
      numberOf90PlusDpdIn12Months: 0,
      totalOverDueAmount: 0,
      activeMonthlyEmi: 0
    }
  },
  bureauResultErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isFetchDevialtionDocListLoading: false,
  isFetchDevialtionDocListSuccess: false,
  isFetchDevialtionDocListFailed: false,
  fetchDevialtionDocListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  deviationDocumentList: [],
  isUploadDeviationDocsLoading: false,
  isUploadDeviationDocsSuccess: false,
  isUploadDeviationDocsFailed: false,
  uploadDeviationDocsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isDeleteDeviationDocsLoading: false,
  isDeleteDeviationDocsSuccess: false,
  isDeleteDeviationDocsFailed: false,
  deleteDeviationDocsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isDownloadDeviationDocsLoading: false,
  isDownloadDeviationDocsSuccess: false,
  isDownloadDeviationDocsFailed: false,
  downloadDeviationDocsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  bankAnalysisDetails: null,
  isBankAnalysisDetailsLoading: false,
  isBankAnalysisDetailsSuccess: false,
  isBankAnalysisDetailsFailed: false,
  bankAnalysisDetailsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  }
};

export const getCaseSummaryDetails = createAsyncThunk(
  'casesummary/getAllDetails',
  async ({ userId, borrowingDetailsId }, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/users/getQDEDetails?userId=${userId}&borrowingDetailsId=${borrowingDetailsId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const viewImage = async uploadedImage => {
  try {
    const imageSource = await api.get(
      `documents/view?fileUrl=${uploadedImage}`,
      {
        responseType: 'blob'
      }
    );

    if (imageSource) {
      const blobData = imageSource.data;
      const blobUrl = URL.createObjectURL(blobData);
      return blobUrl;
    }
  } catch (error) {
    console.error('Error fetching image:', error);
  }
};

export const viewXML = async uploadedImage => {
  try {
    const response = await api.get(`documents/view?fileUrl=${uploadedImage}`, {
      responseType: 'blob'
    });

    const blobData = response.data;

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(blobData);

      reader.onload = () => {
        const xmlText = reader.result;
        resolve(xmlText);
      };

      reader.onerror = error => {
        reject(error);
      };
    });
  } catch (error) {
    console.error('Error fetching xml:', error);
    throw error;
  }
};

export const getCaseCountDetails = createAsyncThunk(
  'casesummary/getCaseCount',
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.get(`/users/getBorrowerCountForCredit`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getTotalPoolAmount = createAsyncThunk(
  'casesummary/getPoolAmount',
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.get(`/borrowers/lendbox/getPoolAmount`);
      const amountData = response.data['data']['balance'];
      return amountData;
      // return new Promise((resolve, reject) => {
      //   resolve(1000000);
      // });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getCaseListing = createAsyncThunk(
  'casesummary/getcaselisting',
  async (pageData, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/users/getBorrowingDetailsForCredit`,
        pageData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const sendCreditDecisioning = createAsyncThunk(
  'casesummary/send_Credit_Decisioning',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/borrowers/updateCreditDecision`,
        payload
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchBureauResult = createAsyncThunk(
  'casesummary/fetch_Bureau_Result',
  async (lbUserId, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `/borrowers/fetchBureauPull?lbUserId=${lbUserId}`
      );
      return response.data;
      // return new Promise((resolve, reject) => {
      //   setTimeout(() => {
      //     resolve({
      //       success: true,
      //       msg: 'Result fetch successful',
      //       data: {
      //         score: 300,
      //         totalActiveAccounts: 6,
      //         totalSecuredAccounts: 1,
      //         totalUnSecuredAccounts: 0,
      //         numberOfSpecialMentionedAccounts: 1,
      //         numberOfSubStandardAccounts: 3,
      //         numberOfDoubtfullAccounts: 2,
      //         numberOfLossAccounts: 2,
      //         numberOf30PlusDpdIn3Months: 40,
      //         numberOf90PlusDpdIn12Months: 46,
      //         totalOverDueAmount: 2864326,
      //         activeMonthlyEmi: 51664
      //       }
      //     });
      //   }, 400);
      // });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchDeviationDocumentList = createAsyncThunk(
  'casesummary/fetch_deviation_document_list',
  async ({ borrowerDetailsId, referenceId }, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `documents/deviationMailUpload?documentType=${DOC_TYPE_DEVIATION_MAIL}&borrowerDetailsId=${borrowerDetailsId}&referenceId=${referenceId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const uploadDeviationDocument = createAsyncThunk(
  'casesummary/upload_deviation_document',
  async (
    { data, userId, borrowerDetailsId, referenceId },
    { rejectWithValue }
  ) => {
    try {
      const response = await multipartApi.post(
        `documents/deviationMailUpload?userId=${userId}&documentType=${DOC_TYPE_DEVIATION_MAIL}&borrowerDetailsId=${borrowerDetailsId}&referenceId=${referenceId}&userType=${USER_TYPE_BORROWER}`,
        data
      );

      // await api.post(`/deviation`, {
      //   borrowingDetailsId: borrowerDetailsId,
      //   userId: userId
      // });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteDeviationDocument = createAsyncThunk(
  'casesummary/delete_deviation_document',
  async (
    { fileName, deviationName, borrowingDetailsId },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.delete(
        `/documents/deviation?fileName=${fileName}&deviationName=${deviationName}&borrowingDetailsId=${borrowingDetailsId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const downloadDeviationDocument = createAsyncThunk(
  'casesummary/download_deviation_document',
  async ({ fileName, fileUrl }, { rejectWithValue }) => {
    try {
      const response = await api.get(`/documents/download?fileUrl=${fileUrl}`, {
        responseType: 'blob'
      });

      if (response) {
        const blobData = response.data;
        const blobUrl = URL.createObjectURL(blobData);
        let filename = `${fileName}`;
        if (blobUrl) {
          const a = document.createElement('a');
          a.href = blobUrl;
          a.download = filename;
          a.click();
        }
        return blobUrl;
      }
    } catch (error) {
      return rejectWithValue({
        error: true,
        errorMessage: 'Document download failed.',
        description: 'Something Went Wrong',
        statusCode: error.response.status
      });
    }
  }
);

export const fetchBankAnalysisDetails = createAsyncThunk(
  'bankAnalysis/fetch_bank_analysis_details',
  async ({ fileName, fileUrl }, { rejectWithValue }) => {
    try {
      const response = await api.get(`/documents/download?fileUrl=${fileUrl}`, {
        responseType: 'blob'
      });

      if (response) {
        const blobData = response.data;
        const blobUrl = URL.createObjectURL(blobData);
        let filename = `${fileName}`;
        if (blobUrl) {
          const a = document.createElement('a');
          a.href = blobUrl;
          a.download = filename;
          a.click();
        }
        return blobUrl;
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const downloadBankAnalysisDetails = createAsyncThunk(
  'bankAnalysis/download_bank_analysis_details',
  async ({ fileName, fileUrl }, { rejectWithValue }) => {
    try {
      const response = await api.get(`/documents/download?fileUrl=${fileUrl}`, {
        responseType: 'blob'
      });

      if (response) {
        const blobData = response.data;
        const blobUrl = URL.createObjectURL(blobData);
        let filename = `${fileName}`;
        if (blobUrl) {
          const a = document.createElement('a');
          a.href = blobUrl;
          a.download = filename;
          a.click();
        }
        return blobUrl;
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchBureauResultManually = createAsyncThunk(
  'casesummary/fetch_Bureau_Result_Mannually',
  async (lbUserId, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `/borrowers/lendbox/bureauPull?lbUserId=${lbUserId}`
      );
      return response.data;
      // return new Promise((resolve, reject) => {
      //   setTimeout(() => {
      //     resolve({
      //       success: true,
      //       msg: 'Result fetch successful',
      //       data: {
      //         score: 300,
      //         totalActiveAccounts: 6,
      //         totalSecuredAccounts: 1,
      //         totalUnSecuredAccounts: 0,
      //         numberOfSpecialMentionedAccounts: 1,
      //         numberOfSubStandardAccounts: 3,
      //         numberOfDoubtfullAccounts: 2,
      //         numberOfLossAccounts: 2,
      //         numberOf30PlusDpdIn3Months: 40,
      //         numberOf90PlusDpdIn12Months: 46,
      //         totalOverDueAmount: 2864326,
      //         activeMonthlyEmi: 51664
      //       }
      //     });
      //   }, 1000);
      // });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const caseSummarySlice = createSlice({
  name: 'caseSummary',
  initialState: intial,
  extraReducers: builder => {
    builder
      .addCase(getCaseSummaryDetails.pending, state => {
        (state.isCaseSummaryLoading = true),
          (state.caseSummaryDetails = {}),
          (state.isCaseSummaryFailed = false),
          (state.caseSummaryErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(getCaseSummaryDetails.fulfilled, (state, action) => {
        (state.isCaseSummaryLoading = false),
          (state.caseSummaryDetails = action.payload);
      })
      .addCase(getCaseSummaryDetails.rejected, (state, action) => {
        (state.isCaseSummaryLoading = false), (state.caseSummaryDetails = {});
        (state.isCaseSummaryFailed = true),
          (state.caseSummaryErrorContainer = {
            ...state.caseSummaryErrorContainer,
            ...action.payload
          });
      })
      .addCase(getCaseCountDetails.pending, state => {
        (state.isCaseCountLoading = true),
          (state.caseCountDetails = {}),
          (state.isCaseCountSuccess = false),
          (state.isCaseCountFailed = false),
          (state.caseCountErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(getCaseCountDetails.fulfilled, (state, action) => {
        (state.isCaseCountLoading = false),
          (state.isCaseCountSuccess = true),
          (state.caseCountDetails = action.payload),
          (state.totalAllocatedAmount = action.payload?.approvedAmount);
      })
      .addCase(getCaseCountDetails.rejected, (state, action) => {
        (state.isCaseCountLoading = false),
          (state.isCaseCountFailed = true),
          (state.caseCountErrorContainer = {
            ...state.caseCountErrorContainer,
            ...action.payload
          });
      })
      .addCase(getTotalPoolAmount.pending, state => {
        (state.isTotalPoolAmountLoading = true),
          (state.isTotalPoolAmountSuccess = false),
          (state.isTotalPoolAmountFailed = false),
          (state.totalPoolAmount = 0),
          (state.totalPoolAmountErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(getTotalPoolAmount.fulfilled, (state, action) => {
        (state.isTotalPoolAmountLoading = false),
          (state.isTotalPoolAmountSuccess = true),
          (state.totalPoolAmount = action.payload);
      })
      .addCase(getTotalPoolAmount.rejected, (state, action) => {
        (state.isTotalPoolAmountLoading = false),
          (state.isTotalPoolAmountFailed = true),
          (state.totalPoolAmountErrorContainer = {
            ...state.totalPoolAmountErrorContainer,
            ...action.payload
          });
      })
      .addCase(getCaseListing.pending, state => {
        (state.isCaseListingLoading = true),
          (state.isCaseListingSuccess = false),
          (state.isCaseListingFailed = false),
          (state.caseListingErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
        state.caseListDetails = {
          content: [],
          pageable: {},
          totalElements: 0,
          totalPages: 0,
          last: false,
          size: 0,
          number: 0,
          sort: [],
          numberOfElements: 0,
          first: false,
          empty: false
        };
      })
      .addCase(getCaseListing.fulfilled, (state, action) => {
        (state.isCaseListingLoading = false),
          (state.isCaseListingSuccess = true),
          (state.caseListDetails = action.payload);
      })
      .addCase(getCaseListing.rejected, (state, action) => {
        (state.isCaseListingLoading = false),
          (state.isCaseListingFailed = true),
          (state.caseListDetails = {
            content: [],
            pageable: {},
            totalElements: 0,
            totalPages: 0,
            last: false,
            size: 0,
            number: 0,
            sort: [],
            numberOfElements: 0,
            first: false,
            empty: false
          }),
          (state.caseListingErrorContainer = {
            ...state.caseListingErrorContainer,
            ...action.payload
          });
      })
      .addCase(sendCreditDecisioning.pending, state => {
        (state.isCaseDecisioningLoading = true),
          (state.isCaseDecisioningSuccess = false),
          (state.isCaseDecisioningFailed = false),
          (state.caseDecisioningErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(sendCreditDecisioning.fulfilled, state => {
        (state.isCaseDecisioningLoading = false),
          (state.isCaseDecisioningSuccess = true);
      })
      .addCase(sendCreditDecisioning.rejected, (state, action) => {
        (state.isCaseDecisioningLoading = false),
          (state.isCaseDecisioningFailed = true),
          (state.caseDecisioningErrorContainer = {
            ...state.caseDecisioningErrorContainer,
            ...action.payload
          });
      })

      .addCase(fetchBureauResult.pending, state => {
        (state.isBureauResultLoading = true),
          (state.isBureauResultSuccess = false),
          (state.isBureauResultFailed = false),
          (state.bureauResultErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(fetchBureauResult.fulfilled, (state, action) => {
        (state.isBureauResultLoading = false),
          (state.isBureauResultSuccess = true),
          (state.bureauResult = action.payload);
      })
      .addCase(fetchBureauResult.rejected, (state, action) => {
        (state.isBureauResultLoading = false),
          (state.isBureauResultFailed = true),
          (state.bureauResultErrorContainer = {
            ...state.bureauResultErrorContainer,
            ...action.payload
          });
      })
      .addCase(fetchBureauResultManually.pending, state => {
        (state.isBureauResultLoading = true),
          (state.isBureauResultSuccess = false),
          (state.isBureauResultFailed = false),
          (state.bureauResultErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(fetchBureauResultManually.fulfilled, (state, action) => {
        (state.isBureauResultLoading = false),
          (state.isBureauResultSuccess = true),
          (state.bureauResult = action.payload);
      })
      .addCase(fetchBureauResultManually.rejected, (state, action) => {
        (state.isBureauResultLoading = false),
          (state.isBureauResultFailed = true),
          (state.bureauResultErrorContainer = {
            ...state.bureauResultErrorContainer,
            ...action.payload
          });
      })
      .addCase(fetchDeviationDocumentList.pending, state => {
        (state.isFetchDevialtionDocListLoading = true),
          (state.isFetchDevialtionDocListSuccess = false),
          (state.isFetchDevialtionDocListFailed = false),
          (state.fetchDevialtionDocListErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(fetchDeviationDocumentList.fulfilled, (state, action) => {
        (state.isFetchDevialtionDocListLoading = false),
          (state.isFetchDevialtionDocListSuccess = true),
          (state.deviationDocumentList = action.payload);
      })
      .addCase(fetchDeviationDocumentList.rejected, (state, action) => {
        (state.isFetchDevialtionDocListLoading = false),
          (state.isFetchDevialtionDocListFailed = true),
          (state.fetchDevialtionDocListErrorContainer = {
            ...state.fetchDevialtionDocListErrorContainer,
            ...action.payload
          });
      })
      .addCase(uploadDeviationDocument.pending, state => {
        (state.isUploadDeviationDocsLoading = true),
          (state.isUploadDeviationDocsSuccess = false),
          (state.isUploadDeviationDocsFailed = false),
          (state.uploadDeviationDocsErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(uploadDeviationDocument.fulfilled, (state, action) => {
        (state.isUploadDeviationDocsLoading = false),
          (state.isUploadDeviationDocsSuccess = true),
          (state.deviationDocumentList = action.payload);
      })
      .addCase(uploadDeviationDocument.rejected, (state, action) => {
        (state.isUploadDeviationDocsLoading = false),
          (state.isUploadDeviationDocsFailed = true),
          (state.uploadDeviationDocsErrorContainer = {
            ...state.uploadDeviationDocsErrorContainer,
            ...action.payload
          });
      })
      .addCase(deleteDeviationDocument.pending, state => {
        (state.isDeleteDeviationDocsLoading = true),
          (state.isDeleteDeviationDocsSuccess = false),
          (state.isDeleteDeviationDocsFailed = false),
          (state.deleteDeviationDocsErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(deleteDeviationDocument.fulfilled, (state, action) => {
        (state.isDeleteDeviationDocsLoading = false),
          (state.isDeleteDeviationDocsSuccess = true),
          (state.deviationDocumentList = action.payload);
      })
      .addCase(deleteDeviationDocument.rejected, (state, action) => {
        (state.isDeleteDeviationDocsLoading = false),
          (state.isDeleteDeviationDocsFailed = true),
          (state.deleteDeviationDocsErrorContainer = {
            ...state.deleteDeviationDocsErrorContainer,
            ...action.payload
          });
      })
      .addCase(downloadDeviationDocument.pending, state => {
        (state.isDownloadDeviationDocsLoading = true),
          (state.isDownloadDeviationDocsSuccess = false),
          (state.isDownloadDeviationDocsFailed = false),
          (state.downloadDeviationDocsErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(downloadDeviationDocument.fulfilled, state => {
        (state.isDownloadDeviationDocsLoading = false),
          (state.isDownloadDeviationDocsSuccess = true);
      })
      .addCase(downloadDeviationDocument.rejected, (state, action) => {
        (state.isDownloadDeviationDocsLoading = false),
          (state.isDownloadDeviationDocsFailed = true),
          (state.downloadDeviationDocsErrorContainer = {
            ...state.downloadDeviationDocsErrorContainer,
            ...action.payload
          });
      })
      .addCase(logoutAndClearToken, () => {
        return intial;
      })
      .addCase(clearDecisioningState, state => {
        (state.isCaseDecisioningSuccess = false),
          (state.isCaseDecisioningFailed = false);
      })
      .addCase(clearBureauNotification, state => {
        (state.isBureauResultFailed = false),
          (state.isBureauResultSuccess = false);
      })
      .addCase(clearDeviationUploadNotification, state => {
        state.isUploadDeviationDocsSuccess = false;
        state.isUploadDeviationDocsFailed = false;
        state.isDeleteDeviationDocsSuccess = false;
        state.isDeleteDeviationDocsFailed = false;
        state.isDownloadDeviationDocsSuccess = false;
        state.isDownloadDeviationDocsFailed = false;
      });
  }
});

export default caseSummarySlice.reducer;
