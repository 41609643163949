import { Box, Grid } from '@mui/material';
import { StyledGrid } from '../../../../utils/styledLayout';
import ReportsFilter from './ReportsFilter';
import { useEffect, useState } from 'react';
import { createTitle, formatDate, handleError } from '../../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearDownloadReportNotification,
  downloadReport
} from '../../../../redux/reducers/adminSlice';
import Loader from '../../../../components/Loader';
import SnackBarBox from '../../../../components/SnackBarBox';
import { REPORT_DOWNLOAD_SUCCESS } from '../../../../utils/messages';
import { LoadingButton } from '@mui/lab';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CustomDataTable from '../../../../components/CustomDataTable';
import {
  fetchReportAdmin,
  revertAllAdminReport
} from '../../../../redux/reducers/ReportAdminSlice';
function ReportsView() {
  const dispatch = useDispatch();

  // const {
  //   isDownloadReportLoading,
  //   isDownloadReportSuccess,
  //   isDownloadReportFailed,
  //   downloadReportErrorContainer
  // } = useSelector(state => state.admin);

  const {
    isAdminReportListLoading,
    isAdminReportListSuccess,
    isAdminReportListFailed,
    adminReportData
  } = useSelector(state => state.adminReport);

  const [initialValues, setInitialValues] = useState({
    type: 'investor',
    userLevel: 'all',
    maturityDate: 'Today',
    page: 0,
    size: 10,
    stateIds: [1, 20]
  });
  const [type, setType] = useState('investor');

  const onFilterSubmit = value => {
    console.log('Search', value?.type);
    setType(value?.type);
    const newValues = {
      ...value,
      userLevel: value?.userLevel.toLowerCase()
    };

    dispatch(fetchReportAdmin(newValues));

    // const newValues = {
    //   type: value?.type,
    //   fullname: value.searchCriteria === 'fullname' ? value.searchField : '',
    //   fromDate: value.startDate
    //     ? formatDate(value.startDate, 'YYYY-MM-DD')
    //     : '',
    //   toDate: value.endDate ? formatDate(value.endDate, 'YYYY-MM-DD') : ''
    // };
    // dispatch(
    //   downloadReport({
    //     pageData: newValues,
    //     type: value?.type
    //   })
    // );
  };

  // useEffect(() => {
  //   dispatch(fetchReportAdmin(initialValues));
  // }, [initialValues]);

  useEffect(() => {
    dispatch(revertAllAdminReport());
  }, []);

  useEffect(() => {
    document.title = createTitle('Reports');
    dispatch(clearDownloadReportNotification());
  }, []);

  const headersList = [
    {
      id: 'investorId',
      label: 'Investor ID',
      sortable: true,
      width: 100,
      visibleFor: ['investor']
    },
    {
      id: 'investorName',
      label: 'Investor Name',
      sortable: true,
      width: 150,
      visibleFor: ['investor']
    },
    {
      id: 'borrowerId',
      label: 'Borrower ID',
      sortable: true,
      width: 100,
      visibleFor: ['borrower']
    },
    {
      id: 'borrowerName',
      label: 'Borrower Name',
      sortable: true,
      width: 150,
      visibleFor: ['borrower']
    },

    {
      id: 'email',
      label: 'Email ID',
      sortable: true,
      width: 150,
      visibleFor: ['investor', 'borrower']
    },
    {
      id: 'mobileNo',
      label: 'Mobile',
      sortable: true,
      width: 130,
      visibleFor: ['investor', 'borrower']
    },
    {
      id: 'productName',
      label: 'Product Name',
      sortable: true,
      width: 150,
      visibleFor: ['investor', 'borrower']
    },
    {
      id: 'investmentAmount',
      label: 'Invested Amount',
      sortable: true,
      width: 130,
      visibleFor: ['investor']
    },
    {
      id: 'borrowedAmount',
      label: 'Borrowed Amount',
      sortable: true,
      width: 130,
      visibleFor: ['borrower']
    },

    {
      id: 'roi',
      label: 'ROI',
      sortable: true,
      width: 100,
      visibleFor: ['borrower']
    },
    {
      id: 'tenure',
      label: 'Tenure (Months)',
      sortable: true,
      width: 100,
      visibleFor: ['investor', 'borrower']
    },
    // {
    //   id: 'maturityAmount',
    //   label: 'Maturity Amount',
    //   sortable: true,
    //   width: 130,
    //   visibleFor: ['investor']
    // },
    {
      id: 'endDate',
      label: 'Maturity Date',
      sortable: true,
      width: 120,
      visibleFor: ['investor', 'borrower']
    },
    {
      id: 'monthlyEmi',
      label: 'Monthly EMI',
      sortable: true,
      width: 130,
      visibleFor: ['borrower']
    },

    {
      id: 'outstandingAmount',
      label: ' Outstanding Amount',
      sortable: true,
      width: 130,
      visibleFor: ['borrower']
    },
    {
      id: 'emiDate',
      label: 'EMI Date',
      sortable: true,
      width: 120,
      visibleFor: ['borrower']
    },
    {
      id: 'panNo',
      label: 'PAN Number',
      sortable: true,
      width: 130,
      visibleFor: ['investor', 'borrower']
    },
    {
      id: 'aadhaarNo',
      label: 'Aadhaar Number',
      sortable: true,
      width: 150,
      visibleFor: ['investor', 'borrower']
    },
    {
      id: 'age',
      label: 'Age',
      sortable: true,
      width: 50,
      visibleFor: ['investor']
    },
    // {
    //   id: 'location',
    //   label: 'Location',
    //   sortable: true,
    //   width: 150,
    //   visibleFor: ['investor']
    // },
    {
      id: 'accountNo',
      label: 'Account Number',
      sortable: true,
      width: 150,
      visibleFor: ['investor', 'borrower']
    },
    {
      id: 'ifsc',
      label: 'IFSC Code',
      sortable: true,
      width: 100,
      visibleFor: ['investor', 'borrower']
    }
    // {
    //   id: 'withdrawReinvest',
    //   label: 'Withdraw/\nRe-Invest',
    //   sortable: true,
    //   // width: 120,
    //   visibleFor: ['investor']
    // },
    // {
    //   id: 'withdrawAmount',
    //   label: 'Withdraw Amount',
    //   sortable: true,
    //   width: 130,
    //   visibleFor: ['investor']
    // },
    // {
    //   id: 'reinvestedAmount',
    //   label: 'Re-invested Amount',
    //   sortable: true,
    //   width: 150,
    //   visibleFor: ['investor']
    // },
    // {
    //   id: 'status',
    //   label: 'Status',
    //   sortable: true,
    //   width: 100,
    //   visibleFor: ['investor', 'borrower']
    // }
  ];

  const getVisibleHeaders = type => {
    return headersList.filter(header => header.visibleFor.includes(type));
  };

  const headers = getVisibleHeaders(type);
  return (
    <>
      <StyledGrid
        container
        direction="row"
        justifyContent="left"
        alignItems="left"
      >
        <Box
          sx={{
            borderRadius: 5,
            backgroundColor: 'white',
            width: '100%',
            marginTop: 3,
            paddingTop: 3,
            paddingBottom: 3
          }}
        >
          <ReportsFilter onFilterSubmit={onFilterSubmit} />
        </Box>
        {!isAdminReportListLoading && isAdminReportListSuccess && (
          <Box
            sx={{
              borderRadius: 5,
              backgroundColor: 'white',
              width: '100%',
              boxShadow: '0px 1px 17px -5px rgba(0,0,0,0.67)',
              padding: 2,
              overflow: 'hidden', // Ensure any overflow is hidden
              color: 'black'
            }}
          >
            <Grid
              item
              container
              xs={12}
              justifyContent="flex-end"
              alignItems="center"
            >
              <LoadingButton
                startIcon={<FileUploadIcon />}
                variant="outlined"
                sx={{
                  borderColor: '#303157',
                  borderRadius: '6px',
                  color: 'black',
                  borderWidth: '2px',
                  width: '110px',
                  height: '45px',
                  marginRight: '20px',
                  fontWeight: '600',
                  fontSize: '14px'
                }}
                // onClick={onClickBulkHdlr}
              >
                EXPORT
                <input
                  key={'uploadProfile'}
                  type="file"
                  style={{ display: 'none' }}
                  accept={'.xlsx'}
                />
              </LoadingButton>
            </Grid>
            <Grid
              container
              direction="row"
              sx={{ marginTop: 1, overflow: 'auto' }}
            >
              <Box sx={{ width: '100%', overflow: 'auto', padding: 3 }}>
                <CustomDataTable
                  columns={headers}
                  allowSelection={false}
                  // defaultOrderBy={'createdDate'}
                  data={
                    Array.isArray(adminReportData?.adminReportList)
                      ? adminReportData?.adminReportList
                      : []
                  }
                  onPageChange={val => {
                    setInitialValues({ ...initialValues, page: val });
                  }}
                  onRowsPerPageChange={val => {
                    setInitialValues({ ...initialValues, size: val });
                  }}
                  defaultPage={initialValues?.page}
                  defaultRowsPerPage={initialValues?.size}
                  totalCount={adminReportData?.totalCount}
                  isLoading={isAdminReportListLoading}
                />
              </Box>
            </Grid>
          </Box>
        )}

        {/* {isDownloadReportLoading && <Loader key="report_download" />}

        {!isDownloadReportLoading && isDownloadReportFailed && (
          <SnackBarBox
            type="error"
            message={handleError(downloadReportErrorContainer.errorMessage)}
          />
        )}

        {!isDownloadReportLoading && isDownloadReportSuccess && (
          <SnackBarBox type="success" message={REPORT_DOWNLOAD_SUCCESS} />
        )} */}
      </StyledGrid>
    </>
  );
}

export default ReportsView;
