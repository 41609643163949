import { Button, Grid, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PropTypes from 'prop-types';

function BackButton({ text = 'Back', path }) {
  const navigate = useNavigate();

  const handleBackButton = () => {
    navigate(path);
    return;
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      marginTop={3}
    >
      <Grid>
        <Tooltip placement="top" title="Click to go back">
          <Button
            onClick={handleBackButton}
            size="small"
            startIcon={<ArrowBackIcon />}
          >
            {text}
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

BackButton.propTypes = {
  text: PropTypes.string,
  path: PropTypes.string
};

export default BackButton;
