import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { Grid, Radio } from '@mui/material';
import { printStringValue, toCamelCase } from '../utils/utils';
import SkeletonBars from './SkeletonBars';

const visuallyHidden = {
  position: 'absolute',
  top: -9999,
  left: -9999,
  width: 1,
  height: 1,
  overflow: 'hidden'
};

const EnhancedTableHead = ({
  columns,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  allowSelection,
  headStyle,
  checkType
}) => {
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={headStyle}>
        {allowSelection && checkType === 'checkbox' && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all items'
              }}
            />
          </TableCell>
        )}
        {allowSelection && checkType === 'radio' && (
          <TableCell padding="checkbox"></TableCell>
        )}
        {columns.map(column => (
          <TableCell
            key={column.id}
            align={column.numeric ? 'right' : 'left'}
            padding={column.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === column.id ? order : false}
            sx={{ fontWeight: '700' }}
          >
            {column.sortable ? (
              <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : 'asc'}
                onClick={createSortHandler(column.id)}
                sx={{ color: 'black' }}
              >
                {column.label}
                {orderBy === column.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <Typography sx={{ color: 'black' }}>{column.label}</Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  columns: PropTypes.array.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  numSelected: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  allowSelection: PropTypes.bool,
  checkType: PropTypes.string
};

function CustomDataTable({
  columns,
  data,
  onPageChange,
  onRowsPerPageChange,
  defaultPage = 0,
  defaultRowsPerPage = 5,
  selectedRows,
  onSelectionChange,
  totalCount,
  allowSelection,
  headStyle,
  onRowClick = () => {
    return false;
  },
  isCursor = true,
  isLoading = false,
  checkType = 'checkbox'
}) {
  const [order, setOrder] = useState(null);
  const [orderBy, setOrderBy] = useState(null);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(defaultPage);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  useEffect(() => {
    setSelected(selectedRows || []);
  }, [selectedRows]);

  useEffect(() => {
    setPage(defaultPage);
  }, [totalCount]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = data.map(item => item[columns[0].uniquekey]);
      setSelected(newSelected);
      onSelectionChange && onSelectionChange(newSelected);
      return;
    }
    setSelected([]);
    onSelectionChange && onSelectionChange([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    onSelectionChange && onSelectionChange(newSelected);
  };

  const handleRadioClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex !== -1) {
      return;
    }
    newSelected = newSelected.concat(id);
    setSelected(newSelected);
    onSelectionChange && onSelectionChange(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onPageChange && onPageChange(newPage);
  };

  const handleChangeRowsPerPage = event => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    console.log('Hi', newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    onRowsPerPageChange && onRowsPerPageChange(newRowsPerPage);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const emptyRows = rowsPerPage - data.length;

  const visibleRows = useMemo(
    () =>
      stableSort(data, getComparator(order, orderBy)).slice(
        0 * rowsPerPage,
        0 + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, data]
  );

  const handleRowClick = row => {
    onRowClick(row);
  };

  return (
    <>
      <Box sx={{ width: '100%', overflowX: 'auto' }}>
        <TableContainer sx={{ maxHeight: 500 }}>
          <Table aria-labelledby="tableTitle" size={'medium'} stickyHeader>
            <EnhancedTableHead
              columns={columns}
              numSelected={selected.length}
              order={order ? order : 'desc'}
              orderBy={orderBy ? orderBy : 'id'}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data?.length}
              allowSelection={allowSelection}
              headStyle={headStyle}
              checkType={checkType}
            />
            <TableBody>
              {data?.length > 0 ? (
                visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row[columns[0].uniquekey]);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row[columns[0].uniquekey]}
                      selected={isItemSelected}
                      sx={{
                        cursor: isCursor ? 'pointer' : 'default',
                        '&.Mui-selected': {
                          backgroundColor: 'rgba(0, 0, 0, 0.08)'
                        },
                        fontWeight: '700',
                        '&:hover': {
                          backgroundColor: '#ccc!important'
                        }
                      }}
                    >
                      {allowSelection && checkType === 'checkbox' && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            onClick={event => {
                              handleClick(event, row[columns[0].uniquekey]);
                            }}
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId
                            }}
                          />
                        </TableCell>
                      )}
                      {allowSelection && checkType === 'radio' && (
                        <TableCell padding="checkbox">
                          <Radio
                            onClick={event => {
                              handleRadioClick(
                                event,
                                row[columns[0].uniquekey]
                              );
                            }}
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId
                            }}
                          />
                        </TableCell>
                      )}
                      {columns.map((column, columnIndex) => (
                        <TableCell
                          key={column.id}
                          align={column.numeric ? 'right' : 'left'}
                          width={column.width}
                          sx={{
                            minWidth: column.width,
                            whiteSpace: 'pre-line',
                            fontWeight: '600',
                            borderBottom: '1px solid #ccc'
                          }}
                          onClick={() => handleRowClick(row)}
                        >
                          {column.render
                            ? column.render(row, columnIndex)
                            : printStringValue(toCamelCase(row[column.id]))}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })
              ) : !isLoading ? (
                <TableRow>
                  <TableCell
                    colSpan={columns.length + 1}
                    sx={{ textAlign: 'center' }}
                  >
                    <Typography variant="body1" sx={{ p: 2 }}>
                      No data found.
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  <TableRow>
                    <TableCell
                      colSpan={columns.length + 1}
                      sx={{ textAlign: 'center' }}
                    >
                      <SkeletonBars key="table" />
                    </TableCell>
                  </TableRow>
                </>
              )}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows
                  }}
                >
                  <TableCell colSpan={columns.length + 1} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Grid item>
          <TablePagination
            padding="0"
            rowsPerPageOptions={[5, 10, 15, 20]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </>
  );
}

CustomDataTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      width: PropTypes.string, // Add this line for width prop
      sortable: PropTypes.bool,
      numeric: PropTypes.bool,
      render: PropTypes.func
    })
  ).isRequired,
  data: PropTypes.array.isRequired,
  onPageChange: PropTypes.func, // New prop
  onRowsPerPageChange: PropTypes.func, // New prop
  defaultPage: PropTypes.number,
  defaultRowsPerPage: PropTypes.number,
  onSelectionChange: PropTypes.func,
  selectedRows: PropTypes.array,
  totalCount: PropTypes.number.isRequired,
  allowSelection: PropTypes.bool,
  checkType: PropTypes.string
};

export default CustomDataTable;
