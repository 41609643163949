import { Grid, IconButton, Skeleton, Tooltip, Typography } from '@mui/material';
import { colorGolden, colorGrayish } from '../../config/theme';
import { useDispatch, useSelector } from 'react-redux';
import SnackBarBox from '../../components/SnackBarBox';
import {
  checkNumberOf30PlusDpdIn3Months,
  checkNumberOf90PlusDpdIn12Months,
  checkNumberOfDoubtfullAccounts,
  checkNumberOfSpecialMentionedAccounts,
  checkNumberOfSubStandardAccounts,
  checkTotalActiveAccounts,
  checkValidScore,
  createTitle,
  formatAmountInINR,
  handleError,
  printStringValue
} from '../../utils/utils';
import ErrorIcon from '@mui/icons-material/Error';
import { useEffect } from 'react';
import {
  clearBureauNotification,
  fetchBureauResultManually
} from '../../redux/reducers/casesummarySlice';
import RefreshIcon from '@mui/icons-material/Refresh';

const iconStyle = {
  color: colorGolden,
  paddingLeft: '2px',
  cursor: 'pointer'
};
export const BureauDetails = () => {
  const dispatch = useDispatch();

  const {
    isBureauResultLoading,
    isBureauResultFailed,
    bureauResultErrorContainer,
    bureauResult,
    caseSummaryDetails
  } = useSelector(state => state.caseSummary);

  const borrowerDetails = caseSummaryDetails['borrowerDetails'];

  const bureauResultData = bureauResult['data'];

  useEffect(() => {
    document.title = createTitle('Bureau Details');
    return () => dispatch(clearBureauNotification());
  }, []);

  const handleFetchBureauManually = () => {
    if (borrowerDetails?.lbUserId) {
      dispatch(fetchBureauResultManually(borrowerDetails?.lbUserId));
    }
  };

  return (
    <>
      {!isBureauResultLoading && (
        <>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <Grid item>
              <Tooltip placement="top" title="Fetch bureau">
                <IconButton
                  onClick={handleFetchBureauManually}
                  color="primary"
                  sx={{ p: '10px' }}
                  aria-label="menu"
                >
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container spacing={2} p={2}>
            <Grid item xs={12} md={3} sm={6}>
              <div style={{ display: 'flex' }}>
                <Typography
                  variant="subtitle2"
                  fontWeight={'500'}
                  color={colorGrayish}
                >
                  Score
                </Typography>

                <Tooltip
                  placement="top"
                  title={checkValidScore(bureauResultData?.score, 'message')}
                >
                  {checkValidScore(bureauResultData?.score, 'status') && (
                    <ErrorIcon sx={iconStyle} />
                  )}
                </Tooltip>
              </div>

              <Typography variant="subtitle2" fontWeight={'600'}>
                {printStringValue(bureauResultData?.score)}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3} sm={6}>
              <div style={{ display: 'flex' }}>
                <Typography
                  variant="subtitle2"
                  fontWeight={'500'}
                  color={colorGrayish}
                >
                  Total Active Accounts
                </Typography>

                <Tooltip
                  placement="top"
                  title={checkTotalActiveAccounts(
                    bureauResultData?.totalActiveAccounts,
                    'message'
                  )}
                >
                  {checkTotalActiveAccounts(
                    bureauResultData?.totalActiveAccounts,
                    'status'
                  ) && <ErrorIcon sx={iconStyle} />}
                </Tooltip>
              </div>
              <Typography variant="subtitle2" fontWeight={'600'}>
                {printStringValue(bureauResultData?.totalActiveAccounts)}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3} sm={6}>
              <Typography
                variant="subtitle2"
                fontWeight={'500'}
                color={colorGrayish}
              >
                Total Secured Accounts
              </Typography>
              <Typography variant="subtitle2" fontWeight={'600'}>
                {printStringValue(bureauResultData?.totalSecuredAccounts)}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3} sm={6}>
              <Typography
                variant="subtitle2"
                fontWeight={'500'}
                color={colorGrayish}
              >
                Total Unsecured Accounts
              </Typography>
              <Typography variant="subtitle2" fontWeight={'600'}>
                {printStringValue(bureauResultData?.totalUnSecuredAccounts)}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3} sm={6}>
              <div style={{ display: 'flex' }}>
                <Typography
                  variant="subtitle2"
                  fontWeight={'500'}
                  color={colorGrayish}
                >
                  Number of Special Mentioned Accounts
                </Typography>

                <Tooltip
                  placement="top"
                  title={checkNumberOfSpecialMentionedAccounts(
                    bureauResultData?.numberOfSpecialMentionedAccounts,
                    'message'
                  )}
                >
                  {checkNumberOfSpecialMentionedAccounts(
                    bureauResultData?.numberOfSpecialMentionedAccounts,
                    'status'
                  ) && <ErrorIcon sx={iconStyle} />}
                </Tooltip>
              </div>

              <Typography variant="subtitle2" fontWeight={'600'}>
                {printStringValue(
                  bureauResultData?.numberOfSpecialMentionedAccounts
                )}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3} sm={6}>
              <div style={{ display: 'flex' }}>
                <Typography
                  variant="subtitle2"
                  fontWeight={'500'}
                  color={colorGrayish}
                >
                  Number of Substandard Accounts
                </Typography>

                <Tooltip
                  placement="top"
                  title={checkNumberOfSubStandardAccounts(
                    bureauResultData?.numberOfSubStandardAccounts,
                    'message'
                  )}
                >
                  {checkNumberOfSubStandardAccounts(
                    bureauResultData?.numberOfSubStandardAccounts,
                    'status'
                  ) && <ErrorIcon sx={iconStyle} />}
                </Tooltip>
              </div>

              <Typography variant="subtitle2" fontWeight={'600'}>
                {printStringValue(
                  bureauResultData?.numberOfSubStandardAccounts
                )}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3} sm={6}>
              <div style={{ display: 'flex' }}>
                <Typography
                  variant="subtitle2"
                  fontWeight={'500'}
                  color={colorGrayish}
                >
                  Number of Doubtful Accounts
                </Typography>

                <Tooltip
                  placement="top"
                  title={checkNumberOfDoubtfullAccounts(
                    bureauResultData?.numberOfDoubtfullAccounts,
                    'message'
                  )}
                >
                  {checkNumberOfDoubtfullAccounts(
                    bureauResultData?.numberOfDoubtfullAccounts,
                    'status'
                  ) && <ErrorIcon sx={iconStyle} />}
                </Tooltip>
              </div>

              <Typography variant="subtitle2" fontWeight={'600'}>
                {printStringValue(bureauResultData?.numberOfDoubtfullAccounts)}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3} sm={6}>
              <Typography
                variant="subtitle2"
                fontWeight={'500'}
                color={colorGrayish}
              >
                Number of Loss Accounts
              </Typography>
              <Typography variant="subtitle2" fontWeight={'600'}>
                {printStringValue(bureauResultData?.numberOfLossAccounts)}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3} sm={6}>
              <div style={{ display: 'flex' }}>
                <Typography
                  variant="subtitle2"
                  fontWeight={'500'}
                  color={colorGrayish}
                >
                  Number of 30plusDpd In 3Months
                </Typography>

                <Tooltip
                  placement="top"
                  title={checkNumberOf30PlusDpdIn3Months(
                    bureauResultData?.numberOf30PlusDpdIn3Months,
                    'message'
                  )}
                >
                  {checkNumberOf30PlusDpdIn3Months(
                    bureauResultData?.numberOf30PlusDpdIn3Months,
                    'status'
                  ) && <ErrorIcon sx={iconStyle} />}
                </Tooltip>
              </div>

              <Typography variant="subtitle2" fontWeight={'600'}>
                {printStringValue(bureauResultData?.numberOf30PlusDpdIn3Months)}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3} sm={6}>
              <div style={{ display: 'flex' }}>
                <Typography
                  variant="subtitle2"
                  fontWeight={'500'}
                  color={colorGrayish}
                >
                  Number of 90plusDpd In 12Months
                </Typography>

                <Tooltip
                  placement="top"
                  title={checkNumberOf90PlusDpdIn12Months(
                    bureauResultData?.numberOf90PlusDpdIn12Months,
                    'message'
                  )}
                >
                  {checkNumberOf90PlusDpdIn12Months(
                    bureauResultData?.numberOf90PlusDpdIn12Months,
                    'status'
                  ) && <ErrorIcon sx={iconStyle} />}
                </Tooltip>
              </div>
              <Typography variant="subtitle2" fontWeight={'600'}>
                {printStringValue(
                  bureauResultData?.numberOf90PlusDpdIn12Months
                )}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3} sm={6}>
              <Typography
                variant="subtitle2"
                fontWeight={'500'}
                color={colorGrayish}
              >
                Total Over Due Amount
              </Typography>
              <Typography variant="subtitle2" fontWeight={'600'}>
                {bureauResultData?.totalOverDueAmount
                  ? formatAmountInINR(bureauResultData?.totalOverDueAmount)
                  : '-'}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3} sm={6}>
              <Typography
                variant="subtitle2"
                fontWeight={'500'}
                color={colorGrayish}
              >
                Active Monthly EMI
              </Typography>
              <Typography variant="subtitle2" fontWeight={'600'}>
                {printStringValue(bureauResultData?.activeMonthlyEmi)}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}

      {isBureauResultLoading && (
        <Skeleton
          sx={{ p: 0 }}
          variant="rectangular"
          height={100}
          width={'100%'}
        />
      )}

      {!isBureauResultLoading && isBureauResultFailed && (
        <SnackBarBox
          type="error"
          message={handleError(bureauResultErrorContainer?.errorMessage)}
        />
      )}
    </>
  );
};
