import { LoadingButton } from '@mui/lab';
import { Box, Dialog, DialogContent, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IMG_FAILED, IMG_SUCCESS } from '../utils/imageUrls';

const successStyle = {
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  maxWidth: '100%',
  maxHeight: '100%',
  marginTop: '2rem'
};

const successArchStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  backgroundColor: '#F3FCF7',
  height: '130px',
  borderRadius: '0 0 50% 50%'
};
const failedArchStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  backgroundColor: '#FEF6F4',
  height: '130px',
  borderRadius: '0 0 50% 50%'
};
function ResponseModal({
  fullwidth,
  type,
  message,
  buttonText,
  navigationPath,
  onClose
}) {
  const navigate = useNavigate();

  const handleClose = () => {
    onClose();
  };

  const handleNavigate = navigationPath => {
    onClose();
    if (navigationPath) navigate(navigationPath);
    else return;
  };

  return (
    <Dialog
      fullWidth={fullwidth}
      maxWidth="xs"
      open={true}
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      disableEscapeKeyDown
      scroll="body"
      className="custom-dialog"
    >
      <DialogContent className="MuiDialogContentRootc">
        <Box>
          {type === 'success' ? (
            <>
              <div style={successArchStyle}></div>
              <img src={IMG_SUCCESS} alt="success" style={successStyle} />
            </>
          ) : (
            <>
              <div style={failedArchStyle}></div>
              <img src={IMG_FAILED} alt="success" style={successStyle} />
            </>
          )}

          {type === 'success' ? (
            <Typography
              align="center"
              variant="body1"
              fontWeight={600}
              sx={{
                fontSize: '1rem',
                color: '#5EAC1B',
                marginTop: '150px'
              }}
            >
              {message}
            </Typography>
          ) : (
            <Typography
              align="center"
              variant="body1"
              fontWeight={600}
              sx={{
                fontSize: '1rem',
                color: '#db3131',
                marginTop: '150px'
              }}
            >
              {message}
            </Typography>
          )}

          <LoadingButton
            fullWidth
            style={{
              textTransform: 'uppercase',
              marginTop: '3.5rem',
              textAlign: 'center',
              justifyContent: 'center'
            }}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleNavigate(navigationPath)}
          >
            {buttonText}
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default ResponseModal;
