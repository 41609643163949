import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import CategoryIcon from '@mui/icons-material/Category';
import { useDispatch } from 'react-redux';
import { LOGIN_ADMIN_MODULE_USER, moduleList } from '../../utils/constant';
import { authenticateUser } from '../../redux/reducers/authSlice';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {
  enterEmailOrMobileNew,
  enterOnlyAlphabatesOrNumber,
  enterWithNoSpaces,
  isArrayNotEmpty,
  isEmail,
  isValidEmail
} from '../../utils/utils';
import { colorGolden } from '../../config/theme';
import GenericInput from '../../components/GenericInput';
import { FormButtonBottom } from '../../utils/styledLayout';
import { SELECT_MODULE_MESSAGE } from '../../utils/messages';
import SnackBarBox from '../../components/SnackBarBox';

export const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(true);

  const [showError, setShowError] = useState(false);

  const formControl = {
    margin: '0.7rem auto'
  };

  const handleSubmit = values => {
    if (isEmail(values.username)) {
      dispatch(authenticateUser({ ...values, email: values.username }));
    } else {
      dispatch(authenticateUser({ ...values, mobileNo: values.username }));
    }
  };

  const formik = useFormik({
    initialValues: {
      module: '',
      username: '',
      password: ''
    },

    validationSchema: yup.object({
      module: yup.string().required('Module is required'),
      password: yup.string().required('Password is required'),
      username: yup
        .string()
        .required('Email ID or Mobile number is required')
        .test(
          'is-email-or-phone',
          'Please enter a valid email or mobile number',
          value => {
            return isValidEmail(value) || /^\d{10}$/.test(value);
          }
        )
    }),
    // onSubmit: value => {
    //   dispatch(authenticateUser(value));
    // }
    onSubmit: handleSubmit
  });

  useEffect(() => {
    formik.setFieldValue('module', LOGIN_ADMIN_MODULE_USER);
  }, []);

  const handleOnlyEmailOrMobile = (e, field) => {
    formik.setFieldValue(field, enterEmailOrMobileNew(e.target.value));
  };

  const handleFormValue = (e, field) => {
    formik.setFieldValue(
      field,
      enterOnlyAlphabatesOrNumber(e.target.value).toUpperCase()
    );
  };

  const handleResetPassword = () => {
    if (!formik.values.module) {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 3000);
      return;
    }
    navigate(`/reset-password/${formik.values.module}`, {
      state: formik.values.module
    });
    return;
  };

  return (
    <Box sx={{ marginTop: '2rem' }}>
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        <FormControl sx={{ ...formControl }} fullWidth>
          <InputLabel id="select-module" required>
            Select Module
          </InputLabel>
          <Select
            displayEmpty
            required
            labelId="select-module"
            id="select-module"
            label="Select Module"
            size="small"
            value={formik.values.module || ''}
            onChange={formik.handleChange}
            name="module"
            placeholder="Module"
            sx={{ textAlign: 'left' }}
            startAdornment={
              <span
                style={{
                  padding: '0.5rem',
                  cursor: 'pointer',
                  display: 'inline-flex',
                  alignItems: 'center',
                  color: colorGolden
                }}
              >
                <CategoryIcon />
              </span>
            }
          >
            <MenuItem value="">
              <em style={{ fontStyle: 'normal' }}>Select Module</em>
            </MenuItem>

            {isArrayNotEmpty(moduleList) &&
              moduleList.map((type, _idx) => {
                return (
                  <MenuItem key={`${_idx}_${type?.key}`} value={type?.key}>
                    <em style={{ fontStyle: 'normal' }}>{type?.value}</em>
                  </MenuItem>
                );
              })}
          </Select>
          {formik.touched.module && formik.errors.module && (
            <FormHelperText sx={{ textAlign: 'start' }}>
              {formik.errors.module}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl variant="outlined" sx={formControl} fullWidth>
          <GenericInput
            label="Email ID or Mobile number"
            name="username"
            size="small"
            inputProps={{ maxLength: 64 }}
            value={formik.values.username}
            onChange={e => handleOnlyEmailOrMobile(e, 'username')}
            error={formik.touched.username && formik.errors.username}
            startIcon={<MailOutlineIcon className="icon_color" />}
          />
        </FormControl>
        <FormControl variant="outlined" sx={formControl} fullWidth>
          <GenericInput
            required
            label="Password"
            size="small"
            name="password"
            type={!showPassword ? 'text' : 'password'}
            inputProps={{ maxLength: 16, minLength: 8 }}
            value={formik.values.password}
            error={formik.touched.password && formik.errors.password}
            startIcon={<LockOutlinedIcon className="icon_color" />}
            endIcon={
              showPassword ? (
                <VisibilityOffOutlinedIcon className="icon_color" />
              ) : (
                <VisibilityOutlinedIcon className="icon_color" />
              )
            }
            onEndIconClick={() => {
              setShowPassword(!showPassword);
            }}
            onChange={e =>
              formik.setFieldValue(
                'password',
                enterWithNoSpaces(e.target.value)
              )
            }
          />
        </FormControl>
        <Grid container>
          <Grid item textAlign={'end'} xs>
            <Button
              variant="text"
              style={{ fontWeight: '600' }}
              onClick={handleResetPassword}
              disabled={showError}
            >
              Reset Password
            </Button>
          </Grid>
        </Grid>
        <FormButtonBottom sx={formControl}>
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            size="medium"
            color="primary"
            sx={{
              mt: 2,
              fontSize: 15,
              fontWeight: '600',
              textTransform: 'uppercase'
            }}
          >
            Login
          </LoadingButton>
        </FormButtonBottom>
      </form>

      {showError && (
        <SnackBarBox type="error" message={SELECT_MODULE_MESSAGE} />
      )}
    </Box>
  );
};
