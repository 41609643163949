import { Grid, Icon, Typography } from '@mui/material';
import { colorGrayish } from '../../../../../config/theme';
import { useSelector } from 'react-redux';
import {
  createTitle,
  formatDate,
  printStringValue
} from '../../../../../utils/utils';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  DOC_TYPE_AADHAAR_BACK,
  DOC_TYPE_AADHAAR_FRONT
} from '../../../../../utils/constant';
import ViewImageQDE from './ViewImageQDE';
import { useEffect } from 'react';

export const AadhaarDetails = () => {
  const { deviationQDEDetails } = useSelector(state => state.admin);

  const borrowerDetails = deviationQDEDetails['borrowerDetails'];

  const isAadhaarVerified = Boolean(
    borrowerDetails?.aadhaarNumber,
    borrowerDetails?.nameAsPerAadhaar,
    borrowerDetails?.dobAsPerAadhaar
  );

  useEffect(() => {
    document.title = createTitle('Aadhaar Details');
  }, []);

  return (
    <>
      {borrowerDetails && (
        <>
          <Grid container spacing={2} p={2}>
            <Grid item xs={12} md={6} sm={6} lg={6} xl={3}>
              <Typography
                variant="subtitle2"
                fontWeight={'500'}
                color={colorGrayish}
              >
                Aadhaar Front
              </Typography>

              <ViewImageQDE
                key={DOC_TYPE_AADHAAR_FRONT}
                docType={DOC_TYPE_AADHAAR_FRONT}
              />
            </Grid>

            <Grid item xs={12} md={6} sm={6} lg={6} xl={3}>
              <Typography
                variant="subtitle2"
                fontWeight={'500'}
                color={colorGrayish}
              >
                Aadhaar Back
              </Typography>
              <ViewImageQDE
                key={DOC_TYPE_AADHAAR_BACK}
                docType={DOC_TYPE_AADHAAR_BACK}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} pl={2} pr={2} pb={2}>
            <Grid item xs={12} md={3} sm={6}>
              <Typography
                variant="subtitle2"
                fontWeight={'500'}
                color={colorGrayish}
              >
                Aadhaar Number
              </Typography>
              <Typography variant="subtitle2" fontWeight={'600'}>
                {printStringValue(borrowerDetails?.aadhaarNumber)}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3} sm={6}>
              <Typography
                variant="subtitle2"
                fontWeight={'500'}
                color={colorGrayish}
              >
                Name as per Aadhaar
              </Typography>
              <Typography variant="subtitle2" fontWeight={'600'}>
                {printStringValue(borrowerDetails?.nameAsPerAadhaar)}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3} sm={6}>
              <Typography
                variant="subtitle2"
                fontWeight={'500'}
                color={colorGrayish}
              >
                Date of Birth
              </Typography>
              <Typography variant="subtitle2" fontWeight={'600'}>
                {formatDate(borrowerDetails?.dobAsPerAadhaar, 'DD-MM-YYYY')}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3} sm={6}>
              <Typography
                variant="subtitle2"
                fontWeight={'500'}
                color={colorGrayish}
              >
                Address line 1
              </Typography>
              <Typography variant="subtitle2" fontWeight={'600'}>
                {printStringValue(borrowerDetails?.address1)}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3} sm={6}>
              <Typography
                variant="subtitle2"
                fontWeight={'500'}
                color={colorGrayish}
              >
                Address line 2
              </Typography>
              <Typography variant="subtitle2" fontWeight={'600'}>
                {printStringValue(borrowerDetails?.address2)}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3} sm={6}>
              <Typography
                variant="subtitle2"
                fontWeight={'500'}
                color={colorGrayish}
              >
                Landmark
              </Typography>
              <Typography variant="subtitle2" fontWeight={'600'}>
                {printStringValue(borrowerDetails?.landmark)}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3} sm={6}>
              <Typography
                variant="subtitle2"
                fontWeight={'500'}
                color={colorGrayish}
              >
                PIN Code
              </Typography>
              <Typography variant="subtitle2" fontWeight={'600'}>
                {printStringValue(borrowerDetails?.pinCode)}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3} sm={6}>
              <Typography
                variant="subtitle2"
                fontWeight={'500'}
                color={colorGrayish}
              >
                City
              </Typography>
              <Typography variant="subtitle2" fontWeight={'600'}>
                {printStringValue(borrowerDetails?.city)}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3} sm={6}>
              <Typography
                variant="subtitle2"
                fontWeight={'500'}
                color={colorGrayish}
              >
                State
              </Typography>
              <Typography variant="subtitle2" fontWeight={'600'}>
                {printStringValue(borrowerDetails?.state)}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3} sm={6}>
              <Typography
                variant="subtitle2"
                fontWeight={'500'}
                color={colorGrayish}
              >
                Aadhaar Verification
              </Typography>
              <Typography
                variant="subtitle2"
                fontWeight={'600'}
                display={'flex'}
              >
                {isAadhaarVerified ? 'Verified' : 'Not Verified'}
                {isAadhaarVerified ? (
                  <Icon
                    sx={{ marginLeft: '10px' }}
                    component={CheckCircleIcon}
                    fontSize="medium"
                    color="success"
                  />
                ) : (
                  <Icon
                    sx={{ marginLeft: '10px' }}
                    component={ErrorIcon}
                    fontSize="medium"
                    color="warning"
                  />
                )}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
