import { Typography } from '@mui/material';
import { useState } from 'react';

export const RegionCell = ({ regions }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  if (!regions) return <Typography>-</Typography>;

  // Split the comma-separated string into an array
  const regionArray = regions.split(',');

  if (expanded) {
    return (
      <Typography variant="body2" sx={{ fontWeight: '550', color: 'black' }}>
        {regionArray.join(', ')}
        <span
          onClick={handleToggle}
          style={{ color: '#335DAD', cursor: 'pointer' }}
        >
          {' '}
          less
        </span>
      </Typography>
    );
  }

  const displayRegions = regionArray.slice(0, 1).join(', ');
  const remainingRegions = regionArray.length > 1 ? ' more...' : '';

  return (
    <Typography variant="body2" sx={{ fontWeight: '550', color: 'black' }}>
      {displayRegions}
      {remainingRegions && (
        <span
          onClick={handleToggle}
          style={{ color: '#335DAD', cursor: 'pointer' }}
        >
          {' '}
          {remainingRegions}
        </span>
      )}
    </Typography>
  );
};
