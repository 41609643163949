import { useEffect, useState } from 'react';
import { lightTheme } from './config/theme';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import SplashScreen from './components/SplashScreen';
import {
  Route,
  Routes,
  BrowserRouter as Router,
  Navigate
} from 'react-router-dom';
import NotFound from './pages/NotFound';
import Maintenance from './pages/Maintenance';
import Forbidden from './pages/Forbidden';
import Login from './pages/LoginPage';
import DashboardPage from './pages/Dashboard';
import Layout from './components/Layout';
import ProtectedRoutes from './components/ProtectedRoutes';
import SessionExpired from '../src/components/SessionExpired';
import {
  ADMIN_MENUS_LIST,
  ADMIN_MODULE,
  CREDIT_DASHBOARD,
  CREDIT_MENUS_LIST,
  CREDIT_MODULE,
  getMenuListForRole
  // L1_MENUS_LIST,
  // L2_MENUS_LIST
} from './utils/constant';
import { useSelector } from 'react-redux';
import { CaseSummary } from './containers/casesummary/CaseSummary';
import ResetPassword from './containers/auth/reset/ResetPassword';
import { accessibleRoutes } from './routes/accesibleRoutes';
import BusinessUser from './containers/dashboard/admin/user_access_management/business_user/BusinessUser';
import AdminDashboard from './containers/dashboard/admin/AdminDashboard';
import Branches from './containers/dashboard/admin/user_access_management/branches/Branches';
import RMView from './containers/dashboard/admin/user_access_management/rm/RMView';
import AdminDaviationUpload from './containers/dashboard/admin/daviation/AdminDaviationUpload';
import DeviationQDE from './containers/dashboard/admin/daviation/DeviationQDE';
import ReportsView from './containers/dashboard/admin/reports/ReportsView';
import L1Users from './containers/dashboard/admin/user_access_management/l1_user/L1Users';
import AddL1Users from './containers/dashboard/admin/user_access_management/l1_user/AddL1Users';
import EditL1Users from './containers/dashboard/admin/user_access_management/l1_user/EditL1User';
import L2Users from './containers/dashboard/admin/user_access_management/l2_user/L2Users';
import AddL2Users from './containers/dashboard/admin/user_access_management/l2_user/AddL2Users';
import EditL2Users from './containers/dashboard/admin/user_access_management/l2_user/EditL2User';
import L3Users from './containers/dashboard/admin/user_access_management/l3_user/L3Users';
import AddL3Users from './containers/dashboard/admin/user_access_management/l3_user/AddL3Users';
import EditL3Users from './containers/dashboard/admin/user_access_management/l3_user/EditL3User';
import UserDashboard from './containers/dashboard/admin/UserDashBoard';
import Dashboard from './containers/dashboard/admin/AdminDashboard';
import L1ViewL2UsersList from './containers/dashboard/l1User/L2Users';
import L1ViewL3UsersList from './containers/dashboard/l1User/L3Users';
import L2ViewL3UsersList from './containers/dashboard/l2User/L2ofL3User';

function App() {
  const [showSplash, setShowSplash] = useState(
    Boolean(process.env.NODE_ENV === 'production')
  );

  const { isSessionExpired } = useSelector(state => state.session);

  const { authData } = useSelector(state => state.auth);

  const sessionData = JSON.parse(
    sessionStorage.getItem('x-auth-token-admin-panel') || '{}'
  );
  const { fullName } = sessionData;

  const menusToDisplay = getMenuListForRole(authData?.role);
  useEffect(() => {
    const splashTimer = setTimeout(() => {
      setShowSplash(false);
    }, 1500);
    return () => {
      clearTimeout(splashTimer);
    };
  }, []);

  return (
    <>
      <ThemeProvider theme={lightTheme}>
        <CssBaseline />
        <Box>
          {showSplash ? (
            <SplashScreen />
          ) : (
            <>
              <Router>
                {isSessionExpired && <SessionExpired />}
                <Routes>
                  <Route
                    element={
                      <ProtectedRoutes accessibleRoutes={accessibleRoutes} />
                    }
                  >
                    <Route
                      path="/credit-dashboard"
                      element={
                        <Layout
                          title="Dashboard"
                          menulist={CREDIT_MENUS_LIST}
                          module={CREDIT_MODULE}
                        >
                          <DashboardPage module={CREDIT_DASHBOARD} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/dashboard-casesummary/:id/:userId"
                      element={
                        <Layout
                          title="Credit Case Summary"
                          menulist={CREDIT_MENUS_LIST}
                          module={CREDIT_MODULE}
                        >
                          <CaseSummary type={'dashboard'} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/admin-dashboard"
                      element={
                        <Layout
                          title={fullName}
                          menulist={ADMIN_MENUS_LIST}
                          module={ADMIN_MODULE}
                        >
                          <Dashboard />
                        </Layout>
                      }
                    />
                    <Route
                      path="/user-dashboard"
                      element={
                        <Layout
                          title={fullName}
                          menulist={menusToDisplay}
                          module={ADMIN_MODULE}
                        >
                          <UserDashboard />
                        </Layout>
                      }
                    />
                    <Route
                      path="/l1/l2User"
                      element={
                        <Layout
                          title={fullName}
                          menulist={menusToDisplay}
                          module={ADMIN_MODULE}
                        >
                          <L1ViewL2UsersList />
                        </Layout>
                      }
                    />
                    <Route
                      path="/l1/l3User"
                      element={
                        <Layout
                          title={fullName}
                          menulist={menusToDisplay}
                          module={ADMIN_MODULE}
                        >
                          <L1ViewL3UsersList />
                        </Layout>
                      }
                    />
                    <Route
                      path="/l2/l3User"
                      element={
                        <Layout
                          title={fullName}
                          menulist={menusToDisplay}
                          module={ADMIN_MODULE}
                        >
                          <L2ViewL3UsersList />
                        </Layout>
                      }
                    />
                    <Route
                      path="/l1"
                      element={
                        <Layout
                          title={fullName}
                          menulist={ADMIN_MENUS_LIST}
                          module={ADMIN_MODULE}
                        >
                          <L1Users />
                        </Layout>
                      }
                    />
                    <Route
                      path="/addL1User"
                      element={
                        <Layout
                          title={fullName}
                          menulist={ADMIN_MENUS_LIST}
                          module={ADMIN_MODULE}
                        >
                          <AddL1Users />
                        </Layout>
                      }
                    />
                    <Route
                      path="/editL1User"
                      element={
                        <Layout
                          title={fullName}
                          menulist={ADMIN_MENUS_LIST}
                          module={ADMIN_MODULE}
                        >
                          <EditL1Users />
                        </Layout>
                      }
                    />
                    <Route
                      path="/l2"
                      element={
                        <Layout
                          title={fullName}
                          menulist={ADMIN_MENUS_LIST}
                          module={ADMIN_MODULE}
                        >
                          <L2Users />
                        </Layout>
                      }
                    />
                    <Route
                      path="/addL2User"
                      element={
                        <Layout
                          title={fullName}
                          menulist={menusToDisplay}
                          module={ADMIN_MODULE}
                        >
                          <AddL2Users />
                        </Layout>
                      }
                    />
                    <Route
                      path="/editL2User"
                      element={
                        <Layout
                          title={fullName}
                          menulist={menusToDisplay}
                          module={ADMIN_MODULE}
                        >
                          <EditL2Users />
                        </Layout>
                      }
                    />
                    <Route
                      path="/l3"
                      element={
                        <Layout
                          title={fullName}
                          menulist={ADMIN_MENUS_LIST}
                          module={ADMIN_MODULE}
                        >
                          <L3Users />
                        </Layout>
                      }
                    />
                    <Route
                      path="/addL3User"
                      element={
                        <Layout
                          title={fullName}
                          menulist={menusToDisplay}
                          module={ADMIN_MODULE}
                        >
                          <AddL3Users />
                        </Layout>
                      }
                    />
                    <Route
                      path="/editL3User"
                      element={
                        <Layout
                          title={fullName}
                          menulist={menusToDisplay}
                          module={ADMIN_MODULE}
                        >
                          <EditL3Users />
                        </Layout>
                      }
                    />
                    <Route
                      path="/admin-daviation"
                      element={
                        <Layout
                          title="Deviation"
                          menulist={ADMIN_MENUS_LIST}
                          module={ADMIN_MODULE}
                        >
                          <AdminDaviationUpload />
                        </Layout>
                      }
                    />
                    <Route
                      path="/deviation-qde/:id/:userId"
                      element={
                        <Layout
                          title="Deviation QDE"
                          menulist={ADMIN_MENUS_LIST}
                          module={ADMIN_MODULE}
                        >
                          <DeviationQDE type={'dashboard'} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/reports-view"
                      element={
                        <Layout
                          title={fullName}
                          menulist={ADMIN_MENUS_LIST}
                          module={ADMIN_MODULE}
                        >
                          <ReportsView />
                        </Layout>
                      }
                    />
                    <Route
                      path="/l1Reports-view"
                      element={
                        <Layout
                          title={fullName}
                          menulist={menusToDisplay}
                          module={ADMIN_MODULE}
                        >
                          <ReportsView />
                        </Layout>
                      }
                    />
                  </Route>

                  <Route path="*" element={<NotFound />} />
                  <Route path="maintenance" element={<Maintenance />} />
                  <Route path="/forbidden" element={<Forbidden />} />
                  <Route path="login" Component={Login} />
                  <Route exact path="/" element={<Navigate to="/login" />} />
                  <Route
                    path="/reset-password/:moduleName"
                    element={<ResetPassword />}
                  />
                </Routes>
              </Router>
            </>
          )}
        </Box>
      </ThemeProvider>
    </>
  );
}

export default App;
