import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { colorDanger, colorGolden } from '../config/theme';

export const imageAllowedTypes = ['image/jpeg', 'image/jpg'];
export const CaptureImageAllowedTypes = ['image/jpeg', 'image/jpg'];
export const UploadDocumentAllowedTypes = [
  'image/jpeg',
  'image/jpg',
  'application/pdf'
];

export const UploadDocumentAllowedPDFTypes = ['application/pdf'];
export const imageAllowedExtention = ['jpg', 'jpeg'];
export const UploadDocumentAllowedExtention = ['jpg', 'jpeg', 'pdf'];
export const CaptureImageAllowedExtention = ['jpg', 'jpeg'];
export const IMAGE_SIZE_LIMIT_MESSAGE = `Max size is ${process.env.REACT_APP_MULTIPART_FILE_SIZE_LIMIT} MB.`;
export const OCR_IMAGE_SIZE_LIMIT_MESSAGE = `Max size is ${process.env.REACT_APP_BORROWER_MULTIPART_FILE_SIZE_LIMIT_OCR} MB.`;
export const SELECT_IMAGE_MESSAGE = `Please upload an image in either JPG, JPEG format.`;
export const UPLOAD_DOCUMENT_SELECT_IMAGE_MESSAGE = `Please upload an image in either JPG, JPEG or PDF format.`;

export const BORROWER_IMAGE_SIZE_LIMIT_MESSAGE = `Max size is ${process.env.REACT_APP_BORROWER_MULTIPART_FILE_SIZE_LIMIT_OCR} MB.`;
export const SELECT_BORROWER_IMAGE_MESSAGE = `Please upload an image in either JPG, JPEG, PNG, or PDF format.`;
export const BORROWER_IMAGE_ALLOWED_TYPE = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/pdf'
];
export const BORROWER_IMAGE_EXTENSION = ['jpg', 'jpeg', 'png', 'pdf'];

export const NET_WORTH_MANDATORY_LIMIT = 1000000;

export const USER_TYPE = 'User';
export const RERECTED_TO_THE_LOGIN_PAGE =
  'You will be redirected to the login page';
export const GO_BACK = 'Go Back';

export const PASSWORD_RESET_TYPE = 'password';
export const PIN_RESET_TYPE = 'pin';
export const CREATE_ACCOUNT = 'Create Account';
export const UNEXPECTED_ERROR =
  'It appears that there is an error on the server.';

export const INVESTOR_REQUIREMENT = 'investorRequirements';
export const PAN_VERIFICATION = 'panVerification';
export const AADHAR_VERIFICATION = 'aadhaarVerification';
export const UPLOAD_LATEST_PHOTO = 'photoUpload';
export const PENNY_DROP_VERIFICATION = 'bankAccountVerification';
export const NOMINEE_DETAILS = 'nomineeDetails';
export const DOCUMENT_UPLOAD = 'documentUpload';
export const INVESTOR_SUMMARY = 'investorSummary';

export const BORROWER_REQUIREMENTS = 'borrowerRequirements';

export const USER_TYPE_INVESTOR = 'Investor';

export const USER_TYPE_BORROWER = 'Borrower';

export const OTP_VERIFICATION = 'otpVerification';
export const ADD_BASIC_DETAILS = 'addBasicDetails';
export const PASSWORD_SETUP = 'passwordSetup';
export const PIN_SETUP = 'pinSetup';
export const FINISH = 'finish';

export const LEND_BOX_INVESTOR_INITIAL = 'initial';
export const LEND_BOX_INVESTOR_REGISTRATION_STAGE = 'investorRegistration';
export const LEND_BOX_INVESTOR_DOCUMENT_UPLOAD = 'documentUpload';
export const LEND_BOX_INVESTOR_WALLET_ORDER = 'walletOrder';

export const LEND_BOX_REGISTRATION = 'lendboxRegistration';
export const LEND_BOX_E_SIGNING = 'eSigning';

export const PAYMENT_INITIATED = 'paymentInitiated';

export const CAPTURE_IMAGE = 'Capture image';

export const investorSteps = [
  INVESTOR_REQUIREMENT,
  PAN_VERIFICATION,
  AADHAR_VERIFICATION,
  UPLOAD_LATEST_PHOTO,
  DOCUMENT_UPLOAD,
  PENNY_DROP_VERIFICATION,
  NOMINEE_DETAILS
];

export const borrowerSteps = [
  BORROWER_REQUIREMENTS,
  PAN_VERIFICATION,
  AADHAR_VERIFICATION,
  UPLOAD_LATEST_PHOTO,
  DOCUMENT_UPLOAD,
  PENNY_DROP_VERIFICATION
];

export const createUserSteps = [
  ADD_BASIC_DETAILS,
  OTP_VERIFICATION,
  PASSWORD_SETUP,
  PIN_SETUP
];

export const SELECT_NEXT_BUTTON =
  'Clicking "Save & Next" will save your details.';
export const SELECT_NOTIFY_BUTTON =
  'Clicking "Save & Notify" will trigger a mail.';
export const SELECT_BACK_BUTTON = 'Click "Back" to view your details.';

export const DOC_TYPE_PAN = 'pan';
export const DOC_TYPE_AADHAAR = 'aadhaar';
export const DOC_TYPE_NET_WORTH_CERT = 'networth';
export const DOC_TYPE_AADHAAR_FRONT = 'aadhaarFront';
export const DOC_TYPE_AADHAAR_BACK = 'aadhaarBack';
export const DOC_TYPE_PHOTO = 'photo';
export const AADHAR_OCR_FRONT = 'aadhaarFront';
export const AADHAR_OCR_BACK = 'aadhaarBack';
export const DOC_TYPE_CHEQUE_OCR = 'cheque';
export const DOC_TYPE_BANK_STATEMENT_OCR = 'bankStatement';
export const DOC_TYPE_DEVIATION_MAIL = 'deviationmail';
export const DOC_TYPE_BANK_ANALYSIS = 'bankAnalysis';

export const documentTypes = [
  DOC_TYPE_PAN,
  DOC_TYPE_AADHAAR_FRONT,
  DOC_TYPE_AADHAAR_BACK,
  DOC_TYPE_NET_WORTH_CERT
];

/** Razor Pay configs */

export const RAZORPAY_COLOR = colorGolden;
export const RAZORPAY_COMPANY_NAME = 'UniLyfe';
export const RAZORPAY_IMAGE = '/images/splash_logo.png';

export const LENDBOX_HELP_EMAIL = 'support@lendbox.in';
export const LENDBOX_HELP_PHONE = '+91 7291029298';

export const UNILYFE_HELP_EMAIL = 'support@unilyfep2p.com';
export const UNILYFE_HELP_PHONE = '+91 7775965644';
export const UNILYFE_HELP_PHONE_LINK = '917775965644';

export const WHATSAPP_HELP_LINE = '918928380243';

export const ADMIN_MODULE = 'Admin';
export const CREDIT_MODULE = 'Credit';
export const L1_MODULE = 'L1';
export const L2_MODULE = 'L2';
export const L3_MODULE = 'L3';

export const LOGIN_CREDIT_MODULE_USER = 'CREDIT';
export const LOGIN_ADMIN_MODULE_USER = 'ADMIN';
export const LOGIN_L1_MODULE_USER = 'L1';
export const LOGIN_L2_MODULE_USER = 'L2';
export const LOGIN_L3_MODULE_USER = 'L3';

export const moduleList = [
  { key: LOGIN_ADMIN_MODULE_USER, value: ADMIN_MODULE },
  { key: LOGIN_CREDIT_MODULE_USER, value: CREDIT_MODULE }
  // { key: LOGIN_L1_MODULE_USER, value: L1_MODULE },
  // { key: LOGIN_L2_MODULE_USER, value: L2_MODULE },
  // { key: LOGIN_L3_MODULE_USER, value: L3_MODULE }
];

export const CREDIT_DASHBOARD = 'CREDIT_DASHBOARD';
export const ADMIN_DASHBOARD = 'ADMIN_DASHBOARD';
export const CREDIT_DISBURSED_DASHBOARD = 'Disbursed Case';
export const CREDIT_UNDISBURSED_DASHBOARD = 'Undisbursed Case';

export const CREDIT_MENUS_LIST = [
  {
    name: 'Dashboard',
    route: '/credit-dashboard'
  }
];
export const ADMIN_MENUS_LIST = [
  {
    name: 'Dashboard',
    route: '/admin-dashboard',
    hasChild: false
  },
  {
    name: 'User Access Management',
    hasChild: true,
    submenus: [
      {
        name: 'L1',
        route: '/l1'
      },
      {
        name: 'L2',
        route: '/l2'
      },
      {
        name: 'L3',
        route: '/l3'
      }
    ]
  },
  {
    name: 'Reports',
    route: '/reports-view',
    hasChild: false
  },
  {
    name: 'Deviation',
    route: '/admin-daviation',
    hasChild: false
  }
];

// export const L1_MENUS_LIST = [
//   {
//     name: 'Dashboard',
//     route: '/user-dashboard',
//     hasChild: false
//   },
//   {
//     name: 'User Access Mgmt',
//     hasChild: true,
//     submenus: [
//       {
//         name: 'L2',
//         route: '/l2User'
//       },
//       {
//         name: 'L3',
//         route: '/l3User'
//       }
//     ]
//   },
//   {
//     name: 'Reports',
//     route: '/l1Reports-view',
//     hasChild: false
//   }
// ];

// export const L2_MENUS_LIST = [
//   {
//     name: 'Dashboard',
//     route: '/user-dashboard',
//     hasChild: false
//   },
//   {
//     name: 'User Access Mgmt',
//     hasChild: true,
//     submenus: [
//       {
//         name: 'L3',
//         route: '/l3User'
//       }
//     ]
//   },
//   {
//     name: 'Reports',
//     route: '/l1Reports-view',
//     hasChild: false
//   }
// ];

// Function to get the menu list based on the user's role
export const getMenuListForRole = userRole => {
  const AdminMenuList = [
    {
      name: 'Dashboard',
      route: '/admin-dashboard',
      hasChild: false
    },
    {
      name: 'User Access Management',
      hasChild: true,
      submenus: [
        { name: 'L1', route: '/l1' },
        { name: 'L2', route: '/l2' },
        { name: 'L3', route: '/l3' }
      ]
    },
    {
      name: 'Reports',
      route: '/reports-view',
      hasChild: false
    },
    {
      name: 'Deviation',
      route: '/admin-deviation',
      hasChild: false
    }
  ];

  const baseMenuList = [
    {
      name: 'Dashboard',
      route: '/user-dashboard',
      hasChild: false
    },
    {
      name: 'User Access Management',
      hasChild: true,
      submenus: [
        { name: 'L2', route: '/l1/l2User' },
        { name: 'L3', route: '/l1/l3User' }
      ]
    },
    {
      name: 'Reports',
      route: '/l1Reports-view',
      hasChild: false
    }
  ];
  const baseMenuListL2 = [
    {
      name: 'Dashboard',
      route: '/user-dashboard',
      hasChild: false
    },
    {
      name: 'User Access Management',
      hasChild: true,
      submenus: [{ name: 'L3', route: '/l2/l3User' }]
    },
    {
      name: 'Reports',
      route: '/l1Reports-view',
      hasChild: false
    }
  ];

  const baseMenuListForL3 = [
    {
      name: 'Dashboard',
      route: '/user-dashboard',
      hasChild: false
    },
    {
      name: 'Reports',
      route: '/l1Reports-view',
      hasChild: false
    }
  ];

  if (userRole === 'ROLE_ADMIN') {
    return AdminMenuList;
  } else if (userRole === 'ROLE_L1') {
    return baseMenuList;
  } else if (userRole === 'ROLE_L2') {
    return baseMenuListL2.map(menu => {
      if (menu.name === 'User Access Management' && menu.hasChild) {
        return {
          ...menu,
          submenus: menu.submenus.filter(submenu => submenu.name === 'L3')
        };
      }
      return menu;
    });
  }

  // Return an empty menu or a default menu if the role doesn't match
  return baseMenuListForL3;
};

export const SEARCH_CRITERIA_LIST = [
  { key: '', value: 'None' },
  { key: 'lbUserId', value: 'Transaction ID' },
  { key: 'borrowerName', value: 'Borrower Name' },
  { key: 'mobileNumber', value: 'Mobile Number' },
  { key: 'creditStatus', value: 'Credit Status' }
];

export const BRANCHES_SEARCH_CRITERIA_LIST = [
  { key: '', value: 'None' },
  { key: 'branchName', value: 'Branch Name' },
  { key: 'location', value: 'Location' },
  { key: 'phoneNo', value: 'Phone Number' },
  { key: 'email', value: 'Email ID' }
];

// export const BRANCH_SEARCH_CRITERIA_LIST = [
//   { key: '', value: 'None' },
//   { key: 'branchName', value: 'Branch Name' },
//   { key: 'location', value: 'Location' },
//   { key: 'phoneNo', value: 'Phone Number' },
//   { key: 'email', value: 'Email ID' }
// ];

export const CASE_CATEGORY_LIST = [
  { key: '', value: 'None' },
  { key: 'disbursed', value: 'Disbursed' },
  { key: 'undisbursed', value: 'Undisbursed' }
];

export const DEVIATION_SEARCH_CRITERIA_LIST = [
  { key: '', value: 'None' },
  { key: 'loanType', value: 'Loan Type' },
  { key: 'fullName', value: 'Full Name' }
];

export const DASHBOARD_SEARCH_CRITERIA_LIST = [
  { key: '', value: 'None' },
  { key: 'fullname', value: 'Full Name' }
];
export const RM_SEARCH_CRITERIA_LIST = [
  { key: '', value: 'None' },
  { key: 'relationshipManagerId', value: 'Relationship Manager ID' },
  { key: 'mobileNo', value: 'Mobile Number' },
  { key: 'bankAccountNo', value: 'Bank Account No.' },
  { key: 'ifsc', value: 'IFSC' },
  { key: 'email', value: 'Email' }
];
export const INVESTOR_BORROWER_SEARCH_CRITERIA_LIST = [
  { key: '', value: 'None' },
  { key: 'fullname', value: 'Full Name' }
];

export const BUSINESS_SEARCH_CRITERIA_LIST = [
  { key: '', value: 'None' },
  { key: 'businessName', value: 'Business Name' },
  { key: 'purpose', value: 'Purpose' }
];

export const TENURE_LIST = [3, 6, 9, 12];

export const MOREVERT_EDIT_DELETE_OPTION_LIST = [
  { label: 'Edit', icon: <EditIcon sx={{ marginRight: 2 }} /> },
  {
    label: 'Delete',
    icon: <DeleteIcon sx={{ marginRight: 2, color: colorDanger }} />
  }
];

export const MOREVERT_EDIT_OPTION_LIST = [
  { label: 'Edit', icon: <EditIcon sx={{ marginRight: 2 }} /> }
];

export const states = [
  { id: 1, state: 'Andhra Pradesh' },
  { id: 2, state: 'Arunachal Pradesh' },
  { id: 3, state: 'Assam' },
  { id: 4, state: 'Bihar' },
  { id: 5, state: 'Chhattisgarh' },
  { id: 6, state: 'Goa' },
  { id: 7, state: 'Gujarat' },
  { id: 8, state: 'Haryana' },
  { id: 9, state: 'Himachal Pradesh' },
  { id: 10, state: 'Jharkhand' },
  { id: 11, state: 'Karnataka' },
  { id: 12, state: 'Kerala' },
  { id: 13, state: 'Madhya Pradesh' },
  { id: 14, state: 'Maharashtra' },
  { id: 15, state: 'Manipur' },
  { id: 16, state: 'Meghalaya' },
  { id: 17, state: 'Mizoram' },
  { id: 18, state: 'Nagaland' },
  { id: 19, state: 'Odisha' },
  { id: 20, state: 'Punjab' },
  { id: 21, state: 'Rajasthan' },
  { id: 22, state: 'Sikkim' },
  { id: 23, state: 'Tamil Nadu' },
  { id: 24, state: 'Telangana' },
  { id: 25, state: 'Tripura' },
  { id: 26, state: 'Uttar Pradesh' },
  { id: 27, state: 'Uttarakhand' },
  { id: 28, state: 'West Bengal' }
];
