import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
  colorGolden,
  colorLightGray,
  colorMidLightGray
} from '../../../../../config/theme';
import { Add, Remove } from '@mui/icons-material';
import { LoanDetails } from './LoanDetails';
import { PANDetails } from './PANDetails';
import { AadhaarDetails } from './AadhaarDetails';
import { UploadPhotoDetails } from './UploadPhotoDetails';
import { UploadDocumentDetails } from './UploadDocumentDetails';
import { BankAccountDetails } from './BankAccountDetails';
import { ReferencesDetails } from './ReferencesDetails';
import { createTitle } from '../../../../../utils/utils';

const accordianSummaryStyle = {
  height: 60,
  backgroundColor: colorLightGray,
  borderBottom: `1px solid ${colorMidLightGray}`,
  fontWeight: '600'
};

const accordianStyle = {
  border: `1px solid ${colorMidLightGray}`,
  width: '100%',
  mt: 1
};

const QDEDetails = () => {
  const [detailExpanded, setDetailExpanded] = useState({ ['item-0']: true });

  const handleDetailAccordionChange = panel => (event, isExpanded) => {
    setDetailExpanded({ [panel]: isExpanded });
  };

  useEffect(() => {
    document.title = createTitle('QDE Details');
  }, []);

  return (
    <>
      <Grid container p={2}>
        <Accordion
          sx={accordianStyle}
          onChange={handleDetailAccordionChange(`item-${0}`)}
          expanded={Boolean(detailExpanded[`item-${0}`])}
          elevation={0}
        >
          <AccordionSummary
            sx={accordianSummaryStyle}
            expandIcon={
              !detailExpanded[`item-${0}`] ? (
                <Add sx={{ color: colorGolden, fontSize: '2rem' }} />
              ) : (
                <Remove sx={{ color: colorGolden, fontSize: '2rem' }} />
              )
            }
          >
            Loan Details
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <LoanDetails />
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={accordianStyle}
          onChange={handleDetailAccordionChange(`item-${1}`)}
          expanded={Boolean(detailExpanded[`item-${1}`])}
          elevation={0}
        >
          <AccordionSummary
            sx={accordianSummaryStyle}
            expandIcon={
              !detailExpanded[`item-${1}`] ? (
                <Add sx={{ color: colorGolden, fontSize: '2rem' }} />
              ) : (
                <Remove sx={{ color: colorGolden, fontSize: '2rem' }} />
              )
            }
          >
            Pan Verification
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <PANDetails />
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={accordianStyle}
          onChange={handleDetailAccordionChange(`item-${2}`)}
          expanded={Boolean(detailExpanded[`item-${2}`])}
          elevation={0}
        >
          <AccordionSummary
            sx={accordianSummaryStyle}
            expandIcon={
              !detailExpanded[`item-${2}`] ? (
                <Add sx={{ color: colorGolden, fontSize: '2rem' }} />
              ) : (
                <Remove sx={{ color: colorGolden, fontSize: '2rem' }} />
              )
            }
          >
            Aadhaar Verification
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <AadhaarDetails />
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={accordianStyle}
          onChange={handleDetailAccordionChange(`item-${3}`)}
          expanded={Boolean(detailExpanded[`item-${3}`])}
          elevation={0}
        >
          <AccordionSummary
            sx={accordianSummaryStyle}
            expandIcon={
              !detailExpanded[`item-${3}`] ? (
                <Add sx={{ color: colorGolden, fontSize: '2rem' }} />
              ) : (
                <Remove sx={{ color: colorGolden, fontSize: '2rem' }} />
              )
            }
          >
            Upload Latest Photo
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <UploadPhotoDetails />
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={accordianStyle}
          onChange={handleDetailAccordionChange(`item-${4}`)}
          expanded={Boolean(detailExpanded[`item-${4}`])}
          elevation={0}
        >
          <AccordionSummary
            sx={accordianSummaryStyle}
            expandIcon={
              !detailExpanded[`item-${4}`] ? (
                <Add sx={{ color: colorGolden, fontSize: '2rem' }} />
              ) : (
                <Remove sx={{ color: colorGolden, fontSize: '2rem' }} />
              )
            }
          >
            Document Upload
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <UploadDocumentDetails />
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={accordianStyle}
          onChange={handleDetailAccordionChange(`item-${5}`)}
          expanded={Boolean(detailExpanded[`item-${5}`])}
          elevation={0}
        >
          <AccordionSummary
            sx={accordianSummaryStyle}
            expandIcon={
              !detailExpanded[`item-${5}`] ? (
                <Add sx={{ color: colorGolden, fontSize: '2rem' }} />
              ) : (
                <Remove sx={{ color: colorGolden, fontSize: '2rem' }} />
              )
            }
          >
            Bank Account Verification
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <BankAccountDetails />
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={accordianStyle}
          onChange={handleDetailAccordionChange(`item-${6}`)}
          expanded={Boolean(detailExpanded[`item-${6}`])}
          elevation={0}
        >
          <AccordionSummary
            sx={accordianSummaryStyle}
            expandIcon={
              !detailExpanded[`item-${6}`] ? (
                <Add sx={{ color: colorGolden, fontSize: '2rem' }} />
              ) : (
                <Remove sx={{ color: colorGolden, fontSize: '2rem' }} />
              )
            }
          >
            References
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <ReferencesDetails />
          </AccordionDetails>
        </Accordion>
      </Grid>
    </>
  );
};

export default QDEDetails;
