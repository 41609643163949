import { Grid, Typography } from '@mui/material';
import { colorGrayish } from '../../../../../config/theme';
import { useSelector } from 'react-redux';
import {
  calculateMonthlyEMI,
  calculateTotalLoanPayment,
  createTitle,
  formatINR,
  printStringValue
} from '../../../../../utils/utils';
import { useEffect } from 'react';

export const LoanDetails = () => {
  const { deviationQDEDetails } = useSelector(state => state.admin);

  const borrowingDetails = deviationQDEDetails['borrowingDetails'];

  useEffect(() => {
    document.title = createTitle('Loan Details');
  }, []);

  return (
    <>
      {borrowingDetails && (
        <Grid container spacing={2} p={2}>
          <Grid item xs={12} md={3} sm={6}>
            <Typography
              variant="subtitle2"
              fontWeight={'500'}
              color={colorGrayish}
            >
              Tenure (in months)
            </Typography>
            <Typography variant="subtitle2" fontWeight={'600'}>
              {printStringValue(
                borrowingDetails?.approvedTenureInMonths
                  ? borrowingDetails?.approvedTenureInMonths
                  : borrowingDetails?.tenureInMonths
              )}
            </Typography>
          </Grid>

          <Grid item xs={12} md={3} sm={6}>
            <Typography
              variant="subtitle2"
              fontWeight={'500'}
              color={colorGrayish}
            >
              Loan Amount(₹)
            </Typography>
            <Typography variant="subtitle2" fontWeight={'600'}>
              {formatINR(
                borrowingDetails?.approvedAmount
                  ? borrowingDetails?.approvedAmount
                  : borrowingDetails?.amount
              )}
            </Typography>
          </Grid>

          <Grid item xs={12} md={3} sm={6}>
            <Typography
              variant="subtitle2"
              fontWeight={'500'}
              color={colorGrayish}
            >
              ROI
            </Typography>
            <Typography variant="subtitle2" fontWeight={'600'}>
              {printStringValue(
                borrowingDetails?.approvedRoi
                  ? borrowingDetails?.approvedRoi
                  : borrowingDetails?.roi
              )}{' '}
              %
            </Typography>
          </Grid>

          <Grid item xs={12} md={3} sm={6}>
            <Typography
              variant="subtitle2"
              fontWeight={'500'}
              color={colorGrayish}
            >
              Monthly EMI
            </Typography>
            <Typography variant="subtitle2" fontWeight={'600'}>
              {formatINR(
                calculateMonthlyEMI(
                  borrowingDetails?.approvedAmount
                    ? borrowingDetails?.approvedAmount
                    : borrowingDetails?.amount,
                  borrowingDetails?.approvedTenureInMonths
                    ? borrowingDetails?.approvedTenureInMonths
                    : borrowingDetails?.tenureInMonths,
                  borrowingDetails?.approvedRoi
                    ? borrowingDetails?.approvedRoi
                    : borrowingDetails?.roi
                )
              )}
            </Typography>
          </Grid>

          <Grid item xs={12} md={3} sm={6}>
            <Typography
              variant="subtitle2"
              fontWeight={'500'}
              color={colorGrayish}
            >
              Total Payment
            </Typography>
            <Typography variant="subtitle2" fontWeight={'600'}>
              {formatINR(
                calculateTotalLoanPayment(
                  borrowingDetails?.approvedAmount
                    ? borrowingDetails?.approvedAmount
                    : borrowingDetails?.amount,
                  borrowingDetails?.approvedTenureInMonths
                    ? borrowingDetails?.approvedTenureInMonths
                    : borrowingDetails?.tenureInMonths,
                  borrowingDetails?.approvedRoi
                    ? borrowingDetails?.approvedRoi
                    : borrowingDetails?.roi
                )
              )}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};
