import {
  ROLE_ADMIN_USER,
  ROLE_CREDIT_USER,
  ROLE_L1_USER,
  ROLE_L2_USER,
  ROLE_L3_USER
} from '../utils/userRoles';

export const accessibleRoutes = [
  { path: '/credit-dashboard', roles: [ROLE_CREDIT_USER] },
  { path: '/admin-dashboard', roles: [ROLE_ADMIN_USER, ROLE_L1_USER] },
  {
    path: '/user-dashboard',
    roles: [ROLE_L1_USER, ROLE_L2_USER, ROLE_L3_USER]
  },
  {
    path: '/dashboard-casesummary',
    roles: [ROLE_CREDIT_USER]
  },
  { path: '/l1/l2User', roles: [ROLE_L1_USER] },
  { path: '/l1/l3User', roles: [ROLE_L1_USER] },
  { path: '/l2/l3User', roles: [ROLE_L2_USER] },

  { path: '/l1', roles: [ROLE_ADMIN_USER] },
  { path: '/addL1User', roles: [ROLE_ADMIN_USER] },
  { path: '/l2', roles: [ROLE_ADMIN_USER] },
  { path: '/addL2User', roles: [ROLE_ADMIN_USER, ROLE_L1_USER] },
  { path: '/editL2User', roles: [ROLE_ADMIN_USER, ROLE_L1_USER] },
  { path: '/l3', roles: [ROLE_ADMIN_USER] },
  { path: '/addL3User', roles: [ROLE_ADMIN_USER, ROLE_L2_USER] },
  { path: '/editL3User', roles: [ROLE_ADMIN_USER, ROLE_L2_USER] },
  { path: '/rm-view', roles: [ROLE_ADMIN_USER] },
  { path: '/admin-daviation', roles: [ROLE_ADMIN_USER, ROLE_L1_USER] },
  { path: '/deviation-qde', roles: [ROLE_ADMIN_USER] },
  { path: '/reports-view', roles: [ROLE_ADMIN_USER] },
  { path: '/l1Reports-view', roles: [ROLE_L1_USER, ROLE_L2_USER, ROLE_L3_USER] }
];
