import { Typography } from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { colorGrayish } from '../config/theme';

const OtpTimer = ({ expiryTimestamp, onTimeout, typographyStyle }) => {
  function calculateTimeLeft() {
    return Number(timeLeft) - 1;
  }

  const [timeLeft, setTimeLeft] = useState(expiryTimestamp);

  const handleTimeout = useCallback(() => {
    const timer = setTimeout(() => {
      const newTimeLeft = calculateTimeLeft();
      if (newTimeLeft === 0) {
        onTimeout();
      } else {
        setTimeLeft(newTimeLeft);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [calculateTimeLeft, onTimeout]);

  useEffect(() => {
    const timer = handleTimeout();

    return () => clearTimeout(timer);
  }, [handleTimeout]);

  return (
    <div style={{ display: 'inline', color: 'your_desired_color' }}>
      <Typography
        style={{ ...typographyStyle }}
        margin="1.5rem"
        align="center"
        variant="subtitle2"
      >
        <p>
          <span style={{ color: colorGrayish }}>Time Remaining</span>{' '}
          <strong>0:{timeLeft.toString().padStart(2, '0')}s</strong>
        </p>
      </Typography>
    </div>
  );
};

OtpTimer.propTypes = {
  expiryTimestamp: PropTypes.any,
  onTimeout: PropTypes.func,
  typographyStyle: PropTypes.any
};
export default OtpTimer;
