import { Grid, Typography } from '@mui/material';
import { colorGrayish } from '../../../../../config/theme';
import {
  DOC_TYPE_AADHAAR_BACK,
  DOC_TYPE_AADHAAR_FRONT,
  DOC_TYPE_BANK_STATEMENT_OCR,
  DOC_TYPE_PAN
} from '../../../../../utils/constant';
import ViewImageQDE from './ViewImageQDE';
import { useEffect } from 'react';
import { createTitle } from '../../../../../utils/utils';

export const UploadDocumentDetails = () => {
  useEffect(() => {
    document.title = createTitle('Upload Document Details');
  }, []);

  return (
    <>
      <Grid container spacing={2} p={2}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <Typography
            variant="subtitle2"
            fontWeight={'500'}
            color={colorGrayish}
          >
            Pan Card Image
          </Typography>
          <ViewImageQDE key={DOC_TYPE_PAN} docType={DOC_TYPE_PAN} />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <Typography
            variant="subtitle2"
            fontWeight={'500'}
            color={colorGrayish}
          >
            Aadhaar Front
          </Typography>
          <ViewImageQDE
            key={DOC_TYPE_AADHAAR_FRONT}
            docType={DOC_TYPE_AADHAAR_FRONT}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <Typography
            variant="subtitle2"
            fontWeight={'500'}
            color={colorGrayish}
          >
            Aadhaar Back
          </Typography>
          <ViewImageQDE
            key={DOC_TYPE_AADHAAR_BACK}
            docType={DOC_TYPE_AADHAAR_BACK}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <Typography
            variant="subtitle2"
            fontWeight={'500'}
            color={colorGrayish}
          >
            Bank Statement (Last 3 month)
          </Typography>
          <ViewImageQDE
            key={DOC_TYPE_BANK_STATEMENT_OCR}
            docType={DOC_TYPE_BANK_STATEMENT_OCR}
          />
        </Grid>
      </Grid>
    </>
  );
};
