import { createAction, createSlice } from '@reduxjs/toolkit';
import { setActiveMenuData } from '../../config/cookie';

export const setActiveMenu = createAction('SET_ACTIVE_MENU');
export const setIsMenuOpen = createAction('SET_OPEN_MENU');
export const logoutAndClearToken = createAction('LOGOUT_AND_CLEAR_TOKEN');

const intial = {
  activeMenu: 'Dashboard',
  isMenuOpen: false
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState: intial,
  extraReducers: builder => {
    builder
      .addCase(setActiveMenu, (state, action) => {
        state.activeMenu = action.payload;
        setActiveMenuData(action.payload);
      })
      .addCase(setIsMenuOpen, (state, action) => {
        state.isMenuOpen = action.payload;
      })
      .addCase(logoutAndClearToken, () => {
        return intial;
      });
  }
});

export default menuSlice.reducer;
