import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import { useDispatch, useSelector } from 'react-redux';
import { monthWiseReportChart } from '../../../../redux/reducers/ChartSlice';
import {
  Grid,
  IconButton,
  SvgIcon,
  Dialog,
  DialogContent,
  Menu,
  MenuItem
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Expand05Icon, FileDownload03Icon } from '../../../../utils/iconSvg';
import { colorGolden, colorPrimaryBlue } from '../../../../config/theme';

const MonthlyInvestmentBorrowingLineChart = () => {
  const {
    isMonthWiseReportLoading,
    isMonthWiseReportSuccess,
    isMonthWiseReportFailed,
    monthWiseReport,
    isMonthWiseReportErrorContainer
  } = useSelector(state => state.chartReport);

  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(false);
  const [chartWrapper, setChartWrapper] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    dispatch(monthWiseReportChart());
  }, []);

  const handleClickOpen = () => {
    setIsExpanded(prev => !prev);
  };

  const handleDownload = () => {
    if (chartWrapper) {
      const imageURI = chartWrapper.getChart().getImageURI();
      const link = document.createElement('a');
      link.href = imageURI;
      link.download = 'monthly-investment-borrowing-chart.png';
      link.click();
    }
  };

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const chartOptions = {
    title: '',
    chartArea: {
      width: '90%',
      height: '70%',
      bottom: 40
    },
    hAxis: {
      title: 'Month',
      textStyle: { fontSize: 12, italic: false },
      titleTextStyle: { fontSize: 12, italic: false, bold: true },
      ticks: [
        { v: 0, f: 'Jan' },
        { v: 1, f: 'Feb' },
        { v: 2, f: 'Mar' },
        { v: 3, f: 'Apr' },
        { v: 4, f: 'May' },
        { v: 5, f: 'Jun' },
        { v: 6, f: 'Jul' },
        { v: 7, f: 'Aug' },
        { v: 8, f: 'Sep' },
        { v: 9, f: 'Oct' },
        { v: 10, f: 'Nov' },
        { v: 11, f: 'Dec' }
      ]
    },
    vAxis: {
      title: 'Thousands',
      minValue: 0,
      format: 'short',
      textStyle: { fontSize: 12, italic: false },
      titleTextStyle: { fontSize: 12, italic: false, bold: true },
      viewWindow: {
        min: 0,
        max: 50000 // Adjusted for maximum value in dataset
      },
      gridlines: { count: 6 },
      ticks: [0, 10000, 20000, 30000, 40000, 50000]
    },
    series: {
      0: {
        pointShape: 'circle',
        pointSize: 5
      }, // Sum of Invested Amount
      1: {
        pointShape: 'circle',
        pointSize: 5
      } // Sum of Borrowed Amount
    },
    colors: [colorPrimaryBlue, colorGolden],
    legend: {
      position: 'none' // Disable default legend
    },
    height: 350
  };

  const legendItems = [
    { color: colorPrimaryBlue, text: 'Sum of Invested Amount' },
    { color: colorGolden, text: 'Sum of Borrowed Amount' }
  ];

  return (
    <div>
      <Grid container alignItems="center" justifyContent="space-between" mb={2}>
        <Grid item>
          <div
            style={{
              fontSize: '15px',
              fontWeight: 600
            }}
          >
            Invested and Borrowed Amount by Month
          </div>
        </Grid>
        <Grid item>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleMenuClick}
          >
            <MoreVertIcon fontSize="small" />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={() => {
                handleClickOpen();
                handleMenuClose();
              }}
            >
              <SvgIcon
                component={Expand05Icon}
                sx={{ width: 16, height: 16, mr: 1 }}
              />
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleDownload();
                handleMenuClose();
              }}
            >
              <SvgIcon
                component={FileDownload03Icon}
                style={{ fontSize: 20, marginRight: 8 }}
              />
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
      {isMonthWiseReportLoading ? (
        <p>Loading...</p>
      ) : isMonthWiseReportFailed ? (
        <p>Error loading data</p>
      ) : (
        <>
          <Grid item xs={12} mb={2}>
            <Chart
              chartType="LineChart"
              width="100%"
              height={chartOptions.height + 'px'}
              data={monthWiseReport}
              options={chartOptions}
              getChartWrapper={chartWrapper => setChartWrapper(chartWrapper)}
            />
          </Grid>
          {/* Custom Legend */}
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap'
              }}
            >
              {legendItems.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '5px 10px'
                  }}
                >
                  <div
                    style={{
                      width: '22px',
                      height: '12px',
                      backgroundColor: item.color,
                      marginRight: '5px'
                    }}
                  ></div>
                  <span
                    style={{
                      fontSize: '12px',
                      fontWeight: 'normal',
                      fontFamily: 'Arial, sans-serif'
                    }}
                  >
                    {item.text}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <Dialog
            open={isExpanded}
            onClose={handleClickOpen}
            maxWidth="lg"
            fullWidth
          >
            <DialogContent>
              <Chart
                chartType="LineChart"
                width="100%"
                height="500px"
                data={monthWiseReport}
                options={{ ...chartOptions, height: 500 }}
              />
              {/* Custom Legend for Expanded View */}
              <div style={{ textAlign: 'center' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap'
                  }}
                >
                  {legendItems.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '5px 10px'
                      }}
                    >
                      <div
                        style={{
                          width: '22px',
                          height: '12px',
                          backgroundColor: item.color,
                          marginRight: '5px'
                        }}
                      ></div>
                      <span
                        style={{
                          fontSize: '12px',
                          fontWeight: 'normal',
                          fontFamily: 'Arial, sans-serif'
                        }}
                      >
                        {item.text}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </>
      )}
    </div>
  );
};

export default MonthlyInvestmentBorrowingLineChart;
