import { Grid, Typography } from '@mui/material';
import { colorGrayish } from '../../config/theme';
import { useSelector } from 'react-redux';
import { createTitle, printStringValue, toCamelCase } from '../../utils/utils';
import { useEffect } from 'react';

export const LeadDetails = () => {
  const { caseSummaryDetails } = useSelector(state => state.caseSummary);

  const borrowerDetails = caseSummaryDetails['borrowerDetails'];

  useEffect(() => {
    document.title = createTitle('Lead Details');
  }, []);

  return (
    <>
      {borrowerDetails && (
        <Grid container spacing={2} p={2}>
          <Grid item xs={12} md={3} sm={6}>
            <Typography
              variant="subtitle2"
              fontWeight={'500'}
              color={colorGrayish}
            >
              Borrower Journey
            </Typography>
            <Typography variant="subtitle2" fontWeight={'600'}>
              {printStringValue(toCamelCase(borrowerDetails?.borrowerJourney))}
            </Typography>
          </Grid>

          <Grid item xs={12} md={3} sm={6}>
            <Typography
              variant="subtitle2"
              fontWeight={'500'}
              color={colorGrayish}
            >
              Borrower Id
            </Typography>
            <Typography variant="subtitle2" fontWeight={'600'}>
              {printStringValue(borrowerDetails?.borrowerId)}
            </Typography>
          </Grid>

          <Grid item xs={12} md={3} sm={6}>
            <Typography
              variant="subtitle2"
              fontWeight={'500'}
              color={colorGrayish}
            >
              Full Name
            </Typography>
            <Typography variant="subtitle2" fontWeight={'600'}>
              {printStringValue(borrowerDetails?.borrowerName)}
            </Typography>
          </Grid>

          {/* <Grid item xs={12} md={3} sm={6}>
            <Typography
              variant="subtitle2"
              fontWeight={'500'}
              color={colorGrayish}
            >
              Last Name
            </Typography>
            <Typography variant="subtitle2" fontWeight={'600'}>
              {printStringValue(borrowerDetails?.lastNameAsPerPan)}
            </Typography>
          </Grid> */}

          <Grid item xs={12} md={3} sm={6}>
            <Typography
              variant="subtitle2"
              fontWeight={'500'}
              color={colorGrayish}
            >
              Mobile Number
            </Typography>
            <Typography variant="subtitle2" fontWeight={'600'}>
              {printStringValue(borrowerDetails?.mobileNumber)}
            </Typography>
          </Grid>

          <Grid item xs={12} md={3} sm={6}>
            <Typography
              variant="subtitle2"
              fontWeight={'500'}
              color={colorGrayish}
            >
              Email ID
            </Typography>
            <Typography variant="subtitle2" fontWeight={'600'}>
              {printStringValue(borrowerDetails?.emailId)}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};
