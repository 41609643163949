import { Grid, Typography } from '@mui/material';
import { colorGrayish } from '../../../../../config/theme';
import { useSelector } from 'react-redux';
import { createTitle, printStringValue } from '../../../../../utils/utils';
import { DOC_TYPE_CHEQUE_OCR } from '../../../../../utils/constant';
import ViewImageQDE from './ViewImageQDE';
import { useEffect } from 'react';

export const BankAccountDetails = () => {
  const { deviationQDEDetails } = useSelector(state => state.admin);

  const bankAccount = deviationQDEDetails['bankAccount'];

  useEffect(() => {
    document.title = createTitle('Bank Account Details');
  }, []);

  return (
    <>
      {bankAccount && (
        <Grid container spacing={2} p={2}>
          <Grid item xs={12} md={12} sm={12}>
            <Typography
              variant="subtitle2"
              fontWeight={'500'}
              color={colorGrayish}
            >
              Cheque Image
            </Typography>

            <ViewImageQDE
              key={DOC_TYPE_CHEQUE_OCR}
              docType={DOC_TYPE_CHEQUE_OCR}
            />
          </Grid>

          <Grid item xs={12} md={3} sm={6}>
            <Typography
              variant="subtitle2"
              fontWeight={'500'}
              color={colorGrayish}
            >
              Account Number
            </Typography>
            <Typography variant="subtitle2" fontWeight={'600'}>
              {printStringValue(bankAccount?.accountNo)}
            </Typography>
          </Grid>

          <Grid item xs={12} md={3} sm={6}>
            <Typography
              variant="subtitle2"
              fontWeight={'500'}
              color={colorGrayish}
            >
              IFSC Code
            </Typography>
            <Typography variant="subtitle2" fontWeight={'600'}>
              {printStringValue(bankAccount?.ifsc)}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};
