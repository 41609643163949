import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import { useState } from 'react';
import PowerSettingsNewTwoToneIcon from '@mui/icons-material/PowerSettingsNewTwoTone';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutAndClearToken } from '../../redux/reducers/authSlice';
import ConfirmBox from '../../components/ConfirmBox';
import PropTypes from 'prop-types';

function HeaderBar({ title = '' }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = () => {
    dispatch(logoutAndClearToken());
    navigate('/login');
    return;
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: theme =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[200]
            : theme.palette.grey[900]
      }}
      elevation={0}
    >
      <Toolbar>
        <Typography
          variant="body1"
          fontWeight="700"
          fontSize={25}
          component="div"
          sx={{ flexGrow: 1 }}
        >
          {title}
        </Typography>
        <div>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => setIsModalOpen(true)}
            color="primary"
          >
            <PowerSettingsNewTwoToneIcon />
          </IconButton>
        </div>
      </Toolbar>
      {isModalOpen && (
        <ConfirmBox
          title="Logout"
          subtitle={`Are you sure you want to Logout?`}
          isOpen={isModalOpen}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
        />
      )}
    </AppBar>
  );
}
HeaderBar.propTypes = {
  title: PropTypes.string
};

export default HeaderBar;
