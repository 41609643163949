import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const CustomCard = styled(Card)(({ theme, color }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  background: `linear-gradient(to right, ${color[0]}, ${color[1]})`,
  boxShadow: '1px 3px 3px 0px rgba(0, 0, 0, 0.30)'
}));

const GradientCard = ({ color, title1, title2, count }) => {
  return (
    <CustomCard color={color}>
      <CardContent sx={{ textAlign: 'center' }}>
        <Typography
          fontSize={15}
          color="white"
          variant="subtitle2"
          component="div"
          fontWeight={700}
        >
          {title1}
        </Typography>
        <Typography
          fontSize={15}
          color="white"
          variant="subtitle2"
          component="div"
          fontWeight={700}
        >
          {title2}
        </Typography>
        <Typography
          fontSize={20}
          marginTop={1}
          fontWeight={800}
          variant="subtitle2"
          color="white"
        >
          {count ? count : 0}
        </Typography>
      </CardContent>
    </CustomCard>
  );
};

GradientCard.propTypes = {
  color: PropTypes.array,
  title1: PropTypes.string,
  title2: PropTypes.string,
  count: PropTypes.string
};

export default GradientCard;
