import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
  colorGolden,
  colorLightGray,
  colorMidLightGray
} from '../../config/theme';
import { Add, Remove } from '@mui/icons-material';
import { FamilyReferenceDetails } from './reference/FamilyReferenceDetails';
import { NonFamilyReferenceDetails } from './reference/NonFamilyReferenceDetails';
import { createTitle } from '../../utils/utils';

export const ReferencesDetails = () => {
  const [detailExpanded, setDetailExpanded] = useState({ ['item-0']: true });

  const handleDetailAccordionChange = panel => (event, isExpanded) => {
    setDetailExpanded({ [panel]: isExpanded });
  };

  useEffect(() => {
    document.title = createTitle('Reference Details');
  }, []);

  return (
    <>
      <Grid container p={2}>
        <Accordion
          sx={{
            border: `1px solid ${colorMidLightGray}`,
            width: '100%',
            mt: 1
          }}
          onChange={handleDetailAccordionChange(`item-${0}`)}
          expanded={Boolean(detailExpanded[`item-${0}`])}
          elevation={0}
        >
          <AccordionSummary
            sx={{
              height: 60,
              backgroundColor: colorLightGray,
              borderBottom: `1px solid ${colorMidLightGray}`,
              fontWeight: '600'
            }}
            expandIcon={
              !detailExpanded[`item-${0}`] ? (
                <Add sx={{ color: colorGolden, fontSize: '2rem' }} />
              ) : (
                <Remove sx={{ color: colorGolden, fontSize: '2rem' }} />
              )
            }
          >
            Family Reference
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <FamilyReferenceDetails
              refresh={Boolean(detailExpanded[`item-${0}`])}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            border: `1px solid ${colorMidLightGray}`,
            width: '100%',
            mt: 1
          }}
          onChange={handleDetailAccordionChange(`item-${1}`)}
          expanded={Boolean(detailExpanded[`item-${1}`])}
          elevation={0}
        >
          <AccordionSummary
            sx={{
              height: 60,
              backgroundColor: colorLightGray,
              borderBottom: `1px solid ${colorMidLightGray}`,
              fontWeight: '600'
            }}
            expandIcon={
              !detailExpanded[`item-${1}`] ? (
                <Add sx={{ color: colorGolden, fontSize: '2rem' }} />
              ) : (
                <Remove sx={{ color: colorGolden, fontSize: '2rem' }} />
              )
            }
          >
            Non Family Reference
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <NonFamilyReferenceDetails
              refresh={Boolean(detailExpanded[`item-${1}`])}
            />
          </AccordionDetails>
        </Accordion>
      </Grid>
    </>
  );
};
