import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api, multipartApi } from '../../config/api';
import { downloadFileExcelGetApi } from '../../utils/utils';

export const revertAllL1User = createAction('REVERT_ALL_L1_User');
export const clearL1ListNotification = createAction(
  'CLEAR_L1List_NOTIFICATION'
);

export const clearL1ViewNotification = createAction(
  'CLEAR_L1View_NOTIFICATION'
);

const initial = {
  isCreateEmployeeL1Loading: false,
  isCreateEmployeeL1Success: false,
  isCreateEmployeeL1Failed: false,
  createEmployeeL1ErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  islistingL1Loading: false,
  islistingL1Success: false,
  islistingL1Failed: false,
  l1UsersData: {
    totalCount: 0,
    l1UserList: []
  },
  listingL1ErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isemployeeByL1IdLoading: false,
  isemployeeL1ByIdSuccess: false,
  isemployeeL1ByIdFailed: false,
  employeeL1ByIdData: {},
  isemployeeL1ByIdErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isproductsLoading: false,
  isproductsSuccess: false,
  isproductsFailed: false,
  products: [],
  productsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isStatesLoading: false,
  isStatesSuccess: false,
  isStatesFailed: false,
  states: [],
  statesErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isreportToSalesLoading: false,
  isreportToSalesSuccess: false,
  isreportToSalesFailed: false,
  reportToSales: [],
  reportToSalesErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isViewAggrmtLoading: false,
  isViewAggrmtSuccess: false,
  isViewAggrmtFailed: false,
  viewAggrmt: '',
  viewAggrmtErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isSampleExcelLoading: false,
  isSampleExcelSuccess: false,
  isSampleExcelFailed: false,
  sampleExcelData: '',
  isSampleExcelErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isReportToLevelLoading: false,
  isReportToLevelSuccess: false,
  isReportToLevelFailed: false,
  reportToLevelData: '',
  isReportToLevelErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isStateWiseCityLoading: false,
  isStateWiseCitySuccess: false,
  isStateWiseCityFailed: false,
  stateWiseCityData: '',
  isStateWiseCityErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  }
};

export const saveEmployeeL1 = createAsyncThunk(
  'admin/saveEmployeeL1',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await multipartApi.post(
        `/admin/saveEmployeeL1`,
        payload
      );

      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchListingL1 = createAsyncThunk(
  'admin/listingL1',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post(`/admin/listingL1`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const employeeL1DeatilsById = createAsyncThunk(
  'admin/employeeL1DeatilsById',
  async (Id, { rejectWithValue }) => {
    try {
      const response = await api.get(`/admin/employeeL1DeatilsById?id=${Id}`);

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchProducts = createAsyncThunk(
  'admin/fetchProducts',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`/dropDown/products`);

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchStates = createAsyncThunk(
  'admin/statesMaster',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`/dropDown/statesMaster`);

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchReportToSales = createAsyncThunk(
  'admin/fetchReportToSales',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`/dropDown/reportToSales`);

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const bulkUploadL1 = createAsyncThunk(
  'admin/bulkUploadL1',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await multipartApi.post(`/admin/bulkUploadL1`, payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const viewEmploymentAgreement = filePath => async () => {
  try {
    const response = await api.get(
      `/documents/viewEmploymentAgreement?fileUrl=${filePath}`,
      {
        responseType: 'blob'
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching the employment agreement:', error);
    throw error;
  }
};

// export const sampleExcel = createAsyncThunk(
//   '/documents/sampleExcel',
//   async (Level, { rejectWithValue }) => {
//     try {
//       const response = await api.get(`/documents/sampleExcel/${Level}`);

//       return response.data || null;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

export const downloadSampleExcel = createAsyncThunk(
  'admin/documents/sampleExcel',
  async ({ fileName, level }, { rejectWithValue }) => {
    try {
      return await downloadFileExcelGetApi({
        apiPath: `/documents/sampleExcel/${level}`,
        fileName: fileName
      });
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const downloadReportToLevel = createAsyncThunk(
  'admin/documents/reportToLevel',
  async ({ fileName, level }, { rejectWithValue }) => {
    try {
      return await downloadFileExcelGetApi({
        apiPath: `/admin/reportToLevel?type=${level}`,
        fileName: fileName
      });
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const downloadCityMaster = createAsyncThunk(
  'admin/documents/cityMaster',
  async ({ fileName }, { rejectWithValue }) => {
    try {
      return await downloadFileExcelGetApi({
        apiPath: `/admin/cityMaster`,
        fileName: fileName
      });
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const EmployeeL1Slice = createSlice({
  name: 'users',
  initialState: initial,
  extraReducers: builder => {
    builder

      .addCase(revertAllL1User, state => {
        state.isCreateEmployeeL1Loading = false;
        state.isCreateEmployeeL1Success = false;
        state.isCreateEmployeeL1Failed = false;
        state.createEmployeeL1ErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(clearL1ViewNotification, state => {
        (state.isemployeeByL1IdLoading = false),
          (state.isemployeeL1ByIdSuccess = false),
          (state.isemployeeL1ByIdFailed = false),
          (state.employeeL1ByIdData = initial.employeeL1ByIdData),
          (state.isemployeeL1ByIdErrorContainer = {
            error: false,
            errorMessage: 'Internal Server Error',
            description: 'Something Went Wrong',
            statusCode: 0
          });
      })
      .addCase(clearL1ListNotification, state => {
        (state.islistingL1Loading = false),
          (state.islistingL1Success = false),
          (state.l1UsersData = {
            totalCount: 0,
            l1UserList: []
          }),
          (state.islistingL1Failed = false),
          (state.listingL1ErrorContainer = {
            error: false,
            errorMessage: 'Internal Server Error',
            description: 'Something Went Wrong',
            statusCode: 0
          }),
          (state.isemployeeByL1IdLoading = false),
          (state.isemployeeL1ByIdSuccess = false),
          (state.isemployeeL1ByIdFailed = false),
          (state.employeeL1ByIdData = initial.employeeL1ByIdData),
          (state.isemployeeL1ByIdErrorContainer = {
            error: false,
            errorMessage: 'Internal Server Error',
            description: 'Something Went Wrong',
            statusCode: 0
          });
      })

      .addCase(saveEmployeeL1.pending, state => {
        state.isCreateEmployeeL1Loading = true;
        state.isCreateEmployeeL1Success = false;
        state.isCreateEmployeeL1Failed = false;
        state.createEmployeeL1ErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(saveEmployeeL1.fulfilled, state => {
        state.isCreateEmployeeL1Loading = false;
        state.isCreateEmployeeL1Failed = false;
        state.isCreateEmployeeL1Success = true;
      })
      .addCase(saveEmployeeL1.rejected, (state, action) => {
        state.isCreateEmployeeL1Loading = false;
        state.isCreateEmployeeL1Success = false;
        state.isCreateEmployeeL1Failed = true;
        state.createEmployeeL1ErrorContainer = {
          ...state.createEmployeeL1ErrorContainer,
          ...action.payload
        };
      })

      .addCase(fetchListingL1.pending, state => {
        state.islistingL1Loading = true;
        state.islistingL1Success = false;
        state.islistingL1Failed = false;
        state.listingL1ErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(fetchListingL1.fulfilled, (state, action) => {
        state.islistingL1Loading = false;
        state.islistingL1Success = true;
        state.l1UsersData = {
          totalCount: action.payload?.count,
          l1UserList: action.payload?.data
        };
        state.islistingL1Failed = false;
      })

      .addCase(fetchListingL1.rejected, (state, action) => {
        state.islistingL1Loading = false;
        state.islistingL1Success = false;
        state.islistingL1Failed = true;
        state.l1UsersData = initial.l1UsersData;
        state.listingL1ErrorContainer = {
          ...state.listingL1ErrorContainer,
          ...action.payload
        };
      })

      .addCase(employeeL1DeatilsById.pending, state => {
        state.isemployeeByL1IdLoading = true;
        state.isemployeeL1ByIdSuccess = false;
        state.isemployeeL1ByIdFailed = false;
        state.isemployeeL1ByIdErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(employeeL1DeatilsById.fulfilled, (state, action) => {
        state.isemployeeByL1IdLoading = false;
        state.isemployeeL1ByIdSuccess = true;
        state.employeeL1ByIdData = action.payload || '';
        state.isemployeeL1ByIdFailed = false;
      })

      .addCase(employeeL1DeatilsById.rejected, (state, action) => {
        state.isemployeeByL1IdLoading = false;
        state.isemployeeL1ByIdSuccess = false;
        state.isemployeeL1ByIdFailed = true;
        state.employeeL1ByIdData = initial.employeeL1ByIdData;

        state.isemployeeL1ByIdErrorContainer = {
          ...state.isemployeeL1ByIdErrorContainer,
          ...action.payload
        };
      })

      .addCase(fetchProducts.pending, state => {
        state.isproductsLoading = true;
        state.isproductsSuccess = false;
        state.isproductsFailed = false;
        state.productsErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.isproductsLoading = false;
        state.isproductsSuccess = true;
        state.products = action.payload || '';
        state.isproductsFailed = false;
      })

      .addCase(fetchProducts.rejected, (state, action) => {
        state.isproductsLoading = false;
        state.isproductsSuccess = false;
        state.isproductsFailed = true;
        state.products = initial.products;

        state.productsErrorContainer = {
          ...state.productsErrorContainer,
          ...action.payload
        };
      })

      .addCase(fetchStates.pending, state => {
        state.isStatesLoading = true;
        state.isStatesSuccess = false;
        state.isStatesFailed = false;
        state.statesErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(fetchStates.fulfilled, (state, action) => {
        state.isStatesLoading = false;
        state.isStatesSuccess = true;
        state.states = action.payload || [];
        state.isStatesFailed = false;
      })

      .addCase(fetchStates.rejected, (state, action) => {
        state.isStatesLoading = false;
        state.isStatesSuccess = false;
        state.isStatesFailed = true;
        state.states = initial.states;

        state.productsErrorContainer = {
          ...state.statesErrorContainer,
          ...action.states
        };
      })

      .addCase(fetchReportToSales.pending, state => {
        state.isreportToSalesLoading = true;
        state.isreportToSalesSuccess = false;
        state.isreportToSalesFailed = false;
        state.reportToSalesErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(fetchReportToSales.fulfilled, (state, action) => {
        state.isreportToSalesLoading = false;
        state.isreportToSalesSuccess = true;
        state.reportToSales = action.payload || '';
        state.isreportToSalesFailed = false;
      })

      .addCase(fetchReportToSales.rejected, (state, action) => {
        state.isreportToSalesLoading = false;
        state.isreportToSalesSuccess = false;
        state.isreportToSalesFailed = true;
        state.reportToSales = initial.reportToSales;

        state.reportToSalesErrorContainer = {
          ...state.reportToSalesErrorContainer,
          ...action.payload
        };
      })
      .addCase(downloadSampleExcel.pending, state => {
        state.isSampleExcelLoading = true;
        state.isSampleExcelSuccess = false;
        state.isSampleExcelFailed = false;
        state.isSampleExcelErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(downloadSampleExcel.fulfilled, (state, action) => {
        state.isSampleExcelLoading = false;
        state.isSampleExcelSuccess = true;
        state.sampleExcelData = action.payload || '';
        state.isSampleExcelFailed = false;
      })

      .addCase(downloadSampleExcel.rejected, (state, action) => {
        state.isSampleExcelLoading = false;
        state.isSampleExcelSuccess = false;
        state.isSampleExcelFailed = true;
        state.sampleExcelData = initial.sampleExcelData;

        state.isSampleExcelErrorContainer = {
          ...state.isSampleExcelErrorContainer,
          ...action.payload
        };
      })

      .addCase(downloadReportToLevel.pending, state => {
        state.isReportToLevelLoading = true;
        state.isReportToLevelSuccess = false;
        state.isReportToLevelFailed = false;
        state.isReportToLevelErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(downloadReportToLevel.fulfilled, (state, action) => {
        state.isReportToLevelLoading = false;
        state.isReportToLevelSuccess = true;
        state.reportToLevelData = action.payload || '';
        state.isReportToLevelFailed = false;
      })

      .addCase(downloadReportToLevel.rejected, (state, action) => {
        state.isReportToLevelLoading = false;
        state.isReportToLevelSuccess = false;
        state.isReportToLevelFailed = true;
        state.reportToLevelData = initial.reportToLevelData;

        state.isReportToLevelErrorContainer = {
          ...state.isReportToLevelErrorContainer,
          ...action.payload
        };
      })

      .addCase(downloadCityMaster.pending, state => {
        state.isStateWiseCityLoading = true;
        state.isStateWiseCitySuccess = false;
        state.isStateWiseCityFailed = false;
        state.isStateWiseCityErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(downloadCityMaster.fulfilled, (state, action) => {
        state.isStateWiseCityLoading = false;
        state.isStateWiseCitySuccess = true;
        state.stateWiseCityData = action.payload || '';
        state.isStateWiseCityFailed = false;
      })

      .addCase(downloadCityMaster.rejected, (state, action) => {
        state.isStateWiseCityLoading = false;
        state.isStateWiseCitySuccess = false;
        state.isStateWiseCityFailed = true;
        state.stateWiseCityData = initial.stateWiseCityData;

        state.isStateWiseCityErrorContainer = {
          ...state.isStateWiseCityErrorContainer,
          ...action.payload
        };
      });
  }
});

export default EmployeeL1Slice.reducer;
