import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { getActiveMenuData, getAuthData, getToken } from '../config/cookie';
import { useDispatch } from 'react-redux';
import { setAuthDetailsByLocalStorage } from '../redux/reducers/authSlice';
import PropTypes from 'prop-types';
import { setActiveMenu } from '../redux/reducers/menuSlice';

const ProtectedRoutes = ({ accessibleRoutes }) => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);

  const token = getToken();
  const authData = getAuthData();
  const activeMenu = getActiveMenuData();

  if (token && auth.authData?.userId === 0 && authData !== undefined) {
    dispatch(setAuthDetailsByLocalStorage(authData));
    dispatch(setActiveMenu(activeMenu));
  }

  const isAuthenticated = Boolean(token && authData?.role);

  if (isAuthenticated) {
    const currentPathAccessible = accessibleRoutes.some(
      route =>
        window.location.pathname.startsWith(route.path) &&
        route.roles.includes(authData?.role)
    );

    if (currentPathAccessible) {
      return <Outlet />;
    } else {
      return <Navigate to="/forbidden" />;
    }
  } else {
    return <Navigate to="/login" />;
  }
};

ProtectedRoutes.propTypes = {
  accessibleRoutes: PropTypes.array
};

export default ProtectedRoutes;
