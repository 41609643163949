import { Box, Chip, Grid, Typography } from '@mui/material';

import {
  colorGolden,
  colorGrayish,
  colorPrimaryBlue
} from '../../../config/theme';
import {
  createTitle,
  formatAmountInINR,
  formatDate,
  handleError,
  printStringValue,
  toCamelCase,
  returnStatusColor,
  formatINR
} from '../../../utils/utils';
import CountTiles from './CountTiles';
import TableFilter from './TableFilter';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCaseCountDetails,
  getCaseListing,
  getTotalPoolAmount
} from '../../../redux/reducers/casesummarySlice';
import { useNavigate } from 'react-router-dom';
import SnackBarBox from '../../../components/SnackBarBox';
import CustomDataTable from '../../../components/CustomDataTable';
import { StyledGrid } from '../../../utils/styledLayout';

function CreditDashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = createTitle('Credit Dashboard');
  }, []);

  const [pageData, setPageData] = useState({
    page: 0,
    size: 5,
    loanStatus: '',
    fromDate: '',
    toDate: '',
    mobileNumber: '',
    creditStatus: '',
    borrowerName: ''
  });

  const {
    caseCountDetails,
    isCaseCountLoading,
    totalAllocatedAmount,
    totalPoolAmount,
    caseListDetails,
    isCaseListingLoading,
    isCaseListingFailed,
    caseListingErrorContainer
  } = useSelector(state => state.caseSummary);

  const headersList = [
    {
      uniquekey: 'id',
      id: 'loanId',
      label: 'Transaction ID',
      sortable: true
    },
    { id: 'borrowerId', label: 'Borrower ID', sortable: true },
    {
      id: 'borrowerName',
      label: 'Borrower Name',
      sortable: true
    },
    {
      id: 'createdDate',
      label: 'Created Date',
      sortable: true,
      render: row => {
        return <>{formatDate(row['createdDate'], 'DD-MM-YYYY')}</>;
      }
    },
    {
      id: 'updatedDate',
      label: 'Updated Date',
      sortable: true,
      render: row => {
        return <>{formatDate(row['updatedDate'], 'DD-MM-YYYY')}</>;
      }
    },
    {
      id: 'mobileNumber',
      label: 'Mobile Number',
      sortable: true
    },
    {
      id: 'requestedAmount',
      label: 'Requested Amount',
      sortable: true,
      render: row => {
        return <>{formatINR(row['requestedAmount'])}</>;
      }
    },
    {
      id: 'requestedTenure',
      label: 'Requested Tenure(Months)',
      sortable: true
    },
    {
      id: 'roi',
      label: 'ROI(%)',
      sortable: true,
      render: row => {
        return (
          <>{`${row['roi'] ? printStringValue(row['roi']) + '%' : '-'} `}</>
        );
      }
    },
    {
      id: 'creditApprovedAmount',
      label: 'Credit Approved Amount',
      sortable: true,
      render: row => {
        return (
          <>
            {row['creditStatus'] === 'rejected'
              ? '-'
              : printStringValue(formatINR(row['creditApprovedAmount']))}
          </>
        );
      }
    },
    {
      id: 'creditApprovedTenure',
      label: 'Credit Approved Tenure(Months)',
      sortable: true,
      render: row => {
        return (
          <>
            {row['creditStatus'] === 'rejected'
              ? '-'
              : printStringValue(row['creditApprovedTenure'])}
          </>
        );
      }
    },
    {
      id: 'creditApprovedRoi',
      label: 'Credit Approved ROI(%)',
      sortable: true,
      render: row => {
        return (
          <>
            {row['creditStatus'] === 'rejected'
              ? '-'
              : `${row['creditApprovedRoi'] ? printStringValue(row['creditApprovedRoi']) + '%' : '-'} `}
          </>
        );
      }
    },
    { id: 'loanType', label: 'Loan Type', sortable: true },
    {
      id: 'creditStatus',
      label: 'Credit Status',
      sortable: true,
      render: row => {
        return (
          <Chip
            sx={{
              ...returnStatusColor(row['creditStatus'])
            }}
            label={printStringValue(toCamelCase(row['creditStatus']))}
          />
        );
      }
    },
    {
      id: 'deviationStatus',
      label: 'Deviation Status',
      sortable: true,
      render: row => {
        return (
          <>
            {row['deviationStatus'] ? (
              <Chip
                sx={{
                  ...returnStatusColor(row['deviationStatus'])
                }}
                label={printStringValue(toCamelCase(row['deviationStatus']))}
              />
            ) : (
              <Typography align="center">
                {printStringValue(toCamelCase(row['deviationStatus']))}
              </Typography>
            )}
          </>
        );
      }
    }
  ];

  useEffect(() => {
    dispatch(getCaseCountDetails());
    dispatch(getTotalPoolAmount());
  }, []);

  const onRowClick = rowDetails => {
    return navigate(
      `/dashboard-casesummary/${rowDetails?.id}/${rowDetails?.userId}`
    );
  };

  const onFilterSubmit = values => {
    setPageData({
      ...pageData,
      page: 0,
      loanStatus: values.caseCategory,
      fromDate: values.startDate
        ? formatDate(values.startDate, 'YYYY-MM-DD')
        : '',
      toDate: values.endDate ? formatDate(values.endDate, 'YYYY-MM-DD') : '',
      mobileNumber:
        values.searchCriteria === 'mobileNumber' ? values.searchField : '',
      creditStatus:
        values.searchCriteria === 'creditStatus' ? values.searchField : '',
      borrowerName:
        values.searchCriteria === 'borrowerName' ? values.searchField : '',
      loanId: values.searchCriteria === 'lbUserId' ? values.searchField : ''
    });
  };

  useEffect(() => {
    dispatch(getCaseListing(pageData));
  }, [pageData]);

  const onPageChange = page => {
    setPageData({ ...pageData, page: page });
  };
  const onRowsPerPageChange = page => {
    setPageData({ ...pageData, size: page });
  };

  return (
    <>
      <StyledGrid
        container
        direction="row"
        justifyContent="left"
        alignItems="left"
      >
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="center"
        >
          <Grid item xs={12} sm={12} md={3} lg={3} xl={2.2}>
            <Typography
              color={colorGrayish}
              variant="subtitle2"
              fontWeight={600}
            >
              Total Pool Amount :{' '}
              <strong style={{ color: 'black' }}>
                {formatAmountInINR(totalPoolAmount)}
              </strong>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Typography
              color={colorGrayish}
              variant="subtitle2"
              fontWeight={600}
            >
              Total Allocated Amount :{' '}
              <strong style={{ color: 'black' }}>
                {formatAmountInINR(totalAllocatedAmount)}
              </strong>
            </Typography>
          </Grid>
        </Grid>

        <CountTiles
          isLoading={isCaseCountLoading}
          creditApproved={caseCountDetails?.loanApproved}
          creditPending={caseCountDetails?.loanPending}
          creditRejected={caseCountDetails?.loanRejected}
          creditReponed={caseCountDetails?.loanReopen}
          totalApplications={caseCountDetails?.totalApplication}
        />

        <Box
          sx={{
            borderRadius: 5,
            backgroundColor: 'white',
            width: '100%',
            marginTop: 3
          }}
        >
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
              <Typography
                paddingTop={2}
                paddingBottom={1}
                paddingLeft={3}
                paddingRight={3}
                fontSize={22}
                color={colorPrimaryBlue}
                variant="subtitle2"
                fontWeight={600}
              >
                Case Listing
              </Typography>
            </Grid>
          </Grid>

          <TableFilter onFilterSubmit={onFilterSubmit} />

          <Grid container direction="row" sx={{ marginTop: 1, padding: '8px' }}>
            <CustomDataTable
              columns={headersList}
              data={caseListDetails['content']}
              headStyle={{ backgroundColor: colorGolden, color: 'black' }}
              totalCount={caseListDetails['totalElements']}
              defaultRowsPerPage={5}
              defaultPage={pageData?.page}
              onRowsPerPageChange={onRowsPerPageChange}
              onPageChange={onPageChange}
              onRowClick={onRowClick}
            />
          </Grid>

          {!isCaseListingLoading && isCaseListingFailed && (
            <SnackBarBox
              type="error"
              message={handleError(caseListingErrorContainer?.errorMessage)}
            />
          )}
        </Box>
      </StyledGrid>
    </>
  );
}

export default CreditDashboard;
