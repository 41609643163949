import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography,
  DialogTitle,
  FormHelperText,
  Autocomplete,
  Checkbox,
  Divider,
  InputAdornment,
  ListItemText,
  IconButton,
  Tooltip
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  FormButtonBottom,
  StyledGrid
} from '../../../../../utils/styledLayout';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import {
  colorLightYellow,
  colorPrimaryBlue,
  colorWhite
} from '../../../../../config/theme';
import UploadIcon from '@mui/icons-material/Upload';
import { LoadingButton } from '@mui/lab';
import SearchIcon from '@mui/icons-material/Search';
import ImageADDIcon from '../../../../../assets/ImageADDIcon.svg';
import * as yup from 'yup';
import ModalDialog from '../../../../../components/ModalDialog';
import {
  capitalizeWords,
  createTitle,
  CustomCalendarIcon,
  enterOnlyAadhaarNumber,
  enterOnlyAlphabates,
  enterOnlyAlphabatesOrNumber,
  enterOnlyAlphabetsAndNumbers,
  enterOnlyEmail,
  enterOnlyNumbers,
  enterOnlyNumbersWithDecimal,
  enterOnlyPanNumber,
  formatDate,
  formatINR,
  getFullName,
  handleError,
  isValidEmail,
  isValidMobileNumber,
  isValidPanNumber,
  isValidPinCode
} from '../../../../../utils/utils';
import { Search, CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import CustomDataTable from '../../../../../components/CustomDataTable';
import EditImage from '../../../../../assets/editPen.svg';
import DeleteImage from '../../../../../assets/delete.svg';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import {
  SELECT_BORROWER_IMAGE_MESSAGE,
  UploadDocumentAllowedPDFTypes,
  states
} from '../../../../../utils/constant';
import { fetchProducts } from '../../../../../redux/reducers/EmployeeL1Slice';
import { NEW_L3_SAVE_SUCCESS } from '../../../../../utils/messages';
import SnackBarBox from '../../../../../components/SnackBarBox';
import Loader from '../../../../../components/Loader';
import {
  fetchReportToL2,
  revertAllL3User,
  saveEmployeeL3,
  clearL3ViewNotification
} from '../../../../../redux/reducers/EmployeeL3Slice';

const StyledPopper = styled(Popper)(({ theme }) => ({
  '& .MuiAutocomplete-listbox': {
    maxHeight: 'calc(35vh - 70px)',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.4em',
      height: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(0, 0, 0, 0.3)',
      borderRadius: '4px'
    }
  }
}));

const formControl = {
  margin: '0.5rem auto'
};

const EditL3Users = () => {
  const [isShowAddInsModal, setIsShowAddInsModal] = useState(false);
  const [isIncentiveData, setIsIncentiveData] = useState([]);
  const [selectedValues, setSelectedValues] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [imageUploadError, setImageUploadError] = useState({
    error: false,
    errorMessage: ''
  });
  const [isEditing, setIsEditing] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);

  const {
    l2UsersList,
    isCreateEmployeeL3Loading,
    isCreateEmployeeL3Success,
    isCreateEmployeeL3Failed,
    createEmployeeL3ErrorContainer,
    employeeL3ByIdData
  } = useSelector(state => state.employeeL3);

  const { products } = useSelector(state => state.employeeL1);
  // const {
  //   isCreateEmployeeL1Loading,
  //   isCreateEmployeeL1Success,
  //   isCreateEmployeeL1Failed,
  //   createEmployeeL1ErrorContainer,
  //   employeeL1ByIdData
  // } = useSelector(state => state.employeeL1);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const fileInputRef = useRef(null);

  useEffect(() => {
    dispatch(fetchProducts());
    dispatch(fetchReportToL2());
  }, []);

  useEffect(() => {
    document.title = createTitle('EditL3User');

    if (employeeL3ByIdData) {
      // Destructure employeeL1ByIdData to get values
      const {
        levelName,
        name,
        category,
        email,
        mobileNo,
        pinCode,
        targetAmtOfInvestor,
        achiveAmtOfInvestor,
        targetAmtOfBorrower,
        achiveAmtOfBorrower,
        accHolderName,
        accountNo,
        ifscCode,
        aadhaarNumber,
        panNo,
        aggrementPath,
        aggrementDate,
        incentiveDetails,
        reportToCheckList
      } = employeeL3ByIdData;

      const formatAgreementDate = date => {
        if (!date || date === 'Invalid Date') {
          return null;
        }

        const [day, month, year] = date.split('-').map(Number);

        const parsedDate = new Date(year, month - 1, day);

        if (isNaN(parsedDate.getTime())) {
          return null;
        }

        return parsedDate;
      };
      formik.setFieldValue('levelName', levelName ?? '');
      formik.setFieldValue('name', name ?? '');
      formik.setFieldValue('email', email ?? '');
      formik.setFieldValue('mobileNo', mobileNo ?? '');
      formik.setFieldValue('pinCode', pinCode ?? '');
      formik.setFieldValue('category', category ?? '');
      formik.setFieldValue(
        'targetAmtOfInvestor',
        targetAmtOfInvestor === 0.0 || targetAmtOfInvestor == null
          ? ''
          : targetAmtOfInvestor
      );

      formik.setFieldValue(
        'achiveAmtOfInvestor',
        achiveAmtOfInvestor === 0.0 || achiveAmtOfInvestor == null
          ? ''
          : achiveAmtOfInvestor
      );

      formik.setFieldValue(
        'targetAmtOfBorrower',
        targetAmtOfBorrower === 0.0 || targetAmtOfBorrower == null
          ? ''
          : targetAmtOfBorrower
      );

      formik.setFieldValue(
        'achiveAmtOfBorrower',
        achiveAmtOfBorrower === 0.0 || achiveAmtOfBorrower == null
          ? ''
          : achiveAmtOfBorrower
      );
      formik.setFieldValue(
        'reportToL2Id',
        reportToCheckList ?? { key: '', value: '' }
      );
      formik.setFieldValue('accHolderName', accHolderName ?? '');
      formik.setFieldValue('accountNo', accountNo ?? '');
      formik.setFieldValue('ifscCode', ifscCode ?? '');
      formik.setFieldValue('aadhaarNumber', aadhaarNumber ?? '');
      formik.setFieldValue('panNo', panNo ?? '');
      formik.setFieldValue('aggrementPath', aggrementPath ?? '');
      formik.setFieldValue('aggrementDate', formatAgreementDate(aggrementDate));
      formik.setFieldValue('incentiveDetails', incentiveDetails ?? []);
      aggrementPath && setUploadedFileName(aggrementPath);
      // if (region) {
      //   setSelectedRegion({ region });
      //   setSelectedStates([]);
      // } else {
      //   setSelectedRegion(null);
      //   setSelectedStates(
      //     (stateCheckList ?? []).map(state => ({
      //       key: state.key,
      //       value: state.value,
      //       others: null
      //     }))
      //   );
      // }
      if (incentiveDetails) {
        setIsIncentiveData(incentiveDetails);
      } else {
        setIsIncentiveData([]);
      }
    }
  }, [employeeL3ByIdData]);

  const handleEdit = row => {
    setCurrentRow(row);
    setIsEditing(true);
    setIsShowAddInsModal(true);
    addIncentiveFormik.setValues({
      productName: row.productName,
      percentange: row.percentange,
      productKey: row.productKey
    });
  };

  const onCancelIncentiveHdlr = () => {
    setCurrentRow(null);
    setIsEditing(false);
    setIsShowAddInsModal(false);
    addIncentiveFormik.resetForm();
  };

  const handleCloseAddL3 = () => {
    formik.resetForm();
    dispatch(revertAllL3User());
    dispatch(clearL3ViewNotification());
    navigate(-1);
  };
  useEffect(() => {
    if (!isCreateEmployeeL3Loading && isCreateEmployeeL3Success) {
      setTimeout(() => {
        handleCloseAddL3();
        dispatch(revertAllL3User());
        dispatch(clearL3ViewNotification());
      }, 1500);
      return;
    }
    if (!isCreateEmployeeL3Loading && isCreateEmployeeL3Failed) {
      setTimeout(() => {
        //setLoading(false);
        dispatch(revertAllL3User());
        return;
      }, 1500);
      return;
    }

    // return () => dispatch(revertFormScheduleReminder());
  }, [
    isCreateEmployeeL3Loading,
    isCreateEmployeeL3Success,
    isCreateEmployeeL3Failed
  ]);

  const handleDelete = id => {
    setIsIncentiveData(prevData =>
      prevData.filter(incentive => incentive.productKey !== id.productKey)
    );
  };

  const handleImageUpload = event => {
    const file = event.target.files[0];

    if (file) {
      const allowedTypes = UploadDocumentAllowedPDFTypes;
      const maxSizeMB = 5;
      const fileName = file.name;

      if (allowedTypes.includes(file.type)) {
        if (file.size / (1024 * 1024) <= maxSizeMB) {
          setUploadedFileName(fileName);
          setImageUploadError({ error: false, errorMessage: '' });
        } else {
          setImageUploadError({
            error: true,
            errorMessage: `File size exceeds the maximum allowed (${maxSizeMB} MB).`
          });
        }
      } else {
        setImageUploadError({
          error: true,
          errorMessage: 'Please upload a PDF file.'
        });
      }

      formik.setFieldValue('aggrementPath', file);
    }
  };
  const headersList = [
    {
      id: 'productName',
      label: 'Product Name',
      sortable: true,
      align: 'center',
      width: '45%'
    },
    {
      id: 'percentange',
      label: 'Incentive Percentage(%)',
      sortable: true,
      width: 40,
      align: 'center',
      render: row => (
        <div style={{ textAlign: 'center', width: '40%' }}>
          {row.percentange}
        </div>
      )
    },

    {
      id: 'action',
      label: 'Action',
      sortable: true,
      width: 30,
      align: 'center',
      render: row => {
        return (
          <>
            <Tooltip placement="top" title="Edit">
              <IconButton aria-label="edit" onClick={() => handleEdit(row)}>
                <img
                  src={EditImage}
                  alt="Edit"
                  style={{ width: '20px', height: '24px' }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip placement="top" title="Delete">
              <IconButton aria-label="delete" onClick={() => handleDelete(row)}>
                <img
                  src={DeleteImage}
                  alt="Delete"
                  style={{ width: '20px', height: '24px' }}
                />
              </IconButton>
            </Tooltip>
          </>
        );
      }
    }
  ];

  const handleOpenAddIncentive = () => {
    setIsShowAddInsModal(!isShowAddInsModal);
  };

  const validateFields = values => {
    const errors = {};

    const { accountNo, accHolderName, ifscCode } = values;

    const isAnyFieldEntered = !!(accountNo || accHolderName || ifscCode);
    const areAllFieldsEntered = !!(accountNo && accHolderName && ifscCode);

    if (isAnyFieldEntered && !areAllFieldsEntered) {
      errors.accountNo =
        'If any one of Account Number, Account Holder Name, or IFSC Code is entered, the other two must also be entered.';
      errors.accHolderName =
        'If any one of Account Number, Account Holder Name, or IFSC Code is entered, the other two must also be entered.';
      errors.ifscCode =
        'If any one of Account Number, Account Holder Name, or IFSC Code is entered, the other two must also be entered.';
    }

    return errors;
  };
  const validationSchema = yup.object().shape({
    levelName: yup.string().required('Name is required'),
    name: yup.string().required('Name is required'),
    mobileNo: yup
      .string()
      .test(
        'is-valid-mobile-number',
        'The mobile number should start with 6-9 and consist of exactly 10 digits.',
        value => isValidMobileNumber(value)
      )
      .required('Mobile Number is required'),
    email: yup
      .string()
      .test('is-valid-email', 'Please provide a valid email address.', value =>
        isValidEmail(value)
      )
      .required('Email ID is required'),
    pinCode: yup
      .string()
      .test('is-valid-pinCode', 'Please provide a valid Pin Code.', value =>
        isValidPinCode(value)
      )
      .required('Email ID is required'),
    accHolderName: yup.lazy(value =>
      value
        ? yup.string().min(3, 'Account name must be at least 3 characters')
        : yup.string()
    ),
    accountNo: yup.lazy(value =>
      value
        ? yup.string().matches(/^\d{8,}$/, 'Must be a valid account number')
        : yup.mixed()
    ),
    ifscCode: yup.lazy(value =>
      value
        ? yup
            .string()
            .matches(/^[A-Za-z]{4}\d{7}$/, 'Must be a valid IFSC code')
        : yup.mixed()
    ),
    aadhaarNumber: yup.lazy(value =>
      value
        ? yup.string().matches(/^\d{12}$/, 'Must be a valid Aadhaar number')
        : yup.mixed()
    ),
    panNo: yup.lazy(value =>
      value
        ? yup
            .string()
            .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, 'Must be a valid PAN number')
        : yup.mixed()
    ),
    aggrementPath: yup.mixed(),
    aggrementDate: yup
      .string()
      .nullable()
      .test(
        'is-within-range',
        'Date must be between 01-01-1900 and today',
        value => {
          if (!value) return true;
          const date = dayjs(value);

          const minDate = dayjs('1900-01-01');
          const maxDate = dayjs();

          if (
            date.isValid() &&
            date.isAfter(minDate) &&
            date.isBefore(maxDate)
          ) {
            return true;
          } else {
            return false;
          }
        }
      )
  });

  const sessionData = JSON.parse(
    sessionStorage.getItem('x-auth-token-admin-panel') || '{}'
  );
  const { userId, role } = sessionData;

  const formik = useFormik({
    initialValues: {
      levelName: '',
      name: '',
      email: '',
      mobileNo: '',
      pinCode: '',
      targetAmtOfInvestor: '',
      targetAmtOfBorrower: '',
      reportToL2Id: '',
      accHolderName: '',
      accountNo: '',
      ifscCode: '',
      aadhaarNumber: '',
      panNo: '',
      aggrementPath: '',
      aggrementDate: null
    },
    validationSchema: validationSchema,
    validate: validateFields,

    onSubmit: values => {
      const formattedDate = values.aggrementDate
        ? dayjs(values.aggrementDate).format('DD-MM-YYYY')
        : null;

      const incentiveDetails = isIncentiveData.map(item => ({
        productName: item.productName,
        productKey: item.productKey,
        percentange: parseFloat(item.percentange)
      }));
      let submitPayload = {
        id: location?.state?.id,
        levelName: values.levelName,
        name: values.name,
        email: values.email,
        mobileNo: values.mobileNo,
        pinCode: values.pinCode,
        targetAmtOfInvestor: parseFloat(values.targetAmtOfInvestor),
        targetAmtOfBorrower: parseFloat(values.targetAmtOfBorrower),
        reportToL2Id: values.reportToL2Id ? values.reportToL2Id.key : null,
        accHolderName: values.accHolderName,
        accountNo: values.accountNo,
        ifscCode: values.ifscCode,
        aadhaarNumber: values.aadhaarNumber,
        panNo: values.panNo,
        aggrementDate: formattedDate,
        incentiveDetails: incentiveDetails
      };
      // console.log('Form values:', submitPayload);
      const createFormData = new FormData();
      createFormData.append(
        'file',
        formik.values.aggrementPath ? formik.values.aggrementPath : null
      );
      createFormData.append('employeeL3info', JSON.stringify(submitPayload));
      dispatch(saveEmployeeL3(createFormData));
    }
  });

  const onSelectionChange = value => {
    setSelectedValues(value);
  };

  const addIncentiveFormik = useFormik({
    initialValues: {
      productName: '',
      percentange: '',
      productKey: ''
    },
    validationSchema: yup.object({
      productName: yup.string().required('productName Manager is required'),
      percentange: yup.string().required('Incentive % is required')
    }),
    onSubmit: values => {
      if (isEditing) {
        const updatedData = isIncentiveData.map(item =>
          item.productKey === currentRow.productKey
            ? { ...item, ...values }
            : item
        );
        setIsIncentiveData(updatedData);
        setIsEditing(false);
      } else {
        const newIncentive = {
          ...values,
          id: values.productKey //uuidv4()
        };
        setIsIncentiveData(prevData => [...prevData, newIncentive]);
      }

      addIncentiveFormik.resetForm();
      setIsShowAddInsModal(false);
    }
  });

  return (
    <StyledGrid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Box
        sx={{
          borderRadius: 5,
          backgroundColor: 'white',
          width: '100%',
          boxShadow: '0px 1px 17px -5px rgba(0,0,0,0.67)',
          paddingLeft: 1,
          paddingRight: 1,
          padding: 4
        }}
      >
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          <DialogTitle
            sx={{
              m: 0,
              p: 1,
              marginLeft: '5px',
              backgroundColor: 'white',
              fontWeight: '600',
              color: colorPrimaryBlue
            }}
            id="customized-dialog-title"
          >
            Edit L3 User
          </DialogTitle>
          <Grid container spacing={3} marginBottom={4}>
            <Grid item xs={12} sm={4}>
              <TextField
                size="small"
                fullWidth
                label="Level Name"
                required
                inputProps={{ maxLength: 40 }}
                value={formik.values.levelName}
                onChange={formik.handleChange}
                name="levelName"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                size="small"
                fullWidth
                label="Full Name"
                required
                value={formik.values.name}
                inputProps={{ maxLength: 40 }}
                onChange={e => {
                  const value = e.target.value;
                  const cleanedValue = enterOnlyAlphabates(value);
                  const capitalizedValue = capitalizeWords(cleanedValue);
                  formik.setFieldValue('name', capitalizedValue);
                }}
                name="name"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                size="small"
                fullWidth
                label="Email ID"
                required
                value={formik.values.email}
                onChange={e => {
                  const email = e.target.value.replace(/[^a-zA-Z@.^0-9]/g, '');
                  formik.setFieldValue('email', email);
                }}
                onBlur={formik.handleBlur}
                name="email"
                error={Boolean(formik.touched.email && formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>

            {/* <Grid item xs={12} sm={4}>
              <FormControl variant="outlined" size="small" fullWidth required>
                <InputLabel id="category">category </InputLabel>
                <Select
                  labelId="category"
                  id="category"
                  label="category"
                  value={formik.values.category}
                  onChange={formik.handleChange}
                  name="category"
                >
                  <MenuItem value="">Select Category</MenuItem>
                  <MenuItem value="Non-Credit">Non-Credit</MenuItem>
                  <MenuItem value="Credit">Credit</MenuItem>
                </Select>
                {formik.touched.category && formik.errors.category && (
                  <FormHelperText error>
                    {formik.errors.category}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid> */}
          </Grid>
          <Grid container spacing={3} marginBottom={4}>
            <Grid item xs={12} sm={4}>
              {/* Phone Number Input */}
              <TextField
                required
                label="Mobile No"
                name="mobileNo"
                size="small"
                fullWidth
                inputProps={{ maxLength: 10 }}
                placeholder="Mobile No"
                value={formik.values.mobileNo}
                onChange={e => {
                  formik.setFieldValue(
                    'mobileNo',
                    enterOnlyNumbers(e.target.value)
                  );
                }}
                error={
                  formik.touched.mobileNo && Boolean(formik.errors.mobileNo)
                }
                helperText={formik.touched.mobileNo && formik.errors.mobileNo}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                required
                label="Pin Code"
                name="pinCode"
                size="small"
                fullWidth
                inputProps={{ maxLength: 6 }}
                placeholder="Pin Code"
                value={formik.values.pinCode}
                onChange={e => {
                  formik.setFieldValue(
                    'pinCode',
                    enterOnlyNumbers(e.target.value)
                  );
                }}
                error={formik.touched.pinCode && Boolean(formik.errors.pinCode)}
                helperText={formik.touched.pinCode && formik.errors.pinCode}
              />
            </Grid>
            {role !== 'ROLE_L2' && (
              <Grid item xs={12} sm={4}>
                <FormControl variant="outlined" size="small" fullWidth required>
                  <InputLabel id="reportToL2Id">Report to L2</InputLabel>
                  <Select
                    labelId="reportToL2Id"
                    id="reportToL2Id"
                    label="Report to L2"
                    value={formik.values.reportToL2Id?.value || ''}
                    onChange={event => {
                      const selectedValue = l2UsersList.find(
                        option => option.value === event.target.value
                      );
                      formik.setFieldValue(
                        'reportToL2Id',
                        selectedValue ?? { key: '', value: '' }
                      );
                      if (selectedValue) {
                        formik.setFieldValue('category', selectedValue?.others);
                      }
                    }}
                    name="reportToL2Id"
                  >
                    <MenuItem value="">Select Option</MenuItem>
                    {l2UsersList &&
                      l2UsersList.map(option => (
                        <MenuItem key={option.key} value={option.value}>
                          {option.value}
                        </MenuItem>
                      ))}
                  </Select>
                  {formik.touched.reportToL2Id &&
                    formik.errors.reportToL2Id && (
                      <FormHelperText error>
                        {formik.errors.reportToL2Id}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={3} marginBottom={4}>
            <Grid item xs={12} sm={4}>
              <TextField
                size="small"
                fullWidth
                value={formik.values.targetAmtOfInvestor}
                onChange={e => {
                  formik.setFieldValue(
                    'targetAmtOfInvestor',
                    enterOnlyNumbersWithDecimal(e.target.value)
                  );
                }}
                name="targetAmtOfInvestor"
                label="Annual Target (Investor) (₹)"
                inputProps={{ maxLength: 20 }}
              />
            </Grid>
            {formik.values.category !== 'Non-Credit' && (
              <Grid item xs={12} sm={4}>
                <TextField
                  size="small"
                  fullWidth
                  value={formik.values.targetAmtOfBorrower}
                  onChange={e => {
                    formik.setFieldValue(
                      'targetAmtOfBorrower',
                      enterOnlyNumbersWithDecimal(e.target.value)
                    );
                  }}
                  name="targetAmtOfBorrower"
                  label="Annual Target (Borrower) (₹)"
                  inputProps={{ maxLength: 20 }}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={4}>
              <TextField
                size="small"
                fullWidth
                value={formik.values.accHolderName}
                onChange={e => {
                  formik.setFieldValue(
                    'accHolderName',
                    enterOnlyAlphabates(e.target.value)
                  );
                }}
                name="accHolderName"
                label="Account Holder Name"
                inputProps={{ maxLength: 40 }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.accHolderName &&
                  Boolean(formik.errors.accHolderName)
                }
                helperText={
                  formik.touched.accHolderName && formik.errors.accHolderName
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} marginBottom={4}>
            <Grid item xs={12} sm={4}>
              <TextField
                size="small"
                fullWidth
                value={formik.values.accountNo}
                name="accountNo"
                label="Account Number"
                inputProps={{ maxLength: 20 }}
                onChange={e => {
                  formik.setFieldValue(
                    'accountNo',
                    enterOnlyNumbers(e.target.value)
                  );
                }}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.touched.accountNo && formik.errors.accountNo
                )}
                helperText={formik.touched.accountNo && formik.errors.accountNo}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                size="small"
                fullWidth
                value={formik.values.ifscCode}
                name="ifscCode"
                label="IFSC Code"
                inputProps={{ maxLength: 11 }}
                onChange={e => {
                  formik.setFieldValue(
                    'ifscCode',
                    String(
                      enterOnlyAlphabetsAndNumbers(e.target.value)
                    ).toUpperCase()
                  );
                }}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.touched.ifscCode && formik.errors.ifscCode
                )}
                helperText={formik.touched.ifscCode && formik.errors.ifscCode}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                size="small"
                fullWidth
                value={formik.values.aadhaarNumber}
                //onChange={formik.handleChange}
                name="aadhaarNumber"
                label="Aadhaar Number"
                inputProps={{ maxLength: 12 }}
                onChange={e => {
                  const aadhaarNumber = enterOnlyAadhaarNumber(e.target.value);
                  formik.setFieldValue(
                    'aadhaarNumber',
                    String(aadhaarNumber).toUpperCase()
                  );
                }}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.touched.aadhaarNumber && formik.errors.aadhaarNumber
                )}
                helperText={
                  formik.touched.aadhaarNumber && formik.errors.aadhaarNumber
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} marginBottom={4}>
            <Grid item xs={12} sm={4}>
              <TextField
                size="small"
                fullWidth
                value={formik.values.panNo}
                name="panNo"
                label="PAN Number"
                inputProps={{ maxLength: 12 }}
                onChange={e => {
                  const upperCasePanNo = e.target.value.toUpperCase();
                  formik.setFieldValue(
                    'panNo',
                    enterOnlyPanNumber(upperCasePanNo)
                  );
                }}
                onBlur={formik.handleBlur}
                error={Boolean(formik.touched.panNo && formik.errors.panNo)}
                helperText={formik.touched.panNo && formik.errors.panNo}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    minDate={dayjs('01-01-1900')}
                    disableFuture={dayjs(new Date())}
                    label="Agreement Date"
                    name="aggrementDate"
                    value={
                      formik.values.aggrementDate
                        ? dayjs(formik.values.aggrementDate)
                        : null
                    }
                    format="DD-MM-YYYY"
                    slotProps={{ textField: { size: 'small' } }}
                    onChange={date =>
                      formik.setFieldValue('aggrementDate', date)
                    }
                    slots={{
                      openPickerIcon: CustomCalendarIcon
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        error={
                          formik.touched.aggrementDate &&
                          Boolean(formik.errors.aggrementDate)
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
                {formik.touched.aggrementDate &&
                  formik.errors.aggrementDate && (
                    <FormHelperText>
                      {formik.errors.aggrementDate}
                    </FormHelperText>
                  )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={3} marginBottom={4}>
            <Grid item sm={12} lg={1.4} marginLeft={1}>
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: 'bold', color: colorPrimaryBlue }}
              >
                Agreement Copy
              </Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <LoadingButton
                startIcon={<UploadIcon />}
                onClick={() => fileInputRef.current.click()}
                variant="outlined"
                sx={{
                  background: '#FFF8E6',
                  border: '1px dashed #FD9600',
                  borderRadius: '10px',
                  opacity: 1
                }}
              >
                UPLOAD
                <input
                  ref={fileInputRef}
                  key={'uploadProfile'}
                  type="file"
                  style={{ display: 'none' }}
                  accept={UploadDocumentAllowedPDFTypes.join(',')}
                  onChange={handleImageUpload}
                />
              </LoadingButton>
              {uploadedFileName && (
                <div>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: 'bold', color: 'primary' }}
                  >
                    Uploaded File:
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      wordBreak: 'break-word',
                      overflowWrap: 'break-word',
                      maxWidth: '100%',
                      whiteSpace: 'normal'
                    }}
                  >
                    {uploadedFileName}
                  </Typography>
                </div>
              )}

              {imageUploadError.error && (
                <div style={{ color: 'red' }}>
                  <Typography variant="body1">
                    Error: {imageUploadError.errorMessage}
                  </Typography>
                </div>
              )}
            </Grid>
          </Grid>

          <Grid
            container
            spacing={3}
            marginBottom={4}
            marginTop={4}
            marginLeft={0.5}
            marginRight={0.5}
            style={{
              background: '#FFFFFF',
              border: '1px solid #999999',
              borderRadius: '6px',
              opacity: 1,
              width: '100%'
            }}
          >
            <Grid container spacing={3} padding={3}>
              <Grid item sm={12} lg={10} justifyContent="flex-start">
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 'bold',
                    color: colorPrimaryBlue,
                    display: 'flex'
                  }}
                >
                  Incentive
                </Typography>
              </Grid>
              <Grid
                item
                sm={12}
                lg={2}
                sx={{
                  display: 'flex',
                  paddingRight: '10px',
                  cursor: 'pointer'
                }}
                justifyContent="flex-end"
              >
                <img
                  onClick={handleOpenAddIncentive}
                  src={ImageADDIcon}
                  alt="Add Icon"
                />
              </Grid>

              <Grid item sm={12} lg={10} justifyContent="flex-start">
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: 'black',
                    display: 'flex'
                  }}
                >
                  Click on the + button to add incentive.
                </Typography>
              </Grid>
            </Grid>
            {isIncentiveData && isIncentiveData?.length > 0 && (
              <Grid container direction="row" sx={{ marginTop: 1 }}>
                <CustomDataTable
                  columns={headersList}
                  data={isIncentiveData}
                  headStyle={{ color: 'black' }}
                  onSelectionChange={onSelectionChange}
                  selectedRows={selectedValues}
                  defaultPage={0}
                  defaultRowsPerPage={5}
                  totalCount={5}
                />
              </Grid>
            )}
          </Grid>

          <Grid px={2} pt={1} item display={'flex'} justifyContent={'end'}>
            <Button
              variant="outlined"
              sx={{
                borderColor: colorPrimaryBlue,
                color: colorPrimaryBlue,
                fontWeight: '700',
                fontSize: '14px',
                mr: 2,
                width: '115px',
                height: '50px',
                borderWidth: 2
              }}
              onClick={handleCloseAddL3}
            >
              CANCEL
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              sx={{
                color: colorWhite,
                fontWeight: '500',
                fontSize: '14px',
                mr: 2,
                width: '120px',
                height: '50px',
                borderWidth: 2
              }}
            >
              SUBMIT
            </LoadingButton>
          </Grid>
        </form>
        {/* {isCreateEmployeeL2Loading && <Loader key="edit_l2" />} */}

        {!isCreateEmployeeL3Loading && isCreateEmployeeL3Failed && (
          <SnackBarBox
            type="error"
            message={handleError(createEmployeeL3ErrorContainer.errorMessage)}
          />
        )}

        {!isCreateEmployeeL3Loading && isCreateEmployeeL3Success && (
          <SnackBarBox type="success" message={NEW_L3_SAVE_SUCCESS} />
        )}

        {isShowAddInsModal && (
          <ModalDialog
            fullwidth={true}
            isShowTitle={true}
            titleName={isEditing ? 'EDIT INCENTIVE' : 'ADD INCENTIVE'}
            size="xs"
            open={isShowAddInsModal}
            onClose={() => {
              setIsShowAddInsModal(false);
              setIsEditing(false);
              addIncentiveFormik.resetForm();
            }}
          >
            <form autoComplete="off" onSubmit={addIncentiveFormik.handleSubmit}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                marginTop={0.5}
              >
                <Grid item xs={12} sx={{ width: '80%' }}>
                  <FormControl size="small" fullWidth required>
                    <InputLabel>Product Name</InputLabel>
                    <Select
                      label="Product Name"
                      name="productName"
                      value={addIncentiveFormik.values.productName}
                      onChange={e => {
                        const selectedProduct = products.find(
                          product => product.value === e.target.value
                        );
                        addIncentiveFormik.setFieldValue(
                          'productName',
                          e.target.value
                        );
                        addIncentiveFormik.setFieldValue(
                          'productKey',
                          selectedProduct ? selectedProduct.key : ''
                        );
                      }}
                      error={
                        addIncentiveFormik.touched.productName &&
                        Boolean(addIncentiveFormik.errors.productName)
                      }
                    >
                      {products
                        .filter(
                          product =>
                            !isIncentiveData.some(
                              data => data.productName === product.value
                            ) ||
                            (isEditing &&
                              currentRow.productName === product.value)
                        )
                        .map(product => (
                          <MenuItem key={product.key} value={product.value}>
                            {product.value}
                          </MenuItem>
                        ))}
                    </Select>
                    {addIncentiveFormik.touched.productName &&
                      addIncentiveFormik.errors.productName && (
                        <FormHelperText error>
                          {addIncentiveFormik.errors.productName}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sx={{ width: '80%' }}>
                  <input
                    type="hidden"
                    name="productKey"
                    value={addIncentiveFormik.values.productKey}
                  />
                </Grid>

                <Grid item xs={12} sx={{ width: '80%' }}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      size="small"
                      fullWidth
                      label="Incentive Percentage"
                      required
                      name="percentange"
                      inputProps={{
                        maxLength: 6,
                        inputMode: 'decimal'
                      }}
                      value={addIncentiveFormik?.values.percentange}
                      onChange={e => {
                        const value = e.target.value;
                        const regex =
                          /^(100(\.0{0,2})?|([1-9]?[0-9](\.[0-9]{0,2})?))$/;
                        if (value === '' || regex.test(value)) {
                          addIncentiveFormik.handleChange(e);
                        }
                      }}
                      error={
                        addIncentiveFormik.touched.percentange &&
                        Boolean(addIncentiveFormik.errors.percentange)
                      }
                      helperText={
                        addIncentiveFormik.touched.percentange &&
                        addIncentiveFormik.errors.percentange
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} textAlign="right">
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: colorPrimaryBlue,
                      color: colorPrimaryBlue,
                      fontWeight: '700',
                      fontSize: '14px',
                      mr: 2,
                      width: '110px',
                      height: '48px',
                      borderWidth: 2
                    }}
                    onClick={onCancelIncentiveHdlr}
                  >
                    CANCEL
                  </Button>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{
                      color: colorWhite,
                      fontWeight: '500',
                      fontSize: '14px',
                      mr: 2,
                      width: '115px',
                      height: '48px',
                      borderWidth: 2
                    }}
                  >
                    SUBMIT
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </ModalDialog>
        )}
      </Box>
    </StyledGrid>
  );
};

export default EditL3Users;
