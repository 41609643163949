import { useEffect, useState } from 'react';
import { StyledGrid } from '../../../../utils/styledLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createTitle } from '../../../../utils/utils';
import SkeletonBars from '../../../../components/SkeletonBars';
import BackButton from '../../../../components/BackButton';
import { Button, Grid, Typography } from '@mui/material';
import AccordionComponent from '../../../../components/AccordionComponent';
import { Add, Remove } from '@mui/icons-material';
import { colorGolden } from '../../../../config/theme';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getDeviationQDEDetails } from '../../../../redux/reducers/adminSlice';
import QDEDetails from './QDEAdmin/QDEDetails';

function DeviationQDE() {
  const { id, userId } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { isFetchDeviationQDELoading, isFetchDeviationQDEFailed } = useSelector(
    state => state.admin
  );

  useEffect(() => {
    document.title = createTitle('Deviation QDE');
    dispatch(
      getDeviationQDEDetails({ userId: userId, borrowingDetailsId: id })
    );
  }, []);

  const [expanded, setExpanded] = useState({ ['qde']: true });

  const handleAccordionChange = panel => isExpanded => {
    setExpanded({ [panel]: isExpanded });
  };

  return (
    <>
      <StyledGrid
        container
        direction="row"
        justifyContent="left"
        alignItems="left"
      >
        {isFetchDeviationQDELoading ? (
          <SkeletonBars />
        ) : !isFetchDeviationQDEFailed ? (
          <>
            <BackButton text="Back to Deviation" path="/admin-daviation" />
            <Grid container>
              <Grid item xs={12} mt={5}>
                <AccordionComponent
                  style={{
                    minHeight: 60,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                  key="qde"
                  onChange={() =>
                    handleAccordionChange('qde')(!expanded['qde'])
                  }
                  expanded={Boolean(expanded[`qde`])}
                  titleStyleProp={{ fontSize: 20 }}
                  icon={
                    !expanded[`qde`] ? (
                      <Add sx={{ color: colorGolden, fontSize: '2rem' }} />
                    ) : (
                      <Remove sx={{ color: colorGolden, fontSize: '2rem' }} />
                    )
                  }
                  title={'QDE'}
                >
                  {expanded[`qde`] && (
                    <>
                      <QDEDetails />
                    </>
                  )}
                </AccordionComponent>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid
              padding={5}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="body1" fontWeight={800} align="center">
                ⚠️ Apologies, we couldn&apos;t find any details for the
                requested borrower.
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                onClick={() => {
                  navigate('/admin-daviation');
                  return;
                }}
                variant="contained"
                startIcon={<ArrowBackIcon />}
              >
                Back to Dashboard
              </Button>
            </Grid>
          </>
        )}
      </StyledGrid>
    </>
  );
}

export default DeviationQDE;
