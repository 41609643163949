import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Typography, styled } from '@mui/material';
import CenteredLogo from '../components/CenteredLogo';
import { clearLoginState, pingServer } from '../redux/reducers/authSlice';
import { createTitle, handleError } from '../utils/utils';
import { IMG_LOGIN_BG } from '../utils/imageUrls';
import SnackBarBox from '../components/SnackBarBox';
import Loader from '../components/Loader';
import {
  ROLE_ADMIN_USER,
  ROLE_CREDIT_USER,
  ROLE_L1_USER,
  ROLE_L2_USER,
  ROLE_L3_USER
} from '../utils/userRoles';
import { useNavigate } from 'react-router-dom';
import { LoginForm } from '../containers/auth/LoginForm';

const CustomGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: { padding: '0px 0px' },
  [theme.breakpoints.up('md')]: { padding: '0px 60px' }
}));

export default function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(pingServer());
    document.title = createTitle('Login');
    return () => dispatch(clearLoginState());
  }, []);

  const {
    isLoading,
    isLoggedIn,
    isError,
    errorContainer,
    authData,
    applicationStatus,
    isPingServerLoading
  } = useSelector(state => state.auth);

  // useEffect(() => {
  //   if (isLoggedIn && authData?.role === ROLE_CREDIT_USER) {
  //     navigate('/credit-dashboard');
  //     return;
  //   } else if (isLoggedIn && authData?.role === ROLE_ADMIN_USER) {
  //     navigate('/admin-dashboard');
  //     return;
  //   } else if (isLoggedIn && authData?.role === ROLE_L1_USER) {
  //     navigate('/user-dashboard');
  //     return;
  //   }
  // }, [isLoggedIn]);
  useEffect(() => {
    if (isLoggedIn) {
      const { role } = authData || {};
      if (role === ROLE_CREDIT_USER) {
        navigate('/credit-dashboard');
      } else if (role === ROLE_ADMIN_USER) {
        navigate('/admin-dashboard');
      } else if ([ROLE_L1_USER, ROLE_L2_USER, ROLE_L3_USER].includes(role)) {
        navigate('/user-dashboard');
      }
    }
  }, [isLoggedIn]);

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={false}
          sm={7}
          md={7}
          lg={7}
          sx={{
            backgroundImage: `url(${IMG_LOGIN_BG})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'white',
            backgroundSize: 'contain',
            backgroundPosition: 'center'
          }}
        />

        <CustomGrid item xs={12} sm={5} md={5} lg={5} elevation={6}>
          <Box
            sx={{
              my: 10,
              mx: 4,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <CenteredLogo />
            <Typography
              component="h1"
              variant="h5"
              fontWeight={'700'}
              color={'#000'}
              align="center"
            >
              Welcome!
            </Typography>
            {!isPingServerLoading && applicationStatus === 'DOWN' && (
              <Typography marginTop={2} variant="subtitle2" align="left">
                🔴 System is offline. Please try again later.
              </Typography>
            )}
            <LoginForm />
          </Box>

          {!isLoading && isError && (
            <SnackBarBox
              type="error"
              message={handleError(errorContainer?.errorMessage)}
            />
          )}
        </CustomGrid>
      </Grid>
      {isPingServerLoading && <Loader key="ping" text="" />}
      {isLoading && <Loader key="ping" text="" />}
    </>
  );
}
