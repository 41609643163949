import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import * as yup from 'yup';
import {
  colorLightGray,
  colorMidLightGray,
  colorSuccess
} from '../../config/theme';
import { LoadingButton } from '@mui/lab';
import { FormButtonBottom } from '../../utils/styledLayout';

import { useFormik } from 'formik';
import {
  calculateAmountTOBeApproved,
  createTitle,
  enterOnlyAlphabates,
  enterOnlyNumbers,
  formatINR,
  handleError,
  isArrayNotEmpty,
  printStringValue
} from '../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearDecisioningState,
  sendCreditDecisioning
} from '../../redux/reducers/casesummarySlice';
import SnackBarBox from '../../components/SnackBarBox';
import ModalDialog from '../../components/ModalDialog';
import Loader from '../../components/Loader';
import { TENURE_LIST } from '../../utils/constant';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const formControl = {
  margin: '0.5rem auto'
};

const successStyle = {
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  maxWidth: '100%',
  maxHeight: '100%',
  marginTop: '2rem'
};

const successArchStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  backgroundColor: '#F3FCF7',
  height: '130px',
  borderRadius: '0 0 50% 50%'
};

const labelStyle = {
  display: 'flex',
  flexDirection: 'column'
};

const Decisioning = ({ flagCount }) => {
  const dispatch = useDispatch();

  const tableDataCellStyle = { fontWeight: '600', fontSize: 15 };
  const tableHeaderCellStyle = { fontWeight: '600', fontSize: 16 };

  const { totalAllocatedAmount, totalPoolAmount, caseSummaryDetails } =
    useSelector(state => state.caseSummary);

  const borrowingDetails = caseSummaryDetails['borrowingDetails'];

  useEffect(() => {
    document.title = createTitle('Decisioning');
    return () => dispatch(clearDecisioningState());
  }, []);

  return (
    <>
      <Grid
        container
        maxWidth={'xl'}
        p="1rem 1rem"
        display={'flex'}
        flexDirection={'column'}
      >
        <Grid item xs={12} sx={{ pb: 2 }}>
          <Typography fontWeight={'600'}>Borrower</Typography>

          <TableContainer
            component={Paper}
            sx={{ mt: 1, border: `1px solid ${colorMidLightGray}` }}
            elevation={0}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead
                sx={{
                  backgroundColor: colorLightGray,
                  borderBottom: `1px solid ${colorMidLightGray}`
                }}
              >
                <TableRow>
                  <TableCell sx={{ ...tableHeaderCellStyle }}>
                    Pool Amount
                  </TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle }}>
                    Allocated Amount
                  </TableCell>

                  <TableCell sx={{ ...tableHeaderCellStyle }}>
                    Requested Amount
                  </TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle }}>
                    Tenure (in month)
                  </TableCell>
                  <TableCell sx={{ ...tableHeaderCellStyle }}>
                    ROI (%)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ ...tableDataCellStyle }}
                  >
                    {formatINR(totalPoolAmount)}
                  </TableCell>
                  <TableCell sx={{ ...tableDataCellStyle }}>
                    {formatINR(totalAllocatedAmount)}
                  </TableCell>
                  <TableCell sx={{ ...tableDataCellStyle }}>
                    {formatINR(borrowingDetails?.amount)}
                  </TableCell>
                  <TableCell sx={{ ...tableDataCellStyle }}>
                    {printStringValue(borrowingDetails?.tenureInMonths)}
                  </TableCell>
                  <TableCell sx={{ ...tableDataCellStyle }}>
                    {borrowingDetails?.roi
                      ? `${printStringValue(borrowingDetails?.roi)} % `
                      : '-'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Divider light />
        <Grid item xs={12} sx={{ pt: 2, pb: 2 }}>
          {borrowingDetails?.isLoanApproved && (
            <>
              <Typography color={colorSuccess} fontWeight={'600'}>
                Approved
              </Typography>
              <ApprovedLoanForm />
            </>
          )}
          {!borrowingDetails?.isLoanApproved && (
            <>
              <Typography fontWeight={'600'}>Credit</Typography>
              <CreditForm flagCount={flagCount} />
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

const CreditForm = ({ flagCount }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    isCaseDecisioningLoading,
    isCaseDecisioningSuccess,
    isCaseDecisioningFailed,
    caseDecisioningErrorContainer,
    caseSummaryDetails,
    totalPoolAmount,
    totalAllocatedAmount
  } = useSelector(state => state.caseSummary);

  const borrowingDetails = caseSummaryDetails['borrowingDetails'];

  const amountToBeApproved = calculateAmountTOBeApproved(
    totalPoolAmount,
    totalAllocatedAmount,
    borrowingDetails?.amount
  );

  const formik = useFormik({
    initialValues: {
      id: borrowingDetails?.id,
      finalAmount: '',
      finalTenure: '',
      finalROI: '',
      creditStatus: '',
      remark: '',
      is_loan_reopen: ''
    },
    validationSchema: yup.object({
      finalTenure: yup
        .number()
        .required('Please provide the required tenure.')
        .positive('The tenure must be positive.')
        .moreThan(
          Number(9),
          `The tenure must be greater than ${borrowingDetails?.tenureInMonths} Months`
        )
        .max(12, 'Maximum tenure should be 12 Months')
        .min(3, 'Minimum tenure should be 3 Months'),
      finalAmount: yup
        .number()
        .required('Please enter the necessary amount to be approved.')
        .positive('Amount to be approved must be positive.')
        .moreThan(0, 'Amount to be approved must be greater than 0 rupees')
        .max(
          amountToBeApproved,
          `Amount to be approved must not exceed ${formatINR(
            amountToBeApproved
          )} rupees`
        ),
      finalROI: yup
        .number()
        .required('Please enter the ROI.')
        .positive('The ROI must be positive.')
        .moreThan(0, 'The ROI must be greater than 0')
        .max(24, 'Maximum tenure should be 24%'),
      creditStatus: yup
        .string()
        .required('Please select credit status approve/reject.'),
      remark: yup.string().required('Please enter the remark.')
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: values => {
      const newValues = {
        id: values?.id,
        approvedAmount: values?.finalAmount,
        approvedTenureInMonths: values?.finalTenure,
        approvedRoi: values?.finalROI,
        isLoanApproved: values?.creditStatus === 'approve' ? true : false,
        remarksFromCreditDecisioning: values?.remark,
        isLoanReopen: '',
        creditStatus: values?.creditStatus
      };

      if (
        borrowingDetails?.isLoanApproved !== null &&
        borrowingDetails?.isLoanApproved === false
      ) {
        newValues['isLoanReopen'] = true;
      }
      dispatch(sendCreditDecisioning(newValues));
    }
  });

  const isReopen = () => {
    return (
      borrowingDetails?.isLoanApproved !== null &&
      borrowingDetails?.isLoanApproved === false
    );
  };

  const handleLoanAmount = amount => {
    formik.setFieldValue('finalAmount', enterOnlyNumbers(amount));
  };

  const handleReferenceTag = value => {
    formik.setFieldValue('creditStatus', value);
  };

  const handleTenureChange = value => {
    if (Number(borrowingDetails?.tenureInMonths) > value) {
      formik.setFieldError(
        'finalTenure',
        `The tenure must be greater than ${borrowingDetails?.tenureInMonths} Months`
      );
      return;
    } else {
      formik.setFieldValue('finalTenure', value);
    }
  };

  return (
    <>
      <Box sx={{ marginTop: '2rem' }}>
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          <Grid container spacing={1} mb={2}>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <FormControl
                variant="outlined"
                sx={{ ...formControl, width: '90%' }}
                fullWidth
              >
                <TextField
                  label="Final Amount(₹)"
                  required
                  name="finalAmount"
                  type="tel"
                  inputProps={{ maxLength: 15 }}
                  value={formik.values.finalAmount}
                  onChange={e => handleLoanAmount(e.target.value)}
                  onBlur={formik.handleBlur}
                  error={Boolean(
                    formik.touched.finalAmount && formik.errors.finalAmount
                  )}
                  helperText={
                    formik.touched.finalAmount && formik.errors.finalAmount
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <FormControl sx={{ ...formControl, width: '90%' }} fullWidth>
                <InputLabel id="final_tenure" required>
                  Final Tenure
                </InputLabel>
                <Select
                  required
                  labelId="final_tenure"
                  id="final_tenure"
                  label="Final Tenure"
                  value={formik.values.finalTenure}
                  onChange={e => handleTenureChange(e.target.value)}
                  onBlur={formik.handleBlur}
                  name="finalTenure"
                  placeholder="Final Tenure"
                  sx={{ textAlign: 'left' }}
                >
                  {isArrayNotEmpty(TENURE_LIST) &&
                    TENURE_LIST.map((tenure, _idx) => {
                      return (
                        <MenuItem
                          key={`${_idx}_${tenure}`}
                          value={tenure}
                          disabled={
                            Number(borrowingDetails?.tenureInMonths) > tenure
                          }
                        >
                          <em style={{ fontStyle: 'normal' }}>{tenure}</em>
                        </MenuItem>
                      );
                    })}
                </Select>
                {formik.touched.finalTenure && formik.errors.finalTenure && (
                  <FormHelperText sx={{ textAlign: 'start' }}>
                    {formik.errors.finalTenure}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <FormControl
                sx={{ ...formControl, width: '90%' }}
                fullWidth
                size="medium"
              >
                <TextField
                  inputProps={{ maxLength: 2 }}
                  label="Final ROI (%)"
                  name="finalROI"
                  type="tel"
                  onChange={e =>
                    formik.setFieldValue(
                      'finalROI',
                      enterOnlyNumbers(e.target.value)
                    )
                  }
                  onBlur={formik.handleBlur}
                  required
                  value={formik.values.finalROI}
                  error={Boolean(
                    formik.touched.finalROI && formik.errors.finalROI
                  )}
                  helperText={formik.touched.finalROI && formik.errors.finalROI}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Divider light />

          <Grid
            container
            sx={{ mt: 2 }}
            display={'flex'}
            flexDirection={'column'}
          >
            <Grid item xs={12} sm={4} alignSelf={'start'}>
              <FormControl sx={{}} size="small">
                <RadioGroup
                  name="creditStatus"
                  value={formik.values.creditStatus}
                  onChange={e => handleReferenceTag(e.target.value)}
                >
                  <Grid container justifyContent="center">
                    {flagCount !== 7 && (
                      <Grid item>
                        <FormControlLabel
                          name="creditStatus"
                          value="approve"
                          control={<Radio color="golden" />}
                          label={
                            <Typography sx={labelStyle}>
                              {isReopen() ? 'Re-Approve' : 'Approve'}
                            </Typography>
                          }
                        />
                      </Grid>
                    )}
                    <Grid item>
                      <FormControlLabel
                        name="creditStatus"
                        value="reject"
                        control={<Radio color="golden" />}
                        label={
                          <Typography sx={labelStyle}>
                            {isReopen() ? 'Re-Reject' : 'Reject'}
                          </Typography>
                        }
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
                {formik.touched.creditStatus && formik.errors.creditStatus && (
                  <FormHelperText sx={{ textAlign: 'center' }}>
                    {formik.errors.creditStatus}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} mb={2} marginTop={2}>
              <FormControl sx={formControl} fullWidth size="small">
                <TextField
                  inputProps={{ maxLength: 255 }}
                  size="medium"
                  label="Remarks"
                  name="remarks"
                  multiline
                  rows={3}
                  onChange={e => {
                    formik.setFieldValue(
                      'remark',
                      enterOnlyAlphabates(e.target.value)
                    );
                  }}
                  required
                  value={formik.values.remark}
                  error={Boolean(formik.touched.remark && formik.errors.remark)}
                  helperText={formik.touched.remark && formik.errors.remark}
                />
              </FormControl>
            </Grid>

            <Divider light />
            <Grid item xs={12} sm={4} mt={2}>
              <FormButtonBottom sx={formControl}>
                <LoadingButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  color="primary"
                  sx={{
                    height: 45,
                    fontWeight: '600',
                    textTransform: 'uppercase'
                  }}
                >
                  Save
                </LoadingButton>
              </FormButtonBottom>
            </Grid>
          </Grid>
        </form>

        {isCaseDecisioningLoading && (
          <Loader key="case_decisioning" text="Submitting case decision" />
        )}

        {!isCaseDecisioningLoading && isCaseDecisioningFailed && (
          <SnackBarBox
            type="error"
            message={handleError(caseDecisioningErrorContainer.errorMessage)}
          />
        )}
      </Box>

      {!isCaseDecisioningLoading && isCaseDecisioningSuccess && (
        <ModalDialog fullwidth={true}>
          <Box>
            <div style={successArchStyle}></div>
            <img src="/images/success.png" alt="success" style={successStyle} />
            <Typography
              align="center"
              variant="body1"
              fontWeight={600}
              sx={{
                fontSize: '1rem',
                color: '#5EAC1B',
                marginTop: '150px'
              }}
            >
              The decision has been successfully submitted.
            </Typography>
            <LoadingButton
              fullWidth
              style={{
                textTransform: 'uppercase',
                marginTop: '3.5rem',
                textAlign: 'center',
                justifyContent: 'center'
              }}
              variant="contained"
              size="small"
              onClick={() => {
                navigate('/credit-dashboard');
                return;
              }}
            >
              Ok
            </LoadingButton>
          </Box>
        </ModalDialog>
      )}
    </>
  );
};
const ApprovedLoanForm = () => {
  const tableDataCellStyle = { fontWeight: '600', fontSize: 15 };
  const tableHeaderCellStyle = { fontWeight: '600', fontSize: 16 };

  const { caseSummaryDetails, totalPoolAmount, totalAllocatedAmount } =
    useSelector(state => state.caseSummary);

  const borrowingDetails = caseSummaryDetails['borrowingDetails'];

  return (
    <>
      <Box sx={{ marginTop: '2rem' }}>
        <TableContainer
          component={Paper}
          sx={{ mt: 1, border: `1px solid ${colorMidLightGray}` }}
          elevation={0}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead
              sx={{
                backgroundColor: '#d1edb9',
                borderBottom: `1px solid ${colorMidLightGray}`
              }}
            >
              <TableRow>
                <TableCell sx={{ ...tableHeaderCellStyle }}>
                  Pool Amount
                </TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle }}>
                  Allocated Amount
                </TableCell>

                <TableCell sx={{ ...tableHeaderCellStyle }}>
                  Approved Amount
                </TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle }}>
                  Approved Tenure (in month)
                </TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle }}>ROI (%)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ ...tableDataCellStyle }}
                >
                  {formatINR(totalPoolAmount)}
                </TableCell>
                <TableCell sx={{ ...tableDataCellStyle }}>
                  {formatINR(totalAllocatedAmount)}
                </TableCell>
                <TableCell sx={{ ...tableDataCellStyle }}>
                  {formatINR(borrowingDetails?.approvedAmount)}
                </TableCell>
                <TableCell sx={{ ...tableDataCellStyle }}>
                  {printStringValue(borrowingDetails?.approvedTenureInMonths)}
                </TableCell>
                <TableCell sx={{ ...tableDataCellStyle }}>
                  {borrowingDetails?.approvedRoi
                    ? `${printStringValue(borrowingDetails?.approvedRoi)} % `
                    : '-'}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

Decisioning.propTypes = {
  flagCount: PropTypes.any
};

export default Decisioning;
