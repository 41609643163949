import { Box, FormControl } from '@mui/material';
import GenericInput from '../../../components/GenericInput';
import { enterOnlyNumbers } from '../../../utils/utils';
import SmartphoneOutlinedIcon from '@mui/icons-material/SmartphoneOutlined';
import { LoadingButton } from '@mui/lab';
import ShowBackToLoginButton from './ShowBackToLoginButton';
import PropTypes from 'prop-types';

const formControl = {
  margin: '0.5rem auto'
};

function ForgotPasswordForm({ formik }) {
  return (
    <Box sx={{ marginTop: '2rem' }}>
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        <FormControl sx={formControl} fullWidth>
          <GenericInput
            required
            label="Mobile number"
            name="mobileNo"
            size="small"
            inputProps={{ maxLength: 10 }}
            value={formik.values.mobileNo}
            onChange={e =>
              formik.setFieldValue('mobileNo', enterOnlyNumbers(e.target.value))
            }
            error={formik.touched.mobileNo && formik.errors.mobileNo}
            startIcon={<SmartphoneOutlinedIcon className="icon_color" />}
          />
        </FormControl>

        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          size="medium"
          sx={{
            mt: 2,
            fontSize: 15,
            fontWeight: '600',
            textTransform: 'uppercase'
          }}
        >
          Send Otp
        </LoadingButton>
        <ShowBackToLoginButton />
      </form>
    </Box>
  );
}

ForgotPasswordForm.propTypes = {
  formik: PropTypes.any
};

export default ForgotPasswordForm;
