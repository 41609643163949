import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api, multipartApi } from '../../config/api';

export const revertAllAdminReport = createAction('REVERT_ALL_ADMIN_REPORT');

const initial = {
  isUserLevelListLoading: false,
  isUserLevelListSuccess: false,
  isUserLevelListFailed: false,
  isUserLevelListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isMappedUserListLoading: false,
  isMappedUserListSuccess: false,
  isMappedUserListFailed: false,
  isMappedUserList: [],
  isMappedUserListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isMaturityDateLoading: false,
  isMaturityDateSuccess: false,
  isMaturityDateFailed: false,
  isMaturityDateList: [],
  isMaturityDateErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isReportDateLoading: false,
  isReportDateSuccess: false,
  isReportDateFailed: false,
  isReportDateList: [],
  isReportDateErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isAdminReportListLoading: false,
  isAdminReportListSuccess: false,
  isAdminReportListFailed: false,
  adminReportData: {
    totalCount: 0,
    adminReportList: []
  },
  adminReportErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  }
};

export const fetchUserLevel = createAsyncThunk(
  'admin/IdentifierUserLevel',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `/dropDown/identifier?dropdownIdentifier=userLevel`
      );

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchReportAdmin = createAsyncThunk(
  'admin/reportAdmin',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post(`/reports/admin`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMappedUser = createAsyncThunk(
  'admin/fetchMappedUser',
  async (level, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `/dropDown/identifier?dropdownIdentifier=mappedUser&mappedUserType=${level}`
      );

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAdminReportMaturityDate = createAsyncThunk(
  'admin/fetchAdminReportMaturityDate',
  async (level, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `/dropDown/identifier?dropdownIdentifier=${level}`
      );

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAdminReportDate = createAsyncThunk(
  'admin/fetchAdminReportDate',
  async (level, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `/dropDown/identifier?dropdownIdentifier=${level}`
      );

      return response.data || null;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const AdminReportSlice = createSlice({
  name: 'adminReport',
  initialState: initial,
  extraReducers: builder => {
    builder
      .addCase(revertAllAdminReport, state => {
        state.isAdminReportListLoading = false;
        state.isAdminReportListSuccess = false;
        state.isAdminReportListFailed = false;
        state.adminReportData = {
          totalCount: 0,
          adminReportList: []
        };
        state.adminReportErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };

        (state.isMappedUserListLoading = false),
          (state.isMappedUserListSuccess = false),
          (state.isMappedUserListFailed = false),
          (state.isMappedUserList = []),
          (state.isMappedUserListErrorContainer = {
            error: false,
            errorMessage: 'Internal Server Error',
            description: 'Something Went Wrong',
            statusCode: 0
          });
      })
      .addCase(fetchUserLevel.pending, state => {
        state.isUserLevelListLoading = true;
        state.isUserLevelListSuccess = false;
        state.isUserLevelListFailed = false;
        state.isUserLevelListErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(fetchUserLevel.fulfilled, (state, action) => {
        state.isUserLevelListLoading = false;
        state.isUserLevelListSuccess = true;
        state.isUserLevelList = action.payload || '';
        state.isUserLevelListFailed = false;
      })

      .addCase(fetchUserLevel.rejected, (state, action) => {
        state.isUserLevelListLoading = false;
        state.isUserLevelListSuccess = false;
        state.isUserLevelListFailed = true;
        state.isUserLevelList = initial.isUserLevelList;

        state.isUserLevelListErrorContainer = {
          ...state.isUserLevelListErrorContainer,
          ...action.payload
        };
      })

      .addCase(fetchMappedUser.pending, state => {
        state.isMappedUserListLoading = true;
        state.isMappedUserListSuccess = false;
        state.isMappedUserListFailed = false;
        state.isMappedUserListErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(fetchMappedUser.fulfilled, (state, action) => {
        state.isMappedUserListLoading = false;
        state.isMappedUserListSuccess = true;
        state.isMappedUserList = action.payload || '';
        state.isMappedUserListFailed = false;
      })

      .addCase(fetchMappedUser.rejected, (state, action) => {
        state.isMappedUserListLoading = false;
        state.isMappedUserListSuccess = false;
        state.isMappedUserListFailed = true;
        state.isMappedUserList = initial.isMappedUserList;

        state.isMappedUserListErrorContainer = {
          ...state.isMappedUserListErrorContainer,
          ...action.payload
        };
      })
      .addCase(fetchAdminReportMaturityDate.pending, state => {
        state.isMaturityDateLoading = true;
        state.isMaturityDateSuccess = false;
        state.isMaturityDateFailed = false;
        state.isMaturityDateErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(fetchAdminReportMaturityDate.fulfilled, (state, action) => {
        state.isMaturityDateLoading = false;
        state.isMaturityDateSuccess = true;
        state.isMaturityDateList = action.payload || '';
        state.isMaturityDateFailed = false;
      })

      .addCase(fetchAdminReportMaturityDate.rejected, (state, action) => {
        state.isMaturityDateLoading = false;
        state.isMaturityDateSuccess = false;
        state.isMaturityDateFailed = true;
        state.isMaturityDateList = initial.isMaturityDateList;

        state.isMaturityDateErrorContainer = {
          ...state.isMaturityDateErrorContainer,
          ...action.payload
        };
      })
      .addCase(fetchAdminReportDate.pending, state => {
        state.isReportDateLoading = true;
        state.isReportDateSuccess = false;
        state.isReportDateFailed = false;
        state.isReportDateErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(fetchAdminReportDate.fulfilled, (state, action) => {
        state.isReportDateLoading = false;
        state.isReportDateSuccess = true;
        state.isReportDateList = action.payload || '';
        state.isReportDateFailed = false;
      })

      .addCase(fetchAdminReportDate.rejected, (state, action) => {
        state.isReportDateLoading = false;
        state.isReportDateSuccess = false;
        state.isReportDateFailed = true;
        state.isReportDateList = initial.iisReportDateList;

        state.isReportDateErrorContainer = {
          ...state.isReportDateErrorContainer,
          ...action.payload
        };
      })
      .addCase(fetchReportAdmin.pending, state => {
        state.isAdminReportListLoading = true;
        state.isAdminReportListSuccess = false;
        state.isAdminReportListFailed = false;
        state.adminReportErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })

      .addCase(fetchReportAdmin.fulfilled, (state, action) => {
        state.isAdminReportListLoading = false;
        state.isAdminReportListSuccess = true;
        state.adminReportData = {
          totalCount: action.payload?.numberOfElements,
          adminReportList: action.payload?.content
        };
        state.isAdminReportListFailed = false;
      })

      .addCase(fetchReportAdmin.rejected, (state, action) => {
        state.isAdminReportListLoading = false;
        state.isAdminReportListSuccess = false;
        state.isAdminReportListFailed = true;
        state.adminReportData = initial.adminReportData;
        state.adminReportErrorContainer = {
          ...state.adminReportErrorContainer,
          ...action.payload
        };
      });
  }
});

export default AdminReportSlice.reducer;
