import React, { useEffect, useMemo } from 'react';
import { Grid, Card, CardContent, Typography, Box } from '@mui/material';
import { StyledGrid } from '../../../utils/styledLayout';
import { colorGolden, colorPrimaryBlue } from '../../../config/theme';
import AgeGroupBarChart from './charts/AgeWiseInvestorBorrower';
import MonthlyInvestmentBorrowingLineChart from './charts/MonthWiseInvestorBorower';
import {
  getPoolAmount,
  revertPoolAmt
} from '../../../redux/reducers/ChartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { createTitle } from '../../../utils/utils';
import RegionWiseBarChart from './charts/RegionWise';

const UserDashboard = () => {
  const {
    isPoolAmtLoading,
    isPoolAmtSuccess,
    isPoolAmtFailed,
    poolAmt,
    isPoolAmtErrorContainer
  } = useSelector(state => state.chartReport);

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = createTitle('Dashboard');

    dispatch(getPoolAmount());
    return () => {
      dispatch(revertPoolAmt());
    };
  }, [dispatch]);

  const poolBalance = useMemo(() => {
    return poolAmt?.data?.balance;
  }, [poolAmt]);

  return (
    <StyledGrid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Box
        sx={{
          borderRadius: 5,
          backgroundColor: 'white',
          width: '100%',
          boxShadow: '0px 1px 17px -5px rgba(0,0,0,0.67)',
          paddingLeft: 1,
          paddingRight: 1,
          padding: 4
        }}
      >
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ color: 'black' }}
        >
          <Grid item>
            <Typography sx={{ color: 'black' }} variant="subtitle2">
              Total Pool amount :
              <span style={{ fontWeight: 'bold', color: 'black' }}>
                {' '}
                {poolBalance}
              </span>
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle2"
              sx={{ color: 'black', marginLeft: '30px' }}
            >
              Interest wallet balance :
              <span style={{ fontWeight: 'bold', color: 'black' }}>
                {' '}
                ₹8,00,000{' '}
              </span>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 20 }}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <MonthlyInvestmentBorrowingLineChart />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={6}>
            <Card>
              <CardContent>
                <AgeGroupBarChart />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card>
              <CardContent>
                <RegionWiseBarChart />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </StyledGrid>
  );
};

export default UserDashboard;
