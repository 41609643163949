import { combineReducers } from 'redux';
import authSlice from './reducers/authSlice';
import layoutSlice from './reducers/layoutSlice';
import sessionSlice from './reducers/sessionSlice';
import menuSlice from './reducers/menuSlice';
import casesummarySlice from './reducers/casesummarySlice';
import adminSlice from './reducers/adminSlice';
import EmployeeL1Slice from './reducers/EmployeeL1Slice';
import EmployeeL2Slice from './reducers/EmployeeL2Slice';
import EmployeeL3Slice from './reducers/EmployeeL3Slice';
import AdminReportSlice from './reducers/ReportAdminSlice';
import ChartSlice from './reducers/ChartSlice';

const reducers = {
  auth: authSlice,
  layout: layoutSlice,
  session: sessionSlice,
  menu: menuSlice,
  caseSummary: casesummarySlice,
  admin: adminSlice,
  employeeL1: EmployeeL1Slice,
  employeeL2: EmployeeL2Slice,
  employeeL3: EmployeeL3Slice,
  adminReport: AdminReportSlice,
  chartReport: ChartSlice
};

export default combineReducers(reducers);
