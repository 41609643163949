import { Skeleton } from '@mui/material';

function SkeletonBars() {
  return (
    <>
      <Skeleton
        sx={{ margin: 1 }}
        width={'100%'}
        variant="rounded"
        height={50}
        animation="wave"
      />
      <Skeleton
        sx={{ margin: 1 }}
        width={'100%'}
        variant="rounded"
        height={50}
        animation="wave"
      />
      <Skeleton
        sx={{ margin: 1 }}
        width={'100%'}
        variant="rounded"
        height={50}
        animation="wave"
      />
      <Skeleton
        sx={{ margin: 1 }}
        width={'100%'}
        variant="rounded"
        height={50}
        animation="wave"
      />
      <Skeleton
        sx={{ margin: 1 }}
        width={'100%'}
        variant="rounded"
        height={50}
        animation="wave"
      />
      <Skeleton
        sx={{ margin: 1 }}
        width={'100%'}
        variant="rounded"
        height={50}
        animation="wave"
      />
      <Skeleton
        sx={{ margin: 1 }}
        width={'100%'}
        variant="rounded"
        height={50}
        animation="wave"
      />
      <Skeleton
        sx={{ margin: 1 }}
        width={'100%'}
        variant="rounded"
        height={50}
        animation="wave"
      />
      <Skeleton
        sx={{ margin: 1 }}
        width={'100%'}
        variant="rounded"
        height={50}
        animation="wave"
      />
    </>
  );
}

export default SkeletonBars;
