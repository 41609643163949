import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { colorGolden } from '../config/theme';
import PropTypes from 'prop-types';

function AccordionComponent({
  title,
  children,
  titleStyleProp,
  icon,
  ...props
}) {
  return (
    <Accordion
      className="accordian"
      sx={{
        marginTop: '1rem',
        boxShadow: '0px 3px 10px 0px rgba(128, 128, 128, 1)',
        borderRadius: '10px!important',
        border: 'none!important'
      }}
      elevation={0}
      {...props}
    >
      <AccordionSummary
        expandIcon={
          icon ? (
            icon
          ) : (
            <ExpandMoreIcon sx={{ color: colorGolden, fontSize: '2rem' }} />
          )
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography
          variant="subtitle2"
          fontWeight={600}
          style={{ ...titleStyleProp }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

AccordionComponent.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  titleStyleProp: PropTypes.any,
  icon: PropTypes.any
};

export default AccordionComponent;
