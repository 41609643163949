import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';

const ModalDialog = ({
  fullwidth,
  children,
  isShowTitle = false,
  titleName = '',
  size = 'xs'
}) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      fullWidth={fullwidth}
      maxWidth={size}
      open={open}
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      disableEscapeKeyDown
      scroll="body"
      className="custom-dialog"
    >
      {isShowTitle && (
        <DialogTitle id="max-width-dialog-title">
          <Grid container spacing={2}>
            <Grid item xs={11}>
              <Typography
                variant="body1"
                fontSize={18}
                fontWeight={600}
                paddingTop={1}
                paddingBottom={1}
                align="left"
              >
                {titleName}
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
      )}

      <DialogContent className="MuiDialogContentRootc">
        {children}
      </DialogContent>
    </Dialog>
  );
};

ModalDialog.propTypes = {
  fullwidth: PropTypes.bool,
  children: PropTypes.any,
  isShowTitle: PropTypes.bool,
  titleName: PropTypes.string
};

export default ModalDialog;
