import React, { useEffect, useMemo } from 'react';
import { Grid, Card, CardContent, Typography, Box } from '@mui/material';
import { StyledGrid } from '../../../utils/styledLayout';
import Chart from 'react-apexcharts';
import { colorGolden, colorPrimaryBlue } from '../../../config/theme';
import AgeGroupBarChart from './charts/AgeWiseInvestorBorrower';
import TotalBusinessWiseLineChart from './charts/TotalBusinessWiseInvestorBorrower';
import BusinessWiseBarChart from './charts/BussinessWiseInvestorBorrower';
import MonthlyInvestmentBorrowingLineChart from './charts/MonthWiseInvestorBorower';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPoolAmount,
  revertPoolAmt
} from '../../../redux/reducers/ChartSlice';
import { createTitle } from '../../../utils/utils';
import RegionWiseBarChart from './charts/RegionWise';

const Dashboard = () => {
  const {
    isPoolAmtLoading,
    isPoolAmtSuccess,
    isPoolAmtFailed,
    poolAmt,
    isPoolAmtErrorContainer
  } = useSelector(state => state.chartReport);

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = createTitle('Dashboard');

    dispatch(getPoolAmount());
    return () => {
      dispatch(revertPoolAmt());
    };
  }, [dispatch]);

  const poolBalance = useMemo(() => {
    return poolAmt?.data?.balance;
  }, [poolAmt]);

  return (
    <StyledGrid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Box
        sx={{
          borderRadius: 5,
          backgroundColor: 'white',
          width: '100%',
          boxShadow: '0px 1px 17px -5px rgba(0,0,0,0.67)',
          paddingLeft: 1,
          paddingRight: 1,
          padding: 4
        }}
      >
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ color: 'black' }}
        >
          <Grid item>
            <Typography sx={{ color: 'black' }} variant="subtitle2">
              Total Pool amount :
              <span style={{ fontWeight: 'bold', color: 'black' }}>
                {' '}
                {poolBalance}
              </span>
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle2"
              sx={{ color: 'black', marginLeft: '30px' }}
            >
              Interest wallet balance :
              <span style={{ fontWeight: 'bold', color: 'black' }}> </span>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 20 }}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <MonthlyInvestmentBorrowingLineChart />
              </CardContent>
            </Card>
          </Grid>

          {/* Two graphs on the second row */}
          <Grid item xs={6}>
            <Card>
              <CardContent>
                <TotalBusinessWiseLineChart />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card>
              <CardContent>
                {/* <Typography variant="h6">Graph 2</Typography> */}
                <AgeGroupBarChart />
                {/* Graph 2 Component Here */}
              </CardContent>
            </Card>
          </Grid>

          {/* Two graphs on the third row */}
          <Grid item xs={6}>
            <Card>
              <CardContent>
                <RegionWiseBarChart />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card>
              <CardContent>
                <BusinessWiseBarChart />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </StyledGrid>
  );
};

export default Dashboard;
