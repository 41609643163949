import { Backdrop, CircularProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { colorWhite } from '../config/theme';

function Loader({ text = '' }) {
  return (
    <Backdrop sx={{ zIndex: 5, color: colorWhite }} open={true}>
      <div>
        <CircularProgress
          sx={{ alignItems: 'center' }}
          color="golden"
          size={60}
        />
        <Typography align="center" sx={{ color: 'white' }} fontWeight={600}>
          {/* {text} */}
        </Typography>
      </div>
    </Backdrop>
  );
}
Loader.propTypes = {
  text: PropTypes.string
};
export default Loader;
