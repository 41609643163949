import { configureStore } from '@reduxjs/toolkit';
import reducers from './reducers';
import logger from 'redux-logger';
import { getToken } from '../config/cookie';
import { api, multipartApi } from '../config/api';
import { setSessionExpired } from './reducers/sessionSlice';
import { thunk } from 'redux-thunk';

const devMode = process.env.NODE_ENV === 'development';

const tokenMiddleware = store => next => action => {
  const token = getToken();

  if (action.error && action?.payload) {
    console.error('Error caught :: > ', action?.payload);
  }

  const isNotAuth = !action?.type?.startsWith('auth');
  const isNotRegistration = !action?.type?.startsWith('registration');
  const isNotToken = token !== null;

  if (isNotAuth && isNotRegistration && isNotToken) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    multipartApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  if (
    action.error &&
    action?.payload?.errorMessage &&
    action?.payload?.errorMessage.toString().includes('JWT')
  ) {
    store.dispatch(setSessionExpired());
  } else {
    return next(action);
  }
};

// const middlewares = [tokenMiddleware, thunk, devMode && logger].filter(Boolean);
const middlewares = [tokenMiddleware, thunk].filter(Boolean);

export const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(...middlewares),
  devTools: devMode
});
